import { Link, Route, Switch } from 'react-router-dom';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { RETAIL, RETAILCUSTOMERS, RETAILREGISTER } from '../../constants/Routes';

import { Header } from '@r3/cbdc-asset-frontend-core';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React from 'react';
import { RedirectWithTabIndex } from '@r3/cbdc-asset-frontend-core';
import RegisterRetailUser from './RegisterRetailUser';
import RetailCustomers from './RetailCustomers/RetailCustomers';
import { useLocation } from 'react-router-dom';
import { useTabStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';
import { ExitToApp } from '@material-ui/icons';

const ManageRetail: React.FC = () => {
    const tabClasses = useTabStyles();
    const { t } = useTranslation();
    const location = useLocation<RedirectWithTabIndex>();
    return (
        <>
            <Header>{t('pageTitle.retail')}</Header>
            <Paper elevation={2} className={`${tabClasses.wrapper} `}>
                <Tabs
                    className={`${tabClasses.root} tabs`}
                    classes={{ flexContainer: tabClasses.tabContainer }}
                    value={location.pathname}
                >
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.customers')}
                        icon={<PeopleRoundedIcon />}
                        value={RETAILCUSTOMERS}
                        component={Link}
                        to={RETAILCUSTOMERS}
                    />
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.registerRetailUser')}
                        icon={<PersonAddIcon />}
                        value={RETAILREGISTER}
                        component={Link}
                        to={RETAILREGISTER}
                    />
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.retailLogin')}
                        icon={<ExitToApp />}
                        value={RETAIL}
                        href={RETAIL}
                        target="_blank"
                    />
                </Tabs>
                <Switch>
                    <Route path={RETAILCUSTOMERS} component={RetailCustomers} />
                    <Route path={RETAILREGISTER} component={RegisterRetailUser} />
                </Switch>
            </Paper>
        </>
    );
};

export default ManageRetail;
