import { Asset, NumberFormat, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { Divider, Paper, Typography } from '@material-ui/core';

import SummaryRow from 'components/commonComponents/SummaryComponents/SummaryRow';
import SummaryTable from 'components/commonComponents/SummaryComponents/SummaryTable';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type ReviewRequestStepProps = {
    selectedParticipant: string;
    swapAsset: Asset;
    selectedAsset: SummedAsset;
    swapAssetAmount: string;
    assetAmount: string;
    assetInVaultAmount: number | undefined;
};

const ReviewRequestStep: React.FC<ReviewRequestStepProps> = (props) => {
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();

    const { selectedParticipant, swapAssetAmount, swapAsset, selectedAsset, assetAmount } = props;

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('crossChainSwap.summaryMessage')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.text}`} style={{ marginTop: 12, marginBottom: 12 }}>
                    {t('transferAssets.receivingParty') + ': '} {selectedParticipant}
                </Typography>

                <SummaryTable>
                    <SummaryRow
                        col1Text={t('crossChainSwap.requestingAmount') + ` of ${swapAsset.tokenName}`}
                        col2Text={NumberFormat.addThousandSeparators(
                            parseFloat(swapAssetAmount).toFixed(swapAsset.tokenDecimals)
                        )}
                    />
                    <SummaryRow
                        col1Text={t('crossChainSwap.offeringAmount') + ` of ${selectedAsset.tokenName}`}
                        col2Text={NumberFormat.addThousandSeparators(
                            parseFloat(assetAmount).toFixed(selectedAsset.tokenDecimal)
                        )}
                    />
                    <SummaryRow
                        col1Text={t('transactionSummary.assetRemainingAfterTransaction', {
                            assetName: selectedAsset.tokenName,
                        })}
                        col2Text={NumberFormat.addThousandSeparators(
                            (selectedAsset.totalInVault - parseFloat(assetAmount)).toFixed(selectedAsset.tokenDecimal)
                        )}
                    />
                </SummaryTable>
            </Paper>
        </div>
    );
};

export default ReviewRequestStep;
