import { DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';
import React, { useState } from 'react';

import CropFreeRoundedIcon from '@material-ui/icons/CropFreeRounded';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton } from '@material-ui/core';
import { NavigatorExtensions } from '@r3/cbdc-asset-frontend-core';
import ShareAccountDetails from './ShareAccountDetails/ShareAccountDetails';
import { useRetailHomeStyles } from 'materialStyles/retailStyles/retailHomeStyles';
import { useUserContextProvider } from '../../contexts/RetailUserLogin/RetailUserContext';

const RetailHeader: React.FC = () => {
    const retailHomeClasses = useRetailHomeStyles();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const userContext = useUserContextProvider();
    const accountId = userContext?.customerDetails?.accountId ? userContext?.customerDetails.accountId : '';
    const customerName = userContext?.customerDetails?.customerName ? userContext?.customerDetails.customerName : '';
    return (
        <div>
            <h1 className={retailHomeClasses.userTitle}>{'Hi ' + customerName}</h1>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <p className={retailHomeClasses.accountId}>Account ID: {accountId}</p>
                <IconButton
                    className={retailHomeClasses.headerButton}
                    onClick={() => NavigatorExtensions.copyToClipBoard(accountId)}
                >
                    Copy
                    <FileCopyIcon className={retailHomeClasses.headerIcon} />
                </IconButton>
                <IconButton
                    className={retailHomeClasses.headerButton}
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                >
                    QR
                    <CropFreeRoundedIcon className={retailHomeClasses.headerIcon} />
                </IconButton>
            </div>
            <DialogWindow
                open={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                title={'Share your account with QR code.'}
            >
                <DialogBody>
                    <ShareAccountDetails accountId={accountId} name={customerName} />
                </DialogBody>
            </DialogWindow>
        </div>
    );
};

export default RetailHeader;
