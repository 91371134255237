import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            loadingContainer: {
                display: 'flex',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            },
        }),
    { index: 1 }
);

const LoadingIcon = () => {
    const styles = useStyles();
    return (
        <div className={styles.loadingContainer}>
            <CircularProgress size={60} color="secondary" />
        </div>
    );
};
export default LoadingIcon;
