import {
    AVAILABLECBDC,
    REDEEMCBDC,
    REQUESTCBDC,
    REQUESTCROSSCHAINSWAP,
    REQUESTDVP,
    TRANSFERCBDC,
} from 'constants/Routes';
import { TabPageMap, TabsWrapper, insertIntoArrayIf, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import CachedIcon from '@material-ui/icons/Cached';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import React from 'react';
import { RedeemCBDC } from './RedeemCBDC/RedeemCBDC';
import { RequestCBDC } from './RequestCBDC/RequestCBDC';
import RequestCrossChainSwap from './RequestCrossChainSwap/RequestCrossChainSwap';
import { RequestDvp } from './RequestDvP/RequestDvP';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { TransferCBDC } from './TransferCBDC/TransferCBDC';
import { ViewAssets } from './ViewAssets/ViewAssets';
import { useTranslation } from 'react-i18next';

export const CBDCManager: React.FC = () => {
    const { t } = useTranslation();
    const { isFeatureEnabled } = useFeatureFlagContext();

    const tapPageMap: TabPageMap[] = [
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('viewAssets'), {
            route: AVAILABLECBDC,
            pageComponent: ViewAssets,
            tabDetails: { icon: <DashboardIcon />, label: t('pageTitle.availableAssets') },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('requestCBDC'), {
            route: REQUESTCBDC,
            pageComponent: RequestCBDC,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <MonetizationOnOutlinedIcon />
                    </div>
                ),
                label: t('pageTitle.requestCBDC'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('transferCBDC'), {
            route: TRANSFERCBDC,
            pageComponent: TransferCBDC,
            tabDetails: {
                icon: <SyncAltIcon />,
                label: t('pageTitle.transferAssets'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('redeemCBDC'), {
            route: REDEEMCBDC,
            pageComponent: RedeemCBDC,
            tabDetails: {
                icon: <CachedIcon />,
                label: t('pageTitle.redeemCBDC'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('dvp'), {
            route: REQUESTDVP,
            pageComponent: RequestDvp,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <SwapHorizontalCircleOutlinedIcon />
                    </div>
                ),
                label: t('pageTitle.requestDvP'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('crossChainSwap'), {
            route: REQUESTCROSSCHAINSWAP,
            pageComponent: RequestCrossChainSwap,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <ShuffleIcon />
                    </div>
                ),
                label: t('pageTitle.requestCrossChainSwap'),
            },
        }),
    ];

    return <TabsWrapper pageTitle={t('pageTitle.assets')} tabPageMap={tapPageMap} />;
};
