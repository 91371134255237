import { CustomerWithDetails } from '../../../hooks/GetCustomerDetails';
import { Typography } from '@material-ui/core';
import { NumberFormat } from '@r3/cbdc-asset-frontend-core';

export type CustomerDetailsProps = {
    customerWithDetails: CustomerWithDetails;
};

const CustomerDialogDetails: React.FC<CustomerDetailsProps> = ({ customerWithDetails }) => {
    return (
        <>
            <Typography gutterBottom>
                <b>Customer Name: </b> {customerWithDetails.customerName}
            </Typography>
            <Typography gutterBottom>
                <b>Customer Id: </b>
                {customerWithDetails.customerId}
            </Typography>
            <Typography gutterBottom>
                <b>Email: </b>
                {customerWithDetails.emailAddress}
            </Typography>
            <Typography gutterBottom>
                <b>Post Code: </b>
                {customerWithDetails.postcode}
            </Typography>
            <Typography gutterBottom>
                <b>Contact Number: </b>
                {customerWithDetails.contactNumber}
            </Typography>
            <Typography gutterBottom>
                <b>Account Name: </b>
                {customerWithDetails.accountName}
            </Typography>
            <Typography gutterBottom>
                <b>Account Id: </b>
                {customerWithDetails.accountId}
            </Typography>
            <Typography gutterBottom>
                <b>Account Key: </b>
                {customerWithDetails.accountKey}
            </Typography>

            <Typography gutterBottom>
                <b>Account Balances: </b>
                {customerWithDetails.accountBalances.map(
                    (acb, index) =>
                        `${acb.tokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                            acb.balance.toFixed(acb.tokenDefinition.decimals)
                        )}${index < customerWithDetails.accountBalances.length - 1 ? '  |  ' : ''}`
                )}
            </Typography>
            <Typography gutterBottom>
                <b>Previous Keys: </b>
                {customerWithDetails.previousKeys.join(', ')}
            </Typography>
            <Typography gutterBottom>
                <b>Last Updated: </b>
                {new Date(customerWithDetails.modifiedOn).toLocaleString()}
            </Typography>
            <Typography gutterBottom>
                <b>Created On: </b>
                {new Date(customerWithDetails.createdOn).toLocaleString()}
            </Typography>
        </>
    );
};

export default CustomerDialogDetails;
