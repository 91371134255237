import { NumberFormat, PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import { Typography } from '@material-ui/core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

type ConfirmPaymentDetailsProps = {
    paymentRequest: PaymentRequestResponse;
    payeeName: string | null;
    assetName: string;
    validAmount: boolean;
};

const ConfirmPaymentDetails: React.FC<ConfirmPaymentDetailsProps> = ({
    paymentRequest,
    payeeName,
    assetName,
    validAmount,
}) => {
    const classes = useLayoutStyles();
    return (
        <div>
            {payeeName && validAmount && (
                <Typography className={classes.text}>
                    <b>Payee Name: </b>
                    {payeeName}
                </Typography>
            )}
            {!validAmount && (
                <Typography className={classes.text} style={{ fontStyle: 'italic', marginBottom: 26, color: 'red' }}>
                    You do not have enough of {assetName} in your account to make this payment.
                </Typography>
            )}
            {!payeeName && validAmount && (
                <Typography className={classes.text} style={{ fontStyle: 'italic', marginBottom: 26, color: 'khaki' }}>
                    You do not have this payee added, make sure this is a trusted payee.
                </Typography>
            )}
            <Typography className={classes.text}>
                <b>Payee Account Id: </b>
                {paymentRequest.accountId}
            </Typography>
            <Typography className={classes.text}>
                <b>Bank Name: </b>
                {paymentRequest.host}
            </Typography>
            <Typography className={classes.text} style={{ marginTop: 20 }}>
                <b>Asset requested: </b>
                {assetName}
            </Typography>
            <Typography className={classes.text}>
                <b>Amount Requested: </b>
                {NumberFormat.addThousandSeparators(paymentRequest.amount)}
            </Typography>
            <Typography className={classes.text} style={{ marginTop: 20 }}>
                <b>Date Created: </b>
                {new Date(paymentRequest.invoiceDate).toLocaleString()}
            </Typography>
            <Typography className={classes.text}>
                <b>Request ID: </b>
                {paymentRequest.requestId}
            </Typography>
        </div>
    );
};

export default ConfirmPaymentDetails;
