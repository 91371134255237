import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useRetailLoginStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            qrDiv: {
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                flexDirection: 'column',
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            qrText: { textAlign: 'center', color: 'white', marginBottom: 16, fontSize: 18 },
        }),
    { index: 1 }
);
