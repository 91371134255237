import { TransferAssets } from './TransferAssets';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetAssetSummary from 'hooks/GetAssetSummary';

const TransferAssetsWithHook = () => {
    const { assets, getAllAssets, assetsError } = useGetAssetDefinitions(true);
    const { getAssetSummary, assetSummary, summaryError } = useGetAssetSummary(true);
    return (
        <TransferAssets
            assets={assets}
            getAllAssets={getAllAssets}
            assetsError={assetsError}
            getAssetSummary={getAssetSummary}
            assetSummary={assetSummary}
            summaryError={summaryError}
        />
    );
};

export default TransferAssetsWithHook;
