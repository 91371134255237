import { Fade, Paper } from '@material-ui/core';
import { approvePvpRequest, denyPvpRequest } from 'api/transactApi';
import { useEffect, useState } from 'react';

import { CrossChainSwapDialog } from './CrossChainSwapDialog';
import CrossChainSwapRequestsList from './CrossChainSwapRequestsList';
import { InfoWidget } from 'components/infoModal/InfoWidget/InfoWidget';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { PvpRequest } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from 'api/resolvePromise';
import { getPermittedNotaries } from 'components/cbdcManager/RequestCrossChainSwap/getPermittedNotaries';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useGetPvpRequests } from 'hooks/GetCrossChainSwapRequests';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type SelectedCrossChainSwapRequest = {
    crossChainSwapRequest: PvpRequest;
    ownRequest: boolean;
};

const CrossChainSwapRequests = () => {
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedCrossChainRequest, setSelectedCrossChainRequest] = useState<SelectedCrossChainSwapRequest | null>(
        null
    );
    const { getAllAssets, getAssetbyId } = useGetAssetDefinitions();
    const { pvpRequests, error, getCrossChainSwapRequests } = useGetPvpRequests();
    const [showInfoWidget, setShowInfoWidget] = useState<boolean>(false);
    const checklistContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { getSummedAssetById } = useGetAssetSummary(true);

    useEffect(() => {
        getAllAssets();
        getCrossChainSwapRequests();
    }, [getAllAssets, getCrossChainSwapRequests]);

    const submitApproval = async () => {
        if (!selectedCrossChainRequest) {
            return;
        }
        const approvalResponse: ResolvedPromise = await approvePvpRequest(
            selectedCrossChainRequest.crossChainSwapRequest.requestId
        );
        if (approvalResponse.error) {
            enqueueSnackbar(t('error.approveCrossChainSwap', { error: approvalResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.approveCrossChainSwap'), {
                variant: 'success',
            });
            checklistContext?.completeStep(location.pathname);
        }
    };

    const submitDeny = async () => {
        if (!selectedCrossChainRequest) {
            return;
        }
        const denyResponse: ResolvedPromise = await denyPvpRequest(
            selectedCrossChainRequest.crossChainSwapRequest.requestId
        );

        if (denyResponse.error) {
            enqueueSnackbar(t('error.denyCrossChainSwap', { error: denyResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.denyCrossChainSwap'), {
                variant: 'success',
            });
        }
    };

    const openInfoModal = (crossChainSwapRequest: PvpRequest, ourRequests: boolean) => {
        setSelectedCrossChainRequest({ crossChainSwapRequest: crossChainSwapRequest, ownRequest: ourRequests });
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedCrossChainRequest(null);
        setModalOpen(false);
    };

    const approve = async () => {
        handleClose();
        await submitApproval();
        getCrossChainSwapRequests();
        setShowInfoWidget(true);
    };

    const deny = async () => {
        handleClose();
        await submitDeny();
        getCrossChainSwapRequests();
        setShowInfoWidget(true);
    };

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column} `}>
                        {error !== null ? (
                            <ListHeader
                                text={error}
                                refreshAction={() => {
                                    getCrossChainSwapRequests();
                                }}
                            />
                        ) : (
                            <>
                                <ListHeader
                                    text={'Cross Chain Swap Requests'}
                                    refreshAction={getCrossChainSwapRequests}
                                />
                                <CrossChainSwapRequestsList
                                    pvpRequests={pvpRequests}
                                    getAssetById={getAssetbyId}
                                    openInfoModal={openInfoModal}
                                />
                                {selectedCrossChainRequest && modalOpen && (
                                    <CrossChainSwapDialog
                                        open={modalOpen}
                                        request={selectedCrossChainRequest.crossChainSwapRequest}
                                        assetSummary={getSummedAssetById(
                                            selectedCrossChainRequest.crossChainSwapRequest.tokenDefinition
                                                .tokenIdentifier
                                        )}
                                        ownRequest={selectedCrossChainRequest.ownRequest}
                                        requestedNotaries={getPermittedNotaries(
                                            getAssetbyId(
                                                selectedCrossChainRequest.crossChainSwapRequest.tokenDefinition
                                                    .tokenIdentifier
                                            ).complianceRules
                                        ).join(' | ')}
                                        offeredNotaries={getPermittedNotaries(
                                            getAssetbyId(
                                                selectedCrossChainRequest.crossChainSwapRequest.offeredTokenDefinition
                                                    .tokenIdentifier
                                            ).complianceRules
                                        ).join(' | ')}
                                        approve={approve}
                                        onClose={handleClose}
                                        decline={deny}
                                    />
                                )}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
            <InfoWidget showing={showInfoWidget} />
        </>
    );
};

export default CrossChainSwapRequests;
