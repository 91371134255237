import { Divider, Paper, SvgIcon, Typography } from '@material-ui/core';
import { NumberFormat, SummedAsset } from '@r3/cbdc-asset-frontend-core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import SummaryRow from 'components/commonComponents/SummaryComponents/SummaryRow';
import SummaryTable from 'components/commonComponents/SummaryComponents/SummaryTable';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type RedeemAssetReviewStepProps = {
    selectedAsset: SummedAsset;
    assetAmount: string;
};

const RedeemAssetReviewStep: React.FC<RedeemAssetReviewStepProps> = (props) => {
    const { selectedAsset, assetAmount } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    Review and confirm the Redemption Request.
                </Typography>

                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.text}`} style={{ marginTop: 12, marginBottom: 12 }}>
                    {t('commonText.issuer') + ': '} {selectedAsset.issuer}
                </Typography>

                <SummaryTable>
                    <SummaryRow
                        col1Text={t('commonText.amount') + ` of ${selectedAsset.tokenName}`}
                        col2Text={NumberFormat.addThousandSeparators(assetAmount)}
                    />
                    <SummaryRow
                        col1Text={t('transactionSummary.assetRemainingAfterTransaction', {
                            assetName: selectedAsset.tokenName,
                        })}
                        col2Text={NumberFormat.addThousandSeparators(
                            (selectedAsset.totalInVault - parseFloat(assetAmount)).toFixed(selectedAsset.tokenDecimal)
                        )}
                    />
                    <SummaryRow
                        col1Text={'Bonds to receive'}
                        col2Text={NumberFormat.addThousandSeparators(assetAmount)}
                        icon={
                            <SvgIcon color="secondary">
                                <BondsIcon />
                            </SvgIcon>
                        }
                    />
                </SummaryTable>
            </Paper>
        </div>
    );
};

export default RedeemAssetReviewStep;
