import { Button, Divider, Switch } from '@material-ui/core';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { NavButtonProps } from 'models/NavPage';
import React from 'react';
import { useNavButtonStyles } from '@r3/cbdc-asset-frontend-core';

export type NavButtonsProps = {
    navButtons: NavButtonProps[];
    receiveNotifications: boolean;
    toggleNotificationService: () => void;
};

const WholesaleNavButtons: React.FC<NavButtonsProps> = ({
    navButtons,
    receiveNotifications,
    toggleNotificationService,
}) => {
    const classes = useNavButtonStyles();
    const history = useHistory();
    const location = useLocation();

    const isButtonHighlighted = (path: string) => {
        if (path === '/') {
            if (history.location.pathname === path) {
                return true;
            }
        } else if (history.location.pathname.includes(path)) {
            return true;
        }
        return false;
    };
    return (
        <div key={location.key} className={classes.navButtonWrapper}>
            {navButtons.map((navButton) => (
                <Button
                    key={navButton.label}
                    className={`navButton  ${classes.buttonNav}  ${
                        isButtonHighlighted(navButton.highlightPath) ? classes.selected : ''
                    }`}
                    startIcon={navButton.icon}
                    color="primary"
                    component={RouterLink}
                    {...{ to: navButton.linkPath }}
                >
                    {navButton.label}
                </Button>
            ))}

            <div className={classes.notifSwitch}>
                <span className={'text'}>Notifications</span>
                <Switch
                    id="NotifcationSwitch"
                    size="small"
                    checked={receiveNotifications}
                    onChange={() => toggleNotificationService()}
                />
            </div>

            <Divider className={classes.divider} />
        </div>
    );
};

export default WholesaleNavButtons;
