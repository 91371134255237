import { CANCELLED_COLOR, FILLED_COLOR, PARTIALLY_FILLED_COLOR } from '../colors';
import { OrderStatusFilter, OrderTableData } from './types';

import { HeadCell } from 'components/commonComponents/Table/Table';

export const ORDER_STATUS_FILTERS: OrderStatusFilter[] = [
    { label: 'Fully filled', key: 'fullyFilled', color: FILLED_COLOR, checked: false },
    { label: 'Partially filled', key: 'partiallyFilled', color: PARTIALLY_FILLED_COLOR, checked: false },
    { label: 'Cancelled', key: 'cancelled', color: CANCELLED_COLOR, checked: false },
];

export const HEAD_CELLS: HeadCell<OrderTableData>[] = [
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'creator', numeric: false, disablePadding: false, label: 'Offer Creator' },
    { id: 'receiver', numeric: false, disablePadding: false, label: 'Offer Executor' },
    { id: 'requestedTokenName', numeric: false, disablePadding: false, label: 'Requested CBDC' },
    { id: 'requestedAssetAmount', numeric: false, disablePadding: false, label: 'Requested Amount' },
    { id: 'receivedAmount', numeric: false, disablePadding: false, label: 'Actual Received' },
    { id: 'offeredTokenName', numeric: false, disablePadding: false, label: 'Offered CBDC' },
    { id: 'offeredAssetAmount', numeric: false, disablePadding: false, label: 'Offered Amount' },
    { id: 'filledAmount', numeric: false, disablePadding: false, label: 'Actual Filled' },
    { id: 'lastUpdated', numeric: false, disablePadding: false, label: 'Tx Time', date: true },
];
