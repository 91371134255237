import {
    Asset,
    AssetRequest,
    AssetResponse,
    Bond,
    DvpRequest,
    KycState,
    NumberFormat,
    PvpRequest,
} from '@r3/cbdc-asset-frontend-core';
import { OptionsObject, useSnackbar } from 'notistack';
import { requestAllAssetRequests, requestAllDvpRequests } from 'api/transactApi';
import { useEffect, useState } from 'react';

import { NOTIFICATIONPOLLINGINTERVAL } from '../../constants/PollingIntervals';
import { ResolvedPromise } from '../../api/resolvePromise';
import { emptyBond } from 'components/treasuryDashboard/BondsDashboard/BondsDashboard';
import { requestAllBonds } from 'api/bondsApi';
import { requestAllKycStates } from 'api/kycApi';
import { requestAssetSummary } from 'api/assetsApi';
import { runPvpNotificationQueries } from './pvpRequestNotifications';
import useExchangeOfferNotifications from './useExchangeOfferNotifications';
import useGetAssetDefinitions from '../../hooks/GetAssetDefinitions';
import useGetOurOwnIdentity from '../../hooks/GetOurOwnIdentity';
import { useInterval } from '@r3/cbdc-asset-frontend-core';

export const DEFAULT_SNACKBAR_INFO_OPTIONS: OptionsObject = {
    variant: 'info',
    autoHideDuration: 10000,
};

export const WholesalePollingNotificationService = () => {
    const [pollingInterval, setPollingInterval] = useState<number | null>(NOTIFICATIONPOLLINGINTERVAL);
    const [pollCount, setPollCount] = useState<number>(0);
    const [fetching, setFetching] = useState<boolean>(false);
    const [vaultAssets, setVaultAssets] = useState<AssetResponse[]>([]);
    const [existingAssets, setExistingAssets] = useState<Asset[]>([]);
    const [existingKycStates, setExistingKycStates] = useState<KycState[]>([]);
    const [existingBonds, setExistingBonds] = useState<Bond>(emptyBond);
    const { assets, getAssetNamebyId, getAllAssets } = useGetAssetDefinitions();
    const { enqueueSnackbar } = useSnackbar();
    const { identity, getOwnIdentity } = useGetOurOwnIdentity();
    const [existingDvpRequests, setExistingDvpRequests] = useState<DvpRequest[]>([]);
    const [existingPvpRequests, setExistingPvpRequests] = useState<PvpRequest[]>([]);
    const [existingAssetRequests, setExistingAssetRequests] = useState<AssetRequest[]>([]);
    const showExchangeOfferNotifications = useExchangeOfferNotifications();

    //Check assets data

    const getAllAssetsData = async () => {
        const response: ResolvedPromise = await requestAssetSummary(true);
        if (response.error) {
            console.log(response.error);
        } else {
            const assetsResponse: AssetResponse[] = response.data;
            if (pollCount > 1 && getAssetTotal(vaultAssets) !== getAssetTotal(assetsResponse)) {
                if (assetsResponse.length === 0) {
                    setPollCount(0);
                } else {
                    checkForNewVaultAssets(assetsResponse);
                }
            }
            setVaultAssets(assetsResponse);
        }
    };

    const getAssetTotal = (assets: AssetResponse[]): number => {
        let total = 0;
        assets.forEach((asset) => {
            total += asset.totalAmount;
        });
        return total;
    };

    const checkForNewVaultAssets = (assets: AssetResponse[]) => {
        assets.forEach((newAsset) => {
            if (
                vaultAssets.findIndex(
                    (vs) => vs.tokenDefinition.tokenIdentifier === newAsset.tokenDefinition.tokenIdentifier
                ) === -1
            ) {
                enqueueSnackbar(
                    `CBDC ASSET: "${
                        newAsset.tokenDefinition.tokenName
                    }" has been added to this vault. Total value: ${NumberFormat.addThousandSeparators(
                        newAsset.totalAmount.toFixed(newAsset.tokenDecimals)
                    )}`,
                    DEFAULT_SNACKBAR_INFO_OPTIONS
                );
            }
            vaultAssets.forEach((existingAsset) => {
                if (
                    newAsset.tokenDefinition.tokenIdentifier === existingAsset.tokenDefinition.tokenIdentifier &&
                    newAsset.totalAmount !== existingAsset.totalAmount
                ) {
                    let wording =
                        newAsset.totalAmount > existingAsset.totalAmount
                            ? 'added to this vault'
                            : 'removed from this vault';

                    let difference = Math.abs(newAsset.totalAmount - existingAsset.totalAmount);

                    enqueueSnackbar(
                        `CBDC ASSET: "${
                            newAsset.tokenDefinition.tokenName
                        }" has been ${wording}. Total value: ${NumberFormat.addThousandSeparators(
                            difference.toFixed(newAsset.tokenDecimals)
                        )}`,
                        DEFAULT_SNACKBAR_INFO_OPTIONS
                    );
                }
            });
        });
    };

    //Check KYC states

    const getKycStates = async () => {
        const kycStatesResponse: ResolvedPromise = await requestAllKycStates(true);
        if (kycStatesResponse.error) {
        } else {
            const states = kycStatesResponse.data as KycState[];

            if (states.length !== existingKycStates.length && pollCount > 1) {
                checkForNewKycStates(states);
            }
            setExistingKycStates(states);
        }
    };

    const checkForNewKycStates = (states: KycState[]) => {
        const newAssets = states.filter((s) => !stringifyAndIncludes(existingKycStates, s));

        newAssets.forEach((state) => {
            enqueueSnackbar(
                `New Member Access State has been received from ${
                    state.issuer
                }. This node can now hold and transfer ${getAssetNamebyId(state.tokenIdentifier)}.`,
                DEFAULT_SNACKBAR_INFO_OPTIONS
            );
        });
    };

    //Check Dvp Requests

    const getAllDvpRequests = async () => {
        const requestsResponse: ResolvedPromise = await requestAllDvpRequests(true);
        if (!requestsResponse.error) {
            const requests = requestsResponse.data as DvpRequest[];

            if (requests.length !== existingDvpRequests.length && pollCount > 1) {
                checkForNewDvpRequests(requests);
            }
            setExistingDvpRequests(requests);
        }
    };

    const checkForNewDvpRequests = (requests: DvpRequest[]) => {
        const newRequests = requests.filter(
            (req) => req.buyer !== identity && !stringifyAndIncludes(existingDvpRequests, req)
        );

        newRequests.forEach((request) => {
            enqueueSnackbar(
                `New DvP request from ${request.buyer}. Requesting ${NumberFormat.addThousandSeparators(
                    request.assetAmount.toFixed(3)
                )} of ${request.tokenDefinition.tokenName}. Go to Requests page to view.`,
                DEFAULT_SNACKBAR_INFO_OPTIONS
            );
        });
    };

    //Check Asset Requests

    const getAllAssetRequests = async () => {
        const requestsResponse: ResolvedPromise = await requestAllAssetRequests(true);
        if (!requestsResponse.error) {
            const requests = requestsResponse.data as AssetRequest[];

            if (requests.length !== existingAssetRequests.length && pollCount > 1) {
                checkForNewAssetRequests(requests);
            }
            setExistingAssetRequests(requests);
        }
    };

    const checkForNewAssetRequests = (requests: AssetRequest[]) => {
        const newRequests = requests.filter(
            (req) => req.requestingParty !== identity && !stringifyAndIncludes(existingAssetRequests, req)
        );

        newRequests.forEach((request) => {
            enqueueSnackbar(
                `New Pull Transfer (CBDC Asset) request from ${
                    request.requestingParty
                }. Requesting ${NumberFormat.addThousandSeparators(request.assetAmount.toFixed(3))} of ${
                    request.tokenDefinition.tokenName
                }. Go to Requests page to view`,
                DEFAULT_SNACKBAR_INFO_OPTIONS
            );
        });
    };

    //Check bonds

    const getAllBondsData = async () => {
        const bondsResponse: ResolvedPromise = await requestAllBonds(true);
        if (bondsResponse.error) {
            return;
        } else {
            let bonds = bondsResponse.data as Bond;
            if (bonds.usdValue !== existingBonds.usdValue && pollCount > 1) {
                if (bonds.usdValue > existingBonds.usdValue) {
                    enqueueSnackbar(
                        `A total of ${NumberFormat.addThousandSeparators(
                            (bonds.usdValue - existingBonds.usdValue).toFixed(3)
                        )} bonds has been added to your vault`,
                        DEFAULT_SNACKBAR_INFO_OPTIONS
                    );
                } else {
                    enqueueSnackbar(
                        `A total of ${NumberFormat.addThousandSeparators(
                            (existingBonds.usdValue - bonds.usdValue).toFixed(3)
                        )} bonds has been removed from, your vault`,
                        DEFAULT_SNACKBAR_INFO_OPTIONS
                    );
                }
            }
            setExistingBonds(bonds);
        }
    };

    useInterval(() => {
        if (!fetching) {
            setFetching(true);
        }
    }, pollingInterval);

    useEffect(() => {
        const fetchAllData = async () => {
            let receivedResponse = await getAllAssets(true);

            if (receivedResponse) {
                await getOwnIdentity(true);
                await getAllDvpRequests();
                await runPvpNotificationQueries(
                    enqueueSnackbar,
                    identity,
                    pollCount,
                    existingPvpRequests,
                    setExistingPvpRequests
                );
                await showExchangeOfferNotifications(pollCount, identity);
                await getAllAssetRequests();
                await getAllAssetsData();
                await getKycStates();
                await getAllBondsData();
                if (assets.length === 0 && pollCount > 0) {
                    setPollCount(0);
                } else {
                    setPollCount(pollCount + 1);
                }
            } else {
                enqueueSnackbar(
                    `Live notifcations have stopped, error contacting server. Please refresh to try again.`,
                    {
                        variant: 'info',
                        persist: true,
                    }
                );
                setPollingInterval(null);
            }
            setFetching(false);
        };

        if (fetching) {
            fetchAllData();
        }
        // eslint-disable-next-line
    }, [fetching]);

    useEffect(() => {
        if (pollCount > 1 && assets.length !== existingAssets?.length) {
            const newAssets = assets.filter((a) => !stringifyAndIncludes(existingAssets, a));
            newAssets.forEach((asset) => {
                enqueueSnackbar(
                    `A new CBDC: ${asset.tokenName} has been defined on the network by ${asset.party}. You can now request this CBDC.`,
                    DEFAULT_SNACKBAR_INFO_OPTIONS
                );
            });
        }
        setExistingAssets(assets);
    }, [assets, enqueueSnackbar, existingAssets, pollCount]);

    return <> </>;
};

const stringifyAndIncludes = (assets: any[], a): boolean => {
    let stringified = assets.map((a) => JSON.stringify(a));
    return stringified.includes(JSON.stringify(a));
};
