import RetailAssetItem from './RetailAssetItem';
import { useRetailAssetsStyles } from 'materialStyles/retailStyles/retailAssetsStyles';
import { useUserContextProvider } from '../../../contexts/RetailUserLogin/RetailUserContext';
import { ColorOperations } from '@r3/cbdc-asset-frontend-core';

const RetailAssetList: React.FC = () => {
    const classes = useRetailAssetsStyles();
    const _colourShades = ColorOperations.getColourShades('#47b366');
    const userContext = useUserContextProvider();
    const accountBalances = userContext?.customerDetails?.accountBalances;

    if (!accountBalances) {
        return <div className={classes.assetList}></div>;
    }

    return (
        <div className={classes.assetList}>
            {accountBalances.length === 0 && <div className={classes.noAssetsText}>{'No Assets '}</div>}
            {accountBalances
                .sort((x, y) => {
                    if (x.tokenDefinition.tokenName < y.tokenDefinition.tokenName) {
                        return -1;
                    }
                    if (x.tokenDefinition.tokenName > y.tokenDefinition.tokenName) {
                        return 1;
                    }
                    return 0;
                })
                .map((asset, index) => {
                    return (
                        <RetailAssetItem
                            key={asset.tokenDefinition.tokenIdentifier}
                            color={_colourShades[index % _colourShades.length]}
                            accountBalance={asset}
                        />
                    );
                })}
        </div>
    );
};

export default RetailAssetList;
