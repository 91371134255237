import React, { useEffect, useState } from 'react';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { AssistantWindow } from './AssistantWindow';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { IconButton } from '@material-ui/core';
import { TutorialWindow } from 'components/sandboxGuide/TutorialWindow';
import { useAssistantStyles } from '@r3/cbdc-asset-frontend-core';

const ASSITANTLOCALSTORAGEKEY = 'assitantOpen';
const TUTORIALLOCALSTORAGEKEY = 'tutorialOpen';
const SANDBOXGUIDESTORAGEKEY = 'tutorialOpened';

export const openFromLocalStorage = (key: string): boolean => {
    let state = localStorage.getItem(key);
    if (state) {
        if (state === 'true') {
            return true;
        } else if (state === 'false') {
            return false;
        }
    } else {
        localStorage.setItem(key, 'false');
        return false;
    }
    return false;
};

export const Assistant = () => {
    const classes = useAssistantStyles();
    const [isAssistantVisible, setIsAssistantVisible] = useState<boolean>(
        openFromLocalStorage(ASSITANTLOCALSTORAGEKEY)
    );
    const [isTutorailVisible, setIsTutorialVisible] = useState<boolean>(openFromLocalStorage(TUTORIALLOCALSTORAGEKEY));
    const [isSandboxGuideFlashing, setIsSandboxGuideFlashing] = useState<boolean>(
        openFromLocalStorage(SANDBOXGUIDESTORAGEKEY)
    );

    const openAssistant = () => {
        setIsAssistantVisible(true);
    };

    const closeAssistant = () => {
        setIsAssistantVisible(false);
    };

    const openTutorial = () => {
        setIsTutorialVisible(true);
    };

    const closeTutorial = () => {
        setIsTutorialVisible(false);
    };

    useEffect(() => {
        if (isAssistantVisible) {
            localStorage.setItem(ASSITANTLOCALSTORAGEKEY, 'true');
        } else {
            localStorage.setItem(ASSITANTLOCALSTORAGEKEY, 'false');
        }
    }, [isAssistantVisible]);

    useEffect(() => {
        if (isTutorailVisible) {
            localStorage.setItem(SANDBOXGUIDESTORAGEKEY, 'true');
            setIsSandboxGuideFlashing(true);
            localStorage.setItem(TUTORIALLOCALSTORAGEKEY, 'true');
        } else {
            localStorage.setItem(TUTORIALLOCALSTORAGEKEY, 'false');
        }
    }, [isTutorailVisible]);

    return (
        <>
            <AssistantWindow isVisible={isAssistantVisible} openWindow={openAssistant} closeWindow={closeAssistant} />
            <TutorialWindow isVisible={isTutorailVisible} openWindow={openTutorial} closeWindow={closeTutorial} />
            <IconButton
                onClick={() => {
                    setIsAssistantVisible(!isAssistantVisible);
                    setIsTutorialVisible(false);
                }}
                className={classes.assistantButton}
                data-testid={'assistant_button'}
                color="primary"
                aria-label="assistant_button"
            >
                <EmojiPeopleIcon style={{ height: 30, width: 30 }} />
            </IconButton>
            <IconButton
                onClick={() => {
                    setIsTutorialVisible(!isTutorailVisible);
                    setIsAssistantVisible(false);
                }}
                className={`${classes.tutorialButton}  ${!isSandboxGuideFlashing ? classes.pulsing : ''}`}
                color="primary"
                aria-label="checklist_button"
            >
                <AssignmentTurnedInIcon style={{ height: 30, width: 30 }} />
            </IconButton>
        </>
    );
};
