import { NumberFormat } from '@r3/cbdc-asset-frontend-core';

export enum Order {
    Ascending = 'asc',
    Descending = 'desc',
}

export function descendingComparator<T extends object>(a: T, b: T, orderBy: keyof T) {
    let aVal = a[orderBy];
    let bVal = b[orderBy];

    //See if a string is a possible number
    //If so sort numerically
    if (typeof aVal === 'string' && typeof bVal === 'string') {
        const aValNumeric = Number(NumberFormat.removeThousandSeparators(aVal as unknown as string));
        const bValNumber = Number(NumberFormat.removeThousandSeparators(bVal as unknown as string));
        if (!Number.isNaN(aValNumeric) && !Number.isNaN(bValNumber)) {
            return defaultDescendingComparator(aValNumeric, bValNumber);
        }
    }
    return defaultDescendingComparator(aVal, bVal);
}

function defaultDescendingComparator(aVal: any, bVal: any) {
    if (bVal < aVal) {
        return -1;
    }
    if (bVal > aVal) {
        return 1;
    }
    return 0;
}

export function getComparator<T extends object>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const preserveOrderSort = <T extends object>(array: T[], comparator: (a: T, b: T) => number): T[] => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};
