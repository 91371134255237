import { Divider, Paper, Typography } from '@material-ui/core';

import MultiSelectDropDown from 'components/commonComponents/MultiSelectionDropDown/MultiSelectDropDown';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type Props = {
    participants: string[];
    setSelectedParticipants: (participants: string[]) => void;
};

const SelectParticipantsStep: React.FC<Props> = ({ participants, setSelectedParticipants }) => {
    const { t: texts } = useTranslation();
    const layoutClasses = useLayoutStyles();

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {texts('liquiditySavings.selectOfferBroadcastParticipants')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <div style={{ marginLeft: 32, marginRight: 32 }}>
                    <Typography
                        className={`${layoutClasses.sectionLabel} `}
                        style={{ fontSize: 16, marginTop: 18, textAlign: 'left' }}
                    >
                        {texts('liquiditySavings.selectParticipantStepAllInfo')}
                    </Typography>
                    <Typography
                        className={`${layoutClasses.sectionLabel} `}
                        style={{ fontSize: 16, marginBottom: 18, textAlign: 'left' }}
                    >
                        {texts('liquiditySavings.selectParticipantStepIndividualInfo')}
                    </Typography>
                </div>
                <MultiSelectDropDown
                    options={participants}
                    onSelectionChanged={setSelectedParticipants}
                    labelText={texts('liquiditySavings.selectParticipantsLabel')}
                    selectAllByDefault={true}
                    selectAllOptionText={texts('commonText.allNetworkParticipants')}
                />
            </Paper>
        </div>
    );
};
export default SelectParticipantsStep;
