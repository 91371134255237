import { Divider, Paper, TextField, Typography } from '@material-ui/core';

import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type PasswordStepProps = {
    accountName: string;
    setAccountName: (str: string) => void;
    password: string;
    setPassword: (str: string) => void;
    passwordCheck: string;
    setPasswordCheck: (str: string) => void;
};

const PasswordStep: React.FC<PasswordStepProps> = ({
    accountName,
    setAccountName,
    password,
    setPassword,
    passwordCheck,
    setPasswordCheck,
}) => {
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();

    const updateFormInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const val = event.target.value;
        if (event.target.name === 'AccountName') {
            setAccountName(val);
        } else if (event.target.name === 'Password') {
            setPassword(val);
        } else if (event.target.name === 'PasswordCheck') {
            setPasswordCheck(val);
        }
    };

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('retail.password')}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                {/* <TextField
                    className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} nameInput`}
                    type={'text'}
                    label={accountName.length === 0 ? 'Please enter an account name' : 'Account Name'}
                    value={accountName}
                    name="AccountName"
                    variant="filled"
                    onChange={(event) => {
                        updateFormInput(event);
                    }}
                    error={accountName.length === 0}
                    required
                    data-testid={'accountName-input'}
                /> */}

                <TextField
                    autoFocus
                    className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} nameInput`}
                    type={'password'}
                    label={password.length === 0 ? 'Please enter a valid password' : 'Password'}
                    value={password}
                    name="Password"
                    variant="filled"
                    onChange={(event) => {
                        updateFormInput(event);
                    }}
                    error={password.length === 0}
                    required
                    data-testid={'password-input'}
                />

                <TextField
                    className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} nameInput`}
                    type={'password'}
                    label={
                        passwordCheck.length === 0 || passwordCheck !== password
                            ? 'Please make sure passwords match'
                            : 'Password'
                    }
                    value={passwordCheck}
                    name="PasswordCheck"
                    variant="filled"
                    onChange={(event) => {
                        updateFormInput(event);
                    }}
                    error={passwordCheck.length === 0 || passwordCheck !== password}
                    required
                    data-testid={'passwordCheck-input'}
                />
            </Paper>
        </div>
    );
};

export default PasswordStep;
