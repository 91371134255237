import { DialogContentText } from '@material-ui/core';
import React from 'react';

type TutorialTextProps = {
    text: string;
    index: number;
};

export const TutorialText: React.FC<TutorialTextProps> = ({ text, index }) => {
    if (text.includes('http')) {
        return (
            <a href={text} style={{ fontSize: 20, color: text.includes('Note') ? '#b4e6fbd6' : 'white' }}>
                {text.includes('Note') ? '' : index + 1 + '. '} {text}
            </a>
        );
    } else {
        return (
            <DialogContentText
                style={{ fontSize: 20, color: text.includes('Note') ? '#b4e6fbd6' : 'white' }}
                color="textPrimary"
                id="alert-dialog-description"
            >
                {text.includes('Note') ? '' : index + 1 + '. '} {text}
            </DialogContentText>
        );
    }
};
