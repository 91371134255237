import { Grow, IconButton, Modal, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import CropFreeIcon from '@material-ui/icons/CropFree';
import ListAltIcon from '@material-ui/icons/ListAlt';
import useAppConfigContext from 'contexts/AppConfig/AppConfig';
import { useInfoModalStyles } from '@r3/cbdc-asset-frontend-core';

type FeedbackModalProps = {
    open: boolean;
    onClose: () => void;
};

export const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, onClose }) => {
    const classes = useInfoModalStyles();
    const [feedbackFormOpen, setFeedbackFormOpen] = useState<boolean>(true);
    const appConfigContext = useAppConfigContext();
    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    onClose();
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.bodyWrapper}>
                    <div className={classes.pageWrapper}>
                        {feedbackFormOpen ? (
                            <div className={classes.headerText}>Leave a question or feedback in the form</div>
                        ) : (
                            <div className={classes.headerText}>
                                Scan QR code on a mobile phone or tablet to access the feedback form
                            </div>
                        )}

                        <Tooltip title="Close Modal" placement="top" className={classes.closeButtonWrapper}>
                            <IconButton onClick={onClose} className={`${classes.indexButton}`}>
                                <CloseIcon className={classes.indexButtonSvg} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={feedbackFormOpen ? 'QR Code' : 'Feedback Form'}
                            placement="top"
                            className={classes.qrButtonWrapper}
                        >
                            <IconButton
                                onClick={() => {
                                    setFeedbackFormOpen(!feedbackFormOpen);
                                }}
                                className={`${classes.indexButton}`}
                            >
                                {feedbackFormOpen ? (
                                    <CropFreeIcon className={classes.indexButtonSvg} />
                                ) : (
                                    <ListAltIcon className={classes.indexButtonSvg} />
                                )}
                            </IconButton>
                        </Tooltip>
                        {feedbackFormOpen && (
                            <Grow in={true} timeout={{ enter: 600, exit: 400 }}>
                                <iframe
                                    title="feedback form"
                                    className={classes.feedbackForm}
                                    width="760px"
                                    height="680px"
                                    src={appConfigContext?.appConfig.feedbackFormUrl}
                                ></iframe>
                            </Grow>
                        )}
                        {!feedbackFormOpen && (
                            <Grow in={true} timeout={{ enter: 600, exit: 400 }}>
                                <div className={classes.imageWrapper}>
                                    <img
                                        alt="Corda"
                                        className={classes.imageSmall}
                                        src={'/appConfig/feedbackFormQr.png'}
                                        data-testid="diagram-image"
                                    ></img>
                                </div>
                            </Grow>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};
