import React, { useContext } from 'react';

import { PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import { useGetPaymentRequests } from '../../hooks/GetPaymentRequests';

type RetailPayementRequestsContextProps = {
    paymentRequests: PaymentRequestResponse[] | null;
    refreshPaymentRequests: (trackPromise?: boolean) => void;
    error: string;
    loading: boolean;
    isOwnPaymentRequest: (requestId: string, accountId: string) => PaymentRequestResponse | null | undefined;
    isRequestFulfilled: (requestId: string) => boolean;
};

export const RetailPayementRequestsContext = React.createContext<RetailPayementRequestsContextProps>(
    {} as RetailPayementRequestsContextProps
);

export const RetailPayementRequestsContextProvider = ({ children }) => {
    const { paymentRequests, getPaymentRequests, isOwnPaymentRequest, isRequestFulfilled, loading, error } =
        useGetPaymentRequests(true);
    return (
        <RetailPayementRequestsContext.Provider
            value={{
                paymentRequests,
                refreshPaymentRequests: getPaymentRequests,
                error,
                loading,
                isOwnPaymentRequest,
                isRequestFulfilled,
            }}
        >
            {children}
        </RetailPayementRequestsContext.Provider>
    );
};

export const useRetailPayementRequestsContext = () => useContext(RetailPayementRequestsContext);
