import { DialogAction, DialogActions, DialogBody, DialogWindow, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { Divider, Typography } from '@material-ui/core';

import DialogTransactionSummary from 'components/commonComponents/DialogTransactionSummary/DialogTransactionSummary';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { PvpRequest } from '@r3/cbdc-asset-frontend-core';
import React from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next/';

export interface SimpleDialogProps {
    open: boolean;
    request: PvpRequest;
    assetSummary: SummedAsset | undefined;
    approve: () => void;
    decline: () => void;
    onClose: () => void;
    ownRequest: boolean;
    requestedNotaries: string;
    offeredNotaries: string;
}

export function CrossChainSwapDialog(props: SimpleDialogProps) {
    const { onClose, approve, request, open, decline, ownRequest, requestedNotaries, offeredNotaries, assetSummary } =
        props;
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const isActionable = request.requestState === 'REQUESTED' && !ownRequest;
    const approveDisabled =
        typeof assetSummary == 'undefined' || request.assetAmount > assetSummary.availableToTransact;
    return (
        <DialogWindow
            open={open}
            onClose={onClose}
            title={t('crossChainSwap.dialogTitle') + ' : ' + request.requestState}
        >
            <DialogBody>
                <Typography gutterBottom>{t('commonLabels.requestId') + ': ' + request.requestId}</Typography>
                <Typography gutterBottom>{'Requestor: ' + request.buyer}</Typography>
                <Typography gutterBottom>{'Requestee: ' + request.seller}</Typography>
                <Typography gutterBottom>
                    {t('crossChainSwapRequest.assetRequested') + ': ' + request.tokenDefinition.tokenName}
                </Typography>
                <Typography gutterBottom>
                    {t('crossChainSwapRequest.requestedAssetAmount') +
                        ': ' +
                        NumberFormat.addThousandSeparators(request.assetAmount)}
                </Typography>
                <Typography gutterBottom>
                    {t('crossChainSwapRequest.requestedAssetNotary') + ': ' + requestedNotaries}
                </Typography>
                <Typography gutterBottom>
                    {t('crossChainSwapRequest.offeredAsset') + ': ' + request.offeredTokenDefinition.tokenName}
                </Typography>
                <Typography gutterBottom>
                    {t('crossChainSwapRequest.offeredAssetAmount') +
                        ': ' +
                        NumberFormat.addThousandSeparators(request.offeredAssetAmount)}
                </Typography>
                <Typography gutterBottom>
                    {t('crossChainSwapRequest.offeredAssetNotary') + ': ' + offeredNotaries}
                </Typography>
                {isActionable && (
                    <>
                        <Divider className={layoutClasses.summaryDivider} />
                        <DialogTransactionSummary asset={assetSummary} toSpend={request.assetAmount} />
                    </>
                )}
            </DialogBody>
            <DialogActions>
                {request.requestState === 'REQUESTED' && !ownRequest && (
                    <>
                        <DialogAction
                            text={t('commonText.approve')}
                            action={approve}
                            icon={<ThumbUpIcon />}
                            disabled={approveDisabled}
                        />
                        <DialogAction text={t('commonText.deny')} action={decline} icon={<NotInterestedIcon />} />
                    </>
                )}
            </DialogActions>
        </DialogWindow>
    );
}
