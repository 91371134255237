import { memo, useState } from 'react';

import { AccountBalance } from '../../../hooks/GetCustomerDetails';
import { Paper } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import { useRetailAssetsStyles } from 'materialStyles/retailStyles/retailAssetsStyles';
import { NumberFormat } from '@r3/cbdc-asset-frontend-core';

type RetailAssetItemProps = {
    accountBalance: AccountBalance;
    color: string;
};

const RetailAssetItem: React.FC<RetailAssetItemProps> = ({ accountBalance, color }) => {
    const classes = useRetailAssetsStyles();
    const trimText = useMediaQuery({ maxWidth: 360 });
    const showAllText = useMediaQuery({ minWidth: 420 });
    const _maxLength = trimText ? 12 : showAllText ? 100 : 14;
    const [clicked, setClicked] = useState<boolean>(false);
    let { tokenName } = accountBalance.tokenDefinition;
    tokenName = tokenName.length > _maxLength && !clicked ? tokenName.substring(0, _maxLength) + '...' : tokenName;
    return (
        <div className={classes.assetWrapper}>
            <Paper className={classes.assetIcon} style={{ backgroundColor: color }}>
                {tokenName[0]}
            </Paper>
            <div
                className={classes.assetNameText}
                onClick={() => {
                    setClicked((prev) => !prev);
                }}
            >
                {tokenName}
            </div>
            <div className={classes.amountText}>
                {NumberFormat.addThousandSeparators(
                    accountBalance.balance.toFixed(accountBalance.tokenDefinition.decimals)
                )}
            </div>
        </div>
    );
};

export default memo(RetailAssetItem);
