import * as ROUTES from 'constants/Routes';

import { Button, IconButton } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import { AppWrapperProps } from './AppWrapperProps';
import DrawerNav from 'components/layout/DrawerNav/DrawerNav';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import LoadingModal from 'components/commonComponents/Loading/LoadingModal';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import NavBar from 'components/layout/NavBar/NavBar';
import { NetworkTab } from 'components/networkTab/NetworkTab';
import Retail from 'components/retailClient/Retail';
import RetailLogin from 'components/retailClient/RetailLogin';
import RetailNavButtons from 'components/layout/NavButtons/RetailNavButtons';
import RetailNotificationService from 'components/retailClient/RetailNotificationService';
import { RetailPayeeContextProvider } from 'contexts/RetailPayeesContext/RetailPayeesContext';
import { RetailPayementRequestsContextProvider } from 'contexts/RetailPaymentRequestContext/RetailPaymentRequestContext';
import { RetailTransactionsContextProvider } from 'contexts/RetailTransactionsContext/RetailTransactionsContext';
import TopNavWrapper from 'components/layout/TopNavWrapper/TopNavWrapper';
import { useAppStyles } from 'materialStyles/appStyles';
import { useHistory } from 'react-router-dom';
import useNotificationService from 'hooks/UseNotificationService';
import { useState } from 'react';
import { useTopNavWrapperStyles } from '@r3/cbdc-asset-frontend-core';
import { useUserContextProvider } from 'contexts/RetailUserLogin/RetailUserContext';

const RetailWrapper: React.FC<AppWrapperProps> = ({ promiseInProgress }) => {
    const classes = useAppStyles();
    const topNavSyles = useTopNavWrapperStyles();
    const history = useHistory();
    const { receiveNotifications, toggleNotificationService } = useNotificationService();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const userContext = useUserContextProvider();

    const isLoggedIn = userContext?.user !== null;

    const logOutButton = (
        <Button
            className={`logOutButton  ${topNavSyles.buttonNav}`}
            startIcon={<ExitToAppRoundedIcon />}
            color="primary"
            onClick={() => {
                userContext?.logoutUser();
                history.push(ROUTES.RETAIL);
                window.location.reload();
            }}
        >
            {'Log Out'}
        </Button>
    );

    return (
        <div>
            <TopNavWrapper
                icon={
                    <AccountBoxRoundedIcon
                        id="AppIcon"
                        className={classes.appIcon}
                        onClick={() => {
                            history.push(ROUTES.RETAIL);
                        }}
                    />
                }
                button={
                    <IconButton
                        className={`menuButton ${classes.menuButton}`}
                        color="primary"
                        onClick={() => {
                            setDrawerOpen(!drawerOpen);
                        }}
                    >
                        <MenuRoundedIcon style={{ width: 35, height: 35 }} />
                    </IconButton>
                }
                navButtons={
                    <RetailNavButtons
                        receiveNotifications={receiveNotifications}
                        toggleNotificationService={toggleNotificationService}
                    />
                }
                additionalButtons={isLoggedIn ? logOutButton : null}
            />

            <>
                {promiseInProgress && <LoadingModal />}

                <div className={`${classes.mainContent} ${promiseInProgress ? classes.blurred : ''}`}>
                    {isLoggedIn ? (
                        <RetailPayeeContextProvider>
                            <RetailTransactionsContextProvider>
                                <RetailPayementRequestsContextProvider>
                                    <Switch>
                                        <Route exact path={ROUTES.RETAIL} component={Retail}></Route>
                                        <Route path={ROUTES.NETWORKTABRETAIL} component={NetworkTab}></Route>
                                    </Switch>
                                    {receiveNotifications && <RetailNotificationService />}
                                </RetailPayementRequestsContextProvider>
                            </RetailTransactionsContextProvider>
                        </RetailPayeeContextProvider>
                    ) : (
                        <Switch>
                            <Route exact path={ROUTES.RETAIL} component={RetailLogin}></Route>
                            <Route exact path={ROUTES.NETWORKTABRETAIL} component={NetworkTab}></Route>
                        </Switch>
                    )}
                </div>
            </>

            <DrawerNav
                open={drawerOpen}
                closeDrawer={() => {
                    setDrawerOpen(!drawerOpen);
                }}
                appName={'Retail Client'}
            >
                <NavBar>
                    <RetailNavButtons
                        receiveNotifications={receiveNotifications}
                        toggleNotificationService={toggleNotificationService}
                    />
                </NavBar>
            </DrawerNav>

            {/* <Assistant /> */}
        </div>
    );
};

export default RetailWrapper;
