import { Fade, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { approveDvpRequest, denyDvpRequest } from 'api/transactApi';

import { DvpRequest } from '@r3/cbdc-asset-frontend-core';
import { DvpRequestDialog } from './DvpRequestDialog';
import DvpRequestsList from './DvpRequestsList';
import { InfoWidget } from 'components/infoModal/InfoWidget/InfoWidget';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { ResolvedPromise } from 'api/resolvePromise';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useGetDvPRequests } from 'hooks/GetDvpRequests';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type SelectedDvpRequest = {
    dvpRequest: DvpRequest;
    ownRequest: boolean;
};

export const DvpRequests = () => {
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedDvpRequest, setSelectedDvpRequest] = useState<SelectedDvpRequest | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [showInfoWidget, setShowInfoWidget] = useState<boolean>(false);
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { dvpRequests, getDvpRequests, error } = useGetDvPRequests();
    const { getSummedAssetById } = useGetAssetSummary(true);

    useEffect(() => {
        getDvpRequests();
    }, [getDvpRequests]);

    const submitApproval = async () => {
        if (!selectedDvpRequest) {
            return;
        }
        const approvalResponse: ResolvedPromise = await approveDvpRequest(selectedDvpRequest.dvpRequest.requestId);
        if (approvalResponse.error) {
            enqueueSnackbar(t('error.approveDvpRequest', { error: approvalResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.approveDvpRequest'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);
        }
    };

    const submitDeny = async () => {
        if (!selectedDvpRequest) {
            return;
        }
        const denyResponse: ResolvedPromise = await denyDvpRequest(selectedDvpRequest.dvpRequest.requestId);

        if (denyResponse.error) {
            enqueueSnackbar(t('error.denyDvpRequest', { error: denyResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.denyDvpRequest'), {
                variant: 'success',
            });
        }
    };

    const openInfoModal = (dvpRequest: DvpRequest, ourRequests: boolean) => {
        setSelectedDvpRequest({ dvpRequest: dvpRequest, ownRequest: ourRequests });
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedDvpRequest(null);
        setModalOpen(false);
    };

    const approve = async () => {
        handleClose();
        await submitApproval();
        getDvpRequests();
        setShowInfoWidget(true);
    };

    const deny = async () => {
        handleClose();
        await submitDeny();
        getDvpRequests();
        setShowInfoWidget(true);
    };

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column} `}>
                        {error.length > 0 ? (
                            <ListHeader
                                text={error}
                                refreshAction={() => {
                                    getDvpRequests();
                                }}
                            />
                        ) : (
                            <>
                                <ListHeader
                                    text={'DvP Requests List'}
                                    refreshAction={() => {
                                        getDvpRequests();
                                    }}
                                />
                                <DvpRequestsList dvpRequests={dvpRequests} openInfoModal={openInfoModal} />
                                {selectedDvpRequest && modalOpen && (
                                    <DvpRequestDialog
                                        open={modalOpen}
                                        request={selectedDvpRequest.dvpRequest}
                                        assetSummary={getSummedAssetById(
                                            selectedDvpRequest.dvpRequest.tokenDefinition.tokenIdentifier
                                        )}
                                        ownRequest={selectedDvpRequest.ownRequest}
                                        approve={approve}
                                        onClose={handleClose}
                                        decline={deny}
                                    />
                                )}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
            <InfoWidget showing={showInfoWidget} />
        </>
    );
};
