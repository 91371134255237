import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

import AssetSummary from 'components/commonComponents/SummaryComponents/AssetSummary';
import { SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type SelectAssetStepProps = {
    selectedAsset: SummedAsset;
    handleAssetChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    summedAssets: SummedAsset[];
};

const SelectAssetStep: React.FC<SelectAssetStepProps> = (props) => {
    const { selectedAsset, handleAssetChange, summedAssets } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    Select Asset from the Vault to Redeem
                </Typography>

                <Divider className={layoutClasses.summaryDivider} />
                <FormControl
                    id="SelectAsset"
                    variant="outlined"
                    className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                >
                    <InputLabel id="SelectLabel">Asset</InputLabel>
                    <Select
                        labelId="SelectLabel"
                        value={selectedAsset.tokenIdentifier}
                        onChange={handleAssetChange}
                        data-testid={'asset-select'}
                    >
                        <MenuItem key={0} value="">
                            <em>None</em>
                        </MenuItem>
                        {summedAssets.map((as, i) => {
                            return (
                                <MenuItem
                                    key={as.tokenName + i}
                                    value={as.tokenIdentifier}
                                    data-testid={'asset-option'}
                                >
                                    {as.tokenName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {selectedAsset.tokenIdentifier.length > 0 && (
                    <>
                        <AssetSummary asset={selectedAsset} customSpendText={t('transferAssets.availableToRedeem')} />
                        <Typography className={`${layoutClasses.text}`}>
                            {t('commonText.issuer') + ': '} {selectedAsset.issuer}
                        </Typography>
                        <Divider className={layoutClasses.summaryDividerSmall} />
                    </>
                )}
            </Paper>
        </div>
    );
};

export default SelectAssetStep;
