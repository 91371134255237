import * as ROUTES from 'constants/Routes';

import { Button, Divider, Switch } from '@material-ui/core';

import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import NetworkButton from '../TopNavWrapper/NetworkButton';
import React from 'react';
import { SMALLSCREENWIDTH } from 'constants/ScreenSizes';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useNavButtonStyles } from '@r3/cbdc-asset-frontend-core';

export type NavButtonsProps = {
    receiveNotifications: boolean;
    toggleNotificationService: () => void;
};

const RetailNavButtons: React.FC<NavButtonsProps> = ({ receiveNotifications, toggleNotificationService }) => {
    const classes = useNavButtonStyles();
    const history = useHistory();
    const isSmallScreen = useMediaQuery({ maxWidth: SMALLSCREENWIDTH });
    return (
        <div className={classes.navButtonWrapper}>
            <Button
                className={`navButton  ${classes.buttonNav} ${
                    history.location.pathname === ROUTES.RETAIL ? classes.selected : ''
                }`}
                startIcon={<DashboardRoundedIcon />}
                color="primary"
                href={ROUTES.RETAIL}
            >
                {'Home'}
            </Button>
            {isSmallScreen && <NetworkButton />}

            <div className={classes.notifSwitch}>
                <span className={'text'}>Notifications</span>
                <Switch
                    id="NotifcationSwitch"
                    size="small"
                    checked={receiveNotifications}
                    onChange={() => toggleNotificationService()}
                />
            </div>

            <Divider className={classes.divider} />
        </div>
    );
};

export default RetailNavButtons;
