import { LISTKYC, REQUESTKYCSTATE } from 'constants/Routes';
import { TabPageMap, TabsWrapper, insertIntoArrayIf, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { ListKyc } from './ListKyc/ListKyc';
import React from 'react';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { RequestKycState } from './RequestAccess/RequestKycState';
import { useTranslation } from 'react-i18next';

export const AccessControls: React.FC = () => {
    const { t } = useTranslation();
    const { isFeatureEnabled } = useFeatureFlagContext();

    const tapPageMap: TabPageMap[] = [
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('viewMemberAccessStates'), {
            route: LISTKYC,
            pageComponent: ListKyc,
            tabDetails: { icon: <DoneOutlineIcon />, label: t('pageTitle.listKyc') },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('requestAccess'), {
            route: REQUESTKYCSTATE,
            pageComponent: RequestKycState,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <RecentActorsIcon />
                    </div>
                ),
                label: t('pageTitle.requestKyc'),
            },
        }),
    ];

    return <TabsWrapper pageTitle={t('pageTitle.kycControl')} tabPageMap={tapPageMap} />;
};
