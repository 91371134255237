import { DialogAction, DialogActions, DialogBody, SummedAsset, useDialogStyles } from '@r3/cbdc-asset-frontend-core';

import DialogState from './DialogState';
import DialogTransactionSummary from 'components/commonComponents/DialogTransactionSummary/DialogTransactionSummary';
import { Divider } from '@material-ui/core';
import { FC } from 'react';
import { Order } from 'models/orders/Order';
import SwapOfferDisplay from 'components/decentralizedExchange/SwapOfferDisplay';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { fillOrder } from 'api/decentralizedExchange';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type Props = {
    order: Order;
    onClose: () => void;
    dispatchDialogState: (dialogState: DialogState) => void;
    dispatchRefresh: () => void;
    assetSummary: SummedAsset | undefined;
};

const OrderDetailsContent: FC<Props> = ({ order, onClose, dispatchDialogState, dispatchRefresh, assetSummary }) => {
    const { t: texts } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dialogStyles = useDialogStyles();
    const interactiveCheckListContext = useInteractiveChecklistContext();

    async function handleFullyFillOrder() {
        onClose();
        const { error } = await fillOrder(order.orderId, order.creator, order.requestedAssetAmount);
        if (error == null) {
            enqueueSnackbar(texts('success.acceptExchangeOffer'), { variant: 'success' });
            interactiveCheckListContext?.completeStep(window.location.pathname);
        } else {
            enqueueSnackbar(texts('error.acceptExchangeOffer', { error }), { variant: 'error' });
        }
        dispatchRefresh();
    }

    function handlePartiallyFillOrder() {
        dispatchDialogState(DialogState.FILL_PARTIAL_ORDER);
    }

    const fullFillDisabled =
        typeof assetSummary == 'undefined' ||
        (assetSummary && order.requestedAssetAmount > assetSummary.availableToTransact);

    return (
        <div data-testid="order_details_content">
            <DialogBody>
                <SwapOfferDisplay order={order} />
                <Divider className={dialogStyles.divider} style={{ marginLeft: 0, marginRight: 0, marginBottom: 12 }} />
                <DialogTransactionSummary
                    asset={assetSummary}
                    toSpend={order.requestedAssetAmount}
                    showTitle={false}
                    showAssetName={false}
                    showSpendAmount={false}
                    showRemainingAssets={false}
                />
            </DialogBody>
            <DialogActions>
                <DialogAction
                    text={texts('liquiditySavings.fullyFillOrder')}
                    action={handleFullyFillOrder}
                    icon={<ThumbUpIcon />}
                    disabled={fullFillDisabled}
                />
                <DialogAction
                    text={texts('liquiditySavings.partiallyFillOrder')}
                    action={handlePartiallyFillOrder}
                    icon={<ThumbUpIcon />}
                    disabled={typeof assetSummary == 'undefined'}
                />
            </DialogActions>
        </div>
    );
};

export default OrderDetailsContent;
