import { Asset, PvpRequest, TransactionRequestState } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import CrossChainSwapRequest from './CrossChainSwapRequest';
import RequestList from 'components/commonComponents/RequestListComponents/RequestList';
import RequestListDivider from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDivider';
import RequestListDropdownSection from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownSection';
import RequestListDropdownTitle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownTitle';
import RequestListDropdownToggle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownToggle';
import useFilterRequests from 'hooks/FilterRequests';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import { useTranslation } from 'react-i18next';

type CrossChainSwapRequestsListProps = {
    pvpRequests: PvpRequest[];
    getAssetById: (id: string) => Asset;
    openInfoModal: (crossChainSwapRequest: PvpRequest, ourRequests: boolean) => void;
};

const CrossChainSwapRequestsList: React.FC<CrossChainSwapRequestsListProps> = ({
    pvpRequests,
    getAssetById,
    openInfoModal,
}) => {
    const { t } = useTranslation();
    const { identity, getOwnIdentity } = useGetOurOwnIdentity();
    const { filterSwitches, displayRequestType } = useFilterRequests(true);
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const [pvpRequestByBuyer, setPvpRequestByBuyer] = useState<Map<string, PvpRequest[]>>();

    useEffect(() => {
        getOwnIdentity();
    }, [getOwnIdentity]);

    useEffect(() => {
        let groups = pvpRequests.groupBy('buyer') as Map<string, PvpRequest[]>;
        setPvpRequestByBuyer(groups);
        setCollapesedKeys(Array.from(groups.keys()));
    }, [pvpRequests]);

    const handlePublicKeyClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };

    return (
        <>
            {filterSwitches}
            <RequestListDivider />
            <RequestList>
                {pvpRequestByBuyer !== undefined &&
                    pvpRequestByBuyer.mapGroups((buyer, items) => {
                        const ourRequests = identity === buyer;
                        const publickKeyElement: JSX.Element = (
                            <RequestListDropdownSection>
                                <RequestListDropdownTitle>
                                    {ourRequests ? (
                                        <b>Our Requests </b>
                                    ) : (
                                        <>
                                            <b>{t('cbdcRequests.requestingParty') + ':'} </b> {buyer}{' '}
                                        </>
                                    )}
                                </RequestListDropdownTitle>
                                <RequestListDropdownToggle
                                    toggleCondition={collapsedKeys.includes(buyer)}
                                    onClick={() => handlePublicKeyClick(buyer)}
                                />
                            </RequestListDropdownSection>
                        );
                        if (collapsedKeys.includes(buyer)) {
                            return <div key={buyer}>{publickKeyElement} </div>;
                        }
                        return (
                            <div key={buyer}>
                                {publickKeyElement}
                                {(items as PvpRequest[])
                                    .filter((req) => displayRequestType(req.requestState as TransactionRequestState))
                                    .reverse()
                                    .map((request) => {
                                        return (
                                            <CrossChainSwapRequest
                                                key={buyer + '_' + request.requestId}
                                                onClick={() => openInfoModal(request, ourRequests)}
                                                request={request}
                                                getAssetById={getAssetById}
                                            />
                                        );
                                    })}
                            </div>
                        );
                    })}
            </RequestList>
            <RequestListDivider />
        </>
    );
};

export default CrossChainSwapRequestsList;
