import { Asset, ComplianceRuleKey, INITIAL_EMPTY_ASSET_STATE, StepperFormStep } from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';

import BondOverviewStep from 'components/commonComponents/GenericStepperFormComponents/BondOverviewStep';
import DvpRequestSummaryStep from './DvpRequestSummaryStep';
import EnterRequestAmountsStep from './EnterRequestAmountsStep';
import { Fade } from '@material-ui/core';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAssetStep from 'components/commonComponents/GenericStepperFormComponents/SelectAssetStep';
import SelectParticipantStep from 'components/commonComponents/GenericStepperFormComponents/SelectParticipantStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { requestDvp } from 'api/transactApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetBondsSummary from 'hooks/GetBondsSummary';
import useGetParticipantsOnNetwork from 'hooks/GetParticipantsOnNetwork';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type Props = {
    ignoreInfoModal?: boolean;
    onSuccessfulSubmit?: () => void;
};

export const RequestDvp: React.FC<Props> = ({ onSuccessfulSubmit, ignoreInfoModal = false }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAsset, setSelectedAsset] = useState<Asset>(INITIAL_EMPTY_ASSET_STATE);
    const [selectedParticipant, setSelectedParticipant] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { assets, getAllAssets, assetsError } = useGetAssetDefinitions();
    const { bonds, getAllBondsData } = useGetBondsSummary();
    const { participants, getParticipants, participantsError } = useGetParticipantsOnNetwork();
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const [bondsAmount, setBondsAmount] = useState<string>('0');
    const { t } = useTranslation();
    const checklistContext = useInteractiveChecklistContext();
    const location = useLocation();

    const handleReset = () => {
        setSelectedParticipant('');
        setAssetAmount('');
        setBondsAmount('');
        setSelectedAsset(INITIAL_EMPTY_ASSET_STATE);
        getAllBondsData();
    };

    useEffect(() => {
        setError((text) => text + (text.length > 0 ? '. ' : '') + participantsError);
    }, [participantsError]);

    const submitOffer = async () => {
        const dvpOfferResponse: ResolvedPromise = await requestDvp(
            selectedParticipant,
            selectedAsset.tokenIdentifier,
            assetAmount,
            bondsAmount
        );

        if (dvpOfferResponse.error) {
            enqueueSnackbar(t('error.requestingDvp', { error: dvpOfferResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.requestingDvp'), {
                variant: 'success',
            });
            checklistContext?.completeStep(location.pathname);
            if (onSuccessfulSubmit) {
                onSuccessfulSubmit();
            }
        }
        handleReset();
    };

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    useEffect(() => {
        getAllBondsData();
        getAllAssets();
        getParticipants();
    }, [enqueueSnackbar, t, getAllAssets, getParticipants, getAllBondsData]);

    const getSelectedAsset = (assetName: string): Asset => {
        return assets.find((a) => a.tokenName === assetName) ?? INITIAL_EMPTY_ASSET_STATE;
    };

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = getSelectedAsset(event.target.value as string);
        setAssetAmount('0');
        setSelectedAsset(asset);
    };

    const invalidAssetAmountInput = (): boolean => {
        if (assetAmount.length <= 0 || parseFloat(assetAmount) <= 0) {
            return true;
        }

        if (
            selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) &&
            parseFloat(selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)!.value) <
                parseFloat(assetAmount)
        ) {
            return true;
        }

        return false;
    };

    const invalidBondsAmountInput = (): boolean => {
        if (bondsAmount.length <= 0 || parseFloat(bondsAmount) <= 0 || parseFloat(bondsAmount) > bonds.usdValue) {
            return true;
        }
        return false;
    };

    const steps: StepperFormStep[] = [
        {
            title: t('requestCBDC.viewBondsStep'),
            component: <BondOverviewStep bonds={bonds} />,
            isNextStepDisabled: () => {
                if (bonds.usdValue > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('commonText.selectParticipantStep'),
            component: (
                <SelectParticipantStep
                    selectedParticipant={selectedParticipant}
                    title={t('requestDvp.selectParticipant')}
                    setSelectedParticipant={setSelectedParticipant}
                    participants={participants.participants}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedParticipant.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('transferAssets.selectAssetStep'),
            component: (
                <SelectAssetStep
                    title={t('requestDvp.selectAvailableAsset')}
                    assets={assets}
                    assetName={selectedAsset.tokenName}
                    handleAssetChange={handleAssetChange}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('requestDvp.offerAmountsStep'),
            component: (
                <EnterRequestAmountsStep
                    selectedAsset={selectedAsset}
                    setAssetAmount={setAssetAmount}
                    setBondsAmount={setBondsAmount}
                    invalidAssetAmountInput={invalidAssetAmountInput()}
                    invalidBondsAmountInput={invalidBondsAmountInput()}
                    bondValue={bonds.usdValue}
                    bondsAmount={bondsAmount}
                />
            ),
            isNextStepDisabled: () => {
                if (assetAmount.length > 0 && !invalidAssetAmountInput() && !invalidBondsAmountInput()) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('requestDvp.confirmDvpOffer'),
            component: (
                <DvpRequestSummaryStep
                    assetAmount={assetAmount}
                    bondsAmount={bondsAmount}
                    assetName={selectedAsset.tokenName}
                    selectedParticipant={selectedParticipant}
                />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitOffer}
                    steps={steps}
                    renderError={error}
                    submitButtonText={'Request'}
                    handleReset={handleReset}
                    ignoreInfoModal={ignoreInfoModal}
                />
            </Fade>
        </>
    );
};
