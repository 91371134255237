import { Card, Divider, IconButton, LinearProgress, Slide, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { CheckListAction } from '../../constants/InteractiveChecklistList';
import ClearIcon from '@material-ui/icons/Clear';
import ReplayIcon from '@material-ui/icons/Replay';
import { TutorialControls } from './TutorialControls';
import { TutorialDialog } from './TutorialDialog';
import { TutorialStep } from './TutorialStep';
import { useAssistantStyles } from '@r3/cbdc-asset-frontend-core';
import { useInteractiveChecklistContext } from '../../contexts/InteractiveChecklist/InteractiveChecklist';

type AssistantProps = {
    isVisible: boolean;
    closeWindow: () => void;
    openWindow: () => void;
};

export const TutorialWindow: React.FC<AssistantProps> = ({ isVisible, closeWindow, openWindow }) => {
    const checkListContext = useInteractiveChecklistContext();
    const classes = useAssistantStyles();
    const [tutorialSteps, setTutorialSteps] = useState<CheckListAction[]>([]);
    const [focusedItem, setFocusedItem] = useState<CheckListAction | null>(null);
    const [progress, setProgress] = useState<number>(2);
    const currentGoalRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (checkListContext) {
            setTutorialSteps(checkListContext.getAllActions());
            setProgress(checkListContext.getCurrentStateStep());
        }
    }, [checkListContext]);

    useEffect(() => {
        if (currentGoalRef.current) {
            currentGoalRef.current.scrollIntoView();
        }
        // eslint-disable-next-line
    }, [currentGoalRef.current]);

    const options: JSX.Element[] = [];

    tutorialSteps.forEach((option, index) => {
        options.push(
            <TutorialStep
                key={index}
                option={option}
                index={index}
                progress={progress}
                currentGoalRef={currentGoalRef}
                setFocusedItem={setFocusedItem}
            />
        );
    });

    const normaliseProgress = (value) => ((value - 0) * 100) / (tutorialSteps.length - 0);

    return (
        <>
            <Slide direction="left" in={isVisible} timeout={{ enter: 350, exit: 350 }}>
                <Card elevation={15} className={`${classes.assistantWindow}`} data-testid={'tutorial_window'}>
                    <div className={classes.assistantHeader}>
                        <div className={classes.assistantTitle}>Sandbox Guide</div>
                        <AssignmentTurnedInIcon color={'secondary'} className={classes.assistantIcon} />
                        <IconButton
                            className={classes.assistantHideButton}
                            onClick={() => {
                                closeWindow();
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <Divider className={classes.divider} />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className={classes.assistantHeaderText}>
                            WS Progress: {((progress / tutorialSteps.length) * 100).toFixed(0) + '% complete'}{' '}
                        </div>
                        <Tooltip title="Restart Checklist" placement="top">
                            <IconButton
                                onClick={() => {
                                    checkListContext?.reset();
                                }}
                                color="secondary"
                                style={{ height: 10, width: 10, marginLeft: 'auto', marginRight: 'auto' }}
                            >
                                <ReplayIcon />
                            </IconButton>
                        </Tooltip>
                    </div>

                    <LinearProgress
                        variant="determinate"
                        color="secondary"
                        value={normaliseProgress(progress)}
                        style={{
                            width: '90%',
                            marginLeft: 6,
                            marginRight: 'auto',
                            marginBottom: 20,
                            height: 20,
                            borderRadius: 6,
                        }}
                        className={`${classes.progressBar} ${
                            checkListContext?.completed && checkListContext.completed === true
                                ? classes.progressBarAdded
                                : ''
                        }`}
                    />

                    <div className={classes.assistantOptions} style={{ height: '65%' }}>
                        {options}
                    </div>

                    <Divider className={classes.divider} />

                    <TutorialControls
                        previousStep={checkListContext?.goBackOneStep}
                        skipCurrentStep={checkListContext?.skipCurrentStep}
                        setFocusedItem={setFocusedItem}
                        currentOption={tutorialSteps[progress]}
                        progress={progress}
                        totalSteps={tutorialSteps.length}
                    />
                </Card>
            </Slide>

            <TutorialDialog setFocusedItem={setFocusedItem} focusedItem={focusedItem} progress={progress} />
        </>
    );
};
