import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { Order } from 'models/orders/Order';
import RequestListContainer from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListContainer';
import RequestListItem from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListItem';
import { useTranslation } from 'react-i18next';

type ExchangeOrderProps = {
    order: Order;
    onClick: () => void;
    showStatus?: boolean;
};

const ExchangeOrder: React.FC<ExchangeOrderProps> = ({ order, onClick }) => {
    const { t } = useTranslation();
    return (
        <RequestListContainer onClick={() => onClick()} data-testid={order.orderId}>
            <RequestListItem>
                <b>{t('liquiditySavings.offerMaker') + ': '}</b>
                {order.creator}
            </RequestListItem>
            <RequestListItem>
                <b>{t('liquiditySavings.currencyRequested') + ': '}</b>
                {`${order.requestedTokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                    order.requestedAssetAmount.toFixed(order.requestedTokenDefinition.tokenDecimals)
                )}`}
            </RequestListItem>
            <RequestListItem>
                <b>{t('liquiditySavings.currencyOffered') + ': '}</b>
                {`${order.offeredTokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                    order.offeredAssetAmount.toFixed(order.offeredTokenDefinition.tokenDecimals)
                )}`}
            </RequestListItem>
            <RequestListItem>
                <b>{t('commonText.txTime') + ': '}</b> {new Date(order.lastUpdated).toLocaleString()}
            </RequestListItem>
        </RequestListContainer>
    );
};

export default ExchangeOrder;
