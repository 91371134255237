import { useCallback, useEffect } from 'react';

import { PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import { PaymentSetterProps } from './types';
import QrReader from 'react-qr-reader';
import { usePaymentRequestService } from '../../../hooks/PaymentRequestService';
import { useState } from 'react';

const QrCodePayment: React.FC<PaymentSetterProps> = ({ setPaymentRequest }) => {
    const [codeValue, setCodeValue] = useState<string>('');
    const { verifyPaymentRequest } = usePaymentRequestService();

    const handleScan = (data) => {
        if (data) {
            setCodeValue(data);
        }
    };
    const handleError = (err) => {
        console.error(err);
    };

    const submit = useCallback(() => {
        const requestJson = JSON.parse(codeValue) as PaymentRequestResponse;
        if (verifyPaymentRequest(requestJson)) {
            setPaymentRequest(requestJson);
        } else {
            alert('Failed to interpret QR code, please try again. Please make sure to use a payment request QR code.');
            setCodeValue('');
        }
    }, [codeValue, setPaymentRequest, verifyPaymentRequest]);

    useEffect(() => {
        if (codeValue.length <= 0) {
            return;
        }
        submit();
    }, [codeValue, submit]);

    return (
        <div style={{ maxWidth: 500, minWidth: 280, height: 'auto', margin: 'auto' }}>
            <QrReader delay={200} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
        </div>
    );
};

export default QrCodePayment;
