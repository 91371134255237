import * as ROUTES from './Routes';

export type CheckListAction = {
    description: string;
    url: string;
    steps: string[];
    clickToComplete?: boolean;
};

const url = window.location.href;
let splitUrl = url.substring(url.indexOf('.'), url.length);
splitUrl = splitUrl.substring(0, splitUrl.indexOf('.com') + 4);

//These are the checklist actions in order that they should be completed in by the user
export const CHECKLISTACTIONS: CheckListAction[] = [
    {
        description: 'Sandbox Guide Wholesale Intro',
        url: '/',
        steps: [
            'If you have not completed the Central Bank Sandbox Guide steps previously, please start there.',
            'Lets recap...',
            'You have defined a new CBDC asset.',
            'You have granted Wholesale Bank One and Two access to the newly created CBDC asset through Member Access States.',
            'You have issued CBDC to Wholesale Bank One.',
            'Now it is time to explore the Sandbox from the Wholesale interface.',
            'Click "Next" to continue the guide.',
        ],
        clickToComplete: true,
    },
    {
        description: 'View Wholesale CBDC Assets',
        url: ROUTES.ASSETDASH,
        steps: [
            'Navigate to the Treasury Dashboard page (top bar).',
            'Navigate to the CBDC tab.',
            'View the amount and types of CBDC assets in the vault.',
        ],
    },
    {
        description: 'View Wholesale Bonds',
        url: ROUTES.BONDSDASH,
        steps: [
            'Navigate to the Treasury Dashboard page (top bar).',
            'Navigate to the Bonds tab.',
            'View the amount of bonds in the Wholesale Bank vault.',
            'Note: Wholesale Banks can request a CBDC asset by using these bonds as collateral.',
            'Note: Bonds are returned to the Wholesale Bank when they redeem their assets with the Central Bank.',
        ],
    },
    {
        description: 'View a list of defined CBDC assets',
        url: ROUTES.AVAILABLECBDC,
        steps: [
            'Navigate to the CBDC Manager page (top bar).',
            'Navigate to the Available CBDC tab.',
            'View a list of CBDC assets created on the network.',
            'Note: Every Wholesale Bank can view any assets defined by the Central Bank.',
        ],
    },
    {
        description: 'View Issued Member Access States',
        url: ROUTES.LISTKYC,
        steps: [
            'Navigate to the Access Controls page (top bar).',
            'Navigate to the List tab.',
            'View all Member Access States issued to that Wholesale Bank.',
        ],
    },
    {
        description: 'Request a Member Access State (Optional)',
        url: ROUTES.REQUESTKYCSTATE,
        steps: [
            'You can skip this step as you have issued a Member Access State already for the new asset, as the Central Bank.',
            'Navigate to the Access Controls page (top bar).',
            'Navigate to the Request Access tab.',
            'Select the asset you would like access to.',
            'Note: A Wholesale Bank can request a Member Access State for any asset on the network.',
        ],
        clickToComplete: true,
    },
    {
        description: 'Request Issuance of CBDC',
        url: ROUTES.REQUESTCBDC,
        steps: [
            'Navigate to the CBDC Manager page (top bar).',
            'Navigate to the Request CBDC tab.',
            'Here you can request issuance of a CBDC asset, using bonds as collateral.',
            'Complete the form and request a specified amount of the newly created CBDC asset.',
            'Note: The Central Bank must respond to this request for the CBDC to be issued.',
        ],
    },
    {
        description: 'Request Redemption of CBDC',
        url: ROUTES.REDEEMCBDC,
        steps: [
            'Navigate to the CBDC Manager page (top bar).',
            'Navigate to the Redeem CBDC tab.',
            'Here you can request to redeem a specified amount of a CBDC asset for bonds.',
            'Complete the form and request redemption, exchanging the CBDC asset for bonds.',
            'Note: The Central Bank must respond to this request.',
        ],
    },
    {
        description: 'Go to the Central Bank interface',
        url: '/',
        steps: [
            'Continue the Sandbox Guide on the Central Bank interface.',
            'There you will approve the CBDC issuance and redemption requests.',
            'Navigate to Central Bank interface.',
            'http://centralbank' + splitUrl,
            'Return here after you have approved the CBDC issuance request.',
            'Press ( Next ) in the Sandbox Guide to continue.',
        ],
        clickToComplete: true,
    },
    {
        description: 'View Updated Wholesale CBDC Assets',
        url: ROUTES.ASSETDASH,
        steps: [
            'Navigate to the Treasury Dashboard page (top bar).',
            'Navigate to the CBDC tab.',
            'View the amount and types of CBDC assets in the vault.',
            'Note: The asset you previously requested has been transfered to your vault.',
        ],
    },
    {
        description: 'View Updated Wholesale Bonds',
        url: ROUTES.BONDSDASH,
        steps: [
            'Navigate to the Treasury Dashboard page (top bar).',
            'Navigate to the Bonds tab.',
            'View the amount of bonds in the vault.',
            'Note: The bonds that were included in the CBDC issuance request have been deducted from your vault.',
            'Note: The bonds that were requested in the CBDC redemption request have been added to your vault.',
        ],
    },
    {
        description: 'Transfer CBDC to Wholesale Two (PUSH)',
        url: ROUTES.TRANSFERCBDC,
        steps: [
            'Navigate to the CBDC Manager page (top bar).',
            'Navigate to the Transfer CBDC tab.',
            'Complete a Push Transfer.',
            'Select the newly defined asset and transfer CBDC to Wholesale Bank Two.',
            '**Take note of how much you have transfered.**',
            'Note: Recall issuing Member Access states for both Wholesale Bank One and Two.',
            'Note: Member Access States will be exchanged and the assets will be moved from Wholesale Bank One to Wholesale Bank Two.',
        ],
    },
    {
        description: 'Request CBDC from Wholesale Two (PULL)',
        url: ROUTES.TRANSFERCBDC,
        steps: [
            'Navigate to the CBDC Manager page (top bar).',
            'Navigate to the Transfer CBDC tab.',
            'Select the newly defined asset and request CBDC from Wholesale Bank Two.',
            'Complete a Pull Transfer Request.',
            '**Request half of what you have transferred in the previous step.**',
            'Note: A request for this CBDC will be sent to Wholesale Bank Two. You can approve / deny this request on the Wholesale Two Client',
        ],
    },
    {
        description: 'Request a DVP Transaction from Wholesale Bank Two',
        url: ROUTES.REQUESTDVP,
        steps: [
            'Navigate to the CBDC Manager page (top bar).',
            'Navigate to the Request DVP Tab.',
            'Select request from WHOLESALETWO.',
            'Select the newly created asset and complete the form.',
            '**Request half of what you have transferred in the push transfer step.**',
            'Note: The bond amount will act as an offer for the CBDC amount.',
            'Note: Wholesale Bank Two will have to approve this DVP request then an atomic transaction will occur.',
        ],
    },
    {
        description: 'Go to the Wholesale Bank Two interface',
        url: '/',
        steps: [
            'You have completed the Sandbox Guide from the Wholesale Bank One interface.',
            'Go to Wholesale Bank Two interface to view the transferred amount and respond to DVP Request.',
            'http://wholesaletwo' + splitUrl,
            'Return here once you are ready to request a Cross Chain Swap. (Click next to continue)',
        ],
        clickToComplete: true,
    },
    {
        description: 'Request Cross Chain Swap from Wholesale Bank Two',
        url: ROUTES.REQUESTCROSSCHAINSWAP,
        steps: [
            'Navigate to the CBDC Manager page (top bar).',
            'Navigate to the Request Cross Chain Swap Tab.',
            'Select request from WHOLESALETWO.',
            'Request the latest asset you have created ( Signed by the Second Notary ).',
            'Offer the asset you created at the start of this guide ( Signed by the first Notary ).',
            'Note: Make sure both Wholesale Bank One and Two have enough assets to perform this swap. ( You can issue more assets from the Central Bank interface )',
            'Note: Wholesale Bank Two will have to approve this Cross Chain Swap request, then an atomic transaction will occur.',
        ],
    },
    {
        description: 'Go to the Wholesale Bank Two interface',
        url: '/',
        steps: [
            'Go to Wholesale Bank Two interface to respond to and complete the Cross Chain Swap.',
            'http://wholesaletwo' + splitUrl,
        ],
        clickToComplete: true,
    },
    {
        description: 'Make an offer on the DLE',
        url: ROUTES.MAKEOFFER,
        steps: [
            'Navigate to the Decentralized Liquidity Exchange page (top bar).',
            'Navigate to the Make Offer tab.',
            'Choose an asset to request and an asset to offer. ( If you do not have the required assets to do this, you can issue more assets from the Central Bank to both Wholesale One and Two. ) ',
            'Enter the amounts of the assets you would like to request and offer. This sets the exchange rate which can be fully filled or partially filled by the counterparty.',
            'Select the broadcast participants for this offer. Select "All network participants". ( Parties that can view and fill this offer. )',
            'Review and and list the offer.',
            'Note: The assets that you have offered are now locked and can not be spent by this bank. This is to guarantee an atomic swap when another party accepts this offer.',
        ],
    },
    {
        description: 'View DLE offers',
        url: ROUTES.MANAGEOFFERS,
        steps: [
            'Navigate to the Decentralized Liquidity Exchange page (top bar).',
            'Navigate to the Manage Offers tab.',
            'View the pending offer you have listed.',
            'Note: You can cancel or update any pending offers you have listed here.',
            'Note: On the left you can view all of the offers that you have listed, that have been filled by other parties.',
        ],
    },
    {
        description: 'Go to the Wholesale Bank Two interface',
        url: '/',
        steps: [
            'You have listed an offer on the DLE, now you can view and fill this offer from any Wholesale bank on the network.',
            'Go to Wholesale Bank Two.',
            'http://wholesaletwo' + splitUrl,
        ],
        clickToComplete: true,
    },
    {
        description: 'You have finished, return to CB Interface',
        url: '/',
        steps: [
            'You have completed the Sandbox Guide from the Wholesale interfaces.',
            'Click "Next" and return to the Central Bank interface to complete the final steps.',
            'Note: Go to the Corda Transactions Dashboard / Home page to view some of the corda transaction activity on the node.',
            'Note: You can view some analytics, in the Balance Over Time Tab of the Treasury Dashboard. Which shows you asset balance over time.',
        ],
        clickToComplete: true,
    },
];

export const CHECKLISTACTIONSWS2: CheckListAction[] = [
    {
        description: 'View Transfered CBDC Assets',
        url: ROUTES.ASSETDASH,
        steps: [
            'Navigate to the Treasury Dashboard page (top bar).',
            'Navigate to the CBDC tab.',
            'View the transferred amount of CBDC in the vault.',
            'Note: The asset you previously transferred from Wholesale Bank One has been transferred to this vault.',
        ],
    },
    {
        description: 'Respond to a Pull Transfer Request',
        url: ROUTES.TRANSFERREQUESTS,
        steps: [
            'Navigate to the Requests page (top bar).',
            'Navigate to the Pull Transfer Requests tab.',
            'Approve the Pull Transfer Request created on the Wholesale Bank One interface.',
        ],
    },
    {
        description: 'Respond to a DVP Request',
        url: ROUTES.DVPREQUESTS,
        steps: [
            'Navigate to the Requests page (top bar).',
            'Navigate to the DVP Requests tab.',
            'Approve the DVP request you created on the Wholesale Bank One interface.',
            'Note: This will initiate an atomic bonds for CBDC swap between Wholesale Bank One and Wholesale Bank Two.',
        ],
    },
    {
        description: 'Go to the Central Bank interface',
        url: '/',
        steps: [
            'Continue the Sandbox Guide on the Central Bank interface.',
            'There you will prepare for a Cross Chain Swap.',
            'Navigate to Central Bank interface.',
            'http://centralbank' + splitUrl,
            'Return here after you have completed a Cross Chain Swap Request from Wholesale Bank One.',
            'Press ( Next ) in the Sandbox Guide to continue.',
        ],
        clickToComplete: true,
    },
    {
        description: 'Respond to the Cross Chain Swap Request',
        url: ROUTES.CROSSCHAINSWAPREQUESTS,
        steps: [
            'Navigate to the Requests page (top bar).',
            'Navigate to the Cross Chain Swap Requests tab.',
            'Approve the Cross Chain Swap request you created on the Wholesale Bank One interface.',
            'Note: This will initiate an atomic PVP transaction between Wholesale Bank One and Wholesale Bank Two.',
            'Note: This atomic swap occurs cross chain ( Between two assets which have different signing Notaries ).',
        ],
    },
    {
        description: 'Go to the Wholesale Bank One interface',
        url: '/',
        steps: [
            'Next you will make an offer on the Decentralized Liquidity Exchange from Wholesale Bank One.',
            'Go to the Wholesale One bank interface. You will return here to view and accept the offer.',
            'http://wholesaleone' + splitUrl,
        ],
        clickToComplete: true,
    },
    {
        description: 'Fill a DLE offer',
        url: ROUTES.BROWSEEXCHANGE,
        steps: [
            'Navigate to the Decentralized Liquidity Exchange page (top bar).',
            'Navigate to the Browse Liquidity Pool tab.',
            'Find the order you have listed from Wholesale Bank One. ( Filtering by asset pair on the left may help. )',
            'Click the order and accept the full offer. ( If you do not have enough of the requested asset, issue some to this bank from the Central Bank for the purpose of this guide. )',
            'Note: You can confirm the assets have been swapped in the Treasury Dashboard -> CBDC Assets page.',
            'Note: You can also accept the offer partially, by entering the amount of the offered asset you want to fill. The difference from the original offered amount will be refunded to the offer maker.',
        ],
    },
    {
        description: 'View offer log',
        url: ROUTES.OFFERLOG,
        steps: [
            'Navigate to the Decentralized Liquidity Exchange page (top bar).',
            'Navigate to the Offer Log tab.',
            'View the order you have just filled. Here you can view all filled orders ( that you participated in ) and orders that were cancelled by this node.',
        ],
    },
    {
        description: 'You have finished, return to the CB Interface',
        url: '/',
        steps: [
            'You have completed the Sandbox Guide from the Wholesale interface.',
            'Click "Next" and return to the Central Bank interface to complete the final steps.',
            'Note: Go to the Corda Transactions Dashboard / Home page to view some of the corda transaction activity on the node.',
            'Note: You can view some analytics, in the Balance Over Time Tab of the Treasury Dashboard. Which shows you asset balance over time.',
        ],
        clickToComplete: true,
    },
];
