import { Header, useLandingPageStyles } from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { TransactionDashboard } from './TransactionDashboard';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

export const TransactionDashboardParent = () => {
    const { t } = useTranslation();
    const classes = useLayoutStyles();
    const homeStyles = useLandingPageStyles();
    const [isTxLogCBDC, setIsTxLogCBDC] = useState<boolean>(true);
    const [txLogQueryType, setTxLogQueryType] = useState<'CBDCToken' | 'KYCReferenceState'>('CBDCToken');

    useEffect(() => {
        if (isTxLogCBDC) {
            setTxLogQueryType('CBDCToken');
        } else {
            setTxLogQueryType('KYCReferenceState');
        }
    }, [isTxLogCBDC]);

    return (
        <>
            <Header>{t('pageTitle.transactionDashboard')}</Header>
            <div style={{ width: '98%' }} className={`  ${classes.columnNoPadding}`}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            marginTop: 12,
                            marginBottom: -10,
                        }}
                    >
                        <Button
                            className={` ${homeStyles.tabButton} ${isTxLogCBDC ? homeStyles.selectedTab : ''} tab`}
                            onClick={() => setIsTxLogCBDC(true)}
                            endIcon={<LocalAtmIcon />}
                        >
                            CBDC States
                        </Button>

                        <Button
                            className={` ${homeStyles.tabButton}  ${!isTxLogCBDC ? homeStyles.selectedTab : ''} tab`}
                            onClick={() => setIsTxLogCBDC(false)}
                            endIcon={<RecentActorsIcon />}
                        >
                            Member Access States
                        </Button>
                    </div>
                    <TransactionDashboard stateToQuery={txLogQueryType} />
                </div>
            </div>
        </>
    );
};
