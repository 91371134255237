import { useEffect, useMemo, useState } from 'react';

import ContextMenu from 'components/commonComponents/ContextMenu/ContextMenu';
import { MouseCoords } from 'models/MouseCoords';
import { createCtx } from 'contexts/createCtx';
import useComponentVisible from 'hooks/ComponentVisible';

type ContextMenuContextProps = {
    handleContextMenuClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    setMenuContent: (menuContext: React.ReactNode | undefined) => void;
};

const [useContextMenu, Provider] = createCtx<ContextMenuContextProps>();

export default useContextMenu;

type ProviderProps = {
    menuContent?: React.ReactNode;
    children?: React.ReactNode;
};

export const ContextMenuProvider: React.FunctionComponent<ProviderProps> = ({
    children,
    menuContent: defaultComponent,
}) => {
    const [mouseCoords, setMouseCoords] = useState<MouseCoords | undefined>(undefined);
    const [menuContent, setMenuContent] = useState<React.ReactNode | undefined>(undefined);

    useEffect(() => {
        setMenuContent(defaultComponent);
    }, [defaultComponent]);

    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    function handleContextMenuClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
        if (!menuContent) return;
        e.preventDefault();
        setMouseCoords({ x: e.pageX, y: e.pageY });
        setIsComponentVisible(!isComponentVisible);
    }

    const contextMenu = useMemo(() => {
        if (!mouseCoords || !menuContent || !isComponentVisible) return null;

        return (
            <ContextMenu
                ref={ref}
                closeMenu={() => {
                    setIsComponentVisible(false);
                }}
                mouseCoords={mouseCoords}
                isOpen={isComponentVisible}
            >
                {menuContent}
            </ContextMenu>
        );
    }, [menuContent, mouseCoords, isComponentVisible, ref, setIsComponentVisible]);

    return (
        <Provider value={{ handleContextMenuClick, setMenuContent }}>
            {children}
            {contextMenu}
        </Provider>
    );
};
