import { OffersDisplayProps, Refreshable } from 'components/decentralizedExchange/Types';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';

import { OrderStatus } from 'models/orders/OrderStatus';
import OrdersListWrapper from '../OffersListComponents/OrdersListWrapper';
import useGetExchangeOrders from 'hooks/DecentralizedExchange/useGetExchangeOffers';
import { useTranslation } from 'react-i18next';
import PendingOrderDialog from './PendingOrderDialog';

const orderStatus: OrderStatus = OrderStatus.OPEN;

const PendingOrders = forwardRef(({ identity }: OffersDisplayProps, ref: React.Ref<Refreshable>) => {
    const { t: texts } = useTranslation();
    const { orders, error, getOrders, isLoading } = useGetExchangeOrders(orderStatus);

    const refreshOrders = useCallback(() => {
        if (identity.length !== 0) {
            getOrders({ identity });
        }
    }, [identity, getOrders]);

    useEffect(() => {
        refreshOrders();
    }, [refreshOrders]);

    useImperativeHandle(ref, () => ({
        refresh() {
            refreshOrders();
        },
    }));

    const displayMessage = isLoading
        ? texts('commonText.loading')
        : error != null
        ? error
        : orders.length === 0
        ? texts('liquiditySavings.noExchangeOffersWithStatus', { status: texts('liquiditySavings.openDisplayStatus') })
        : '';

    return (
        <OrdersListWrapper
            offers={orders}
            displayMessage={displayMessage}
            header={texts('liquiditySavings.pendingOffers')}
            getDialog={(order, open, onClose) => (
                <PendingOrderDialog order={order} open={open} onClose={onClose} refreshOrders={refreshOrders} />
            )}
        />
    );
});

export default PendingOrders;
