import { Asset, AssetResponse, SummedAsset } from '@r3/cbdc-asset-frontend-core';

class AssetTransforms {
    summedAssetsFromSummary = (assetSummaries: AssetResponse[], assets: Asset[]): SummedAsset[] => {
        return assetSummaries.map((assetSummary) => ({
            tokenIdentifier: assetSummary.tokenDefinition.tokenIdentifier,
            totalInVault: assetSummary.totalAmount,
            totalInAccounts: assetSummary.amountHeldByAccounts,
            availableToTransact: assetSummary.amountHeldByNode,
            tokenName: assetSummary.tokenDefinition.tokenName,
            tokenDecimal: assetSummary.tokenDecimals,
            complianceRules: assets.find((a) => a.tokenIdentifier === assetSummary.tokenDefinition.tokenIdentifier)
                ?.complianceRules,
            issuer: assetSummary.issuer,
        }));
    };

    summedAssetFromSummary = (assetSummary: AssetResponse): SummedAsset => {
        return {
            tokenIdentifier: assetSummary.tokenDefinition.tokenIdentifier,
            totalInVault: assetSummary.totalAmount,
            totalInAccounts: assetSummary.amountHeldByAccounts,
            availableToTransact: assetSummary.amountHeldByNode,
            tokenName: assetSummary.tokenDefinition.tokenName,
            tokenDecimal: assetSummary.tokenDecimals,
            issuer: assetSummary.issuer,
        };
    };

    summedAssetFromSummaryWithComplianceRules = (assetSummary: AssetResponse, assets: Asset[]): SummedAsset => {
        return {
            tokenIdentifier: assetSummary.tokenDefinition.tokenIdentifier,
            totalInVault: assetSummary.totalAmount,
            totalInAccounts: assetSummary.amountHeldByAccounts,
            availableToTransact: assetSummary.amountHeldByNode,
            tokenName: assetSummary.tokenDefinition.tokenName,
            complianceRules: assets.find((a) => a.tokenIdentifier === assetSummary.tokenDefinition.tokenIdentifier)
                ?.complianceRules,
            tokenDecimal: assetSummary.tokenDecimals,
            issuer: assetSummary.issuer,
        };
    };
}

export default new AssetTransforms();
