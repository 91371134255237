import { Button, Fade, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Header } from '@r3/cbdc-asset-frontend-core';
import QRCode from 'qrcode.react';
import { useFormStyles } from 'materialStyles/formStyles';
import { useRetailLoginStyles } from 'materialStyles/retailStyles/retailLoginStyles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useUserContextProvider } from '../../contexts/RetailUserLogin/RetailUserContext';

const RetailLogin = () => {
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const [userNameInput, setUserNameInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const formClasses = useFormStyles();
    const classes = useRetailLoginStyles();
    const userContext = useUserContextProvider();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const login = async () => {
        setSubmitButtonDisabled(true);
        const loggedIn = await userContext?.loginUser(userNameInput, passwordInput);
        if (loggedIn) {
            enqueueSnackbar(t('success.loggingIn'), {
                variant: 'success',
            });
        } else {
            enqueueSnackbar(t('error.loggingIn'), {
                variant: 'error',
            });
        }
        setSubmitButtonDisabled(false);
    };

    const handleFormInput = (event) => {
        event.preventDefault();
        const value = event.target.value;
        if (event.target.name === 'Username') {
            setUserNameInput(value.toLowerCase());
        } else if (event.target.name === 'Password') {
            setPasswordInput(value);
        }
    };

    useEffect(() => {
        if (userNameInput.length > 0 && passwordInput.length > 0) {
            setSubmitButtonDisabled(false);
        } else {
            setSubmitButtonDisabled(true);
        }
    }, [userNameInput, passwordInput]);
    return (
        <div className="loginPageWrapper">
            <Header>{'Retail Login'}</Header>
            <Fade in={true}>
                <div className={formClasses.column}>
                    <TextField
                        className={`${formClasses.columnItem} usernameInput ${formClasses.formInput}`}
                        label={'Email'}
                        onChange={(event) => {
                            handleFormInput(event);
                        }}
                        name="Username"
                        value={userNameInput}
                        required
                        variant="filled"
                    />

                    <TextField
                        className={`${formClasses.columnItem} passwordInput ${formClasses.formInput}`}
                        label={'Password'}
                        onChange={(event) => {
                            handleFormInput(event);
                        }}
                        name="Password"
                        value={passwordInput}
                        required
                        type="password"
                        variant="filled"
                    />

                    <div className={formClasses.formControl}>
                        <Button
                            className={`${formClasses.formControlButton} submitButton  ${formClasses.button}`}
                            variant="outlined"
                            color="primary"
                            disabled={submitButtonDisabled}
                            onClick={login}
                        >
                            {'Log In'}
                        </Button>
                    </div>

                    <div className={classes.qrDiv}>
                        <Typography className={classes.qrText}>Scan and log in on mobile</Typography>
                        <QRCode
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            value={window.location.href}
                            size={180}
                            bgColor={'#ffffff'}
                            fgColor={'#000000'}
                            level={'M'}
                            includeMargin={true}
                            renderAs={'svg'}
                        />
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default RetailLogin;
