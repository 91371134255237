import { Button, Typography } from '@material-ui/core';
import { useContext, useState } from 'react';

import ConfirmPaymentDetails from './ConfirmPaymentDetails';
import { PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import { RetailPayementRequestsContext } from '../../../contexts/RetailPaymentRequestContext/RetailPaymentRequestContext';
import { RetailTransactionsContext } from '../../../contexts/RetailTransactionsContext/RetailTransactionsContext';
import { payPaymentRequest } from '../../../api/retailApi';
import { useFormStyles } from 'materialStyles/formStyles';
import useGetRetailAssetDefinitions from '../../../hooks/GetRetailAssetDefinitions';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import useRetailPayeesContext from 'contexts/RetailPayeesContext/RetailPayeesContext';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useUserContextProvider } from '../../../contexts/RetailUserLogin/RetailUserContext';

type ConfirmPaymentProps = {
    paymentRequest: PaymentRequestResponse;
};

const ConfirmPayment: React.FC<ConfirmPaymentProps> = ({ paymentRequest }) => {
    const classes = useLayoutStyles();
    const formClasses = useFormStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { getAssetbyId, assets } = useGetRetailAssetDefinitions(true);
    const { getPayeeNameByAccountId } = useRetailPayeesContext();
    const { isOwnPaymentRequest } = useContext(RetailPayementRequestsContext);
    const { refreshTransactions } = useContext(RetailTransactionsContext);
    const userContext = useUserContextProvider();
    const [paymentCompleted, setPaymentCompleted] = useState<boolean>(false);
    const [isPaymentPending, setIsPaymentPending] = useState<boolean>(false);

    if (assets.length === 0) {
        return (
            <div>
                <Typography className={classes.sectionLabel}>Loading...</Typography>
            </div>
        );
    }

    if (
        userContext?.customerDetails &&
        isOwnPaymentRequest(paymentRequest.requestId, userContext?.customerDetails.accountId)
    ) {
        return (
            <div>
                <Typography className={classes.sectionLabel}>This payment request belongs to this account.</Typography>
            </div>
        );
    }

    if (paymentCompleted) {
        return (
            <div>
                <Typography className={classes.sectionLabel}>You have successfully completed this payment.</Typography>
            </div>
        );
    }

    const submit = async () => {
        setIsPaymentPending(true);
        const response = await payPaymentRequest(paymentRequest);
        if (response.error) {
            enqueueSnackbar(t('error.retailPayment', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.retailPayment', { error: response.error }), {
                variant: 'success',
            });
            setPaymentCompleted(true);
            userContext?.refreshCustomer();
            refreshTransactions();
        }
        setIsPaymentPending(false);
    };

    const payeeName = getPayeeNameByAccountId(paymentRequest.accountId);
    const assetName = getAssetbyId(paymentRequest.tokenIdentifier).tokenName;
    const validAmountInAccount = userContext?.validAmountInAccount(
        paymentRequest.tokenIdentifier,
        paymentRequest.amount
    );

    return (
        <div>
            <ConfirmPaymentDetails
                validAmount={validAmountInAccount ? validAmountInAccount : false}
                paymentRequest={paymentRequest}
                payeeName={payeeName}
                assetName={assetName}
            />
            <Button
                style={{ marginTop: 26 }}
                className={`${formClasses.formControlButton} submitButton  ${formClasses.button}`}
                variant="outlined"
                color="primary"
                onClick={submit}
                disabled={!validAmountInAccount || isPaymentPending}
                data-testid="pay_button"
            >
                {'Pay'}
            </Button>
        </div>
    );
};

export default ConfirmPayment;
