import { Asset, INITIAL_EMPTY_ASSET_STATE, StepperFormStep } from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';

import { Fade } from '@material-ui/core';
import RequestKycOverviewStep from './RequestKycOverviewStep';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAvailableAssetStep from './SelectAvailableAssetStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { kycRequestTo } from 'api/kycApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const RequestKycState: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAsset, setSelectedAsset] = useState<Asset>(INITIAL_EMPTY_ASSET_STATE);
    const [selectedParticipant, setSelectedParticipant] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { t } = useTranslation();
    const { assets, getAssetbyName, getAllAssets, assetsError } = useGetAssetDefinitions();

    const handleReset = () => {
        setSelectedParticipant('');
    };

    const submitKycRequest = async () => {
        const issuanceResponse: ResolvedPromise = await kycRequestTo(
            selectedAsset.tokenIdentifier,
            selectedParticipant
        );

        if (issuanceResponse.error) {
            enqueueSnackbar(t('error.requestingKyc', { error: issuanceResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.requestingKyc'), {
                variant: 'success',
            });
        }
        handleReset();
    };

    useEffect(() => {
        getAllAssets();
    }, [enqueueSnackbar, t, getAllAssets]);

    useEffect(() => {
        setError((text) => text + (text.length > 0 ? '. ' : '') + assetsError);
    }, [assetsError]);

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = getAssetbyName(event.target.value as string);
        setSelectedAsset(asset);
        setSelectedParticipant(asset.party);
    };

    const steps: StepperFormStep[] = [
        {
            title: t('issueAsset.selectAsset'),
            component: (
                <SelectAvailableAssetStep
                    selectedAsset={selectedAsset}
                    handleAssetChange={handleAssetChange}
                    assets={assets}
                    selectedParticipant={selectedParticipant}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('requestKyc.confirmRequest'),
            component: (
                <RequestKycOverviewStep selectedAsset={selectedAsset} selectedParticipant={selectedParticipant} />
            ),
            isNextStepDisabled: () => {
                if (selectedParticipant.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitKycRequest}
                    steps={steps}
                    renderError={error}
                    submitButtonText={t('commonText.request')}
                    handleReset={handleReset}
                />
            </Fade>
        </>
    );
};
