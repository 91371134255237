import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Slide } from '@material-ui/core';
import { CreatorFilterState, OrderStatusFilterState } from './types';
import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from 'constants/Animations';

import { ORDER_STATUS_FILTERS } from './constants';
import fromatParticipant from 'utils/formatParticipant';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useOfferLogStyles } from 'materialStyles/decentralizedExchange/offerLogStyles';
import { useTranslation } from 'react-i18next';

type Props = {
    orderStatusFilterState: OrderStatusFilterState;
    creatorFilterState: CreatorFilterState;
    onCreatorFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onOrderStatusFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    identity: string;
};

const OfferLogTableFilters: React.FC<Props> = ({
    orderStatusFilterState,
    creatorFilterState,
    onCreatorFilterChange,
    onOrderStatusFilterChange,
    identity,
}) => {
    const layoutClasses = useLayoutStyles();
    const offerLogClasses = useOfferLogStyles();
    const { t: labels } = useTranslation();
    return (
        <Slide
            direction="right"
            in={true}
            timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
        >
            <div className={`${layoutClasses.column} ${layoutClasses.columnSmall} ${offerLogClasses.filterWrapper}`}>
                <div className={offerLogClasses.filter}>
                    <FormControl component="fieldset">
                        <FormLabel className={offerLogClasses.filterLabel}>
                            {labels('liquiditySavingsOfferLog.orderStatusFilter')}
                        </FormLabel>
                        <FormGroup>
                            {ORDER_STATUS_FILTERS.map((filter) => (
                                <FormControlLabel
                                    key={filter.key}
                                    className={offerLogClasses.checkBoxLabel}
                                    style={{ color: filter.color }}
                                    control={
                                        <Checkbox
                                            className={offerLogClasses.checkBox}
                                            style={{
                                                color: orderStatusFilterState[filter.key] ? filter.color : '',
                                            }}
                                            checked={orderStatusFilterState[filter.key]}
                                            onChange={onOrderStatusFilterChange}
                                            name={filter.key}
                                        />
                                    }
                                    label={filter.label}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset" style={{ marginTop: 18 }}>
                        <FormLabel className={offerLogClasses.filterLabel} style={{ marginBottom: 16 }}>
                            {labels('liquiditySavingsOfferLog.offerCreator')}
                        </FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                className={offerLogClasses.checkBoxLabel}
                                control={
                                    <Checkbox
                                        className={offerLogClasses.checkBox}
                                        checked={creatorFilterState.thisBank}
                                        onChange={onCreatorFilterChange}
                                        name={'thisBank'}
                                    />
                                }
                                label={fromatParticipant(identity)}
                            />
                            <FormControlLabel
                                className={offerLogClasses.checkBoxLabel}
                                control={
                                    <Checkbox
                                        className={offerLogClasses.checkBox}
                                        checked={creatorFilterState.otherBanks}
                                        onChange={onCreatorFilterChange}
                                        name={'otherBanks'}
                                    />
                                }
                                label={labels('liquiditySavingsOfferLog.thirdPartyNodes')}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </div>
        </Slide>
    );
};

export default OfferLogTableFilters;
