import { useState } from 'react';

const notificationServiceFromLocalStorage = (): boolean => {
    let state = localStorage.getItem('notifService');
    if (state) {
        if (state === 'true') {
            return true;
        } else if (state === 'false') {
            return false;
        }
    } else {
        localStorage.setItem('notifService', 'true');
        return true;
    }
    return false;
};

const useNotificationService = () => {
    const [receiveNotifications, setReceiveNotifications] = useState<boolean>(notificationServiceFromLocalStorage());

    const toggleNotificationService = () => {
        if (receiveNotifications) {
            localStorage.setItem('notifService', 'false');
        } else {
            localStorage.setItem('notifService', 'true');
        }
        setReceiveNotifications(!receiveNotifications);
    };

    return { receiveNotifications, toggleNotificationService };
};

export default useNotificationService;
