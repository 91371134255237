import { FeatureWrapper, useHomeStyles } from '@r3/cbdc-asset-frontend-core';

import NetworkMap from '../networkMap/NetworkMap';
import NetworkNavButton from './NetworkNavButton';
import { RETAIL } from '../../constants/Routes';
import React from 'react';
import { SMALLSCREENWIDTH } from '../../constants/ScreenSizes';
import { Typography } from '@material-ui/core';
import useAppConfigContext from '../../contexts/AppConfig/AppConfig';
import { useMediaQuery } from 'react-responsive';

export const NetworkTab = () => {
    const url = window.location.href;
    const classes = useHomeStyles();
    const appConfigContext = useAppConfigContext();
    const buttons: JSX.Element[] = [];
    const defaultIcons = appConfigContext?.appConfig.defaultIcons === 'true' ? true : false;
    const wholesaleColour = appConfigContext ? appConfigContext?.appConfig.wholesaleBankColour : 'white';
    const centralBankColour = appConfigContext ? appConfigContext?.appConfig.centralBankColour : 'white';
    const isSmallScreen = useMediaQuery({ maxWidth: SMALLSCREENWIDTH });
    if (url.includes('cbdc-sandbox')) {
        const splitUrl = url.substring(url.indexOf('.'), url.length);
        if (!appConfigContext?.appConfig.appType.includes('centralbank')) {
            buttons.push(
                <NetworkNavButton
                    key={'centralbank'}
                    url={'centralbankone' + splitUrl}
                    text={appConfigContext?.appConfig.centralBankName}
                    imgUrl={'/appConfig/networkTabIcons/centralBank.png'}
                    defaultIcon={defaultIcons}
                    color={centralBankColour}
                    clientType={'centralbank'}
                />
            );
        }
        if (!appConfigContext?.appConfig.appType.includes('wholesaleone')) {
            buttons.push(
                <NetworkNavButton
                    key={'wholesaleone'}
                    url={'wholesaleone' + splitUrl}
                    text={appConfigContext?.appConfig.wholesaleBankOneName}
                    imgUrl={'/appConfig/networkTabIcons/wholesaleBankOne.png'}
                    defaultIcon={defaultIcons}
                    color={wholesaleColour}
                    clientType={'wholesale'}
                />
            );
        }
        if (!appConfigContext?.appConfig.appType.includes('wholesaletwo')) {
            buttons.push(
                <NetworkNavButton
                    key={'wholesaletwo'}
                    url={'wholesaletwo' + splitUrl}
                    text={appConfigContext?.appConfig.wholesaleBankTwoName}
                    imgUrl={'/appConfig/networkTabIcons/wholesaleBankTwo.png'}
                    defaultIcon={defaultIcons}
                    color={wholesaleColour}
                    clientType={'wholesale'}
                />
            );
        }
        if (!appConfigContext?.appConfig.appType.includes('wholesalethree')) {
            buttons.push(
                <NetworkNavButton
                    key={'wholesalethree'}
                    url={'wholesalethree' + splitUrl}
                    text={appConfigContext?.appConfig.wholesaleBankThreeName}
                    imgUrl={'/appConfig/networkTabIcons/wholesaleBankThree.png'}
                    defaultIcon={defaultIcons}
                    color={wholesaleColour}
                    clientType={'wholesale'}
                />
            );
        }

        if (appConfigContext && appConfigContext.appConfig.retailColour) {
            buttons.push(
                <FeatureWrapper key={'retailclientwrapper'} featureName="retailCBDC">
                    <NetworkNavButton
                        key={'retailclient'}
                        url={url.substr(0, url.lastIndexOf('/')) + RETAIL}
                        text={'Retail Client'}
                        imgUrl={'/appConfig/networkTabIcons/wholesaleBankThree.png'}
                        defaultIcon={defaultIcons}
                        color={appConfigContext.appConfig.retailColour!}
                        clientType={'retail'}
                    />
                </FeatureWrapper>
            );
        }
    }
    return (
        <>
            {url.includes('cbdc-sandbox') && (
                <>
                    <div style={{ display: 'flex', marginTop: 20 }}>
                        <Typography className={classes.hometext}>CBDC Sandbox Network</Typography>
                    </div>
                    {!isSmallScreen && (
                        <div
                            style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 40, width: 500, height: 400 }}
                        >
                            <NetworkMap />
                        </div>
                    )}

                    <div style={{ display: 'flex', marginTop: 0, marginBottom: 12 }}>
                        <Typography className={classes.appTitle}>
                            You are on '<span>{appConfigContext?.appConfig.appName}'</span>
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', marginTop: 0, marginBottom: 24 }}>
                        <Typography className={classes.subTitle}>
                            Click buttons below to access the other clients.
                        </Typography>
                    </div>
                    <div className={classes.linkWrapper}>{buttons}</div>
                </>
            )}
        </>
    );
};
