import { useEffect, useRef } from 'react';

import CompletedOrders from './CompletedOrders/CompletedOrders';
import { IconButton } from '@material-ui/core';
import PendingOrders from './pendingOrders/PendingOrders';
import ReplayIcon from '@material-ui/icons/Replay';
import useExchangeListStyles from 'materialStyles/exchangeListStyles';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useMediaQuery } from 'react-responsive';

const ManageOrders = () => {
    const classes = useLayoutStyles();
    const { identity, getOwnIdentity } = useGetOurOwnIdentity();
    const listStyles = useExchangeListStyles();
    const completedOffersChild = useRef<React.ElementRef<typeof CompletedOrders>>(null);
    const pendingOffersChild = useRef<React.ElementRef<typeof PendingOrders>>(null);
    const isSmallScreen = useMediaQuery({ maxWidth: 1520 });
    const interactiveCheckListContext = useInteractiveChecklistContext();

    const handleOnRefresh = () => {
        if (completedOffersChild.current) {
            completedOffersChild.current.refresh();
        }
        if (pendingOffersChild.current) {
            pendingOffersChild.current.refresh();
        }
    };

    useEffect(() => {
        getOwnIdentity();
        interactiveCheckListContext?.completeStep(window.location.pathname);
    }, [getOwnIdentity, interactiveCheckListContext]);

    return (
        <div
            className={classes.componentWrapper}
            style={{ gap: 0, justifyContent: 'space-evenly', flexDirection: isSmallScreen ? 'column' : 'row' }}
        >
            <div className={`${classes.columnFillSmall}  ${classes.columnNoPadding}`}>
                <CompletedOrders ref={completedOffersChild} identity={identity} />
            </div>
            <div
                className={`${classes.columnNoPadding}`}
                style={{ margin: isSmallScreen ? 'auto' : '', order: isSmallScreen ? -1 : 0 }}
            >
                <IconButton
                    className={listStyles.refreshButton}
                    data-testid={'refresh_list'}
                    id={'RefreshList'}
                    onClick={handleOnRefresh}
                    color="secondary"
                    component="span"
                >
                    <ReplayIcon className={listStyles.refreshIcon} />
                </IconButton>
            </div>
            <div className={`${classes.columnFillSmall} ${classes.columnNoPadding}`}>
                <PendingOrders ref={pendingOffersChild} identity={identity} />
            </div>
        </div>
    );
};

export default ManageOrders;
