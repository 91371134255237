import { Asset, EMPTY_SUMMED_STATE, INITIAL_EMPTY_ASSET_STATE, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { ChangeEvent, useEffect, useState } from 'react';
import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

import AssetSummary from 'components/commonComponents/SummaryComponents/AssetSummary';
import { getPermittedNotaries } from './getPermittedNotaries';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

export type SelectSwapAssetsStepProps = {
    assets: Asset[];
    transferableAssets: SummedAsset[];
    selectedAsset: SummedAsset;
    setSelectedAsset: (asset: SummedAsset) => void;
    selectedSwapAsset: Asset;
    setSelectedSwapAsset: (asset: Asset) => void;
};

const SelectSwapAssetsStep: React.FC<SelectSwapAssetsStepProps> = (props) => {
    const { selectedAsset, setSelectedAsset, selectedSwapAsset, setSelectedSwapAsset, assets, transferableAssets } =
        props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const [notPermittedNotaries, setNotPermittedNotaries] = useState<string[]>([]);
    const [selectableOfferAssets, setSelectableOfferAssets] = useState<SummedAsset[]>(transferableAssets);

    useEffect(() => {
        setSelectedAsset(EMPTY_SUMMED_STATE);
        setNotPermittedNotaries(getPermittedNotaries(selectedSwapAsset.complianceRules));
        // eslint-disable-next-line
    }, [selectedSwapAsset]);

    useEffect(() => {
        let filteredTransferableAssets: SummedAsset[] = [];
        transferableAssets.forEach((asset) => {
            let toAdd = true;
            notPermittedNotaries.forEach((blockedNotary, index) => {
                if (getPermittedNotaries(asset.complianceRules).findIndex((a) => a === blockedNotary) > -1) {
                    toAdd = false;
                }
            });
            if (toAdd) {
                filteredTransferableAssets.push(asset);
            }
        });
        setSelectableOfferAssets(filteredTransferableAssets);
    }, [notPermittedNotaries, transferableAssets]);

    const getSelectedAsset = (assetName: string): Asset => {
        return assets.find((a) => a.tokenName === assetName) ?? INITIAL_EMPTY_ASSET_STATE;
    };

    const getSelectedTransferableAsset = (assetName: string): SummedAsset => {
        return transferableAssets.find((a) => a.tokenName === assetName) ?? EMPTY_SUMMED_STATE;
    };

    const handleAssetChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        if (event.target.name === 'SelectSwapAsset') {
            setSelectedSwapAsset(getSelectedAsset(event.target.value as string));
        }
        if (event.target.name === 'SelectAssetTo') {
            setSelectedAsset(getSelectedTransferableAsset(event.target.value as string));
        }
    };

    const outgoingAsset = transferableAssets.find((asset) => asset.tokenIdentifier === selectedAsset.tokenIdentifier);

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('crossChainSwap.selectAssetToRequest')}
                </Typography>

                <Typography className={`${layoutClasses.text}`} style={{ fontSize: 16 }}>
                    {'( Both assets must have different signing notaries. )'}
                </Typography>

                <Divider className={layoutClasses.summaryDivider} />
                <FormControl
                    id="SelectAssetToRequest"
                    variant="outlined"
                    className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                >
                    <InputLabel>CBDC Asset To Request</InputLabel>
                    <Select
                        value={selectedSwapAsset.tokenName}
                        onChange={handleAssetChange}
                        data-testid={'asset-request-select'}
                        name={'SelectSwapAsset'}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {assets.map((as, i) => {
                            return (
                                <MenuItem key={as.tokenName + i} value={as.tokenName} data-testid={'asset-option'}>
                                    {as.tokenName + ' : ' + getPermittedNotaries(as.complianceRules).join(' | ')}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <Typography className={`${layoutClasses.text}`}>
                    {`Asset notaries: ` + notPermittedNotaries.join(' | ')}
                </Typography>

                {selectableOfferAssets.length === 0 ? (
                    <>
                        <Divider className={layoutClasses.summaryDivider} />
                        <Typography className={`${layoutClasses.text}`} style={{ textAlign: 'center' }}>
                            {
                                'No assets in the vault available for cross chain swap. ( Compatible assets must be on a seperate Notary to the asset being requested ) '
                            }
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography className={`${layoutClasses.sectionLabel}`}>
                            {t('crossChainSwap.selectAssetToOffer')}
                        </Typography>

                        <Divider className={layoutClasses.summaryDivider} />
                        <FormControl
                            id="SelectAssetToOffer"
                            variant="outlined"
                            className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                        >
                            <InputLabel id="SelectLabel">CBDC Asset To Offer</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={selectedAsset.tokenName}
                                onChange={handleAssetChange}
                                data-testid={'asset-offer-select'}
                                name={'SelectAssetTo'}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {selectableOfferAssets.map((as, i) => {
                                    return (
                                        <MenuItem
                                            key={as.tokenName + i}
                                            value={as.tokenName}
                                            data-testid={'asset-option'}
                                        >
                                            {as.tokenName +
                                                ' : ' +
                                                getPermittedNotaries(as.complianceRules).join(' | ')}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </>
                )}

                {selectedAsset.tokenIdentifier.length > 0 && selectableOfferAssets.length > 0 && (
                    <>
                        <Typography className={`${layoutClasses.text}`}>
                            {`Asset notaries: ` + getPermittedNotaries(selectedAsset.complianceRules).join(' | ')}
                        </Typography>
                        {outgoingAsset && <AssetSummary asset={outgoingAsset} />}
                    </>
                )}
            </Paper>
        </div>
    );
};

export default SelectSwapAssetsStep;
