import React, { ChangeEvent } from 'react';

import { TextField } from '@material-ui/core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { ColorOperations } from '@r3/cbdc-asset-frontend-core';

type ColourInputProps = {
    colour: string;
    handleInputChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    name: string;
};

const ColourInput: React.FC<ColourInputProps> = (props) => {
    const { colour, handleInputChange, name } = props;
    const layoutClasses = useLayoutStyles();

    return (
        <TextField
            size="small"
            name={name}
            label={!ColorOperations.isValidHexColour(colour) ? 'Invalid Colour Value' : name}
            variant="filled"
            value={colour}
            error={!ColorOperations.isValidHexColour(colour)}
            className={`${layoutClasses.formInput}`}
            style={{ marginTop: 2, marginBottom: 2 }}
            onChange={(event) => handleInputChange(event)}
        />
    );
};

export default ColourInput;
