const LOGGING_ENABLED = process.env.REACT_APP_LOGGING_ENABLED;

export default class Logger {
    public static Log(messageName: string, message: any, withTrace?: boolean) {
        if (LOGGING_ENABLED !== 'true') {
            return;
        }
        console.log(`${new Date().toISOString()}: ${messageName}: `, message);
        if (withTrace) {
            console.trace();
        }
    }
}
