import { Paper } from '@material-ui/core';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';

const LandingPieWrapper = ({ children }) => {
    const layoutClasses = useLayoutStyles();
    return (
        <Paper
            elevation={5}
            style={{ paddingBottom: 28, width: '100%', overflow: 'hidden' }}
            className={` ${layoutClasses.column}   ${layoutClasses.columnNoPadding}`}
        >
            {children}
        </Paper>
    );
};

export default LandingPieWrapper;
