import { Paper, Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Asset, NumberFormat } from '@r3/cbdc-asset-frontend-core';

export const useAssetListStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            assetList: {
                maxHeight: '95%',
                width: '100%',
                padding: 8,
                overflow: 'scroll',
            },
            header: {
                fontSize: 18,
                color: theme.palette.primary.contrastText,
            },
            assetItem: {
                transition: 'all 0.15s ease-in-out',
                '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: theme.palette.secondary.main,
                    transform: 'scale(1.05)',
                },
                height: 50,
                width: '100%',
                marginTop: 16,
                marginBottom: 16,
                display: 'flex',
                backgroundColor: theme.palette.primary.light,
                flexDirection: 'column',
                padding: 4,
            },
            assetSelected: {
                backgroundColor: theme.palette.secondary.main,
                transform: 'scale(1.05)',
            },
            assetName: {
                color: theme.palette.primary.contrastText,
                fontSize: 16,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            assetTotal: {
                color: theme.palette.primary.contrastText,
                fontSize: 14,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        }),
    { index: 1 }
);

export interface AssetWithTotal {
    asset: Asset;
    total: number;
}

interface AssetListProps {
    assetsWithTotal: AssetWithTotal[];
    setSelectedAsset: (id: Asset) => void;
    selectedAsset: Asset | undefined;
}

export const AssetList: React.FC<AssetListProps> = (props) => {
    const { assetsWithTotal: assets, setSelectedAsset, selectedAsset } = props;
    const classes = useAssetListStyles();
    const [defaulAssetSet, setDefaultAssetSet] = useState(false);

    useEffect(() => {
        if (assets.length > 0 && !defaulAssetSet) {
            setSelectedAsset(assets[0].asset);
            setDefaultAssetSet(true);
        }
        // eslint-disable-next-line
    }, [assets]);

    return (
        <div className={classes.assetList}>
            <div className={classes.header}>CBDC types on the network</div>
            {assets.map((a, index) => {
                return (
                    <Paper
                        id={a.asset.tokenName}
                        key={index}
                        className={`${classes.assetItem} ${
                            a.asset.tokenName === selectedAsset?.tokenName ? classes.assetSelected : ''
                        }`}
                        onClick={() => {
                            setSelectedAsset(a.asset);
                        }}
                    >
                        <div className={`${classes.assetName} assetName`}>{a.asset.tokenName}</div>
                        <div className={`${classes.assetTotal} balance`}>
                            Balance: {NumberFormat.addThousandSeparators(a.total)}
                        </div>
                    </Paper>
                );
            })}
        </div>
    );
};
