import { CustomerWithDetails } from 'hooks/GetCustomerDetails';
import apiCall from './apiCall';

/**
 * @remarks
 * Requests all customers on the current node
 *
 * @param searchTerm used to filter by email or customer name
 *
 * @returns AxiosResponse resolves to customers data or error
 */
export const requestAllCustomers = async (searchTerm: string = '') => {
    return apiCall({ method: 'post', path: '/api/customer/', params: { searchTerm: searchTerm } });
};

/**
 * @remarks
 * Request a specific customer's detaisl
 *
 * @param custId customer id
 *
 * @returnsAxiosResponse resolves to customer details data or error
 */
export const requestCustomerDetails = async (custId: string) => {
    return apiCall({ method: 'get', path: '/api/customer/' + custId });
};

/**
 * @remarks
 * Update the customer details
 *
 * @param customer updated customer object
 *
 * @returns AxiosResponse resolves to customer details data or error
 */
export const updateCustomerDetails = async (customer: CustomerWithDetails) => {
    return apiCall({
        method: 'post',
        path: '/api/customer/' + customer.customerId + '/update/',
        params: {
            customerName: customer.customerName,
            contactNumber: customer.contactNumber,
            emailAddress: customer.emailAddress,
            postcode: customer.postcode,
        },
    });
};

/**
 * @remarks
 * Update the customer details
 *
 * @param customer updated customer object
 *
 * @returns AxiosResponse resolves to updated customer details data or error
 */
export const createCustomerAccount = async (accountName: string, customerId: string) => {
    return apiCall({
        method: 'post',
        path: `/api/customer/${customerId}/createAccount`,
        params: {
            accountName: accountName,
        },
    });
};

/**
 * @remarks
 * Deposits assets to a retail account
 *
 * @param accountId the accountId of retail account to deposit to
 * @param tokenIdentifier the id of the asset to be deposited
 * @param quantity the amount of the asset to deposit
 *
 * @returns AxiosResponse resolves to confirmed transfer details data or error
 */
export const depositToRetailAccount = async (accountId: string, tokenIdentifier: string, quantity: number) => {
    return apiCall({
        method: 'post',
        path: `/api/customer/transferToCustomer`,
        params: {
            accountId: accountId,
            tokenIdentifier: tokenIdentifier,
            quantity: quantity,
        },
    });
};

/**
 * @remark
 * Used to register and create new accounts for retail customers
 *
 * @param name
 * @param email
 * @param phone
 * @param address
 * @param postCode
 *
 * @returns AxiosResponse resolves to new customer object or error
 */
export const registerRetailCustomer = async (
    name: string,
    email: string,
    phone: string,
    password: string,
    postCode: string
) => {
    return apiCall({
        method: 'post',
        path: '/api/customer/create',
        params: {
            customerName: name,
            emailAddress: email,
            contactNumber: phone,
            password: password,
            postcode: postCode,
        },
    });
};
