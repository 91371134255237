import { Paper, SvgIcon, Typography } from '@material-ui/core';

import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import { ReactComponent as CBIcon } from 'icons/CentralBank.svg';
import { NETWORKTABRETAIL } from '../../constants/Routes';
import React from 'react';
import { useHomeStyles } from '@r3/cbdc-asset-frontend-core';

type ClientType = 'wholesale' | 'centralbank' | 'retail';

type NetworkNavButtonProps = {
    url: string;
    text: string | undefined;
    imgUrl: string;
    defaultIcon: boolean;
    color: string;
    clientType: ClientType;
};

const NetworkNavButton: React.FC<NetworkNavButtonProps> = (props) => {
    const { url, text, imgUrl, defaultIcon, color, clientType } = props;
    const classes = useHomeStyles();
    const prefix = window.location.href.includes('https') ? 'https://' : 'http://';
    let navUrl = url.includes(prefix) ? url : prefix + url;
    if (navUrl.includes(NETWORKTABRETAIL)) {
        navUrl = navUrl.replace(NETWORKTABRETAIL, '');
    }
    return (
        <Paper
            className={classes.linkButton}
            onClick={() => {
                window.open(navUrl, '_blank');
            }}
        >
            <div className={classes.innerDiv}>
                <Typography className={classes.linkText}>{text}</Typography>
            </div>
            <div className={classes.innerDiv}>
                {defaultIcon ? (
                    <>
                        {clientType === 'centralbank' && (
                            <SvgIcon className={` ${classes.linkIcon}`} style={{ color: color }}>
                                <CBIcon />
                            </SvgIcon>
                        )}
                        {clientType === 'wholesale' && (
                            <AccountBalanceRoundedIcon style={{ color: color }} className={`${classes.linkIcon}`} />
                        )}
                        {clientType === 'retail' && (
                            <AccountBoxRoundedIcon style={{ color: color }} className={`${classes.linkIcon}`} />
                        )}
                    </>
                ) : (
                    <img className={`${classes.linkIcon}`} src={imgUrl} alt={text} />
                )}
            </div>
        </Paper>
    );
};

export default NetworkNavButton;
