import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from 'constants/Animations';
import {
    DashBoardPieTheme,
    DashBoardResponsivePie,
    LandingPagePieTheme,
} from '../DashboardComponents/DashboardResponsivePie';
import { Divider, Fade, Paper, Slide } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { ASSETPOLLINGINTERVAL } from 'constants/PollingIntervals';
import { AssetResponse } from '@r3/cbdc-asset-frontend-core';
import { DashboardFilter } from '../DashboardComponents/DashboardFilter';
import { InfoBox } from '../DashboardComponents/InfoBox';
import { useDashboardPieData } from 'hooks/useDashboardPieData';
import { useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

export const AssetDashboard: React.FC = () => {
    const [filterAssets, setFilterAssets] = useState<string[]>([]);
    const [vaultAssets, setVaultAssets] = useState<AssetResponse[]>([]);
    const classes = useDashboardStyles();
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { getAssetSummary, assetSummary, summaryError } = useGetAssetSummary();
    const handlePieClick = () => {};
    const { uniqueAssets, assetsInVault, pieData, updatePieData } = useDashboardPieData();

    const isXsmallScreen = useMediaQuery({ maxWidth: 1250 });
    const isSmallScreen = useMediaQuery({ maxWidth: 1500 });
    const isLargeScreen = useMediaQuery({ minWidth: 1700 });
    let pieSizeY = 130;

    if (isXsmallScreen) {
        pieSizeY = 220;
    }
    if (isSmallScreen) {
        pieSizeY = 180;
    }
    if (isLargeScreen) {
        pieSizeY = 130;
    }

    useEffect(() => {
        checkListContext?.completeStep(location.pathname);
    }, [location.pathname, checkListContext]);

    useEffect(() => {
        getAssetSummary();
    }, [getAssetSummary]);

    useEffect(() => {
        if (ASSETPOLLINGINTERVAL <= 0) {
            return;
        }
        const interval = setInterval(function () {
            getAssetSummary(true);
        }, ASSETPOLLINGINTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [getAssetSummary]);

    const newAssetsAdded = useCallback((): boolean => {
        for (let asset of assetSummary) {
            if (
                vaultAssets.findIndex(
                    (vAsset) => vAsset.tokenDefinition.tokenIdentifier === asset.tokenDefinition.tokenIdentifier
                ) === -1
            ) {
                return true;
            }
        }
        return false;
    }, [assetSummary, vaultAssets]);

    useEffect(() => {
        if (newAssetsAdded()) {
            setFilterAssets(assetSummary.map((asset) => asset.tokenDefinition.tokenName));
        }
        setVaultAssets(assetSummary);
    }, [assetSummary, newAssetsAdded]);

    const updateFilter = (name: string) => {
        let newFilterArray: string[] = [];
        if (filterAssets.includes(name)) {
            newFilterArray = filterAssets.filter((f) => f !== name);
        } else {
            newFilterArray = [...filterAssets];
            newFilterArray.push(name);
        }
        setFilterAssets(newFilterArray);
    };

    useEffect(() => {
        updatePieData(vaultAssets, filterAssets);
    }, [filterAssets, updatePieData, vaultAssets]);

    return (
        <>
            <Fade in={true}>
                <Paper elevation={5} className={`${layoutClasses.componentWrapper}`}>
                    {summaryError.length > 0 ? (
                        <>
                            <InfoBox title={summaryError} content={''} />
                        </>
                    ) : (
                        <>
                            <InfoBox title={t('dashBoard.assetTypes')} content={pieData.length.toString()} />
                            <InfoBox
                                title={t('dashBoard.totalAssetsInVault')}
                                content={assetsInVault.toFixed(3).toString()}
                            />
                        </>
                    )}
                </Paper>
            </Fade>

            {vaultAssets.length > 0 && (
                <div className={layoutClasses.componentWrapper}>
                    <Slide
                        direction="right"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div className={`${layoutClasses.column} ${layoutClasses.columnSmall}`}>
                            <DashboardFilter
                                assetSummary={assetSummary}
                                uniqueAssets={uniqueAssets}
                                filterAssets={filterAssets}
                                updateFilter={updateFilter}
                            />
                        </div>
                    </Slide>

                    <Slide
                        direction="left"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnFill} ${layoutClasses.columnNoPadding} `}
                            style={{ overflow: 'hidden' }}
                        >
                            <div
                                id="PieGraph"
                                className={` ${classes.nivoWrapper}`}
                                style={{ overflow: 'hidden', height: 700 }}
                            >
                                <DashBoardResponsivePie
                                    pieMarginsTopBottom={pieSizeY}
                                    altColour={true}
                                    pieTheme={isSmallScreen || isXsmallScreen ? LandingPagePieTheme : DashBoardPieTheme}
                                    data={pieData}
                                    handlePieClick={handlePieClick}
                                />
                                <Divider className={classes.divider} />
                            </div>
                        </div>
                    </Slide>
                </div>
            )}
        </>
    );
};
