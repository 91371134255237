import { Button, Typography } from '@material-ui/core';

import ConfirmPayment from './ConfirmPayment';
import CropFreeRoundedIcon from '@material-ui/icons/CropFreeRounded';
import PayWithCode from './PayWithCode';
import { PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import QrCodePayment from './QrCodePayment';
import TextFieldsRoundedIcon from '@material-ui/icons/TextFieldsRounded';
import { useEffect } from 'react';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useRetailStickyButtonsStyles } from 'materialStyles/retailStyles/retailStickyButtonsStyles';
import { useState } from 'react';

enum RetailPayOption {
    QRCODE,
    CODE,
    CONFIRMPAYMENT,
    NONE,
}

const RetailPay = () => {
    const classes = useRetailStickyButtonsStyles();
    const layoutClasses = useLayoutStyles();
    const [payOption, setPayOption] = useState<RetailPayOption>(RetailPayOption.NONE);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequestResponse | null>(null);

    useEffect(() => {
        if (paymentRequest) {
            setPayOption(RetailPayOption.CONFIRMPAYMENT);
        }
    }, [paymentRequest]);

    return (
        <div>
            <Typography
                className={layoutClasses.centerText}
                style={{ marginBottom: 18, fontSize: 24, fontWeight: 400 }}
            >
                {payOption === RetailPayOption.NONE && 'Choose Payment Option'}
                {payOption === RetailPayOption.QRCODE && 'Scan Payment Request QR Code'}
                {payOption === RetailPayOption.CODE && 'Enter or Paste a payment request code'}
                {payOption === RetailPayOption.CONFIRMPAYMENT && 'Confirm Payment'}
            </Typography>
            <div style={{ margin: 'auto auto 20px auto', width: '95%' }}>
                {payOption === RetailPayOption.QRCODE && <QrCodePayment setPaymentRequest={setPaymentRequest} />}
                {payOption === RetailPayOption.CODE && <PayWithCode setPaymentRequest={setPaymentRequest} />}
                {payOption === RetailPayOption.CONFIRMPAYMENT && paymentRequest && (
                    <ConfirmPayment paymentRequest={paymentRequest} />
                )}
            </div>
            <div style={{ display: 'flex' }}>
                {payOption !== RetailPayOption.QRCODE && payOption !== RetailPayOption.CONFIRMPAYMENT && (
                    <Button
                        size="large"
                        className={classes.button}
                        startIcon={<CropFreeRoundedIcon className={classes.buttonIcon} />}
                        onClick={() => {
                            setPayOption(RetailPayOption.QRCODE);
                        }}
                        data-testid="qr_pay_button"
                    >
                        Scan QR
                    </Button>
                )}
                {payOption !== RetailPayOption.CODE && payOption !== RetailPayOption.CONFIRMPAYMENT && (
                    <Button
                        size="large"
                        className={classes.button}
                        startIcon={<TextFieldsRoundedIcon className={classes.buttonIcon} />}
                        onClick={() => {
                            setPayOption(RetailPayOption.CODE);
                        }}
                        data-testid="code_pay_button"
                    >
                        Code
                    </Button>
                )}
            </div>
        </div>
    );
};

export default RetailPay;
