import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type SelectAssetToRequestStepProps = {
    assetName: string;
    handleAssetChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    assetSelectOptions: JSX.Element[];
};
const SelectAssetToRequestStep: React.FC<SelectAssetToRequestStepProps> = (props) => {
    const { assetName, handleAssetChange, assetSelectOptions } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('requestCBDC.selectAsset')}</Typography>

                <Divider className={layoutClasses.summaryDivider} />
                <FormControl
                    id="SelectAsset"
                    variant="outlined"
                    className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                >
                    <InputLabel id="SelectLabel">Asset</InputLabel>
                    <Select
                        labelId="SelectLabel"
                        value={assetName}
                        onChange={handleAssetChange}
                        data-testid={'asset-select'}
                    >
                        <MenuItem key={0} value="">
                            <em>None</em>
                        </MenuItem>
                        {assetSelectOptions}
                    </Select>
                </FormControl>
            </Paper>
        </div>
    );
};

export default SelectAssetToRequestStep;
