export const getRequestStatusColorWithId = (state: string, id: string | null) => {
    let statusColor = 'white';
    if ((id === null && state === 'APPROVED') || state === 'FAILED') {
        state = 'FAILED';
        statusColor = 'red';
    } else if (state === 'REQUESTED') {
        statusColor = 'yellow';
    } else if (state === 'APPROVED') {
        statusColor = 'lawngreen';
    } else if (state === 'DENIED') {
        statusColor = 'red';
    }

    return statusColor;
};

export const getRequestStatusColor = (state: string) => {
    let statusColor = 'white';
    if (state === 'REQUESTED') {
        statusColor = 'yellow';
    } else if (state === 'APPROVED') {
        statusColor = 'lawngreen';
    } else if (state === 'DENIED') {
        statusColor = 'red';
    }
    return statusColor;
};
