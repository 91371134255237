import { Button, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import { NavigatorExtensions, PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import QRCode from 'qrcode.react';
import { RetailPayementRequestsContext } from '../../../contexts/RetailPaymentRequestContext/RetailPaymentRequestContext';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

export type PaymentRequestProps = {
    requestResponse: PaymentRequestResponse;
};

const PaymentRequest: React.FC<PaymentRequestProps> = ({ requestResponse }) => {
    const [fullEncodedOpen, setFullEncodedOpen] = useState<boolean>(false);
    const layoutClasses = useLayoutStyles();
    const { refreshPaymentRequests, isRequestFulfilled } = useContext(RetailPayementRequestsContext);
    const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(null);
    const requestAsJsonString = JSON.stringify(requestResponse);
    const base64Encoded = btoa(requestAsJsonString);

    useEffect(() => {
        const interval = setInterval(function () {
            refreshPaymentRequests(true);
        }, 1000);
        setPollingInterval(interval);
        return () => {
            clearInterval(interval);
        };
    }, [refreshPaymentRequests]);

    const stopPolling = () => {
        if (pollingInterval) {
            clearInterval(pollingInterval);
        }
    };

    const fulfilledPayment = isRequestFulfilled(requestResponse.requestId);
    if (fulfilledPayment) stopPolling();

    return (
        <div style={{ width: '100%', margin: 'auto', display: 'flex', flexDirection: 'column' }}>
            {fulfilledPayment && (
                <Typography className={layoutClasses.sectionLabel} style={{ color: 'lightgreen' }}>
                    This payment request has been fulfilled.
                </Typography>
            )}
            {!fulfilledPayment && (
                <>
                    <Typography className={layoutClasses.centerText}>Share the QR code with the payer.</Typography>
                    <QRCode
                        imageSettings={{
                            src: ' https://cdn-images-1.medium.com/max/1200/1*d2RiMW4zoHLUK-751E38gQ.png',
                            height: 80,
                            width: 80,
                            excavate: true,
                        }}
                        style={{ margin: '20px auto 20px auto' }}
                        value={requestAsJsonString}
                        size={250}
                        bgColor={'#ffffff'}
                        fgColor={'#000000'}
                        level={'M'}
                        includeMargin={true}
                        renderAs={'svg'}
                    />
                    <Typography className={layoutClasses.centerText}>
                        Alternatively, copy and share the text code with the payer.
                    </Typography>
                    <Button
                        className={layoutClasses.button}
                        style={{ maxWidth: 80, minWidth: 80, margin: '10px auto 10px auto', height: 'auto' }}
                        startIcon={<FileCopyIcon />}
                        onClick={() => NavigatorExtensions.copyToClipBoard(base64Encoded)}
                    >
                        Copy
                    </Button>
                    <Typography
                        className={layoutClasses.centerText}
                        style={{
                            maxWidth: 300,
                            wordWrap: 'break-word',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: 20,
                            cursor: fullEncodedOpen ? 'text' : 'pointer',
                        }}
                        onClick={() => {
                            if (!fullEncodedOpen) {
                                setFullEncodedOpen((prev) => !prev);
                            }
                        }}
                        data-testid={'encoded_request'}
                    >
                        {fullEncodedOpen ? base64Encoded : base64Encoded.substr(0, 15) + '...'}
                    </Typography>
                </>
            )}
        </div>
    );
};

export default PaymentRequest;
