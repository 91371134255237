import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useAppStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            menuButton: {
                color: theme.palette.primary.contrastText,
                fontWeight: 'bold',
                fontSize: 18,

                '&:hover': {
                    color: theme.palette.secondary.main,
                },

                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            notifBase: {
                '& #client-snackbar': {
                    maxWidth: 400,
                },
                '& .MuiCollapse-wrapperInner': {
                    width: '99%!important',
                },

                right: 10,
                pointerEvents: 'all',
                marginBottom: 4,
            },
            mainContent: {
                minHeight: '100hv',
                width: '100%',
                paddingBottom: 85,
            },
            blurred: {
                filter: 'blur(5px)',
            },
            appIcon: {
                marginTop: 'auto',
                marginBottom: 'auto',
                width: 40,
                height: 40,
                marginLeft: 4,
                color: theme.palette.secondary.main,
            },
        }),
    { index: 1 }
);
