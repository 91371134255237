const RequestListDropdownTitle: React.FC = ({ children }) => {
    return (
        <div>
            <p className="stateHolderName" data-testid={'holder_filter_name'}>
                {children}
            </p>
        </div>
    );
};

export default RequestListDropdownTitle;
