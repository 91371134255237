import { useRef, useState } from 'react';

import { ArrowForwardIos } from '@material-ui/icons';
import DigitalCurrencyPairList from './DigitalCurrencyPairList';
import ExchangeOrderList from './ExchangeOrderList';
import { IconButton } from '@material-ui/core';
import { OrderSummary } from 'models/orders/OrderSummary';
import ReplayIcon from '@material-ui/icons/Replay';
import useExchangeListStyles from 'materialStyles/exchangeListStyles';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useMediaQuery } from 'react-responsive';

const BrowseExchange = () => {
    const [selectedPair, setSelectedPair] = useState<OrderSummary | null>(null);
    const layoutStyles = useLayoutStyles();
    const listStyles = useExchangeListStyles();
    const isSmallScreen = useMediaQuery({ maxWidth: 1520 });

    const pairListsChild = useRef<React.ElementRef<typeof DigitalCurrencyPairList>>(null);
    const ordersListChild = useRef<React.ElementRef<typeof ExchangeOrderList>>(null);

    const refreshChildren = () => {
        setSelectedPair(null);
        if (pairListsChild.current) {
            pairListsChild.current.refresh();
        }
        if (ordersListChild.current) {
            ordersListChild.current.refresh();
        }
    };

    function handlePairSelected(pair: OrderSummary | null) {
        setSelectedPair((prevState) => (prevState === pair ? null : pair));
    }

    return (
        <>
            <div
                className={layoutStyles.componentWrapper}
                style={{ gap: 0, justifyContent: 'space-evenly', flexDirection: isSmallScreen ? 'column' : 'row' }}
            >
                <div className={`${layoutStyles.columnFillSmall} ${layoutStyles.columnNoPadding}`}>
                    <DigitalCurrencyPairList
                        ref={pairListsChild}
                        selectedPair={selectedPair}
                        onPairSelected={handlePairSelected}
                    />
                </div>

                <div
                    className={`${layoutStyles.columnNoPadding}`}
                    style={{ margin: isSmallScreen ? 'auto' : '', order: isSmallScreen ? -1 : 0 }}
                >
                    <IconButton
                        className={listStyles.refreshButton}
                        data-testid={'refresh_list'}
                        id={'RefreshList'}
                        onClick={refreshChildren}
                        color="secondary"
                        component="span"
                    >
                        <ReplayIcon className={listStyles.refreshIcon} />
                    </IconButton>
                    {!isSmallScreen && (
                        <div
                            className={`${listStyles.label} ${listStyles.alignSelf}`}
                            style={{ margin: '300px auto auto auto' }}
                        >
                            <ArrowForwardIos className={listStyles.filterArrow} />
                        </div>
                    )}
                </div>

                <div className={`${layoutStyles.columnFillSmall} ${layoutStyles.columnNoPadding}`}>
                    <ExchangeOrderList
                        ref={ordersListChild}
                        selectedPair={selectedPair}
                        dispatchRefresh={refreshChildren}
                    />
                </div>
            </div>
        </>
    );
};

export default BrowseExchange;
