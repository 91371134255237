import { useCallback, useState } from 'react';

import { OrderStatus } from 'models/orders/OrderStatus';
import { OrderSummary } from 'models/orders/OrderSummary';
import { getOrderSummaries } from 'api/decentralizedExchange';
import { useTranslation } from 'react-i18next';

interface GetDigitalCurrencyPairsArguments {
    excludeBuyer?: string;
}

const useGetDigitalCurrencyPairs = (orderStatus: OrderStatus) => {
    const [pairs, setPairs] = useState<OrderSummary[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { t: texts } = useTranslation();

    const getPairs = useCallback(
        async (args?: GetDigitalCurrencyPairsArguments) => {
            setIsLoading(true);
            const response = await getOrderSummaries(orderStatus, args?.excludeBuyer);
            if (response.error == null) {
                setPairs(response.data);
            } else {
                setError(texts('error.gettingDigitalCurrencyPairs', { error: response.error }));
            }
            setIsLoading(false);
            return () => setIsLoading(false);
        },
        [orderStatus, texts]
    );

    return { pairs, isLoading, error, getPairs };
};

export default useGetDigitalCurrencyPairs;
