import { Fade, Paper } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';

import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { RedemptionRequest } from '@r3/cbdc-asset-frontend-core';
import { RedemptionRequestDialog } from './RedempationRequestDialog';
import RedemptionRequestsList from './RedemptionRequestsList';
import { ResolvedPromise } from 'api/resolvePromise';
import { requestAllRedemptionRequests } from 'api/redemptionApi';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const RedemptionRequests = () => {
    const layoutClasses = useLayoutStyles();
    const [sortedRedemptionRequests, setSortedRedemptionRequests] = useState<Map<string, RedemptionRequest[]>>();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>('');
    const [selectedRedemptionRequest, setSelectedRedemptionRequest] = useState<RedemptionRequest | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const loadRedemptionRequests = useCallback(async () => {
        const redemptionRequestsResponse: ResolvedPromise = await requestAllRedemptionRequests();
        if (redemptionRequestsResponse.error) {
            enqueueSnackbar(t('error.gettingRedemptionRequests', { error: redemptionRequestsResponse.error }), {
                variant: 'error',
            });
            setError(
                redemptionRequestsResponse.error.length > 0 ? redemptionRequestsResponse.error : t('error.networkError')
            );
        } else {
            if (redemptionRequestsResponse.data.length <= 0) {
                const warningText = t('redemptionRequests.noRequests');
                enqueueSnackbar(warningText, {
                    variant: 'info',
                });
                setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
            }
            let requestsByIssuer = (redemptionRequestsResponse.data as RedemptionRequest[]).groupBy('issuer') as Map<
                string,
                RedemptionRequest[]
            >;
            setSortedRedemptionRequests(requestsByIssuer);
        }
    }, [t, enqueueSnackbar]);

    useEffect(() => {
        loadRedemptionRequests();
    }, [loadRedemptionRequests]);

    const openInfoModal = (redemptionRequest: RedemptionRequest) => {
        setSelectedRedemptionRequest(redemptionRequest);
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedRedemptionRequest(null);
        setModalOpen(false);
    };

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column} `}>
                        {error.length > 0 ? (
                            <ListHeader
                                text={error}
                                refreshAction={() => {
                                    setError('');
                                    loadRedemptionRequests();
                                }}
                            />
                        ) : (
                            <>
                                <ListHeader
                                    text={'Redemption Requests List'}
                                    refreshAction={() => {
                                        loadRedemptionRequests();
                                    }}
                                />
                                <RedemptionRequestsList
                                    sortedRedemptionRequests={sortedRedemptionRequests}
                                    openInfoModal={openInfoModal}
                                />
                                {selectedRedemptionRequest && modalOpen && (
                                    <RedemptionRequestDialog
                                        open={modalOpen}
                                        request={selectedRedemptionRequest}
                                        onClose={handleClose}
                                    />
                                )}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
        </>
    );
};
