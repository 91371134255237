import React from 'react';
import RetailAssetList from './RetailAssets/RetailAssetList';
import RetailFunctions from './RetailFunctions';
import RetailHeader from './RetailHeader';
import RetailPayees from './RetailPayees/RetailPayees';
import RetailPaymentsList from './RetailPayments/RetailPaymentsList';
import RetailSection from './RetailSection';
import RetailSectionTitle from './RetailSectionTitle';
import RetailStickyButtons from './RetailStickyButtons';
import RetailTransactionLog from './RetailTransactionLog/RetailTransactionLog';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useRetailHomeStyles } from 'materialStyles/retailStyles/retailHomeStyles';

const Retail = () => {
    const layoutClasses = useLayoutStyles();
    const classes = useRetailHomeStyles();
    return (
        <div>
            <RetailHeader />
            <div
                style={{
                    maxWidth: 380,
                    marginTop: 20,
                }}
                className={classes.largeScreenSection}
            >
                <RetailFunctions />
            </div>
            <div className={`${layoutClasses.componentWrapper} ${classes.retailWrapper}`}>
                <RetailSection>
                    <RetailSectionTitle>Assets</RetailSectionTitle>
                    <RetailAssetList />
                </RetailSection>
                <RetailSection>
                    <RetailSectionTitle>Payment Requests</RetailSectionTitle>
                    <RetailPaymentsList />
                </RetailSection>
                <RetailSection>
                    <RetailSectionTitle>Payees</RetailSectionTitle>
                    <RetailPayees />
                </RetailSection>
                <RetailSection>
                    <RetailSectionTitle>Transactions</RetailSectionTitle>
                    <RetailTransactionLog />
                </RetailSection>
                <RetailStickyButtons />
            </div>
        </div>
    );
};

export default Retail;
