import { validateContactNumber, validateEmail } from './detailsValidation';

import BasicDetails from './BasicDetails';
import PasswordStep from './PasswordStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { StepperFormStep } from '@r3/cbdc-asset-frontend-core';
import { registerRetailCustomer } from 'api/customerApi';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RegisterRetailUser = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [postCode, setPostCode] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordCheck, setPasswordCheck] = useState<string>('');
    const [accountName, setAccountName] = useState<string>('');

    const steps: StepperFormStep[] = [
        {
            title: t('retail.basicDetailStep'),
            component: (
                <BasicDetails
                    name={name}
                    setName={setName}
                    email={email}
                    setEmail={setEmail}
                    phone={phone}
                    setPhone={setPhone}
                    postCode={postCode}
                    setPostCode={setPostCode}
                />
            ),
            isNextStepDisabled: () =>
                !(name.length > 0 && validateEmail(email) && validateContactNumber(phone) && postCode.length > 0),
        },
        {
            title: t('retail.password'),
            component: (
                <PasswordStep
                    password={password}
                    setPassword={setPassword}
                    passwordCheck={passwordCheck}
                    setPasswordCheck={setPasswordCheck}
                    accountName={accountName}
                    setAccountName={setAccountName}
                />
            ),
            isNextStepDisabled: () => password.length <= 0 || password !== passwordCheck,
        },
    ];

    const submit = async () => {
        const response = await registerRetailCustomer(name, email, phone, password, postCode);
        if (response.error) {
            enqueueSnackbar(t('error.registeringRetailUser', { error: response.error }), {
                variant: 'error',
            });
            return;
        }
        enqueueSnackbar(t('success.registeringRetailUser'), {
            variant: 'success',
        });
    };

    const handleReset = () => {
        setName('');
        setEmail('');
        setPhone('');
        setPostCode('');
        setPassword('');
        setPasswordCheck('');
    };

    return <StepperForm submit={submit} steps={steps} handleReset={handleReset} submitButtonText={'Create Account'} />;
};

export default RegisterRetailUser;
