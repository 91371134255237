export const validateEmail = (email: string) => {
    if (
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email
        ) &&
        email.length <= 254
    ) {
        return true;
    }
    return false;
};

export const validateContactNumber = (text: string) => {
    if ((text.includes('+') && text.indexOf('+') > 0) || text.split('+').length - 1 > 1) {
        return false;
    }
    var numbers = /[0-9 +]+$/;
    if (text.match(numbers) && text.length < 20) {
        return true;
    } else {
        return false;
    }
};
