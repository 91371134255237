import { Button, Paper } from '@material-ui/core';
import { DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';
import { memo, useEffect, useState } from 'react';

import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RetailTransfer from './RetailTransfer';
import SendIcon from '@material-ui/icons/Send';
import { deletePayee } from '../../../api/retailApi';
import useComponentVisible from '../../../hooks/ComponentVisible';
import { useRetailItemStyles } from 'materialStyles/retailStyles/retailItemStyles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export type Payee = {
    payeeName: string;
    description: string;
    customerId: string;
    accountId: string;
    payeeId: string;
    accountHostX500: string;
};

type RetailPayeeItemProps = {
    payee: Payee;
    color: string;
    reFetchData: () => void;
};

const RetailPayeeItem: React.FC<RetailPayeeItemProps> = ({ payee, color, reFetchData }) => {
    const classes = useRetailItemStyles();
    const {
        ref,
        isComponentVisible: isComponentOpen,
        setIsComponentVisible: setIsComponentOpen,
    } = useComponentVisible(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (ref.current && isComponentOpen) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
        // eslint-disable-next-line
    }, [isComponentOpen]);

    const deleteSelectedPayee = async () => {
        const response = await deletePayee(payee.payeeId);
        if (response.error) {
            enqueueSnackbar(t('error.deletingPayee', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.deletingPayee'), {
                variant: 'success',
            });
        }
        reFetchData();
    };

    const dialogClose = () => {
        setIsDialogOpen(false);
    };

    const isDetailsShowing = isDialogOpen || isComponentOpen;
    return (
        <>
            <Paper
                ref={ref}
                className={`${classes.request} ${!isComponentOpen ? classes.hoverableRequest : ''}`}
                elevation={5}
                onClick={() => {
                    if (!isComponentOpen) {
                        setIsComponentOpen(true);
                    }
                }}
                data-testid="payee_request"
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <AccountCircleRoundedIcon
                        className={classes.requestIcon}
                        style={{ color: color, margin: isDetailsShowing ? '13px 8px auto 8px' : 'auto 8px auto 8px' }}
                    />
                </div>
                <div className={classes.itemInfo}>
                    <div className={classes.payeeDesc}>{payee.payeeName}</div>
                    <div className={classes.requestInfoWrapper}>
                        <div>
                            <b>Account Id:</b> {payee.accountId}
                        </div>
                        {isDetailsShowing && (
                            <>
                                <div>
                                    <b>Payee Id:</b> {payee.payeeId}
                                </div>
                                <div>
                                    <b>Bank Name:</b> {payee.accountHostX500}
                                </div>
                            </>
                        )}
                    </div>

                    {isDetailsShowing && (
                        <div style={{ display: 'flex', margin: '8px auto auto auto' }}>
                            <Button
                                className={`${classes.actionButtonTwo} ${classes.deleteIcon}`}
                                onClick={() => {
                                    deleteSelectedPayee();
                                }}
                                endIcon={<DeleteForeverIcon className={classes.buttonIcon} />}
                            >
                                Delete
                            </Button>

                            <Button
                                className={`${classes.actionButtonTwo} ${classes.sendIcon}`}
                                onClick={() => {
                                    setIsDialogOpen(true);
                                }}
                                endIcon={<SendIcon className={classes.buttonIcon} />}
                            >
                                Send
                            </Button>
                        </div>
                    )}
                </div>
                {isDialogOpen && (
                    <DialogWindow open={isDialogOpen} title={'Payment'} onClose={dialogClose}>
                        <DialogBody>
                            <RetailTransfer payeeId={payee.payeeId} finishedTransfer={dialogClose} />
                        </DialogBody>
                    </DialogWindow>
                )}
            </Paper>
        </>
    );
};

export default memo(RetailPayeeItem);
