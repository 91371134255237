import { Theme, createStyles, makeStyles } from '@material-ui/core';

import { Divider } from '@material-ui/core';
import { Drawer } from '@material-ui/core';
import React from 'react';

type Props = {
    open: boolean;
    closeDrawer: Function;
    appName: string;
};

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            appTitle: {
                display: 'block',
                color: theme.palette.secondary.main,
                [theme.breakpoints.up('sm')]: {
                    display: 'none',
                },
            },
            title: {
                textAlign: 'center',
                paddingTop: 20,
                paddingBottom: 20,
                fontWeight: 'bold',
                color: theme.palette.secondary.main,
                fontSize: 20,
            },
            paperRoot: {
                backgroundColor: theme.palette.primary.dark,
            },
            img: {
                marginLeft: 'auto',
                marginRight: 'auto',
                [theme.breakpoints.up('sm')]: {
                    display: 'none',
                },
            },
            divider: {
                backgroundColor: theme.palette.secondary.main,
            },
        }),
    { index: 1 }
);

const DrawerNav: React.FC<Props> = ({ open, closeDrawer, appName, children }) => {
    const drawerStyles = useStyles();
    return (
        <div className="navbar">
            <Drawer
                classes={{ paper: drawerStyles.paperRoot }}
                onClick={() => closeDrawer()}
                onClose={() => closeDrawer()}
                open={open}
            >
                <img className={drawerStyles.img} src={`/cordawht.png`} height={45} width={110} alt="Corda R3" />
                <Divider />
                <div className={`${drawerStyles.title} ${drawerStyles.appTitle}`}>{appName}</div>
                <Divider />
                {/* <div className={drawerStyles.title}>{'Navigation'}</div> */}
                <Divider className={drawerStyles.divider} />
                <span className="navContentWrapper">{children}</span>
            </Drawer>
        </div>
    );
};

export default DrawerNav;
