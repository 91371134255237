import apiCall from './apiCall';

/**
 * @remark
 * Requests the asset summary (tokenDefinitions, totals and retail totals)
 *
 * @param dontTrack to track the promise with a loading spineer as part of react-promise-tracker or not
 *
 * @returns AxiosResponse that resolves in assetSummary data or error
 */
export const requestAssetSummary = async (dontTrack?: boolean) => {
    return apiCall({ method: 'get', path: '/api/assets/summary', dontTrackRequest: dontTrack });
};
