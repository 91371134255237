import { Asset, INITIAL_EMPTY_ASSET_STATE, StepperFormStep } from '@r3/cbdc-asset-frontend-core';
import { Fade, ListSubheader, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import BondOverviewStep from 'components/commonComponents/GenericStepperFormComponents/BondOverviewStep';
import RequestSummaryStep from './RequestSummaryStep';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAmountToRequestStep from './SelectAmountToRequest';
import SelectAssetToRequestStep from './SelectAssetToRequestStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { createIssuanceRequest } from 'api/issuanceApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetBondsSummary from 'hooks/GetBondsSummary';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const RequestCBDC: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAsset, setSelectedAsset] = useState<Asset>(INITIAL_EMPTY_ASSET_STATE);
    const [error, setError] = useState<string>('');
    const { t } = useTranslation();
    const { assets, getAssetbyName, getAllAssets, assetsError } = useGetAssetDefinitions();
    const [amount, setAmount] = useState<string>('0');
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { bonds, getAllBondsData, bondsError } = useGetBondsSummary();

    const submitRequest = async () => {
        const submitResponse: ResolvedPromise = await createIssuanceRequest(
            selectedAsset.party,
            selectedAsset.tokenIdentifier,
            parseFloat(amount)
        );
        if (submitResponse.error) {
            enqueueSnackbar(t('error.requestingCBDC', { error: submitResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.requestingCBDC'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);
        }
    };

    useEffect(() => {
        if (bondsError.length > 0) {
            setError(bondsError);
        }
    }, [bondsError]);

    useEffect(() => {
        getAllAssets();
        getAllBondsData();
    }, [getAllAssets, getAllBondsData]);

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = getAssetbyName(event.target.value as string);
        setSelectedAsset(asset);
        setAmount('0');
    };

    const handleReset = () => {
        getAllBondsData();
        setSelectedAsset(INITIAL_EMPTY_ASSET_STATE);
        setAmount('0');
    };

    const assetSelectOptions = (): JSX.Element[] => {
        const toRender: JSX.Element[] = [];
        let issuer = '';
        let sortedAssets = [...assets];
        sortedAssets.sort((a, b) => (a.party.toLocaleUpperCase() > b.party.toLocaleUpperCase() ? 1 : -1));
        sortedAssets.forEach((as, i) => {
            if (issuer !== as.party) {
                toRender.push(<ListSubheader key={as.party + i}>{as.party}</ListSubheader>);
            }
            issuer = as.party;

            toRender.push(
                <MenuItem key={as.tokenName + i} value={as.tokenName} data-testid={'asset-option'}>
                    {as.tokenName}
                </MenuItem>
            );
        });
        return toRender;
    };

    const invalidAssetAmountInput = (): boolean => {
        if (amount.length <= 0 || parseFloat(amount) < 10) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (amount.length <= 0) {
            setAmount('0');
        }
    }, [amount]);

    const steps: StepperFormStep[] = [
        {
            title: t('requestCBDC.viewBondsStep'),
            component: <BondOverviewStep bonds={bonds} />,
            isNextStepDisabled: () => {
                if (bonds.usdValue > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('requestCBDC.selectAssetStep'),
            component: (
                <SelectAssetToRequestStep
                    assetName={selectedAsset.tokenName}
                    handleAssetChange={handleAssetChange}
                    assetSelectOptions={assetSelectOptions()}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('transferAssets.amountToTransferStep'),
            component: (
                <SelectAmountToRequestStep
                    selectedAsset={selectedAsset}
                    setAmount={setAmount}
                    invalidAssetAmountInput={invalidAssetAmountInput()}
                    amount={amount}
                    bonds={bonds}
                />
            ),
            isNextStepDisabled: () => {
                if (parseFloat(amount) > 0 && parseFloat(amount) <= bonds.usdValue) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('requestCBDC.requestReviewStep'),
            component: (
                <RequestSummaryStep
                    assetName={selectedAsset.tokenName}
                    partyName={selectedAsset.party}
                    bondValue={bonds.usdValue}
                    amount={amount}
                />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitRequest}
                    steps={steps}
                    renderError={error}
                    submitButtonText={t('commonText.request')}
                    handleReset={handleReset}
                />
            </Fade>
        </>
    );
};
