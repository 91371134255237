import { IconButton } from '@material-ui/core';
import { RetailTransactionsContext } from '../../../contexts/RetailTransactionsContext/RetailTransactionsContext';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import TransactionLogItem from './TransactionLogItem';
import { useContext } from 'react';
import { useRetailAssetsStyles } from 'materialStyles/retailStyles/retailAssetsStyles';
import { useRetailItemStyles } from 'materialStyles/retailStyles/retailItemStyles';
import { useUserContextProvider } from 'contexts/RetailUserLogin/RetailUserContext';

const RetailTransactionLog = () => {
    const transactionLogContext = useContext(RetailTransactionsContext);
    const retailAssetStyles = useRetailAssetsStyles();
    const classes = useRetailItemStyles();
    const { data, refreshTransactions, error, loading } = transactionLogContext;
    const userContext = useUserContextProvider();
    return (
        <div className={classes.sectionWrapper}>
            <div className={classes.actionIconWrapper}>
                <IconButton
                    className={classes.actionButton}
                    onClick={() => {
                        refreshTransactions();
                    }}
                >
                    <SyncRoundedIcon data-testid={'refresh_logs'} className={classes.actionIcon} />
                </IconButton>
            </div>
            <div className={classes.list}>
                {(!data || (data && data.length === 0)) && (
                    <div className={retailAssetStyles.noAssetsText}>
                        {error.length > 0 ? error : loading ? 'Loading' : 'No transactions on record'}
                    </div>
                )}
                {data &&
                    data.map((dataRow) => {
                        return (
                            <TransactionLogItem
                                key={dataRow.txId}
                                txLogData={dataRow}
                                accountId={userContext?.customerDetails ? userContext.customerDetails.accountId : ''}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default RetailTransactionLog;
