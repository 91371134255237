import { Asset, LineData, TimePoint } from '@r3/cbdc-asset-frontend-core';
import { AssetList, AssetWithTotal } from './AssetList';
import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from 'constants/Animations';
import { Divider, IconButton, Slide } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getBalanceOverTimeData, getMoneySupplyWithInterest } from 'api/financialReportApi';

import BalanceOverTimeLineTooltip from './BalanceOverTimeLineTooltip';
import { BrushComponent } from './BrushComponent';
import DashboardResponsiveLine from '../DashboardComponents/DashboardResponsiveLine';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { LINE_IDS } from './graphDetails';
import { useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

function parseDate<T>(items: T[], key: string) {
    items.forEach((i) => {
        i[key] = new Date(i[key]);
    });
}

export const BalanceOverTime: React.FC = () => {
    const [assetData, setAssetData] = useState<TimePoint[]>([]);
    const [graphData, setGraphData] = useState<LineData[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<Asset | undefined>(undefined);
    const { assets, getAllAssets } = useGetAssetDefinitions();
    const [assetsWithTotal, setAssetsWithTotal] = useState<AssetWithTotal[]>([]);
    const classes = useDashboardStyles();
    const layoutClasses = useLayoutStyles();
    const { getAssetSummary, assetSummary } = useGetAssetSummary();
    const [nDataPoints, setNDataPoints] = useState<number>(50);

    const fetchData = useCallback(async () => {
        if (selectedAsset === undefined) {
            return;
        }
        const response = await getBalanceOverTimeData(selectedAsset.tokenIdentifier, nDataPoints);
        if (response.data == null) {
            setAssetData(Array<TimePoint>());
            return;
        }
        parseDate(response.data.data, 'x');
        setAssetData(response.data.data);

        if (response.data.data.length === 0) {
            return;
        }

        const interestQueryRepsonse = await getMoneySupplyWithInterest(
            selectedAsset.tokenIdentifier,
            response.data.data[0].x
        );
        if (interestQueryRepsonse.data == null) {
            return;
        }
        parseDate(interestQueryRepsonse.data, 'effectiveStartDate');
    }, [nDataPoints, selectedAsset]);

    const getGraphData = useCallback((assets: TimePoint[]) => {
        const result: LineData[] = [];
        result.push({ id: LINE_IDS.IssuedBase, data: assets });
        return result;
    }, []);

    const brushComponentData = useMemo(() => getGraphData(assetData), [assetData, getGraphData]);

    useEffect(() => {
        getAllAssets(true);
    }, [getAllAssets]);

    useEffect(() => {
        let aTot: AssetWithTotal[] = [];
        assets.forEach((asset) => {
            let assetFromSummary = assetSummary.find(
                (obj) => obj.tokenDefinition.tokenIdentifier === asset.tokenIdentifier
            );
            if (typeof assetFromSummary !== 'undefined') {
                aTot.push({ asset: asset, total: assetFromSummary.totalAmount });
            } else {
                aTot.push({ asset: asset, total: 0 });
            }
        });
        aTot.sort((x, y) => y.total - x.total);
        setAssetsWithTotal(aTot);

        // eslint-disable-next-line
    }, [assetSummary]);

    useEffect(() => {
        fetchData();
        getAssetSummary();
    }, [assets, selectedAsset, nDataPoints, fetchData, getAssetSummary]);

    useEffect(() => {
        setGraphData(getGraphData(assetData));
    }, [assetData, getGraphData]);

    const handleBrush = (startDate: Date, endDate: Date) => {
        let filteredAssets = assetData.filter((a) => a.x >= startDate && a.x <= endDate);
        setGraphData(getGraphData(filteredAssets));
    };

    return (
        <>
            {assets.length > 0 && (
                <div className={layoutClasses.componentWrapper}>
                    <Slide
                        direction="right"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnSmall}`}
                            style={{ height: 900, minWidth: 300 }}
                        >
                            <AssetList
                                assetsWithTotal={assetsWithTotal}
                                setSelectedAsset={setSelectedAsset}
                                selectedAsset={selectedAsset}
                            />
                        </div>
                    </Slide>

                    <Slide
                        direction="left"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            style={{
                                overflow: 'visible',
                                paddingTop: 12,
                                height: 900,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            className={`${layoutClasses.column} ${layoutClasses.columnFill} ${layoutClasses.columnNoPadding} `}
                        >
                            <div>
                                {assetData.length <= 0 ? (
                                    <div style={{ color: 'white', fontSize: 22 }}>
                                        No balance over time data for: {selectedAsset?.tokenName}
                                    </div>
                                ) : (
                                    <div style={{ color: 'white', fontSize: 22 }}>
                                        Balance over time for: {selectedAsset?.tokenName}
                                    </div>
                                )}
                                <div
                                    style={{
                                        width: 320,
                                        display: 'flex',
                                        color: 'white',
                                        alignContent: 'space-evenly',
                                        justifyContent: 'space-evenly',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <div style={{ marginTop: 12, fontSize: 14 }}> History length (data points) :</div>
                                    <IconButton
                                        style={{ color: 'burlywood' }}
                                        onClick={() => {
                                            setNDataPoints(Math.max(10, nDataPoints - 10));
                                        }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <div style={{ marginTop: 12, fontSize: 14 }}>{nDataPoints}</div>
                                    <IconButton
                                        style={{ color: 'burlywood' }}
                                        onClick={() => {
                                            setNDataPoints(Math.max(10, nDataPoints + 10));
                                        }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div
                                className={` ${classes.nivoWrapperLine}`}
                                style={{ height: 620, overflow: 'visible', marginTop: 0 }}
                            >
                                <DashboardResponsiveLine data={graphData} pointTooltip={BalanceOverTimeLineTooltip} />
                                <BrushComponent data={brushComponentData} updateGraph={handleBrush} />
                                <Divider className={classes.divider} />
                            </div>
                        </div>
                    </Slide>
                </div>
            )}
        </>
    );
};
