import { Route, Switch, useHistory } from 'react-router-dom';

import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import { AppWrapperProps } from './AppWrapperProps';
import { Assistant } from 'components/assistant/Assistant';
import DrawerNav from 'components/layout/DrawerNav/DrawerNav';
import { IconButton } from '@material-ui/core';
import LoadingModal from '../commonComponents/Loading/LoadingModal';
import { LoginScreen } from '@r3/cbdc-asset-frontend-core';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import NavBar from 'components/layout/NavBar/NavBar';
import TopNavWrapper from 'components/layout/TopNavWrapper/TopNavWrapper';
import WholesaleNavButtons from 'components/layout/NavButtons/WholesaleNavButtons';
import { WholesalePollingNotificationService } from '../pollingNotificationService/PollingNotificationService';
import { axiosInstance } from 'api/apiConfig';
import { useAppStyles } from 'materialStyles/appStyles';
import { useBasicAuth } from 'hooks/UseBasicAuth';
import useNotificationService from 'hooks/UseNotificationService';
import { useState } from 'react';
import useWholesalePages from 'hooks/useWholesalePages';

const WholesaleWrapper: React.FC<AppWrapperProps> = ({ promiseInProgress }) => {
    const classes = useAppStyles();
    const history = useHistory();
    const { isLoggedIn, loginError } = useBasicAuth();
    const { receiveNotifications, toggleNotificationService } = useNotificationService();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { pages } = useWholesalePages();

    const wholesaleNavButtons = (
        <WholesaleNavButtons
            navButtons={pages.filter((page) => typeof page.navButton !== 'undefined').map((page) => page.navButton!)}
            receiveNotifications={receiveNotifications}
            toggleNotificationService={toggleNotificationService}
        />
    );

    return (
        <div>
            <TopNavWrapper
                icon={
                    <AccountBalanceRoundedIcon
                        id="AppIcon"
                        className={classes.appIcon}
                        onClick={() => {
                            history.push('/');
                        }}
                    />
                }
                button={
                    <IconButton
                        className={`menuButton ${classes.menuButton}`}
                        color="primary"
                        onClick={() => {
                            setDrawerOpen(!drawerOpen);
                        }}
                    >
                        <MenuRoundedIcon style={{ width: 35, height: 35 }} />
                    </IconButton>
                }
                navButtons={wholesaleNavButtons}
            />
            {isLoggedIn ? (
                <>
                    {promiseInProgress && <LoadingModal />}
                    <div className={`${classes.mainContent} ${promiseInProgress ? classes.blurred : ''}`}>
                        <Switch>
                            {pages.map((page) => (
                                <Route
                                    key={page.route}
                                    exact={page.exactRoute}
                                    path={page.route}
                                    component={page.pageComponent}
                                />
                            ))}
                        </Switch>
                    </div>
                </>
            ) : (
                <LoginScreen error={loginError} axiosInstance={axiosInstance} />
            )}

            <DrawerNav
                open={drawerOpen}
                closeDrawer={() => {
                    setDrawerOpen(!drawerOpen);
                }}
                appName={'Wholesale Bank'}
            >
                <NavBar>{wholesaleNavButtons}</NavBar>
            </DrawerNav>
            <Assistant />
            {receiveNotifications && isLoggedIn && <WholesalePollingNotificationService />}
        </div>
    );
};

export default WholesaleWrapper;
