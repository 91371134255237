import { AssetRequest, NumberFormat, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { DialogAction, DialogActions, DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';
import { Divider, Typography } from '@material-ui/core';

import DialogTransactionSummary from 'components/commonComponents/DialogTransactionSummary/DialogTransactionSummary';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import React from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next/';

export interface SimpleDialogProps {
    open: boolean;
    request: AssetRequest;
    assetSummary: SummedAsset | undefined;
    approve: () => void;
    decline: () => void;
    onClose: () => void;
    ownRequest: boolean;
}

export function TransferRequestDialog(props: SimpleDialogProps) {
    const { onClose, approve, request, open, decline, ownRequest, assetSummary } = props;
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const isActionable = request.requestState === 'REQUESTED' && !ownRequest;
    const approveDisabled =
        typeof assetSummary == 'undefined' || request.assetAmount > assetSummary.availableToTransact;
    return (
        <DialogWindow open={open} onClose={onClose} title={'Pull Transfer Request : ' + request.requestState}>
            <DialogBody>
                <Typography gutterBottom>{t('commonLabels.requestId') + ': ' + request.requestId}</Typography>
                <Typography gutterBottom>
                    {t('defineAsset.assetName') + ': ' + request.tokenDefinition.tokenName}
                </Typography>
                <Typography gutterBottom>{'Requestor: ' + request.requestingParty}</Typography>
                <Typography gutterBottom>{'Requestee: ' + request.otherParty}</Typography>
                <Typography gutterBottom>
                    {'Asset Amount Requested: ' + NumberFormat.addThousandSeparators(request.assetAmount.toString())}
                </Typography>

                {isActionable && (
                    <>
                        <Divider className={layoutClasses.summaryDivider} />
                        <DialogTransactionSummary asset={assetSummary} toSpend={request.assetAmount} />
                    </>
                )}
            </DialogBody>
            <DialogActions>
                {isActionable && (
                    <>
                        <DialogAction
                            text={t('commonText.approve')}
                            action={approve}
                            icon={<ThumbUpIcon />}
                            disabled={approveDisabled}
                        />
                        <DialogAction text={t('commonText.deny')} action={decline} icon={<NotInterestedIcon />} />
                    </>
                )}
            </DialogActions>
        </DialogWindow>
    );
}
