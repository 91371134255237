import { NumberFormat, SummedAsset } from '@r3/cbdc-asset-frontend-core';

import SummaryRow from './SummaryRow';
import SummaryTable from './SummaryTable';
import { useTranslation } from 'react-i18next';

type Props = {
    asset: SummedAsset;
    toSpend: number;
    small?: boolean;
};

const TransactionSummary: React.FC<Props> = ({ asset, toSpend, small = false }) => {
    const { t: texts } = useTranslation();

    return (
        <SummaryTable small={small}>
            <SummaryRow
                col1Text={texts('commonText.amount') + ` of ${asset.tokenName}`}
                col2Text={NumberFormat.addThousandSeparators(toSpend.toFixed(asset.tokenDecimal))}
            />
            <SummaryRow
                col1Text={texts('transactionSummary.remainingAfterTransaction')}
                col2Text={NumberFormat.addThousandSeparators(
                    (asset.totalInVault - toSpend).toFixed(asset.tokenDecimal)
                )}
            />
        </SummaryTable>
    );
};

export default TransactionSummary;
