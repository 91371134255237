import apiCall from './apiCall';

/**
 * @remark
 * Requests all of the bonds that the corresponding node holds
 *
 * @returns
 * AxiosResponse which resolved in an error or repsonse data
 */
export const requestAllBonds = async (dontTrack?: boolean) => {
    return apiCall({ method: 'get', path: '/api/bonds/summary', dontTrackRequest: dontTrack });
};
