import { AssetResponse, NumberFormat, ResponsivePieData } from '@r3/cbdc-asset-frontend-core';
import { useCallback, useState } from 'react';

export function useDashboardPieData(pieSize: 'BIG' | 'SMALL' = 'BIG') {
    const [uniqueAssets, setUniqueAssets] = useState<string[]>([]);
    const [assetsInVault, setAssetsInVault] = useState<number>(0);
    const [pieData, setPieData] = useState<ResponsivePieData[]>([]);
    const updatePieData = useCallback(
        (vaultAssets: AssetResponse[], filterAssets: string[]) => {
            let responsePieData: ResponsivePieData[] = [];
            let newAssetNames: string[] = [];
            let assetsInVault = 0;
            let totalToDisplay = 0;

            vaultAssets.forEach((asset) => {
                const pieDataItem: ResponsivePieData = {
                    id: asset.tokenDefinition.tokenName,
                    label: asset.tokenDefinition.tokenName.toUpperCase(),
                    value: parseFloat(asset.totalAmount.toFixed(asset.tokenDecimals)),
                    color: 'white',
                    amountInAccounts: NumberFormat.addThousandSeparators(
                        asset.amountHeldByAccounts.toFixed(asset.tokenDecimals)
                    ),
                    amountInOpenOrders:
                        asset.amountHeldInOpenOrders === undefined
                            ? undefined
                            : NumberFormat.addThousandSeparators(
                                  asset.amountHeldInOpenOrders.toFixed(asset.tokenDecimals)
                              ),
                };

                assetsInVault += asset.totalAmount;
                totalToDisplay += pieDataItem.value;

                newAssetNames.push(asset.tokenDefinition.tokenName);
                if (filterAssets.includes(asset.tokenDefinition.tokenName)) {
                    responsePieData.push(pieDataItem);
                }
            });
            setUniqueAssets(newAssetNames);
            setAssetsInVault(assetsInVault);

            let belowOnePercentSizeMultiplier = pieSize === 'BIG' ? 5 : 5.8;

            responsePieData.forEach((pieData) => {
                let { value, label } = getValueAndLabel(
                    pieData.value,
                    pieData.label,
                    totalToDisplay,
                    belowOnePercentSizeMultiplier
                );

                label = getUpdatedLabelBasedOnHeldAmount(
                    label,
                    pieData.value,
                    pieData.amountInAccounts,
                    pieData.amountInOpenOrders
                );

                pieData.value = value;
                pieData.label = label;
            });
            setPieData(responsePieData);
        },
        [pieSize]
    );

    return { uniqueAssets, assetsInVault, pieData, updatePieData };
}

function getValueAndLabel(
    originalValue: number,
    originalLabel: string,
    totalToDisplay: number,
    belowOnePercentSizeMultiplier: number
): { value: number; label: string } {
    const percentage = (originalValue / totalToDisplay) * 100;
    const getLowValue = (value) => value + (totalToDisplay / 100) * belowOnePercentSizeMultiplier - value;
    let label = originalLabel + ' : ' + NumberFormat.addThousandSeparators(originalValue);
    let value = originalValue;

    if (percentage < 1) {
        label = label + ' - < 1%';
        value = getLowValue(value);
    } else if (percentage < 3.5) {
        label = label + ` - ${percentage.toFixed(2)}%`;
        value = getLowValue(value);
    } else {
        label = label + ` - ${percentage.toFixed(2)}%`;
    }

    return { value, label };
}

function getUpdatedLabelBasedOnHeldAmount(
    originalLabel: string,
    value: number,
    amountInAccounts: string | undefined,
    amountInOpenOrders: string | undefined
): string {
    let label = originalLabel;

    if (isAmountValid(amountInAccounts)) {
        const amount = parseFloat(NumberFormat.removeThousandSeparators(amountInAccounts!));
        label = addAmountWithPercentage(label, amount, value, amountInAccounts!, 'retail accounts');
    }
    if (isAmountValid(amountInOpenOrders)) {
        const amount = parseFloat(NumberFormat.removeThousandSeparators(amountInOpenOrders!));
        label = addAmountWithPercentage(label, amount, value, amountInOpenOrders!, 'exchange offers');
    }

    return label;
}

function isAmountValid(amount: string | undefined) {
    return amount !== undefined && amount.length > 0 && parseFloat(NumberFormat.removeThousandSeparators(amount)) > 0;
}

function addAmountWithPercentage(
    label: string,
    amount: number,
    value: number,
    amountString: string,
    holdSourceLabel: string
) {
    return label + ` | Held in ${holdSourceLabel}: ${amountString} (${((amount / value) * 100).toFixed(2)}%)`;
}
