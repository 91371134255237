import RetailPayeeItem from './RetailPayeeItem';
import { useEffect } from 'react';
import { useRetailAssetsStyles } from 'materialStyles/retailStyles/retailAssetsStyles';
import { useRetailItemStyles } from 'materialStyles/retailStyles/retailItemStyles';
import useRetailPayeesContext from 'contexts/RetailPayeesContext/RetailPayeesContext';

const RetailPayeesList = () => {
    const classes = useRetailItemStyles();
    const retailHomeClasses = useRetailAssetsStyles();
    const { payees, error, getPayees, loading } = useRetailPayeesContext();
    useEffect(() => {
        getPayees();
    }, [getPayees]);
    return (
        <div className={classes.list} data-testid="payee_list">
            {payees.length === 0 && (
                <div className={retailHomeClasses.noAssetsText}>
                    {error.length > 0 ? error : loading ? 'Loading' : 'No payees added '}
                </div>
            )}
            {payees.map((payee) => {
                return (
                    <RetailPayeeItem key={payee.accountId} payee={payee} color={'#fc44a3'} reFetchData={getPayees} />
                );
            })}
        </div>
    );
};

export default RetailPayeesList;
