import {
    Asset,
    AssetResponse,
    ComplianceRuleKey,
    EMPTY_SUMMED_STATE,
    StepperFormStep,
    SummedAsset,
} from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';

import AssetTransforms from 'utils/AssetTransforms';
import EnterAmountStep from './EnterAmountStep';
import { Fade } from '@material-ui/core';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAssetStep from './SelectAssetStep';
import SelectParticipantStep from 'components/commonComponents/GenericStepperFormComponents/SelectParticipantStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import TransferSummaryStep from './TransferSummaryStep';
import { transferAssetsTo } from 'api/transactApi';
import useGetParticipantsOnNetwork from 'hooks/GetParticipantsOnNetwork';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type Props = {
    ignoreInfoModal?: boolean;
    onSuccessfulSubmit?: () => void;
    getAllAssets: (dontTrack?: boolean | undefined) => Promise<boolean>;
    assetsError: string;
    assets: Asset[];
    getAssetSummary: (dontTrack?: boolean | undefined) => Promise<void>;
    assetSummary: AssetResponse[];
    summaryError: string;
};

export const TransferAssets: React.FC<Props> = ({
    onSuccessfulSubmit,
    ignoreInfoModal = false,
    assets,
    getAllAssets,
    assetsError,
    getAssetSummary,
    assetSummary,
    summaryError,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAsset, setSelectedAsset] = useState<SummedAsset>(EMPTY_SUMMED_STATE);
    const [selectedParticipant, setSelectedParticipant] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [assetsForTransfer, setAssetsForTransfer] = useState<SummedAsset[]>([]);
    const { participants, getParticipants, participantsError } = useGetParticipantsOnNetwork();
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const { t } = useTranslation();
    const checklistContext = useInteractiveChecklistContext();
    const location = useLocation();

    const handleReset = () => {
        setSelectedParticipant('');
        setAssetAmount('');
        setSelectedAsset(EMPTY_SUMMED_STATE);
        getAssetSummary();
    };

    useEffect(() => {
        setError((text) => text + (text.length > 0 ? '. ' : '') + participantsError);
    }, [participantsError]);

    const submitTransfer = async () => {
        const issuanceResponse: ResolvedPromise = await transferAssetsTo(
            selectedAsset.tokenIdentifier,
            selectedParticipant,
            assetAmount
        );

        if (issuanceResponse.error) {
            enqueueSnackbar(t('error.transferringAsset', { error: issuanceResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.transferringAsset'), {
                variant: 'success',
            });
            checklistContext?.completeStep(location.pathname);
            if (onSuccessfulSubmit) {
                onSuccessfulSubmit();
            }
        }

        handleReset();
    };

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    useEffect(() => {
        setError(summaryError);
    }, [summaryError]);

    useEffect(() => {
        if (assets.length > 0) {
            getAssetSummary();
        }
        // eslint-disable-next-line
    }, [JSON.stringify(assets), getAssetSummary]);

    useEffect(() => {
        if (assets.length > 0 && assetSummary.length > 0) {
            setError('');
        }
        setAssetsForTransfer(AssetTransforms.summedAssetsFromSummary(assetSummary, assets));
        // eslint-disable-next-line
    }, [JSON.stringify(assetSummary), JSON.stringify(assets)]);

    useEffect(() => {
        getAllAssets();
        getParticipants();
    }, [enqueueSnackbar, t, getAllAssets, getParticipants]);

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const tokenIdentifier: string = event.target.value as string;
        const asset = assetsForTransfer.find((obj) => obj.tokenIdentifier === tokenIdentifier);
        if (asset) {
            setSelectedAsset(asset);
        } else {
            setSelectedAsset(EMPTY_SUMMED_STATE);
        }
    };

    const invalidAssetAmountInput = (): boolean => {
        if (
            assetAmount.length <= 0 ||
            parseFloat(assetAmount) <= 0 ||
            selectedAsset.availableToTransact < parseFloat(assetAmount)
        ) {
            return true;
        }

        if (
            selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) &&
            parseFloat(selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)!.value) <
                parseFloat(assetAmount)
        ) {
            return true;
        }

        return false;
    };

    const steps: StepperFormStep[] = [
        {
            title: t('transferAssets.selectAssetStep'),
            component: (
                <SelectAssetStep
                    selectedAsset={selectedAsset}
                    handleAssetChange={handleAssetChange}
                    assetsForTransfer={assetsForTransfer}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0 && selectedAsset.availableToTransact > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('commonText.selectParticipantStep'),
            component: (
                <SelectParticipantStep
                    title={t('transferAssets.selectParticipant')}
                    selectedParticipant={selectedParticipant}
                    participants={participants.participants}
                    setSelectedParticipant={setSelectedParticipant}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedParticipant.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('transferAssets.amountToTransferStep'),
            component: (
                <EnterAmountStep
                    selectedAsset={selectedAsset}
                    assetAmount={assetAmount}
                    setAssetAmount={setAssetAmount}
                    invalidAssetAmountInput={invalidAssetAmountInput}
                />
            ),
            isNextStepDisabled: () => {
                if (assetAmount.length > 0 && !invalidAssetAmountInput()) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('transferAssets.confirmTransferStep'),
            component: (
                <TransferSummaryStep
                    assetAmount={assetAmount}
                    selectedAsset={selectedAsset}
                    selectedParticipant={selectedParticipant}
                />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitTransfer}
                    steps={steps}
                    renderError={error}
                    submitButtonText={t('commonText.transfer')}
                    handleReset={handleReset}
                    ignoreInfoModal={ignoreInfoModal}
                />
            </Fade>
        </>
    );
};
