import { LandingPage } from './LandingPage';
import { LandingPageContextProvider } from 'contexts/LandingPageContext/LandingPageContext';

const LandingPageContextWrapper = () => {
    return (
        <LandingPageContextProvider>
            <LandingPage />
        </LandingPageContextProvider>
    );
};

export default LandingPageContextWrapper;
