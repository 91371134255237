import { DialogAction, DialogActions, DialogBody } from '@r3/cbdc-asset-frontend-core';
import SwapOfferDisplay from 'components/decentralizedExchange/SwapOfferDisplay';
import { Order } from 'models/orders/Order';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CancelRounded } from '@material-ui/icons';
import { cancelPendingOrder } from 'api/decentralizedExchange';
import { useSnackbar } from 'notistack';
import EditRounded from '@material-ui/icons/EditRounded';

interface Props {
    order: Order;
    onClickUpdate: () => void;
    onClose: () => void;
    refreshOrders: () => void;
}

const OrderDetailsContent: FunctionComponent<Props> = ({ order, onClickUpdate, onClose, refreshOrders }) => {
    const { t: texts } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    async function cancelOrder() {
        onClose();
        const response = await cancelPendingOrder(order.orderId);
        if (response.error === undefined) {
            enqueueSnackbar(texts('success.exchangeOfferCancelled'), { variant: 'success' });
            refreshOrders();
        } else {
            enqueueSnackbar(texts('error.exchangeOfferCancelled', { error: response.error }), { variant: 'error' });
        }
    }

    return (
        <div data-testid="order_details_content">
            <DialogBody>
                <SwapOfferDisplay order={order} />
            </DialogBody>
            <DialogActions>
                <DialogAction
                    key="update_order"
                    icon={<EditRounded />}
                    text={texts('liquiditySavings.updateOffer')}
                    action={onClickUpdate}
                />
                <DialogAction
                    key="cancel_order"
                    icon={<CancelRounded />}
                    text={texts('liquiditySavings.cancelOffer')}
                    action={cancelOrder}
                />
            </DialogActions>
        </div>
    );
};
export default OrderDetailsContent;
