import { PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import { useEffect } from 'react';
import useGetRetailAssetDefinitions from './GetRetailAssetDefinitions';

export const usePaymentRequestService = () => {
    const { getAssetbyId, getAllAssets } = useGetRetailAssetDefinitions();

    useEffect(() => {
        getAllAssets();
    }, [getAllAssets]);

    const verifyPaymentRequest = (request: PaymentRequestResponse): boolean => {
        if (request.requestState !== 'REQUESTED') {
            return false;
        }
        if (!request.requestId || request.requestId.length <= 0) {
            return false;
        }
        if (!request.accountId || request.accountId.length <= 0) {
            return false;
        }
        if (!request.host || request.host.length <= 0) {
            return false;
        }
        if (!request.amount || request.amount <= 0) {
            return false;
        }
        if (!request.amount || request.amount <= 0) {
            return false;
        }
        if (getAssetbyId(request.tokenIdentifier).tokenIdentifier !== request.tokenIdentifier) {
            return false;
        }

        return true;
    };

    return { verifyPaymentRequest };
};
