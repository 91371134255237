import { Divider, Paper, SvgIcon, Typography } from '@material-ui/core';

import { Bond, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type BondOverviewStepProps = {
    bonds: Bond;
};

const BondOverviewStep: React.FC<BondOverviewStepProps> = ({ bonds }) => {
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('requestCBDC.totalBondsInVault')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <div style={{ display: 'flex' }}>
                    <Typography id="BondsText" className={`${layoutClasses.text}`} style={{ marginBottom: 15 }}>
                        {t('requestCBDC.bondsOwned') + '  '}
                    </Typography>
                    <SvgIcon color="secondary" style={{ marginTop: 12 }}>
                        <BondsIcon />
                    </SvgIcon>
                    <Typography id="BondsAmount" className={`${layoutClasses.text}`} style={{ marginBottom: 15 }}>
                        {': ' + NumberFormat.addThousandSeparators(bonds.usdValue)}
                    </Typography>
                </div>
            </Paper>
        </div>
    );
};

export default BondOverviewStep;
