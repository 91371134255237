import { PaymentRequestProps } from './PaymentRequest';
import { Typography } from '@material-ui/core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

const CompletedRequest: React.FC<PaymentRequestProps> = ({ requestResponse }) => {
    const layoutClasses = useLayoutStyles();
    return (
        <div
            style={{
                maxWidth: '100%',
                margin: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
            }}
        >
            <Typography className={layoutClasses.text} style={{ fontSize: 18, color: 'lightgreen' }}>
                {'This payment request has been fulfilled.'}
            </Typography>

            <Typography className={layoutClasses.text} style={{ wordWrap: 'break-word', maxWidth: '100%' }}>
                <b>Transaction ID: </b> {requestResponse.txId}
            </Typography>
            <Typography className={layoutClasses.text} style={{ wordWrap: 'break-word' }}>
                <b>Transaction Date: </b> {new Date(requestResponse.paymentDate).toLocaleString()}
            </Typography>
        </div>
    );
};

export default CompletedRequest;
