import React, { useState } from 'react';
import { useLandingPageStyles, useLayoutStyles } from '@r3/cbdc-asset-frontend-core';

import TabButton from './TabButton';

export type TabComponentPair = {
    tabButton: { icon: React.ReactNode; text: string };
    component: React.ReactNode;
};

type Props = {
    tabComponentPairs: TabComponentPair[];
};

const TabSection: React.FC<Props> = ({ tabComponentPairs }) => {
    const layoutClasses = useLayoutStyles();
    const classes = useLandingPageStyles();
    const [tabIndex, setTabIndex] = useState<number>(0);

    if (tabComponentPairs.length === 0) {
        return null;
    }

    return (
        <div
            className={`${layoutClasses.componentWrapper} `}
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                alignContent: 'space-around',
            }}
        >
            <div
                style={{
                    marginTop: 12,
                    marginBottom: -20,
                }}
            >
                {tabComponentPairs.map(({ tabButton }, index) => (
                    <TabButton
                        selected={index === tabIndex}
                        icon={tabButton.icon}
                        text={tabButton.text}
                        onClick={() => setTabIndex(index)}
                    />
                ))}
            </div>

            {tabComponentPairs[tabIndex].component}
        </div>
    );
};

export default TabSection;
