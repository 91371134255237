import { AssetResponse, ComplianceRuleKey } from '@r3/cbdc-asset-frontend-core';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';

import AssetSummary from 'components/commonComponents/SummaryComponents/AssetSummary';
import { Button } from '@material-ui/core';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import MaxValueRuleSection from 'components/commonComponents/MaxValueRuleSection/MaxValueRuleSection';
import { depositToRetailAccount } from 'api/customerApi';
import { useFormStyles } from 'materialStyles/formStyles';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const EmptyAssetResponse: AssetResponse = {
    issuer: '',
    holder: '',
    tokenDecimals: 0,
    totalAmount: 0,
    amountHeldInOpenOrders: 0,
    amountHeldByAccounts: 0,
    amountHeldByNode: 0,
    tokenDefinition: {
        tokenName: '',
        tokenIdentifier: '',
        definedBy: '',
        decimals: 0,
    },
};

type DepositProps = {
    accountId: string;
    finishCallBack: () => void;
};

const Deposit: React.FC<DepositProps> = ({ accountId, finishCallBack }) => {
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const formClasses = useFormStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { assetSummary, getSummedAssetById } = useGetAssetSummary(true);
    const { getAssetbyId } = useGetAssetDefinitions(true);
    const [selectedAsset, setSelectedAsset] = useState<AssetResponse>(EmptyAssetResponse);
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = assetSummary.find((asset) => asset.tokenDefinition.tokenName === (event.target.value as string));
        if (asset) {
            setSelectedAsset(asset);
        } else {
            setSelectedAsset(EmptyAssetResponse);
        }
        setAssetAmount('0');
    };

    const submit = async () => {
        setSubmitButtonDisabled(true);
        const response = await depositToRetailAccount(
            accountId,
            selectedAsset.tokenDefinition.tokenIdentifier,
            parseFloat(assetAmount)
        );

        if (response.error) {
            enqueueSnackbar(t('error.depositingToCustomer', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.depositingToCustomer', { error: response.error }), {
                variant: 'success',
            });
        }
        setSubmitButtonDisabled(false);
        finishCallBack();
    };

    const assetDefinition = getAssetbyId(selectedAsset.tokenDefinition.tokenIdentifier);
    const maxValue = assetDefinition.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)?.value;
    const maxValueExceeded = maxValue ? parseFloat(assetAmount) > parseFloat(maxValue) : false;

    const invalidAssetAmountInput = parseFloat(assetAmount) > selectedAsset.amountHeldByNode;
    const summedAsset = getSummedAssetById(selectedAsset.tokenDefinition.tokenIdentifier);
    return (
        <div>
            <FormControl
                id="SelectAsset"
                variant="filled"
                className={`${formClasses.columnItem} ${formClasses.formInput} ${layoutClasses.formInput} `}
            >
                <InputLabel id="SelectLabel">Asset</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedAsset.tokenDefinition.tokenName}
                    onChange={handleAssetChange}
                    data-testid="asset-select"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {assetSummary.map((as, i) => {
                        return (
                            <MenuItem
                                key={as.tokenDefinition.tokenName + i}
                                value={as.tokenDefinition.tokenName}
                                data-testid={'asset-option'}
                            >
                                {as.tokenDefinition.tokenName}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            {assetDefinition && maxValue && <MaxValueRuleSection amount={maxValue} />}
            {!summedAsset && (
                <Typography className={layoutClasses.sectionLabel} style={{ textAlign: 'left' }}>
                    {'Select an asset from the vault.'}
                </Typography>
            )}
            {summedAsset && (
                <AssetSummary
                    asset={summedAsset}
                    toSpend={parseFloat(assetAmount) > 0 ? parseFloat(assetAmount) : 0}
                    small
                />
            )}
            <CurrencyInput
                disabled={!summedAsset}
                decimalPlaces={selectedAsset?.tokenDecimals}
                onChange={setAssetAmount}
                label={
                    invalidAssetAmountInput
                        ? t('commonText.invalidAmount') + ', not enough assets in the vault.'
                        : maxValueExceeded
                        ? 'Max value compliance rule exceeded'
                        : t('issueAsset.assetAmount')
                }
                testId="amount-to-transfer"
                invalidInput={invalidAssetAmountInput || maxValueExceeded}
            />
            <Button
                className={`${formClasses.formControlButton}  ${formClasses.button}`}
                style={{ marginTop: 16 }}
                variant="outlined"
                color="primary"
                disabled={
                    invalidAssetAmountInput ||
                    !summedAsset ||
                    parseFloat(assetAmount) <= 0 ||
                    maxValueExceeded ||
                    assetAmount.length <= 0 ||
                    submitButtonDisabled
                }
                onClick={() => submit()}
                data-testid={'submit'}
            >
                {'Deposit'}
            </Button>
        </div>
    );
};

export default Deposit;
