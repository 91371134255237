import { AssetRequest, NumberFormat } from '@r3/cbdc-asset-frontend-core';

import RequestListContainer from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListContainer';
import RequestListItem from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListItem';
import { getRequestStatusColorWithId } from '../requestStatusColors';
import { getRequestStatusText } from '../requestStatusText';
import { useTranslation } from 'react-i18next';

type RedemptionRequestProps = {
    request: AssetRequest;
    onClick: () => void;
};

const TransferRequestComponent: React.FC<RedemptionRequestProps> = ({ onClick, request }) => {
    const { t } = useTranslation();
    return (
        <RequestListContainer onClick={() => onClick()}>
            <RequestListItem>
                {t('defineAsset.assetName') + ': '}
                {request.tokenDefinition.tokenName}
            </RequestListItem>
            <RequestListItem>
                {'Requestee: '}
                {request.otherParty}
            </RequestListItem>
            <RequestListItem>
                {'Asset Amount Requested: '}
                {NumberFormat.addThousandSeparators(request.assetAmount.toString())}
            </RequestListItem>
            <RequestListItem>
                <span>{t('commonText.status') + ': '}</span>
                <span style={{ color: getRequestStatusColorWithId(request.requestState, request.txId) }}>
                    {getRequestStatusText(request.requestState, request.txId)}
                </span>
            </RequestListItem>
        </RequestListContainer>
    );
};

export default TransferRequestComponent;
