import { Divider, Paper, SvgIcon, Typography } from '@material-ui/core';

import { Asset, ComplianceRuleKey, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import React from 'react';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type EnterRequestAmountsStepProps = {
    selectedAsset: Asset;
    setAssetAmount: (val: string) => void;
    setBondsAmount: (val: string) => void;
    invalidAssetAmountInput: boolean;
    invalidBondsAmountInput: boolean;
    bondValue: number;
    bondsAmount: string;
};

const EnterRequestAmountsStep: React.FC<EnterRequestAmountsStepProps> = (props) => {
    const {
        selectedAsset,
        setAssetAmount,
        setBondsAmount,
        invalidAssetAmountInput,
        invalidBondsAmountInput,
        bondValue,
        bondsAmount,
    } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('requestDvp.enterRequestAmounts')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.text}`}>{'Asset amount to request'}</Typography>
                {selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) && (
                    <>
                        <Typography className={`${layoutClasses.text}`}>
                            Maximum Transaction value with this asset{' '}
                            {NumberFormat.addThousandSeparators(
                                selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)
                                    ?.value ?? ''
                            )}
                        </Typography>
                    </>
                )}
                <Typography style={{ fontSize: 18 }} className={`${layoutClasses.text}`}>
                    {t('issueAsset.decimal') + ': ' + selectedAsset.tokenDecimals}
                </Typography>

                <CurrencyInput
                    decimalPlaces={selectedAsset.tokenDecimals}
                    onChange={setAssetAmount}
                    label={invalidAssetAmountInput ? t('commonText.invalidAmount') : 'Asset Amount'}
                    testId="amount-to-request"
                    invalidInput={invalidAssetAmountInput}
                />

                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.text}`}>{t('requestDvp.bondsToOffer')}</Typography>

                <div style={{ display: 'flex' }}>
                    <Typography style={{ fontSize: 18 }} className={`${layoutClasses.text}`}>
                        {t('requestDvp.bondsLeft')}
                    </Typography>

                    <SvgIcon color="secondary" style={{ marginTop: 12 }}>
                        <BondsIcon />
                    </SvgIcon>

                    <Typography style={{ fontSize: 18 }} className={`${layoutClasses.text}`}>
                        {': ' +
                            NumberFormat.addThousandSeparators(
                                (bondValue - (bondsAmount.length === 0 ? 0 : parseFloat(bondsAmount))).toFixed(3)
                            )}
                    </Typography>
                </div>

                <CurrencyInput
                    decimalPlaces={3}
                    onChange={setBondsAmount}
                    label={invalidBondsAmountInput ? t('commonText.invalidAmount') : t('commonText.bondsAmount')}
                    testId="bonds-to-offer"
                    invalidInput={invalidBondsAmountInput}
                />
            </Paper>
        </div>
    );
};

export default EnterRequestAmountsStep;
