import { Divider, Fade, Paper } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';

import { CBDCIssuanceRequest } from '@r3/cbdc-asset-frontend-core';
import { CBDCRequestDialogue } from './CBDCRequestDialogue';
import CBDCRequestsList from './CBDCRequestsList';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { ResolvedPromise } from 'api/resolvePromise';
import { requestAllIssuanceRequests } from 'api/issuanceApi';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const CBDCRequests = () => {
    const listClasses = useListStyles();
    const layoutClasses = useLayoutStyles();
    const [sortedIssuanceRequests, setSortedIssuanceRequests] = useState<Map<string, CBDCIssuanceRequest[]>>();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>('');
    const [selectedIssuanceRequest, setSelectedIssuanceRequest] = useState<CBDCIssuanceRequest | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const loadIssuanceRequests = useCallback(async () => {
        const issuanceRequestsResponse: ResolvedPromise = await requestAllIssuanceRequests();
        if (issuanceRequestsResponse.error) {
            enqueueSnackbar(t('error.gettingIssuanceRequests', { error: issuanceRequestsResponse.error }), {
                variant: 'error',
            });
            setError(
                issuanceRequestsResponse.error.length > 0 ? issuanceRequestsResponse.error : t('error.networkError')
            );
        } else {
            if (issuanceRequestsResponse.data.length <= 0) {
                const warningText = t('cbdcRequests.noRequests');
                enqueueSnackbar(warningText, {
                    variant: 'info',
                });
                setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
            }

            let requestsByIssuer = (issuanceRequestsResponse.data as CBDCIssuanceRequest[]).groupBy('issuer') as Map<
                string,
                CBDCIssuanceRequest[]
            >;
            setSortedIssuanceRequests(requestsByIssuer);
        }
    }, [t, enqueueSnackbar]);

    useEffect(() => {
        loadIssuanceRequests();
    }, [loadIssuanceRequests]);

    const openInfoModal = (issuanceRequest: CBDCIssuanceRequest) => {
        setSelectedIssuanceRequest(issuanceRequest);
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedIssuanceRequest(null);
        setModalOpen(false);
    };

    return (
        <Fade in={true}>
            <div className={`${layoutClasses.componentWrapper} `}>
                <Paper elevation={5} className={`${layoutClasses.column} `}>
                    {error.length > 0 ? (
                        <ListHeader
                            text={error}
                            refreshAction={() => {
                                setError('');
                                loadIssuanceRequests();
                            }}
                        />
                    ) : (
                        <>
                            <ListHeader
                                text={t('cbdcRequests.issuanceRequests')}
                                refreshAction={loadIssuanceRequests}
                            />
                            <CBDCRequestsList
                                sortedIssuanceRequests={sortedIssuanceRequests}
                                openInfoModal={openInfoModal}
                            />
                            <Divider className={listClasses.divider} />
                            {selectedIssuanceRequest && modalOpen && (
                                <CBDCRequestDialogue
                                    open={modalOpen}
                                    request={selectedIssuanceRequest}
                                    onClose={handleClose}
                                />
                            )}
                        </>
                    )}
                </Paper>
            </div>
        </Fade>
    );
};
