import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocalStorage, useSessionStorage } from '@r3/cbdc-asset-frontend-core';

import { AppConfig } from '@r3/cbdc-asset-frontend-core';
import { RETAIL } from '../../constants/Routes';
import { defaultTheme } from '../../constants/Theme';
import { isValidAppConfig } from '../../components/themeConfigTool/isAppConfigValid';

type AppConfigContextProps =
    | {
          appConfig: AppConfig;
          setTempSessionConfig: (appConfig: AppConfig) => void;
          clearTempSessionConfig: () => void;
          tempBrandIconSource: string;
          setTempBrandIconSource: (src: string) => void;
          tempAppIconSource: string;
          setTempAppIconSource: (src: string) => void;
          isRetailClient: boolean;
      }
    | undefined;
export const AppConfigContext = createContext<AppConfigContextProps>(undefined);

export const AppConfigContextProvider = (props: { children: ReactNode }) => {
    //Used for a temporary config
    const [tempConfigSessionStorage, setTempConfigSessionStorage] = useSessionStorage<string>('appConfig', '');
    const [tempAppIconSource, setTempAppIconSource] = useSessionStorage<string>('tempAppIconBlob', '');
    const [tempBrandIconSource, setTempBrandIconSource] = useSessionStorage<string>('tempBrandIconBlob', '');
    const [isRetailClient, setRetailClient] = useState<boolean>(false);
    const [appConfigLocalStorage, setAppConfigLocalStorage] = useLocalStorage<string>(
        'appConfig',
        JSON.stringify(defaultTheme)
    );
    const [appConfig, setAppConfig] = useState<AppConfig>(
        JSON.parse(tempConfigSessionStorage.length > 0 ? tempConfigSessionStorage : appConfigLocalStorage) as AppConfig
    );

    useEffect(() => {
        if (tempConfigSessionStorage.length > 0) {
            setAppConfig(JSON.parse(tempConfigSessionStorage) as AppConfig);
        }
    }, [tempConfigSessionStorage]);

    useEffect(() => {
        if (tempConfigSessionStorage.length > 0) {
            return;
        }
        setAppConfig(JSON.parse(appConfigLocalStorage) as AppConfig);
        // eslint-disable-next-line
    }, [appConfigLocalStorage]);

    useEffect(() => {
        document.title = appConfig.title;
        if (!isValidAppConfig(appConfig)) {
            //Apply Default theme
        }
    }, [appConfig]);

    useEffect(() => {
        setRetailClient(window.location.pathname.includes(RETAIL));
    }, []);

    useEffect(() => {
        if (tempConfigSessionStorage.length > 0) {
            return;
        }
        const getAppConfig = async () => {
            const retailClient = window.location.pathname.includes(RETAIL);
            const response = await fetch(retailClient ? '/appConfig/themeRetail.json' : '/appConfig/theme.json');
            const appConfig: AppConfig = await response.json();
            if (JSON.stringify(appConfig) !== appConfigLocalStorage) {
                setAppConfigLocalStorage(JSON.stringify(appConfig));
            }
        };
        getAppConfig();
    }, [appConfigLocalStorage, setAppConfigLocalStorage, tempConfigSessionStorage]);

    const setTempSessionConfig = (tempAppConfig: AppConfig) => {
        if (isValidAppConfig(tempAppConfig)) {
            setTempConfigSessionStorage(JSON.stringify(tempAppConfig));
        }
    };

    const clearTempSessionConfig = () => {
        setTempConfigSessionStorage('');
        setTempBrandIconSource('');
        setTempAppIconSource('');
        window.location.reload();
    };

    return (
        <AppConfigContext.Provider
            value={{
                appConfig,
                setTempSessionConfig,
                clearTempSessionConfig,
                tempBrandIconSource,
                setTempBrandIconSource,
                tempAppIconSource,
                setTempAppIconSource,
                isRetailClient,
            }}
        >
            {props.children}
        </AppConfigContext.Provider>
    );
};

const useAppConfigContext = () => useContext(AppConfigContext);
export default useAppConfigContext;
