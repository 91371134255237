import { Paper } from '@material-ui/core';
import React from 'react';
import { RequestTransfer } from './RequestTransfer/RequestTransfer';
import TransferAssetsWithHook from './TransferAssets/TransferAssetsWithHook';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

export const TransferCBDC = () => {
    const classes = useLayoutStyles();

    return (
        <div className={classes.componentWrapper} style={{ gap: 0, justifyContent: 'space-evenly' }}>
            <div className={`${classes.columnMid} ${classes.columnNoPadding}`}>
                <div style={{ width: '100%', display: 'flex' }}>
                    <Paper className={classes.sectionBadge}>Push Transfer</Paper>
                </div>
                <TransferAssetsWithHook />
            </div>
            <div className={` ${classes.columnNoPadding} ${classes.columnMid}`}>
                <div style={{ width: '100%', display: 'flex' }}>
                    <Paper className={classes.sectionBadge}>Pull Transfer (Request CBDC)</Paper>
                </div>
                <RequestTransfer />
            </div>
        </div>
    );
};
