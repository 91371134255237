import { DialogWindow, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import DialogState from './DialogState';
import { Order } from 'models/orders/Order';
import OrderDetailsContent from './OrderDetailsContent';
import PartiallyFillOrderContent from './PartiallyFillOrderContent';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useTranslation } from 'react-i18next';

type Props = {
    order: Order;
    open: boolean;
    onClose: () => void;
    dispatchRefresh: () => void;
};

const OrderDetailsDialog: FC<Props> = ({ order, open, onClose, dispatchRefresh }) => {
    const { t: texts } = useTranslation();
    const [dialogState, setDialogState] = useState(DialogState.CLOSED);
    const { assetSummary: assetSummaryResult, getSummedAssetById } = useGetAssetSummary(true);
    const [assetSummary, setAssetSummary] = useState<SummedAsset | undefined>(undefined);

    useEffect(() => {
        setAssetSummary(getSummedAssetById(order.requestedTokenDefinition.tokenIdentifier));
    }, [assetSummaryResult, getSummedAssetById, order.requestedTokenDefinition.tokenIdentifier]);

    const getCurrentContent = useCallback(
        (order, dialogState) => {
            switch (dialogState) {
                case DialogState.SHOW_ORDER_DETAILS:
                    return (
                        <OrderDetailsContent
                            order={order}
                            onClose={onClose}
                            dispatchDialogState={setDialogState}
                            dispatchRefresh={dispatchRefresh}
                            assetSummary={assetSummary}
                        />
                    );
                case DialogState.FILL_PARTIAL_ORDER:
                    return (
                        <PartiallyFillOrderContent
                            order={order}
                            onClose={onClose}
                            dispatchDialogState={setDialogState}
                            dispatchRefresh={dispatchRefresh}
                            assetSummary={assetSummary}
                        />
                    );
                default:
                    return <div />;
            }
        },
        [assetSummary, dispatchRefresh, onClose]
    );
    const dialogTitle = useMemo(() => {
        switch (dialogState) {
            case DialogState.SHOW_ORDER_DETAILS:
                return texts('browseExchange.orderDetailsTitle');
            case DialogState.FILL_PARTIAL_ORDER:
                return texts('browseExchange.partiallyFilleOrderTitle');
            default:
                return '';
        }
    }, [dialogState, texts]);

    useEffect(() => {
        if (open === true && dialogState === DialogState.CLOSED) {
            setDialogState(DialogState.SHOW_ORDER_DETAILS);
        }
        if (open === false && dialogState !== DialogState.CLOSED) {
            setDialogState(DialogState.CLOSED);
        }
    }, [open, dialogState]);

    return (
        <DialogWindow onClose={onClose} open={open} title={dialogTitle}>
            {getCurrentContent(order, dialogState)}
        </DialogWindow>
    );
};

export default OrderDetailsDialog;
