import React, { useContext, useEffect, useState } from 'react';
import { axiosInstance, axiosInstanceRetail } from '../../api/apiConfig';
import { getCustomerDetails, loginRetailCustomer } from '../../api/retailApi';

import { CustomerWithDetails } from '../../hooks/GetCustomerDetails';

const USERKEY = 'retailuser';

type User = {
    username: string;
    password: string;
};

type UserContextProps =
    | {
          user: User | null;
          customerDetails: CustomerWithDetails | null;
          refreshCustomer: (dontTrack?: boolean) => Promise<void>;
          loginUser: (username: string, password: string) => Promise<boolean>;
          logoutUser: () => void;
          validAmountInAccount: (tokenId: string, amount: number) => boolean;
      }
    | undefined;

export const UserContext = React.createContext<UserContextProps>(undefined);

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const [customerDetails, setCustomerDetails] = useState<CustomerWithDetails | null>(null);

    //On page load try to fetch from session storage the deployedUser
    useEffect(() => {
        const usrData = sessionStorage.getItem(USERKEY);
        if (!usrData) {
            setUser(null);
            return;
        }
        const existingUser = JSON.parse(usrData);
        setUser(existingUser);
        const encodedHeader = btoa(`${existingUser.username}:${existingUser.password}`);
        axiosInstanceRetail.defaults.headers.common = { Authorization: `Basic ${encodedHeader}` };
    }, []);

    const refreshCustomer = async (dontTrack?: boolean) => {
        const response = await getCustomerDetails(dontTrack);
        if (response.error) {
        } else {
            setCustomerDetails(response.data as CustomerWithDetails);
        }
    };

    const validAmountInAccount = (tokenId: string, amount: number): boolean => {
        if (!customerDetails) {
            return false;
        }
        const asset = customerDetails.accountBalances.find(
            (balance) => balance.tokenDefinition.tokenIdentifier === tokenId
        );
        if (!asset) {
            return false;
        }
        if (asset.balance < amount) {
            return false;
        }
        return true;
    };

    const loginUser = async (username: string, password: string): Promise<boolean> => {
        const encodedHeader = btoa(`${username}:${password}`);
        axiosInstanceRetail.defaults.headers.common = { Authorization: `Basic ${encodedHeader}` };
        const response = await loginRetailCustomer();
        if (response.error) {
            setUser(null);
            axiosInstance.defaults.headers.common = {};
            return false;
        }
        setUser({ username: username, password: password });
        return true;
    };

    useEffect(() => {
        if (!user) {
            logoutUser();
        } else {
            sessionStorage.setItem(USERKEY, JSON.stringify(user));

            refreshCustomer();
        }
    }, [user]);

    const logoutUser = () => {
        if (sessionStorage.getItem(USERKEY)) {
            sessionStorage.removeItem(USERKEY);
        }
    };

    return (
        <UserContext.Provider
            value={{ user, customerDetails, loginUser, logoutUser, refreshCustomer, validAmountInAccount }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUserContextProvider = () => useContext(UserContext);
