import { NumberFormat, TimeUnit, TxLogData } from '@r3/cbdc-asset-frontend-core';

import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import CallReceivedRoundedIcon from '@material-ui/icons/CallReceivedRounded';
import { Paper } from '@material-ui/core';
import React from 'react';
import useComponentVisible from '../../../hooks/ComponentVisible';
import { useRetailItemStyles } from 'materialStyles/retailStyles/retailItemStyles';
import useRetailPayeesContext from 'contexts/RetailPayeesContext/RetailPayeesContext';

type TransactionLogItemProps = {
    txLogData: TxLogData;
    accountId: string;
};

const TransactionLogItem: React.FC<TransactionLogItemProps> = ({ txLogData, accountId }) => {
    const classes = useRetailItemStyles();
    const {
        ref,
        isComponentVisible: isComponentOpen,
        setIsComponentVisible: setIsComponentOpen,
    } = useComponentVisible(false);
    const { getPayeeNameByAccountId } = useRetailPayeesContext();
    const incomingTx: boolean = accountId === txLogData.holderAccountId;
    const payeeId: string | undefined | null = incomingTx ? txLogData.counterPartyAccountId : txLogData.holderAccountId;
    const payeeName = getPayeeNameByAccountId(payeeId ?? '');

    return (
        <div>
            <Paper
                ref={ref}
                className={`${classes.request} ${!isComponentOpen ? classes.hoverableRequest : ''} ${
                    new Date(txLogData.txTime).isInTimeRangeFromNow(4, TimeUnit.Seconds) ? classes.pulsingUpdated : ''
                }`}
                onClick={() => {
                    if (!isComponentOpen) {
                        setIsComponentOpen(true);
                    }
                }}
                elevation={5}
                data-testid={incomingTx ? 'incoming_transaction' : 'outgoing_transaction'}
            >
                {!incomingTx && <CallMadeRoundedIcon className={`${classes.itemIcon} ${classes.completeIcon}`} />}

                {incomingTx && <CallReceivedRoundedIcon className={`${classes.itemIcon} ${classes.completeIcon}`} />}

                <div className={classes.itemInfo}>
                    <div className={classes.payeeDesc}>
                        <b>Amount: </b>
                        {NumberFormat.addThousandSeparators(
                            (txLogData.amount as number).toFixed(txLogData.tokenDefinition.decimals)
                        )}
                    </div>
                    <div className={classes.requestInfoWrapper}>
                        {
                            <div>
                                <b>{`${payeeName ? 'Payee Name: ' : 'Payee Account Id: '}`}</b>
                                {payeeName ?? payeeId}
                            </div>
                        }
                        <div>
                            <b>Asset Name: </b>
                            {txLogData.tokenDefinition.tokenName}
                        </div>
                        <div>
                            <b>Transaction Date: </b>
                            {new Date(txLogData.txTime).toLocaleString()}
                        </div>

                        {isComponentOpen && (
                            <>
                                <div style={{ wordBreak: 'break-all' }}>
                                    <b>Transaction ID: </b>
                                    {txLogData.txId}
                                </div>
                                <div>
                                    <b>Transaction Type: </b>
                                    {txLogData.command}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Paper>
        </div>
    );
};

export default React.memo(TransactionLogItem);
