import { CBDCIssuanceRequest, TransactionRequestState } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import CBDCRequest from './CBCBRequest';
import RequestList from 'components/commonComponents/RequestListComponents/RequestList';
import RequestListDivider from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDivider';
import RequestListDropdownSection from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownSection';
import RequestListDropdownTitle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownTitle';
import RequestListDropdownToggle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownToggle';
import useFilterRequests from 'hooks/FilterRequests';
import { useTranslation } from 'react-i18next';

type CBDCRequestsListProps = {
    sortedIssuanceRequests?: Map<string, CBDCIssuanceRequest[]>;
    openInfoModal: (request: CBDCIssuanceRequest) => void;
};

const CBDCRequestsList: React.FC<CBDCRequestsListProps> = ({ sortedIssuanceRequests, openInfoModal }) => {
    const { filterSwitches, displayRequestType } = useFilterRequests();
    const { t } = useTranslation();
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);

    const handlePublicKeyClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };

    useEffect(() => {
        const reqKeys = Array.from(sortedIssuanceRequests?.keys() ?? []);
        if (reqKeys.length === 1) {
            setCollapesedKeys([]);
        } else {
            setCollapesedKeys(reqKeys);
        }
    }, [sortedIssuanceRequests]);

    return (
        <>
            {filterSwitches}
            <RequestListDivider />
            <RequestList>
                {sortedIssuanceRequests !== undefined &&
                    sortedIssuanceRequests.mapGroups((issuer, items) => {
                        const publickKeyElement: JSX.Element = (
                            <RequestListDropdownSection>
                                <RequestListDropdownTitle>
                                    <b>{t('commonText.issuer') + ':'} </b>
                                    {issuer}
                                </RequestListDropdownTitle>
                                <RequestListDropdownToggle
                                    toggleCondition={collapsedKeys.includes(issuer)}
                                    onClick={() => handlePublicKeyClick(issuer)}
                                />
                            </RequestListDropdownSection>
                        );
                        if (collapsedKeys.includes(issuer)) {
                            return <div key={issuer}>{publickKeyElement} </div>;
                        }
                        return (
                            <div key={issuer}>
                                {publickKeyElement}
                                {(items as CBDCIssuanceRequest[])
                                    .filter((req) => displayRequestType(req.requestState as TransactionRequestState))
                                    .map((request) => {
                                        return (
                                            <CBDCRequest
                                                key={issuer + '_' + request.requestId}
                                                request={request}
                                                onClick={openInfoModal.bind(null, request)}
                                            />
                                        );
                                    })}
                            </div>
                        );
                    })}
            </RequestList>
        </>
    );
};

export default CBDCRequestsList;
