import { AssetResponse, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { useCallback, useState } from 'react';

import AssetTransforms from 'utils/AssetTransforms';
import { requestAssetSummary } from 'api/assetsApi';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useGetAssetSummary(fetchByDefault?: boolean) {
    const [assetSummary, setAssetSummary] = useState<AssetResponse[]>([]);
    const [summaryError, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const getAssetSummary = useCallback(
        async (dontTrack?: boolean) => {
            setLoading(true);
            const response = await requestAssetSummary(dontTrack);
            if (response.error && !dontTrack) {
                let errormsg = response.error;
                setError(t('error.assetSummary', { error: errormsg }));
            } else {
                const ResponsivePieData: AssetResponse[] = response.data;
                if (ResponsivePieData === null) {
                    setLoading(false);
                    return;
                }
                if (ResponsivePieData && ResponsivePieData.length <= 0 && !dontTrack) {
                    const warningText = t('transferAssets.noAssetsInVault');
                    enqueueSnackbar(warningText, {
                        variant: 'info',
                    });
                    setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
                }
                ResponsivePieData.forEach((assetResponse) => {
                    assetResponse.totalAmount = parseFloat(
                        assetResponse.totalAmount.toFixed(assetResponse.tokenDecimals)
                    );
                });
                setAssetSummary(ResponsivePieData);
            }
            setLoading(false);
        },
        [enqueueSnackbar, t]
    );

    useEffect(() => {
        if (fetchByDefault) {
            getAssetSummary();
        }
    }, [fetchByDefault, getAssetSummary]);

    const getAssetSummaryById = useCallback(
        (id: string): AssetResponse | undefined => {
            return assetSummary.find((x) => x.tokenDefinition.tokenIdentifier === id);
        },
        [assetSummary]
    );

    const getSummedAssetById = useCallback(
        (id: string): SummedAsset | undefined => {
            const assetSummary = getAssetSummaryById(id);
            if (!assetSummary) {
                return;
            }
            return AssetTransforms.summedAssetFromSummary(assetSummary);
        },
        [getAssetSummaryById]
    );

    return { assetSummary, getAssetSummary, summaryError, loading, getSummedAssetById };
}
