import { DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';
import { NumberFormat, TxLogData } from '@r3/cbdc-asset-frontend-core';

import React from 'react';
import { Typography } from '@material-ui/core';

export interface SimpleDialogProps {
    open: boolean;
    row: TxLogData | null;
    onClose: () => void;
}

export function TransactionDialog(props: SimpleDialogProps) {
    const { onClose, open, row } = props;
    const handleClose = () => {
        onClose();
    };
    if (row === null) {
        return <> </>;
    }
    return (
        <DialogWindow title={'Corda Transaction Summary'} open={open} onClose={handleClose}>
            <DialogBody>
                <Typography gutterBottom>{'Transaction ID: ' + row.txId}</Typography>
                <Typography gutterBottom>{'Tx Type: ' + row.command}</Typography>
                <Typography gutterBottom>{'Notary: ' + row.notary}</Typography>
                <Typography gutterBottom>{'Num of Inputs: ' + row.numInputs}</Typography>
                {row.amount !== null && (
                    <Typography gutterBottom>
                        {'Amount: ' +
                            (row.amount !== null && row.amount !== 'N/A'
                                ? NumberFormat.addThousandSeparators(
                                      parseFloat(row.amount.toString()).toFixed(row.tokenDefinition.decimals)
                                  )
                                : 'N/A')}
                    </Typography>
                )}
                <Typography gutterBottom>{'Holder: ' + row.holder}</Typography>
                {row.issueDate !== null && <Typography gutterBottom>{'IssueDate: ' + row.issueDate}</Typography>}
                <Typography gutterBottom>{'Tx Date: ' + new Date(row.txTime).toLocaleString()}</Typography>
                {row.usageCount !== null && <Typography gutterBottom>{'Usage Count: ' + row.usageCount}</Typography>}
                <Typography gutterBottom>{'Consumed: ' + (row.consumed ? 'true' : 'false')}</Typography>
            </DialogBody>
        </DialogWindow>
    );
}
