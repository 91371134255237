import { CBDCToken } from '@r3/cbdc-asset-frontend-core';
import { requestCustomerDetails } from 'api/customerApi';
import { useCallback } from 'react';
import { useState } from 'react';

export const useGetCustomerDetails = () => {
    const [customer, setCustomer] = useState<CustomerWithDetails | null>(null);

    const getCustomer = useCallback(async (customerId: string) => {
        const response = await requestCustomerDetails(customerId);
        if (response.error) {
            //handle error here
        } else {
            setCustomer(response.data as CustomerWithDetails);
        }
    }, []);

    return { customer, getCustomer };
};

export type AccountBalance = {
    tokenDefinition: CBDCToken;
    balance: number;
};

export type CustomerWithDetails = {
    customerId: string;
    customerName: string;
    accountName: string;
    accountBalances: AccountBalance[];
    accountKey: string;
    accountId: string;
    previousKeys: any[];
    contactNumber: string;
    emailAddress: string;
    postcode: string;
    modifiedOn: Date;
    createdOn: Date;
};
