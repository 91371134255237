import {
    CBDCREQUESTSLIST,
    CROSSCHAINSWAPREQUESTS,
    DVPREQUESTS,
    KYCREQUESTSLIST,
    REDEEMREQUESTS,
    TRANSFERREQUESTS,
} from 'constants/Routes';
import { TabPageMap, TabsWrapper, insertIntoArrayIf, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import { CBDCRequests } from './CBDCRequests/CBDCRequests';
import CachedIcon from '@material-ui/icons/Cached';
import CrossChainSwapRequests from './CrossChainSwapRequests/CrossChainSwapRequests';
import { DvpRequests } from './DvpRequests/DvpRequests';
import { KycRequests } from './KycRequests/KycRequets';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import React from 'react';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { RedemptionRequests } from './RedemptionRequests/RedemptionRequests';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import { TransferRequests } from './TranferRequests/TransferRequests';
import { useTranslation } from 'react-i18next';

export const Requests: React.FC = () => {
    const { t } = useTranslation();
    const { isFeatureEnabled } = useFeatureFlagContext();

    const tapPageMap: TabPageMap[] = [
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('issuanceRequests'), {
            route: CBDCREQUESTSLIST,
            pageComponent: CBDCRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowUpwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <MonetizationOnOutlinedIcon />
                    </div>
                ),
                label: t('pageTitle.cbdcRequests'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('redemptionRequests'), {
            route: REDEEMREQUESTS,
            pageComponent: RedemptionRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowUpwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <CachedIcon />
                    </div>
                ),
                label: t('pageTitle.redemptionRequests'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('dvpRequests'), {
            route: DVPREQUESTS,
            pageComponent: DvpRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowUpwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <SwapHorizontalCircleOutlinedIcon />
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                    </div>
                ),
                label: t('pageTitle.dvpRequests'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('pullTransferRequests'), {
            route: TRANSFERREQUESTS,
            pageComponent: TransferRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowUpwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <LocalAtmIcon />
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                    </div>
                ),
                label: t('pageTitle.assetTransferRequests'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('crossChainRequests'), {
            route: CROSSCHAINSWAPREQUESTS,
            pageComponent: CrossChainSwapRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowUpwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <ShuffleIcon />
                        <ArrowDownwardOutlinedIcon style={{ width: 15, height: 15 }} />
                    </div>
                ),
                label: t('pageTitle.crossChainSwapRequests'),
            },
        }),
        ...insertIntoArrayIf<TabPageMap>(isFeatureEnabled('memberAccessRequests'), {
            route: KYCREQUESTSLIST,
            pageComponent: KycRequests,
            tabDetails: {
                icon: (
                    <div style={{ display: 'flex' }}>
                        <ArrowUpwardOutlinedIcon style={{ width: 15, height: 15 }} />
                        <RecentActorsIcon />
                    </div>
                ),
                label: t('pageTitle.kycRequests'),
            },
        }),
    ];

    return <TabsWrapper pageTitle={t('pageTitle.outgoingRequests')} tabPageMap={tapPageMap} />;
};
