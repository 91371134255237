import { Button, TextField } from '@material-ui/core';

import { PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import { PaymentSetterProps } from './types';
import { useFormStyles } from 'materialStyles/formStyles';
import { usePaymentRequestService } from '../../../hooks/PaymentRequestService';
import { useState } from 'react';

const PayWithCode: React.FC<PaymentSetterProps> = ({ setPaymentRequest }) => {
    const [codeValue, setCodeValue] = useState<string>('');
    const formClasses = useFormStyles();
    const { verifyPaymentRequest } = usePaymentRequestService();

    const handleFormInput = (event) => {
        event.preventDefault();
        const value = event.target.value;
        if (event.target.name === 'Code') {
            setCodeValue(value);
        }
    };

    const submit = () => {
        try {
            const requestString = atob(codeValue);
            const requestJson = JSON.parse(requestString) as PaymentRequestResponse;
            if (verifyPaymentRequest(requestJson)) {
                setPaymentRequest(requestJson);
            } else {
                alert('Failed to interpret payment code, please try again.');
                setCodeValue('');
                return;
            }
        } catch (error) {
            alert('Failed to interpret payment code, please try again.');
            setCodeValue('');
            return;
        }
    };

    return (
        <div>
            <TextField
                className={`${formClasses.columnItem} codeInput ${formClasses.formInput}`}
                label={'Code'}
                onChange={(event) => {
                    handleFormInput(event);
                }}
                name="Code"
                value={codeValue}
                required
                type="text"
                variant="filled"
                data-testid="code_input"
            />
            <div className={formClasses.formControl}>
                <Button
                    className={`${formClasses.formControlButton} submitButton  ${formClasses.button}`}
                    variant="outlined"
                    color="primary"
                    disabled={codeValue.length === 0}
                    onClick={submit}
                    data-testid="submit"
                >
                    {'Confirm Code'}
                </Button>
            </div>
        </div>
    );
};

export default PayWithCode;
