import { Theme, alpha, createStyles, makeStyles } from '@material-ui/core';

const useExchangeListStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            list: {
                padding: 10,
                width: 600,
                height: 600,
                overflow: 'auto',
            },
            header: {
                display: 'flex',
                justifyContent: 'center',
            },
            item: {
                height: 'auto',
                marginBottom: theme.spacing(4),
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                backgroundColor: alpha(theme.palette.primary.light, 0.8),
                color: theme.palette.primary.contrastText,
                borderRadius: theme.shape.borderRadius,
                boxShadow:
                    '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
            },
            clickable: {
                cursor: 'pointer',
            },
            selected: {
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                fontWeight: 600,
            },
            hoverItem: {
                '&:hover': {
                    background: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    fontWeight: 600,
                },
            },
            divider: {
                marginBottom: theme.spacing(4),
                marginTop: theme.spacing(4),
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.main,
                width: 500,
            },
            justifyContentSpaceBetween: {
                justifyContent: 'space-between',
            },
            label: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 0,
                paddingLeft: 0,
                fontSize: 18,
                color: theme.palette.secondary.contrastText,
                textTransform: 'uppercase',
                width: 'auto',
                height: '30px',
                borderRadius: theme.shape.borderRadius / 2,
            },
            largeLabelSize: {
                width: '45px',
                height: '45px',
            },
            alignSelf: {
                alignSelf: 'center',
            },
            refreshButton: { border: `1px solid ${theme.palette.secondary.main}` },
            refreshIcon: { width: 36, height: 36 },
            filterArrow: {
                width: 50,
                height: 50,
                margin: 'auto',
                color: alpha(theme.palette.primary.contrastText, 0.8),
            },
        }),

    { index: 1 }
);

export default useExchangeListStyles;
