import { Order } from 'models/orders/Order';
import { OrderStatus } from 'models/orders/OrderStatus';
import { OrderSummary } from 'models/orders/OrderSummary';
import { getCurrencyPairString } from 'components/decentralizedExchange/getCurrencyPairString';
import { getOrders as getDexOrders } from 'api/decentralizedExchange';
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type RelevancyStatus = 'RELEVANT' | 'ALL';

interface GetOffersArguments {
    identity?: string;
    digitalCurrencyPair?: OrderSummary;
    excludeCreator?: string;
    relevancyStatus?: RelevancyStatus;
}

const useGetExchangeOrders = (orderStatus?: OrderStatus, dontTrack?: boolean) => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    const getOrders = useCallback(
        async (args?: GetOffersArguments) => {
            setIsLoading(true);

            const response = await getDexOrders(
                orderStatus,
                args?.identity,
                args?.digitalCurrencyPair?.requestedTokenDefinition.tokenIdentifier,
                args?.digitalCurrencyPair?.offeredTokenDefinition.tokenIdentifier,
                args?.excludeCreator,
                args?.relevancyStatus,
                dontTrack
            );
            if (response.error != null) {
                const errorString =
                    args?.digitalCurrencyPair == null
                        ? t('error.gettingExchangeOffers', { error: response.error })
                        : t('error.gettingExchangeOffersForPair', {
                              currencyPair: getCurrencyPairString(args.digitalCurrencyPair),
                              error: response.error,
                          });
                setError(errorString);
            } else {
                const updatedOffers = response.data as Order[];
                setOrders(updatedOffers);
            }
            setIsLoading(false);
        },
        [dontTrack, orderStatus, t]
    );

    return { orders, isLoading, error, getOrders };
};

export default useGetExchangeOrders;
