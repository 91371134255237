import { forwardRef, useCallback, useImperativeHandle } from 'react';

import { Divider } from '@material-ui/core';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import LoadingIcon from 'components/commonComponents/Loading/LoadingIcon';
import { OrderStatus } from 'models/orders/OrderStatus';
import { OrderSummary } from 'models/orders/OrderSummary';
import { Paper } from '@material-ui/core';
import { Refreshable } from '../Types';
import classNames from 'classnames';
import { getCurrencyPairString } from '../getCurrencyPairString';
import { useEffect } from 'react';
import useExchangeListStyles from 'materialStyles/exchangeListStyles';
import useGetDigitalCurrencyPairs from 'hooks/DecentralizedExchange/useGetDigitalCurrencyPairs';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedPair: OrderSummary | null;
    onPairSelected: (pair: OrderSummary | null) => void;
}

const DigitalCurrencyPairList = forwardRef(({ selectedPair, onPairSelected }: Props, ref: React.Ref<Refreshable>) => {
    const { identity, getOwnIdentity } = useGetOurOwnIdentity();
    const { enqueueSnackbar } = useSnackbar();
    const listStyles = useExchangeListStyles();
    const layoutStyles = useLayoutStyles();
    const { t: labels } = useTranslation();
    const { pairs, isLoading, error, getPairs } = useGetDigitalCurrencyPairs(OrderStatus.OPEN);

    useImperativeHandle(ref, () => ({
        refresh() {
            refreshPairs();
        },
    }));

    const refreshPairs = useCallback(() => {
        if (identity.length !== 0) {
            getPairs({ excludeBuyer: identity });
        }
    }, [identity, getPairs]);

    useEffect(() => {
        getOwnIdentity();
    }, [getOwnIdentity]);

    useEffect(() => {
        refreshPairs();
    }, [refreshPairs]);

    useEffect(() => {
        if (error != null) {
            enqueueSnackbar(error, { variant: 'error' });
        }
    }, [error, enqueueSnackbar]);

    return (
        <div data-testid="currencyPairs" className={`${layoutStyles.componentWrapper}`}>
            <Paper elevation={5} className={`${layoutStyles.column}`}>
                <ListHeader text={labels('browseExchange.offerPairsTitle')} />
                <Divider className={listStyles.divider} />
                <div className={listStyles.list} data-testid="list">
                    {isLoading ? (
                        <LoadingIcon />
                    ) : (
                        pairs.map((pair) => {
                            const currencyPair = getCurrencyPairString(pair);
                            return (
                                <div
                                    key={currencyPair}
                                    className={classNames([
                                        listStyles.item,
                                        listStyles.justifyContentSpaceBetween,
                                        listStyles.clickable,
                                        { [listStyles.selected]: selectedPair === pair },
                                    ])}
                                    onClick={() => onPairSelected(pair)}
                                >
                                    <p>
                                        <b>
                                            {labels('liquiditySavings.cbdcPair') + ': '}
                                            {currencyPair}
                                        </b>
                                    </p>
                                    <p>{`${labels('liquiditySavings.offers')}: ${pair.numberOfOffers}`}</p>
                                </div>
                            );
                        })
                    )}
                </div>
            </Paper>
        </div>
    );
});

export default DigitalCurrencyPairList;
