import { MouseCoords } from 'models/MouseCoords';
import { Paper } from '@material-ui/core';
import { forwardRef } from 'react';

type Props = {
    isOpen: boolean;
    mouseCoords: MouseCoords;
    closeMenu?: () => void;
    children?: React.ReactNode;
};

const ContextMenu = forwardRef(({ mouseCoords, closeMenu, children }: Props, ref) => {
    return (
        <Paper
            onClick={closeMenu}
            ref={ref}
            elevation={20}
            style={{
                position: 'fixed',
                top: mouseCoords.y,
                left: mouseCoords.x,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: 'auto',
                height: 'auto',
                padding: 8,
                zIndex: 200,
            }}
        >
            {children}
        </Paper>
    );
});

export default ContextMenu;
