import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import { Divider } from '@material-ui/core';
import ExchangeOrder from './ExchangeOrder';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import LoadingIcon from 'components/commonComponents/Loading/LoadingIcon';
import { Order } from 'models/orders/Order';
import OrderDetailsDialog from 'components/decentralizedExchange/browseExchange/orderDialog/OrderDetailsDialog';
import { OrderStatus } from 'models/orders/OrderStatus';
import { OrderSummary } from 'models/orders/OrderSummary';
import { Paper } from '@material-ui/core';
import { Refreshable } from 'components/decentralizedExchange/Types';
import { getCurrencyPairString } from '../getCurrencyPairString';
import sortOrdersDescBy from '../sortOrdersBy';
import { useCallback } from 'react';
import { useEffect } from 'react';
import useExchangeListStyles from 'materialStyles/exchangeListStyles';
import useGetExchangeOrders from 'hooks/DecentralizedExchange/useGetExchangeOffers';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

interface Props {
    selectedPair: OrderSummary | null;
    dispatchRefresh: () => void;
}

const ExchangeOrderList = forwardRef(({ selectedPair, dispatchRefresh }: Props, ref: React.Ref<Refreshable>) => {
    const listStyles = useExchangeListStyles();
    const { identity, getOwnIdentity } = useGetOurOwnIdentity();
    const { enqueueSnackbar } = useSnackbar();
    const { orders, isLoading, error, getOrders } = useGetExchangeOrders(OrderStatus.OPEN, true);
    const layoutStyles = useLayoutStyles();
    const { t: texts } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

    useImperativeHandle(ref, () => ({
        refresh() {
            if (identity.length !== 0) {
                getFilteredOffers();
            }
        },
    }));

    const ordersTitlePostfix = useMemo(
        () =>
            selectedPair == null
                ? `: ${orders.length}`
                : ` for ${getCurrencyPairString(selectedPair)} | ${texts('liquiditySavings.offers')}: ${orders.length}`,
        [selectedPair, orders, texts]
    );

    const ordersTitlePrefix = useMemo(() => {
        if (selectedPair == null) {
            return texts('commonText.all') + ' ';
        } else {
            return '';
        }
    }, [selectedPair, texts]);

    const getFilteredOffers = useCallback(() => {
        getOrders({ digitalCurrencyPair: selectedPair ?? undefined, excludeCreator: identity });
    }, [getOrders, selectedPair, identity]);

    function handleDialogClose() {
        setSelectedOrder(null);
        setIsDialogOpen(false);
    }

    function handleOfferSelected(order: Order) {
        setSelectedOrder(order);
        setIsDialogOpen(true);
    }

    useEffect(() => {
        getOwnIdentity();
    }, [getOwnIdentity]);

    useEffect(() => {
        if (identity.length !== 0) {
            getFilteredOffers();
        }
    }, [identity, getFilteredOffers]);

    useEffect(() => {
        if (error != null) {
            enqueueSnackbar(error, { variant: 'error' });
        }
    }, [enqueueSnackbar, error]);

    return (
        <>
            {selectedOrder != null && (
                <OrderDetailsDialog
                    order={selectedOrder}
                    open={isDialogOpen}
                    onClose={handleDialogClose}
                    dispatchRefresh={dispatchRefresh}
                />
            )}
            <div data-testid="exchangeOffers" className={`${layoutStyles.componentWrapper}`}>
                <Paper elevation={5} className={`${layoutStyles.column}`}>
                    <ListHeader
                        text={`${ordersTitlePrefix}${texts('browseExchange.offersTitle')}${ordersTitlePostfix}`}
                    />
                    <Divider className={listStyles.divider} />
                    <div className={`${listStyles.list} ${listStyles.clickable}`} data-testid="list">
                        {isLoading ? (
                            <LoadingIcon />
                        ) : (
                            sortOrdersDescBy(orders, 'lastUpdated').map((order) => (
                                <ExchangeOrder
                                    key={order.orderId}
                                    order={order}
                                    onClick={handleOfferSelected.bind(null, order)}
                                />
                            ))
                        )}
                    </div>
                </Paper>
            </div>
        </>
    );
});

export default ExchangeOrderList;
