import { Divider, Paper } from '@material-ui/core';

import React from 'react';
import { NumberFormat, useInfoBoxStyles } from '@r3/cbdc-asset-frontend-core';

type InfoBoxProps = {
    title: string;
    content: string;
    size?: 'REGULAR' | 'SMALL';
};

export const InfoBox: React.FC<InfoBoxProps> = ({ title, content, size = 'REGULAR' }) => {
    const classes = useInfoBoxStyles();
    let style = size === 'SMALL' ? { padding: 4, maxWidth: 230 } : {};
    let titleStyle = size === 'SMALL' ? { fontSize: 14 } : {};
    return (
        <>
            <Paper id={title.replace(/\s/g, '')} elevation={5} className={`${classes.infoBox}`} style={style}>
                <div className={`${classes.title} infoBoxTitle`} style={titleStyle}>
                    {title}
                </div>
                <div className={`${classes.content} infoBoxContent`}>{NumberFormat.addThousandSeparators(content)}</div>
                <Divider className={classes.divider} />
            </Paper>
        </>
    );
};
