export const LOGIN = '/login';

export const DASHBOARD = '/dashboard';
export const DEMODASH = '/demoDashboard';
export const NETWORKTAB = '/networkTab';

export const RETAIL = '/retail';
export const MANAGERETAIL = '/manageRetail';
export const NETWORKTABRETAIL = RETAIL + NETWORKTAB;

export const RETAILREGISTER = MANAGERETAIL + '/register';
export const RETAILCUSTOMERS = MANAGERETAIL + '/customers';

export const ASSETDASH = DEMODASH + '/cbdcDashboard';
export const BONDSDASH = DEMODASH + '/bonds';
export const BALANCEOVERTIME = DEMODASH + '/balanceOverTime';

export const ASSETS = '/assets';
export const AVAILABLECBDC = ASSETS + '/availableAssets';
export const REQUESTCBDC = ASSETS + '/requestCBDC';
export const REQUESTCROSSCHAINSWAP = ASSETS + '/requestCrosschainSwap';

export const TRANSFERCBDC = ASSETS + '/transferCBDC';
export const REDEEMCBDC = ASSETS + '/redeemCBDC';

export const DVPCONFIG = ASSETS + '/dvpConfig';
export const REQUESTDVP = ASSETS + '/requestDvp';

export const KYCCONTROL = '/kycControl';
export const LISTKYC = KYCCONTROL + '/listKYC';
export const REQUESTKYCSTATE = KYCCONTROL + '/requestKYCState';

export const OUTGOINGREQUESTS = '/requests';
export const DVPREQUESTS = OUTGOINGREQUESTS + '/dvpRequests';
export const KYCREQUESTSLIST = OUTGOINGREQUESTS + '/kycRequests';
export const REDEEMREQUESTS = OUTGOINGREQUESTS + '/redeemRequestsList';
export const CBDCREQUESTSLIST = OUTGOINGREQUESTS + '/cbdcRequestsList';
export const TRANSFERREQUESTS = OUTGOINGREQUESTS + '/transferRequests';
export const CROSSCHAINSWAPREQUESTS = OUTGOINGREQUESTS + '/crossChainSwapRequests';

export const TRANSACTIONDASH = '/transactionDashboard';

export const DECENTRALIZEDEXCHANGE = '/decentralizedLiquidityExchange';

export const MAKEOFFER = DECENTRALIZEDEXCHANGE + '/makeOffer';
export const BROWSEEXCHANGE = DECENTRALIZEDEXCHANGE + '/browse';
export const MANAGEOFFERS = DECENTRALIZEDEXCHANGE + '/manageOffers';
export const OFFERLOG = DECENTRALIZEDEXCHANGE + '/offerLog';
