import { useListStyles } from '@r3/cbdc-asset-frontend-core';

const RequestListDropdownSection: React.FC = ({ children }) => {
    const listClasses = useListStyles();
    return (
        <div className={`${listClasses.sortKey} holderGrouping`} data-testid={'holder_filter'}>
            {children}
        </div>
    );
};

export default RequestListDropdownSection;
