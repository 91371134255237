import { ComplianceRuleKey, NumberFormat, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { Divider, Paper, Typography } from '@material-ui/core';

import AssetSummary from 'components/commonComponents/SummaryComponents/AssetSummary';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import React from 'react';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type SelectEnterAmountStepProps = {
    selectedAsset: SummedAsset;
    assetAmount: string;
    setAssetAmount: (val: string) => void;
    invalidAssetAmountInput: () => boolean;
};

const EnterAmountStep: React.FC<SelectEnterAmountStepProps> = (props) => {
    const { selectedAsset, setAssetAmount, assetAmount, invalidAssetAmountInput } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('transferAssets.enterAmount')}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.text}`}>
                    {t('issueAsset.decimal') + ': '} {selectedAsset.tokenDecimal}
                </Typography>

                <AssetSummary asset={selectedAsset} toSpend={assetAmount.length === 0 ? 0 : parseFloat(assetAmount)} />

                {selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) && (
                    <>
                        <Divider className={layoutClasses.summaryDividerSmall} />
                        <Typography className={`${layoutClasses.text}`}>Compliance Controls </Typography>
                        <Divider className={layoutClasses.summaryDividerSmall} />
                        <Typography className={`${layoutClasses.text}`}>
                            Maximum Transaction value with this asset{' '}
                            {NumberFormat.addThousandSeparators(
                                selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)
                                    ?.value ?? ''
                            )}
                        </Typography>
                    </>
                )}

                <CurrencyInput
                    decimalPlaces={selectedAsset.tokenDecimal}
                    onChange={setAssetAmount}
                    label={invalidAssetAmountInput() ? t('commonText.invalidAmount') : t('commonText.assetInput')}
                    testId="amount-to-issue"
                    invalidInput={invalidAssetAmountInput()}
                />
            </Paper>
        </div>
    );
};

export default EnterAmountStep;
