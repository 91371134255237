import { Divider, Paper, SvgIcon, Typography } from '@material-ui/core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import SummaryRow from 'components/commonComponents/SummaryComponents/SummaryRow';
import SummaryTable from 'components/commonComponents/SummaryComponents/SummaryTable';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type RequestSummaryStepProps = {
    assetName: string;
    partyName: string;
    amount: string;
    bondValue: number;
};

const RequestSummaryStep: React.FC<RequestSummaryStepProps> = (props) => {
    const { assetName, partyName, amount, bondValue } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('requestCBDC.summaryMessage')}</Typography>

                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.text}`}>{t('commonText.issuer') + ': ' + partyName}</Typography>

                <SummaryTable>
                    <SummaryRow
                        col1Text={t('requestCBDC.toBeRequested', {
                            asset: assetName,
                        })}
                        col2Text={NumberFormat.addThousandSeparators(parseFloat(amount).toFixed(3))}
                    />
                    <SummaryRow
                        col1Text={t('requestCBDC.spent')}
                        col2Text={NumberFormat.addThousandSeparators(parseFloat(amount).toFixed(3))}
                        icon={
                            <SvgIcon color="secondary">
                                <BondsIcon />
                            </SvgIcon>
                        }
                    />
                    <SummaryRow
                        col1Text={t('requestCBDC.bondsLeft')}
                        col2Text={NumberFormat.addThousandSeparators((bondValue - parseFloat(amount)).toFixed(3))}
                        icon={
                            <SvgIcon color="secondary">
                                <BondsIcon />
                            </SvgIcon>
                        }
                    />
                </SummaryTable>
            </Paper>
        </div>
    );
};

export default RequestSummaryStep;
