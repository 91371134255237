import { OrderStatus } from 'models/orders/OrderStatus';
import { RelevancyStatus } from 'hooks/DecentralizedExchange/useGetExchangeOffers';
import { ResolvedPromise } from './resolvePromise';
import apiCall from './apiCall';

const BASE_URL = '/api/order';
const URLS = {
    createOrder: `${BASE_URL}/create`,
    getOrders: `${BASE_URL}`,
    updateOrder: (orderId: string) => `${BASE_URL}/${orderId}/update`,
    cancelOpenOrder: (orderId: string) => `${BASE_URL}/${orderId}/cancel`,
    fillOrder: (orderId: string) => `${BASE_URL}/${orderId}/fill`,
    getOrderSummaries: `${BASE_URL}/summaries`,
};

type UpdateOrderParameters = {
    requestedAssetAmount?: number;
    offeredAssetAmount?: number;
    observers?: string[];
};

export const createOrder = async (
    requestedTokenIdentifier: string,
    requestedAssetAmount: number,
    offeredTokenIdentifier: string,
    offeredAssetAmount: number,
    observers: string[]
) =>
    apiCall({
        method: 'post',
        path: URLS.createOrder,
        params: {
            requestedTokenIdentifier: requestedTokenIdentifier,
            requestedAssetAmount: requestedAssetAmount,
            offeredTokenIdentifier: offeredTokenIdentifier,
            offeredAssetAmount: offeredAssetAmount,
            observers: observers,
        },
    });

/**
 * @remarks
 * Gets DEX orders
 *
 * @param status the status of the requested orders to filter by
 * @param creator the creator of the requested orders to filter by
 * @param requestedTokenIdentifier id for requested asset side of the asset pair
 * @param offeredTokenIdentifier  id for the offered asset side of the asset pair
 * @param excludeCreator name of creator to explude
 * @param dontTrack wether to track or not track the promise (global loading spinner)
 * @returns Transformed @ResolvedPromise of request
 */
export const getOrders = async (
    status?: OrderStatus,
    creator?: string,
    requestedTokenIdentifier?: string,
    offeredTokenIdentifier?: string,
    excludeCreator?: string,
    relevancyStatus?: RelevancyStatus,
    dontTrack?: boolean
): Promise<ResolvedPromise> => {
    const params = {
        orderStatus: status,
        creator: creator,
        excludeCreator,
        requestedTokenIdentifier,
        offeredTokenIdentifier,
        relevancyStatus,
    };
    return apiCall({
        method: 'get',
        path: URLS.getOrders,
        params: params,
        dontTrackRequest: dontTrack,
    });
};

export const updateOrder = async (orderId: string, parameters: UpdateOrderParameters): Promise<ResolvedPromise> =>
    apiCall({
        method: 'put',
        path: URLS.updateOrder(orderId),
        params: parameters,
    });

/**
 * @remark
 * Cancels a pending exchange offer from being listed on the DEX
 *
 * @param orderId the id of the PvpRequest / offer
 * @returns Transformed @ResolvedPromise of request
 */
export const cancelPendingOrder = async (orderId: string) =>
    apiCall({
        method: 'put',
        path: URLS.cancelOpenOrder(orderId),
    });

/**
 * @remarks fills an order with the specified quantity
 *
 * @param orderId the Id of the order
 * @param creator party filling the order (self), as noted by BE devs this may be deprecated in the future
 * @param requestedFillAmount the amount to fill this order with
 * @returns Transformed @ResolvedPromise of request
 */
export const fillOrder = (orderId: string, creator: string, requestedFillAmount: number) =>
    apiCall({
        method: 'put',
        path: URLS.fillOrder(orderId),
        params: {
            creator: creator,
            requestedFillAmount: requestedFillAmount,
        },
    });

/**
 * @remarks Gets the pair + order summaries of orders
 *
 * @param status the status of the summaries to query
 * @param excludeCreator creator to exclude
 * @param creator summaries for specific creator
 *
 * @returns Transformed @ResolvedPromise of request
 */
export const getOrderSummaries = async (status: OrderStatus, excludeCreator?: string, creator?: string) =>
    apiCall({
        method: 'get',
        path: URLS.getOrderSummaries,
        params: {
            params: { orderStatus: status, excludeCreator: excludeCreator, creator: creator },
        },
    });
