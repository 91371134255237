import { Asset, ComplianceRuleKey, InterestRateComplianceControl, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { Divider, IconButton } from '@material-ui/core';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';

type AssetComplianceProps = {
    asset: Asset;
    handleAssetDropDownClick: (asset: string) => void;
    collapsed: boolean;
};

export const AssetComplianceRules: React.FC<AssetComplianceProps> = (props) => {
    const { asset, handleAssetDropDownClick, collapsed } = props;
    const listClasses = useListStyles();
    return (
        <div>
            {(!asset.complianceRules || asset.complianceRules?.length <= 0) && (
                <div className={`${listClasses.text} complianceMessage`}>
                    No compliance controls defined for this asset
                </div>
            )}

            {asset.complianceRules && asset.complianceRules?.length > 0 && (
                <>
                    <div className={`${listClasses.text} complianceControls`}>
                        Compliance Controls
                        <IconButton
                            style={{ height: 10, width: 10 }}
                            onClick={() => handleAssetDropDownClick(asset.tokenIdentifier)}
                            data-testid={'holder_filter_expand'}
                            className={'complianceRulesCollapse'}
                        >
                            {!collapsed ? <ExpandMoreIcon color="secondary" /> : <ExpandLessIcon color="secondary" />}
                        </IconButton>
                    </div>
                    {collapsed && <Divider className={listClasses.divider} style={{ width: '80%' }} />}
                    {collapsed &&
                        asset.complianceRules.map((rule, index) => {
                            if (rule.key === ComplianceRuleKey.InterestRate) {
                                return <InterestRateComplianceControl rule={rule} />;
                            }
                            return (
                                <div className={`${listClasses.text} complianceControl`} key={rule.key + index}>
                                    {rule.name +
                                        ': ' +
                                        (rule.key === ComplianceRuleKey.Expiry
                                            ? NumberFormat.addThousandSeparators(
                                                  rule.value.replace('P', '').replace('T', '')
                                              )
                                            : rule.value)}
                                </div>
                            );
                        })}
                </>
            )}
        </div>
    );
};
