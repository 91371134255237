import {
    Asset,
    ComplianceRuleKey,
    ComponentForcesUpdateParentProp,
    INITIAL_EMPTY_ASSET_STATE,
    StepperFormStep,
} from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';

import { Fade } from '@material-ui/core';
import RequestTransferEnterAmountToRequestStep from './RequestTranferEnterAmountToRequestStep';
import RequestTransferSummaryStep from './RequestTranferSummaryStep';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAssetStep from 'components/commonComponents/GenericStepperFormComponents/SelectAssetStep';
import SelectParticipantStep from 'components/commonComponents/GenericStepperFormComponents/SelectParticipantStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { requestPullTransfer } from 'api/transactApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetParticipantsOnNetwork from 'hooks/GetParticipantsOnNetwork';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const RequestTransfer: React.FC<ComponentForcesUpdateParentProp> = ({ forceUpdateParent }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAsset, setSelectedAsset] = useState<Asset>(INITIAL_EMPTY_ASSET_STATE);
    const [selectedParticipant, setSelectedParticipant] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { assets, getAllAssets, assetsError } = useGetAssetDefinitions();
    const { participants, getParticipants, participantsError } = useGetParticipantsOnNetwork();
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const { t } = useTranslation();
    const checklistContext = useInteractiveChecklistContext();
    const location = useLocation();

    const handleReset = () => {
        setSelectedParticipant('');
        setAssetAmount('');

        setSelectedAsset(INITIAL_EMPTY_ASSET_STATE);
    };

    useEffect(() => {
        setError((text) => text + (text.length > 0 ? '. ' : '') + participantsError);
    }, [participantsError]);

    const submitRequest = async () => {
        const dvpOfferResponse: ResolvedPromise = await requestPullTransfer(
            selectedParticipant,
            selectedAsset.tokenIdentifier,
            assetAmount
        );

        if (dvpOfferResponse.error) {
            enqueueSnackbar(t('error.requestingTransfer', { error: dvpOfferResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.requestingTransfer'), {
                variant: 'success',
            });
            checklistContext?.completeStep(location.pathname);
            if (forceUpdateParent) {
                forceUpdateParent();
            }
        }
        handleReset();
    };

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    useEffect(() => {
        getAllAssets();
        getParticipants();
    }, [enqueueSnackbar, t, getAllAssets, getParticipants]);

    const getSelectedAsset = (assetName: string): Asset => {
        return assets.find((a) => a.tokenName === assetName) ?? INITIAL_EMPTY_ASSET_STATE;
    };

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = getSelectedAsset(event.target.value as string);
        setAssetAmount('0');
        setSelectedAsset(asset);
    };

    const invalidAssetAmountInput = (): boolean => {
        if (assetAmount.length <= 0 || parseFloat(assetAmount) <= 0) {
            return true;
        }
        if (
            selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) &&
            parseFloat(selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)!.value) <
                parseFloat(assetAmount)
        ) {
            return true;
        }
        return false;
    };

    const steps: StepperFormStep[] = [
        {
            title: t('transferAssets.selectAssetStep'),
            component: (
                <SelectAssetStep
                    title={t('requestTransfer.selectAsset')}
                    assetName={selectedAsset.tokenName}
                    assets={assets}
                    handleAssetChange={handleAssetChange}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('commonText.selectParticipantStep'),
            component: (
                <SelectParticipantStep
                    title={t('requestTransfer.selectParticipant')}
                    participants={participants.participants}
                    selectedParticipant={selectedParticipant}
                    setSelectedParticipant={setSelectedParticipant}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedParticipant.length > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('transferAssets.amountToTransferStep'),
            component: (
                <RequestTransferEnterAmountToRequestStep
                    selectedAsset={selectedAsset}
                    setAssetAmount={setAssetAmount}
                    invalidAssetAmountInput={invalidAssetAmountInput()}
                />
            ),
            isNextStepDisabled: () => {
                if (assetAmount.length > 0 && !invalidAssetAmountInput()) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('requestTransfer.confirm'),
            component: (
                <RequestTransferSummaryStep
                    assetName={selectedAsset.tokenName}
                    selectedParticipant={selectedParticipant}
                    assetAmount={assetAmount}
                />
            ),
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitRequest}
                    steps={steps}
                    renderError={error}
                    submitButtonText={'Request'}
                    handleReset={handleReset}
                    ignoreInfoModal={true}
                />
            </Fade>
        </>
    );
};
