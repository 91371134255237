import apiCall from './apiCall';

/**
 * @remark
 * Used to get all kyc states
 *
 * @returns
 * AxiosResponse which resolved in an error or repsonse data
 */
export const requestAllKycStates = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/kyc`,
        dontTrackRequest: dontTrack,
    });

/**
 *
 * @remark
 * Used to request a kyc state from a party on the network
 *
 * @param partyName Name of party requesting kyc state from
 *
 * @returns
 * AxiosResponse which resolves in an error or new kyc request object
 */
export const kycRequestTo = async (tokenIdentifier: string, partyName: string) =>
    apiCall({
        method: 'post',
        path: `/api/kyc/requestTo`,
        params: {
            tokenIdentifier: tokenIdentifier,
            party: partyName,
        },
    });

/**
 * @remark
 * Used to get all kyc requests
 *
 * @returns
 * AxiosResponse which resolves in an kyc object array or error
 */
export const requestAllKycRequests = async () =>
    apiCall({
        method: 'get',
        path: `/api/kyc/getRequests`,
    });
