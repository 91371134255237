import { CANCELLED_COLOR, FILLED_COLOR, PARTIALLY_FILLED_COLOR } from '../colors';
import { OrderStatusFilterState, OrderTableData } from './types';

import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { Order } from 'models/orders/Order';
import { OrderStatus } from 'models/orders/OrderStatus';
import fromatParticipant from 'utils/formatParticipant';
import getStatusDisplayLabel from '../getStatusDisplayLabel';

export const isOrderPartiallyFilled = (order: Order) => {
    return order.status === OrderStatus.UNLOCKED && order.offeredAssetAmount !== order.fillAmount;
};

export const isOrderFullyFilled = (order: Order) => {
    return order.status === OrderStatus.UNLOCKED && order.offeredAssetAmount === order.fillAmount;
};

export const getOfferLogStatusText = (order: Order, getLabel: (labelName: string) => string): string => {
    if (isOrderPartiallyFilled(order)) {
        return getLabel('liquiditySavings.partiallyFilledState');
    } else if (isOrderFullyFilled(order)) {
        return getLabel('liquiditySavings.fullyFilledState');
    } else {
        return getStatusDisplayLabel(order.status, getLabel);
    }
};

export const getOfferLogRowColor = (order: Order): string | undefined => {
    if (isOrderPartiallyFilled(order)) {
        return PARTIALLY_FILLED_COLOR;
    } else if (isOrderFullyFilled(order)) {
        return FILLED_COLOR;
    } else if (order.status === OrderStatus.CANCELLED) {
        return CANCELLED_COLOR;
    }

    return undefined;
};

export const shouldDisplayOrder = (order: Order, orderStatusFilterState: OrderStatusFilterState) => {
    if (!(order.status === 'UNLOCKED' || order.status === 'CANCELLED')) {
        return false;
    }

    if (orderStatusFilterState['fullyFilled'] && isOrderFullyFilled(order)) {
        return true;
    }

    if (orderStatusFilterState['partiallyFilled'] && isOrderPartiallyFilled(order)) {
        return true;
    }

    if (orderStatusFilterState['cancelled'] && order.status === OrderStatus.CANCELLED) {
        return true;
    }

    return false;
};

export const applyDataTransform = (orders: Order[], getLabel: (labelName: string) => string): OrderTableData[] => {
    return orders.map((order) => {
        const requestedAssetDecimals = order.requestedTokenDefinition.tokenDecimals;
        const offeredAssetDecimals = order.offeredTokenDefinition.tokenDecimals;
        return {
            creator: fromatParticipant(order.creator),
            receiver: order.receiver ? fromatParticipant(order.receiver) : 'N/A',
            filledAmount: order.fillAmount
                ? NumberFormat.addThousandSeparators(order.fillAmount.toFixed(offeredAssetDecimals))
                : 'N/A',
            receivedAmount: order.requestedFillAmount
                ? NumberFormat.addThousandSeparators(order.requestedFillAmount.toFixed(requestedAssetDecimals))
                : 'N/A',
            requestedTokenName: order.requestedTokenDefinition.tokenName,
            requestedAssetAmount: NumberFormat.addThousandSeparators(
                order.requestedAssetAmount.toFixed(requestedAssetDecimals)
            ),
            offeredTokenName: order.offeredTokenDefinition.tokenName,
            offeredAssetAmount: NumberFormat.addThousandSeparators(
                order.offeredAssetAmount.toFixed(offeredAssetDecimals)
            ),
            expiry: order.expiry,
            orderId: order.orderId,
            status: getOfferLogStatusText(order, getLabel),
            rowColor: getOfferLogRowColor(order),
            lastUpdated: new Date(order.lastUpdated),
        };
    });
};
