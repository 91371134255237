import TouchAppIcon from '@material-ui/icons/TouchApp';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';

type RequestListContainerProps = {
    onClick: () => void;
    borderColor?: string;
};

const RequestListContainer: React.FC<RequestListContainerProps> = ({ children, onClick, borderColor, ...props }) => {
    const listClasses = useListStyles();
    return (
        <div
            data-testid={'request_container'}
            className={`${listClasses.item} ${listClasses.clickableItem}`}
            onClick={() => onClick()}
            style={{ borderLeft: borderColor ? `3px solid ${borderColor}` : 'none' }}
            {...props}
        >
            <div>{children}</div>
            <TouchAppIcon color="secondary" className={listClasses.requestIcon} />
        </div>
    );
};

export default RequestListContainer;
