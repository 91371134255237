import TabSection, { TabComponentPair } from './TabSection';

import { Grid } from '@material-ui/core';
import { useLayoutStyles } from '@r3/cbdc-asset-frontend-core';

type Props = {
    tabComponentPairs: TabComponentPair[];
    tallSection?: boolean;
};

const LandingPieGraphs: React.FC<Props> = ({ tabComponentPairs, tallSection }) => {
    const layoutClasses = useLayoutStyles();

    return (
        <Grid item xs={6}>
            <div
                style={{
                    height: tallSection ? 700 : '100%',
                }}
            >
                <div
                    className={`${layoutClasses.componentWrapper} `}
                    style={{
                        height: '100%',
                        marginTop: 0,
                    }}
                >
                    <TabSection tabComponentPairs={tabComponentPairs} />
                </div>
            </div>
        </Grid>
    );
};

export default LandingPieGraphs;
