import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useLayoutStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            formInput: {
                borderColor: theme.palette.secondary.main,
                minWidth: 250,
                maxWidth: 500,
                width: '100%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                '& input': {
                    backgroundColor: 'white',
                    borderRadius: theme.shape.borderRadius,
                },
                '& .MuiFormHelperText-root': {
                    color: theme.palette.secondary.main,
                },

                '& .MuiFilledInput-underline.Mui-error:after': {
                    width: '98%',
                    margin: 'auto',
                },
            },
            selectInput: {
                minWidth: 250,
                maxWidth: 500,
                width: '100%',
                '& .MuiFormHelperText-root': {
                    color: theme.palette.primary.contrastText,
                },
                '& .MuiFormLabel-root': {
                    color: theme.palette.primary.contrastText,
                },
                '& .MuiSelect-root': {
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.contrastText,
                },
                '& .MuiInputLabel-shrink': {
                    color: `${theme.palette.getContrastText(theme.palette.primary.light)} !important`,
                    borderRadius: theme.shape.borderRadius / 2,
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1),
                    paddingRight: theme.spacing(3),
                    paddingLeft: theme.spacing(3),
                    background: theme.palette.primary.light,
                    boxShadow: theme.shadows[1],
                    transform: 'translate(14px, -8px) scale(0.75) !important',
                },
                marginBottom: theme.spacing(4),
                marginTop: theme.spacing(4),
            },
            formInputSmall: {},
            formWarapper: {
                backgroundColor: theme.palette.primary.main,
            },
            componentWrapper: {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                gap: 20,
                justifyContent: 'space-around',
                margin: theme.spacing(2),
                backgroundColor: '#19223100',
                marginBottom: 0,
            },
            spaceBetween: {
                justifyContent: 'space-between',

                [theme.breakpoints.down('md')]: {
                    justifyContent: 'space-around',
                },
            },
            background: {
                backgroundColor: theme.palette.primary.main,
            },
            column: {
                backgroundColor: theme.palette.primary.main,
                // width: 'auto',
                flex: '1 1 500px',
                maxWidth: 800,
                height: 'auto',
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
                overflow: 'auto',
                padding: 20,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'normal',
                alignItems: 'center',
                borderRadius: theme.shape.borderRadius,
            },
            columnLarge: {
                minWidth: '99%',
            },
            columnMid: {
                minWidth: '48.5%',
                overflow: 'auto',
            },
            columnSmall: {
                minWidth: 360,
                maxWidth: '12.5%',
            },
            columnSmallMed: {
                minWidth: 200,
                maxWidth: '20.5%',
            },
            columnFillSmall: {
                maxWidth: '100%',
                minWidth: 700,
                overflow: 'auto',
            },
            columnFill: {
                maxWidth: '100%',
                minWidth: 800,
                overflow: 'auto',
            },
            columnNoPadding: {
                padding: 2,
            },
            columDark: {
                backgroundColor: '#24344d00',
            },
            normalSpacing: {
                justifyContent: 'normal',
            },
            buttonGroup: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
            },
            button: {
                minWidth: 250,
                width: '100%',
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
                position: 'relative',
                height: 45,
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.secondary.main,
                fontSize: 13,
                transition: 'all 0.1s linear',
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    transform: 'scale(1.1)',
                },
            },
            sectionBadge: {
                fontSize: 20,
                color: 'burlywood',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 24,
                marginBottom: 24,
                padding: 16,
                borderRadius: theme.shape.borderRadius,
                backgroundColor: '#ffffff00',
                border: '1px solid grey',
            },
            buttonAutoSize: {
                width: 'auto',
                minWidth: 100,
                height: 45,
                fontSize: 16,
            },
            iconButton: {
                margin: 'auto',
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
                minWidth: 100,
                width: 100,
                height: 60,
                color: theme.palette.primary.contrastText,
                borderColor: theme.palette.secondary.main,
                fontSize: 13,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                },
            },

            backButton: {
                marginRight: theme.spacing(1),
            },
            instructions: {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            stepLabel: {
                '& .MuiStepIcon-completed': {
                    color: theme.palette.secondary.main,
                },
            },
            headerText: {
                color: theme.palette.primary.contrastText,
                fontSize: 34,
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(4),
            },

            sectionLabel: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                color: theme.palette.primary.contrastText,
                fontSize: 20,
                textAlign: 'center',
            },
            centerText: {
                textAlign: 'center',
            },
            text: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                color: theme.palette.primary.contrastText,
                fontSize: 18,

                [theme.breakpoints.down('sm')]: {
                    fontSize: 15,
                },
            },
            buttonGroupOverride: {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
            summaryDivider: {
                color: theme.palette.secondary.main,
                background: theme.palette.secondary.main,
                marginTop: 10,
                marginBottom: 10,
                width: '70%',
            },
            summaryDividerSmall: {
                color: theme.palette.secondary.light,
                background: theme.palette.secondary.light,
                marginTop: 10,
                marginBottom: 10,
                width: '40%',
                height: 0.5,
            },
        }),
    { index: 1 }
);
