import * as ROUTES from './Routes';

import { InfoModalPage } from '../components/infoModal/InfoModal';

const folderDir = '/embeddedDiagrams/';
// eslint-disable-next-line
const placeHolderImageURL = folderDir + 'placeholder.png';
// eslint-disable-next-line
const placeHolderImageURL2 = folderDir + 'placeholder2.png';

export const DOCS_URL = 'https://docs-cbdc.r3.com/';

export const GetModalInfoData = (url: string): InfoModalPage[] => {
    let modalpages: InfoModalPage[] = [];
    let modalPair = INFO_MODAL_PAGES.find((x) => x.url === url);
    if (modalPair) {
        modalPair.pages.forEach((page) => {
            page.docsLink = modalPair?.docsLink;
        });

        return modalPair.pages;
    }
    return modalpages;
};

type InfoModalPages = {
    url: string;
    pages: InfoModalPage[];
    docsLink: string;
};

//to add a video page: add a new object to the infoMOdalPages array
//headerText: the title of the video
//videoId: youtube video id
// { headerText: 'Request CBDC Video Overview', videoId: 'rokGy0huYEA' }

export const INFO_MODAL_PAGES: InfoModalPages[] = [
    {
        url: '/',
        pages: [
            { headerText: 'Sandbox Applications Video Introduction', videoId: 'aNTDRXJFBpE' },
            { headerText: 'Documentation Video Introduction', videoId: 'dVAVErI5yns' },
        ],
        docsLink: DOCS_URL + '',
    },
    {
        url: ROUTES.REQUESTCBDC,
        pages: [
            { headerText: 'Request CBDC High Level', imageUrl: folderDir + 'requestcbdchighlevel.png' },
            { headerText: 'Request CBDC Corda Flow Sequence', imageUrl: folderDir + 'requestcbdclowlevel.png' },
        ],
        docsLink: DOCS_URL,
    },
    {
        url: ROUTES.TRANSFERCBDC,
        pages: [
            { headerText: 'Push/Pull Transfer Video Overview', videoId: 'FAXnwvuxLds' },
            { headerText: 'Transfer CBDC High Level', imageUrl: folderDir + 'transferassethighlevel.png' },
            { headerText: 'Transfer CBDC Corda Flow Sequence', imageUrl: folderDir + 'transferassetlowlevel.png' },
            {
                headerText: 'Request Pull Transfer High Level',
                imageUrl: folderDir + 'requestpulltransferhighlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'single-asset-transactions/',
    },
    {
        url: ROUTES.REQUESTKYCSTATE,
        pages: [
            {
                headerText: 'Request Member Access High Level',
                imageUrl: folderDir + 'requestmemberaccesshighlevel.png',
            },
            {
                headerText: 'Request Member Access Corda Flow Sequence',
                imageUrl: folderDir + 'requestmemberaccesslowlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'controls/member-access-controls/#requesting-a-member-access-state',
    },
    {
        url: ROUTES.REDEEMCBDC,
        pages: [
            {
                headerText: 'Redeem CBDC High Level',
                imageUrl: folderDir + 'redeemcbdchighlevel.png',
            },
            {
                headerText: 'Redeem CBDC Corda Flow Sequence',
                imageUrl: folderDir + 'redeemcbdclowlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'asset-lifecycle-operations/redemption/',
    },
    {
        url: ROUTES.REQUESTDVP,
        pages: [
            { headerText: 'DVP Transaction Video Overview', videoId: 'lKtIM5vUSEo' },
            {
                headerText: 'Request DVP High Level',
                imageUrl: folderDir + 'requestdvphighlevel.png',
            },
        ],
        docsLink: DOCS_URL + 'multi-asset-transactions/dvp/#requesting-a-dvp-transaction',
    },
    {
        url: ROUTES.DVPREQUESTS,
        pages: [
            { headerText: 'DVP Transaction Video Overview', videoId: 'lKtIM5vUSEo' },
            {
                headerText: 'Approve/Deny DVP High Level',
                imageUrl: folderDir + 'approvedenydvphighlevel.png',
            },
            {
                headerText: 'Approve DVP Corda Flow Sequence',
                imageUrl: folderDir + 'approvedvptransaction.png',
            },
        ],
        docsLink: DOCS_URL + 'multi-asset-transactions/dvp/#responding-to-a-dvp-request',
    },
    {
        url: ROUTES.TRANSFERREQUESTS,
        pages: [
            {
                headerText: 'Approve/Deny Pull Transfer High Level',
                imageUrl: folderDir + 'approvedenypulltransferhighlevel.png',
            },
            {
                headerText: 'Approve/Deny Pull Transfer Corda Flow Sequence',
                imageUrl: folderDir + 'approvedenypulltransferlowlevel.png',
            },
        ],
        docsLink: DOCS_URL,
    },
    {
        url: ROUTES.CROSSCHAINSWAPREQUESTS,
        pages: [
            {
                headerText: 'Approve/Deny Cross Chain Swap Request High Level',
                imageUrl: folderDir + 'approvedenycrosschainswaphighlevel.png',
            },
            {
                headerText: 'Execute Cross Chain Swap Request High Level Part 1',
                imageUrl: folderDir + 'executecrosschainswap1.png',
            },
            {
                headerText: 'Execute Cross Chain Swap Request High Level Part 2',
                imageUrl: folderDir + 'executecrosschainswap2.png',
            },
        ],
        docsLink: DOCS_URL,
    },
    {
        url: ROUTES.REQUESTCROSSCHAINSWAP,
        pages: [
            {
                headerText: 'Request Cross Chain Swap Request High Level',
                imageUrl: folderDir + 'requestcrosschainswap.png',
            },
        ],
        docsLink: DOCS_URL,
    },
];
