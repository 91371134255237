import { useCallback, useEffect, useState } from 'react';

import { Payee } from '../components/retailClient/RetailPayees/RetailPayeeItem';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllPayees } from '../api/retailApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useGetAllPayees(fetchByDefault?: boolean) {
    const [payees, setPayees] = useState<Payee[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const getPayees = useCallback(async () => {
        setLoading(true);
        let kycStatesResponse: ResolvedPromise;
        kycStatesResponse = await requestAllPayees();
        if (kycStatesResponse.error) {
            enqueueSnackbar(t('error.gettingPayees', { error: kycStatesResponse.error }), {
                variant: 'error',
            });
            setError(kycStatesResponse.error.length > 0 ? kycStatesResponse.error : t('error.networkError'));
        } else {
            setPayees(kycStatesResponse.data as Payee[]);
        }
        setLoading(false);
    }, [enqueueSnackbar, t]);

    useEffect(() => {
        if (fetchByDefault) {
            getPayees();
        }
    }, [getPayees, fetchByDefault]);

    const getPayeeNameByAccountId = (accId: string): string | null => {
        const payee = payees.find((payee) => payee.accountId === accId);
        if (payee) {
            return payee.payeeName;
        }
        return null;
    };

    return { payees, error, getPayees, loading, getPayeeNameByAccountId };
}
