import { DialogAction, DialogActions, DialogBody, DialogWindow, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { Divider, SvgIcon, Typography } from '@material-ui/core';
import { DvpRequest, NumberFormat } from '@r3/cbdc-asset-frontend-core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import DialogTransactionSummary from 'components/commonComponents/DialogTransactionSummary/DialogTransactionSummary';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import React from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

export interface SimpleDialogProps {
    open: boolean;
    request: DvpRequest;
    assetSummary: SummedAsset | undefined;
    approve: () => void;
    decline: () => void;
    onClose: () => void;
    ownRequest: boolean;
}

export function DvpRequestDialog(props: SimpleDialogProps) {
    const { onClose, approve, request, open, decline, ownRequest, assetSummary } = props;
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const isActionable = request.requestState === 'REQUESTED' && !ownRequest;
    const approveDisabled =
        typeof assetSummary == 'undefined' || request.assetAmount > assetSummary.availableToTransact;
    return (
        <DialogWindow open={open} onClose={onClose} title={t('dvpRequestDialog.title') + ' : ' + request.requestState}>
            <DialogBody>
                <Typography gutterBottom>{t('commonLabels.requestId') + ': ' + request.requestId}</Typography>
                <Typography gutterBottom>
                    {t('defineAsset.assetName') + ': ' + request.tokenDefinition.tokenName}
                </Typography>
                <Typography gutterBottom>{t('dvpRequestDialog.requestor') + ': ' + request.buyer}</Typography>
                <Typography gutterBottom>{t('dvpRequestDialog.requestee') + ': ' + request.seller}</Typography>
                <Typography gutterBottom>
                    {'Asset Amount Requested: ' + NumberFormat.addThousandSeparators(request.assetAmount.toString())}
                </Typography>
                <div style={{ display: 'flex' }}>
                    <Typography gutterBottom>{t('commonText.value') + ' in bonds'}</Typography>
                    <SvgIcon style={{ width: 18, height: 18 }}>
                        <BondsIcon />
                    </SvgIcon>
                    <Typography gutterBottom>
                        {':  ' + NumberFormat.addThousandSeparators(request.bondAmount.toString())}
                    </Typography>
                </div>

                {isActionable && (
                    <>
                        <Divider className={layoutClasses.summaryDivider} />
                        <DialogTransactionSummary asset={assetSummary} toSpend={request.assetAmount} />
                    </>
                )}
            </DialogBody>
            <DialogActions>
                {isActionable && (
                    <>
                        <DialogAction
                            text={t('commonText.approve')}
                            action={approve}
                            icon={<ThumbUpIcon />}
                            disabled={approveDisabled}
                        />
                        <DialogAction text={t('commonText.deny')} action={decline} icon={<NotInterestedIcon />} />
                    </>
                )}
            </DialogActions>
        </DialogWindow>
    );
}
