import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useRetailStickyButtonsStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            bar: {
                backgroundColor: theme.palette.primary.main,
                position: 'fixed',
                bottom: -20,
                paddingBottom: 15,
                width: '95%',
                height: 90,
                zIndex: 20,
                display: 'flex',
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
                [theme.breakpoints.up('sm')]: {
                    width: 600,
                },
                borderRadius: 25,
                border: '1px solid',
                borderColor: theme.palette.secondary.main,
            },
            button: {
                height: 40,
                width: 120,
                fontSize: 14,
                color: theme.palette.primary.contrastText,
                margin: 'auto auto auto auto',
                transition: 'all 0.1s ease-in-out',
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    transform: 'scale(1.1)',
                },
            },
            buttonIcon: {
                width: 26,
                height: 26,
            },
        }),
    { index: 1 }
);
