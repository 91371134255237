import { Asset, EMPTY_SUMMED_STATE, INITIAL_EMPTY_ASSET_STATE, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { ChangeEvent, useEffect } from 'react';
import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

import AssetSummary from 'components/commonComponents/SummaryComponents/AssetSummary';
import { SelectSwapAssetsStepProps } from 'components/cbdcManager/RequestCrossChainSwap/SelectSwapAssetsStep';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

const SelectAssetsStep: React.FC<SelectSwapAssetsStepProps> = (props) => {
    const { selectedAsset, setSelectedAsset, selectedSwapAsset, setSelectedSwapAsset, assets, transferableAssets } =
        props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();

    const getSelectedAsset = (assetName: string): Asset => {
        return assets.find((a) => a.tokenName === assetName) ?? INITIAL_EMPTY_ASSET_STATE;
    };

    const getSelectedTransferableAsset = (assetName: string): SummedAsset => {
        return transferableAssets.find((a) => a.tokenName === assetName) ?? EMPTY_SUMMED_STATE;
    };

    const handleAssetChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        if (event.target.name === 'SelectSwapAsset') {
            setSelectedSwapAsset(getSelectedAsset(event.target.value as string));
        }
        if (event.target.name === 'SelectAssetTo') {
            setSelectedAsset(getSelectedTransferableAsset(event.target.value as string));
        }
    };

    useEffect(() => {
        if (selectedSwapAsset.tokenIdentifier === selectedAsset.tokenIdentifier) {
            setSelectedAsset(EMPTY_SUMMED_STATE);
        }
        // eslint-disable-next-line
    }, [selectedSwapAsset]);

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('crossChainSwap.selectAssetToRequest')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <FormControl
                    id="SelectAssetToRequest"
                    variant="outlined"
                    className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                >
                    <InputLabel id="SelectLabel">{t('createExchangeOffer.assetToRequest')}</InputLabel>
                    <Select
                        labelId="SelectLabel"
                        value={selectedSwapAsset.tokenName}
                        onChange={handleAssetChange}
                        data-testid={'asset-request-select'}
                        name={'SelectSwapAsset'}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {assets.map((as, i) => {
                            return (
                                <MenuItem key={as.tokenName + i} value={as.tokenName} data-testid={'asset-option'}>
                                    {as.tokenName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {transferableAssets.length === 0 ? (
                    <>
                        <Divider className={layoutClasses.summaryDivider} />
                        <Typography className={`${layoutClasses.text}`} style={{ textAlign: 'center' }}>
                            {t('createExchangeOffer.noTransferableAssets')}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography className={`${layoutClasses.sectionLabel}`}>
                            {t('crossChainSwap.selectAssetToOffer')}
                        </Typography>

                        <Divider className={layoutClasses.summaryDivider} />
                        <FormControl
                            id="SelectAssetToOffer"
                            variant="outlined"
                            className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                        >
                            <InputLabel id="SelectLabel">{t('createExchangeOffer.assetToOffer')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={selectedAsset.tokenName}
                                onChange={handleAssetChange}
                                data-testid={'asset-offer-select'}
                                name={'SelectAssetTo'}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {transferableAssets
                                    .filter((x) => x.tokenIdentifier !== selectedSwapAsset.tokenIdentifier)
                                    .map((as, i) => {
                                        return (
                                            <MenuItem
                                                key={as.tokenName + i}
                                                value={as.tokenName}
                                                data-testid={'asset-option'}
                                            >
                                                {as.tokenName}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </>
                )}

                {selectedAsset.tokenIdentifier.length > 0 && selectedAsset && <AssetSummary asset={selectedAsset} />}
            </Paper>
        </div>
    );
};

export default SelectAssetsStep;
