import { Divider, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { AssetComplianceRules } from './AssetComplianceControls';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ViewAssets: React.FC = () => {
    const listClasses = useListStyles();
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const [error, setError] = useState<string>('');
    const [collapsedAssets, setCollapsedAssets] = useState<string[]>([]);
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { assets, getAllAssets, assetsError } = useGetAssetDefinitions();

    useEffect(() => {
        getAllAssets();
    }, [getAllAssets]);

    useEffect(() => {
        if (assets.length > 0) {
            checkListContext?.completeStep(location.pathname);
        }
    }, [assets, location.pathname, checkListContext]);

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    const handleAssetDropDownClick = (key: string) => {
        if (collapsedAssets.includes(key)) {
            setCollapsedAssets((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapsedAssets((keys) => keys.concat([key]));
        }
    };

    return (
        <>
            <div className={`${layoutClasses.componentWrapper} `}>
                <Paper elevation={5} className={`${layoutClasses.column} `}>
                    {error.length > 0 ? (
                        <>
                            <ListHeader
                                text={error}
                                refreshAction={() => {
                                    setError('');
                                    getAllAssets();
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <ListHeader text={t('viewAssets.assetsHeader')} refreshAction={getAllAssets} />
                            <Divider className={listClasses.divider} />
                            <div className={listClasses.list}>
                                {assets.map((asset, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${listClasses.assetItem} assetDefintion`}
                                            data-testid={'asset_definition'}
                                        >
                                            <div className={`${listClasses.text} assetName`}>
                                                {t('defineAsset.assetName') + ': '}
                                                {asset.tokenName}
                                            </div>
                                            <div className={`${listClasses.text} assetDecimal`}>
                                                {t('defineAsset.assetDecimal') + ': '}
                                                {asset.tokenDecimals}
                                            </div>
                                            <div className={`${listClasses.text} assetIssuer`}>
                                                {t('listKyc.issuer') + ': '}
                                                {asset.party}
                                            </div>
                                            <div className={`${listClasses.text} assetId`}>
                                                {t('commonText.uniqueId') + ': '}
                                                {asset.tokenIdentifier}
                                            </div>
                                            <AssetComplianceRules
                                                asset={asset}
                                                handleAssetDropDownClick={handleAssetDropDownClick}
                                                collapsed={collapsedAssets.includes(asset.tokenIdentifier)}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </Paper>
            </div>
        </>
    );
};
