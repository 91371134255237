import common_en from './translations/en/common.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: { common: common_en },
    },
    lng: 'en',

    //Will be used if translations for the current language are not available
    fallbackLng: 'en',
    defaultNS: 'common',

    interpolation: {
        //React already handles escaping to avoid xss injection
        escapeValue: false,
    },
});

export default i18n;
