import * as ROUTES from 'constants/Routes';

import { insertIntoArrayIf, useFeatureFlagContext } from '@r3/cbdc-asset-frontend-core';

import { AccessControls } from 'components/accessControls/KnowYourCustomer';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import { CBDCManager } from 'components/cbdcManager/CBDCManager';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import DecentralizedExchange from 'components/decentralizedExchange/DecentralizedExchange';
import { DemoDashboard } from 'components/treasuryDashboard/DemoDashboard';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import LandingPageContextWrapper from 'components/landingPage/LandingPageContextWrapper';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import ManageRetail from 'components/manageRetail/ManageRetail';
import { NavPage } from 'models/NavPage';
import { NetworkTab } from 'components/networkTab/NetworkTab';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Requests } from 'components/requests/requests';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import { TransactionDashboardParent } from 'components/transactionDashboard/TransactionDashboardParent';
import { useTranslation } from 'react-i18next';

const useWholesalePages = () => {
    const { t } = useTranslation();

    const { isFeatureEnabled } = useFeatureFlagContext();

    const pages: NavPage[] = [
        {
            navButton: {
                icon: <DashboardRoundedIcon />,
                highlightPath: '/',
                linkPath: '/',
                label: 'Home',
            },
            route: '/',
            exactRoute: true,
            pageComponent: LandingPageContextWrapper,
        },
        ...insertIntoArrayIf(isFeatureEnabled('cbdcManager'), {
            navButton: {
                icon: <LocalAtmIcon />,
                highlightPath: ROUTES.ASSETS,
                linkPath: ROUTES.AVAILABLECBDC,
                label: t('pageTitle.assets'),
            },
            route: ROUTES.ASSETS,
            pageComponent: CBDCManager,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('accessControls'), {
            navButton: {
                icon: <FingerprintIcon />,
                highlightPath: ROUTES.KYCCONTROL,
                linkPath: ROUTES.LISTKYC,
                label: t('pageTitle.kycControl'),
            },
            route: ROUTES.KYCCONTROL,
            pageComponent: AccessControls,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('requests'), {
            navButton: {
                icon: <SwapVertIcon />,
                highlightPath: ROUTES.OUTGOINGREQUESTS,
                linkPath: ROUTES.CBDCREQUESTSLIST,
                label: t('pageTitle.outgoingRequests'),
            },
            route: ROUTES.OUTGOINGREQUESTS,
            pageComponent: Requests,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('treasuryDashboard'), {
            navButton: {
                icon: <DonutLargeIcon />,
                highlightPath: ROUTES.DEMODASH,
                linkPath: ROUTES.ASSETDASH,
                label: 'Treasury Dashboard',
            },
            route: ROUTES.DEMODASH,
            pageComponent: DemoDashboard,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('decentralizedExchange'), {
            navButton: {
                icon: <AccountTreeRoundedIcon />,
                highlightPath: ROUTES.DECENTRALIZEDEXCHANGE,
                linkPath: ROUTES.MAKEOFFER,
                label: t('pageTitle.decentralizedExchange'),
            },
            route: ROUTES.DECENTRALIZEDEXCHANGE,
            pageComponent: DecentralizedExchange,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('txDashboard'), {
            navButton: {
                icon: <ReceiptIcon />,
                highlightPath: ROUTES.TRANSACTIONDASH,
                linkPath: ROUTES.TRANSACTIONDASH,
                label: t('pageTitle.transactionDashboard'),
            },
            route: ROUTES.TRANSACTIONDASH,
            pageComponent: TransactionDashboardParent,
        }),
        ...insertIntoArrayIf(isFeatureEnabled('retailCBDC'), {
            navButton: {
                icon: <AccountBoxRoundedIcon />,
                highlightPath: ROUTES.MANAGERETAIL,
                linkPath: ROUTES.RETAILCUSTOMERS,
                label: t('pageTitle.retail'),
            },
            route: ROUTES.MANAGERETAIL,
            pageComponent: ManageRetail,
        }),
        {
            route: ROUTES.NETWORKTAB,
            pageComponent: NetworkTab,
        },
    ];

    return { pages };
};

export default useWholesalePages;
