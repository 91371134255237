import { Divider, Paper, Typography } from '@material-ui/core';

import { Asset, ComplianceRuleKey, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import React from 'react';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type Props = {
    selectedAsset: Asset;
    setAssetAmount: (val: string) => void;
    invalidAssetAmountInput: boolean;
};

const RequestTransferEnterAmountToRequestStep: React.FC<Props> = (props) => {
    const { selectedAsset, setAssetAmount, invalidAssetAmountInput } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{'Enter asset amount to request'}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                {selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) && (
                    <>
                        <Typography className={`${layoutClasses.text}`}>
                            Maximum Transaction value with this asset{' '}
                            {NumberFormat.addThousandSeparators(
                                selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)
                                    ?.value ?? ''
                            )}
                        </Typography>
                    </>
                )}
                <Typography className={`${layoutClasses.text}`}>
                    {t('issueAsset.decimal') + ': ' + selectedAsset.tokenDecimals}
                </Typography>
                <CurrencyInput
                    decimalPlaces={selectedAsset.tokenDecimals}
                    onChange={setAssetAmount}
                    label={invalidAssetAmountInput ? t('commonText.invalidAmount') : 'Asset Amount'}
                    testId="amount-to-request"
                    invalidInput={invalidAssetAmountInput}
                />
            </Paper>
        </div>
    );
};

export default RequestTransferEnterAmountToRequestStep;
