import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useOfferLogStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            filterWrapper: { minWidth: 240, height: 360 },
            filter: {
                marginTop: 20,
                flexFlow: 'wrap',
                display: 'flex',
                flexDirection: 'column',
                color: theme.palette.primary.contrastText,
                width: 'auto',
            },
            filterLabel: { color: `${theme.palette.primary.contrastText} !important`, marginBottom: 8, fontSize: 18 },
            tableWrapper: { margin: '18px auto auto auto', width: '75%', minWidth: 300 },
            checkBox: {
                color: theme.palette.primary.contrastText,
            },
            checkBoxLabel: {
                color: theme.palette.primary.contrastText,
                '& .MuiFormControlLabel-label': { fontSize: 14 },
            },
        }),
    { index: 1 }
);
