import {
    Checkbox,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuProps,
    Select,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useLayoutStyles } from 'materialStyles/layoutStyles';

const DEFAULT_ALL_VALUE = 'ALL';

const SELECT_MENU_PROPS: Partial<MenuProps> = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 250,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
};

type Props = {
    options: string[];
    onSelectionChanged: (options: string[]) => void;
    labelText: string;
    selectAllByDefault?: boolean;
    selectAllOptionText?: string;
};

const MultiSelectDropDown: React.FC<Props> = ({
    options,
    onSelectionChanged,
    labelText,
    selectAllByDefault,
    selectAllOptionText,
}) => {
    const layoutClasses = useLayoutStyles();
    const [selected, setSelected] = useState<string[]>(selectAllByDefault ? options : []);

    const handleChange = (event) => {
        const value = event.target.value;
        if (value.length === options.length + 1) {
            setSelected([]);
            return;
        }
        if (value[value.length - 1] === DEFAULT_ALL_VALUE) {
            setSelected(options);
            return;
        }
        setSelected(value);
    };

    useEffect(() => {
        onSelectionChanged(selected);
        // eslint-disable-next-line
    }, [selected]);

    const renderValue = (selected: unknown) => {
        if (typeof selected === 'string') {
            return selected;
        }
        const selectedParticipants = selected as string[];
        if (selectedParticipants.length === options.length) {
            return selectAllOptionText ?? 'All';
        }
        return selectedParticipants.join(', ');
    };

    return (
        <FormControl variant="outlined" className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}>
            <InputLabel id="mutiple-select-label">{labelText}</InputLabel>
            <Select
                labelId="mutiple-select-label"
                multiple
                data-testid={'list-options-select'}
                inputProps={{ 'data-testid': 'list-options-input' }}
                value={selected}
                onChange={handleChange}
                renderValue={renderValue}
                MenuProps={SELECT_MENU_PROPS}
            >
                <MenuItem value={DEFAULT_ALL_VALUE} data-testid={'list-option-all'}>
                    <ListItemIcon>
                        <Checkbox checked={selected.length === options.length} />
                    </ListItemIcon>
                    <ListItemText primary={selectAllOptionText ?? 'All'} />
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option} data-testid={`list-option-${option}`}>
                        <ListItemIcon>
                            <Checkbox checked={selected.includes(option)} />
                        </ListItemIcon>
                        <ListItemText primary={option} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default MultiSelectDropDown;
