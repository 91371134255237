import {
    DialogAction,
    DialogActions,
    DialogBody,
    NumberFormat,
    SummedAsset,
    useDialogStyles,
} from '@r3/cbdc-asset-frontend-core';
import { Divider, Typography, makeStyles } from '@material-ui/core';
import { FC, useMemo, useState } from 'react';

import Big from 'big.js';
import CancelIcon from '@material-ui/icons/Cancel';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import DialogState from './DialogState';
import DialogTransactionSummary from 'components/commonComponents/DialogTransactionSummary/DialogTransactionSummary';
import { Order } from 'models/orders/Order';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { calculateOfferedAmountAsString } from 'components/decentralizedExchange/calculateAmount';
import { fillOrder } from 'api/decentralizedExchange';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    grid: {
        display: 'grid',
        rowGap: theme.spacing(2),
        columnGap: theme.spacing(2),
        gridTemplateColumns: `1fr 1fr`,
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(6),
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.contrastText,
        fontSize: 20,
    },
    originalOfferText: {
        fontSize: 18,
        marginTop: theme.spacing(5),
    },
    fillAmount: { paddingLeft: 10, marginTop: -4 },
    fillAmountLabel: { fontSize: 13, marginBottom: 2 },
    fillAmountValue: {
        fontSize: 18,
    },
}));

type Props = {
    order: Order;
    onClose: () => void;
    dispatchDialogState: (dialogState: DialogState) => void;
    dispatchRefresh: () => void;
    assetSummary: SummedAsset | undefined;
};

const PartiallyFillOrderContent: FC<Props> = ({
    order,
    onClose,
    dispatchDialogState,
    dispatchRefresh,
    assetSummary,
}) => {
    const [providedAmount, setProvidedAmount] = useState<number>(order.offeredAssetAmount);
    const [calculatedAmount, setCalculatedAmount] = useState<string>(
        order.offeredAssetAmount.toFixed(order.offeredTokenDefinition.tokenDecimals)
    );
    const { t: texts } = useTranslation();
    const classes = useStyles();
    const dialogStyles = useDialogStyles();
    const { enqueueSnackbar } = useSnackbar();

    const isAmountValid = useMemo(
        () => providedAmount > 0 && providedAmount <= order.requestedAssetAmount,
        [order, providedAmount]
    );

    async function handleSubmit() {
        if (!isAmountValid) {
            return;
        }
        onClose();
        const { error } = await fillOrder(order.orderId, order.creator, providedAmount);
        if (error == null) {
            enqueueSnackbar(texts('success.acceptExchangeOffer'), { variant: 'success' });
        } else {
            enqueueSnackbar(texts('error.acceptExchangeOffer', { error }), { variant: 'error' });
        }
        dispatchRefresh();
    }

    function handleInputChange(value: string) {
        const amount = parseFloat(value);
        setProvidedAmount(Number.isNaN(amount) ? 0 : amount);
        setCalculatedAmount(calculateOfferedAmountAsString(order, amount));
    }

    const submitDisabled =
        typeof assetSummary == 'undefined' ||
        (assetSummary && Big(providedAmount).gt(assetSummary.availableToTransact));

    return (
        <div data-testid="partially_fill_order_content">
            <DialogBody>
                <div className={classes.grid}>
                    <Typography className={`${classes.caption}`}>
                        {texts('liquiditySavings.originalOrderCaption')}
                    </Typography>
                    <Typography className={`${classes.caption}`}>
                        {texts('liquiditySavings.currentOrderCaption')}
                    </Typography>
                    <Typography className={`${classes.originalOfferText}`}>
                        <strong>{texts('liquiditySavings.currencyRequested')}: </strong>
                        {`${order.requestedTokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                            order.requestedAssetAmount.toFixed(order.requestedTokenDefinition.tokenDecimals)
                        )}`}
                    </Typography>
                    <CurrencyInput
                        decimalPlaces={order.requestedTokenDefinition.tokenDecimals}
                        label="Transfer Amount"
                        onChange={handleInputChange}
                        initialValue={order.requestedAssetAmount}
                        invalidInput={!isAmountValid}
                        testId="provided_amount_input"
                    />
                    <Typography className={`${classes.originalOfferText}`}>
                        <strong>{texts('liquiditySavings.currencyOffered')}: </strong>
                        {`${order.offeredTokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                            order.offeredAssetAmount.toFixed(order.offeredTokenDefinition.tokenDecimals)
                        )}`}
                    </Typography>
                    <div className={`${classes.fillAmount}`}>
                        <Typography className={`${classes.fillAmountLabel}`}>Fill Amount</Typography>
                        <Typography className={`${classes.fillAmountValue}`} data-testid="calculated_amount">
                            {NumberFormat.addThousandSeparators(calculatedAmount)}
                        </Typography>
                    </div>
                </div>
                <Divider className={dialogStyles.divider} style={{ marginLeft: 0, marginRight: 0, marginBottom: 12 }} />
                <DialogTransactionSummary
                    asset={assetSummary}
                    toSpend={providedAmount}
                    showTitle={false}
                    showAssetName={false}
                    showSpendAmount={false}
                    showRemainingAssets={false}
                />
            </DialogBody>
            <DialogActions>
                <DialogAction
                    text={texts('commonText.submit')}
                    action={handleSubmit}
                    icon={<ThumbUpIcon />}
                    disabled={submitDisabled || !isAmountValid}
                />
                <DialogAction text={texts('commonText.cancel')} action={onClose} icon={<CancelIcon />} />
            </DialogActions>
        </div>
    );
};

export default PartiallyFillOrderContent;
