import { Theme, alpha, createStyles, makeStyles } from '@material-ui/core';

export const useRetailHomeStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            userTitle: {
                color: theme.palette.primary.contrastText,
                opacity: 0.9,
                fontWeight: 100,
                fontSize: 32,
                margin: '32px 0px 0px 12px',
                [theme.breakpoints.up('sm')]: {
                    fontSize: 40,
                    margin: '32px 0px 0px 42px',
                },
            },
            sectionTitle: {
                width: '100%',
                color: theme.palette.primary.contrastText,
                fontWeight: 300,
                textAlign: 'left',
            },
            retailSection: {
                maxWidth: 500,
                maxHeight: 520,
                marginTop: 0,
                overflow: 'hidden',
                [theme.breakpoints.up('sm')]: {
                    paddingLeft: 38,
                    paddingRight: 38,
                },

                [theme.breakpoints.up('md')]: {
                    maxWidth: 550,
                    background: `linear-gradient(-50deg, ${theme.palette.primary.main} 85%, ${theme.palette.primary.light} 130%)`,
                },
                [theme.breakpoints.down('sm')]: {
                    backgroundColor: ' rgba(255, 0, 0, 0)',
                    padding: '0px 8px 0px 8px',
                    boxShadow: 'none',
                },
            },
            retailWrapper: {
                justifyContent: 'space-around',
                [theme.breakpoints.up('sm')]: {
                    marginTop: 46,
                },

                [theme.breakpoints.only('lg')]: {
                    justifyContent: 'space-between',
                },
            },
            largeScreenSection: {
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            },
            smallScreenSection: {
                [theme.breakpoints.up('sm')]: {
                    display: 'none',
                },
            },
            accountId: {
                color: alpha(theme.palette.primary.contrastText, 0.6),
                margin: '12px 0px 0px 16px',
                [theme.breakpoints.up('sm')]: {
                    margin: '12px 0px 0px 46px',
                },
            },
            headerIcon: { width: 18, height: 18, marginLeft: 4 },
            headerButton: { margin: 'auto 2px auto 2px', color: 'white', opacity: 1, fontSize: 12 },
        }),
    { index: 1 }
);
