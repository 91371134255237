import { useCallback, useEffect, useState } from 'react';

import QrReader from 'react-qr-reader';
import { ShareRetailAccountDetails } from '@r3/cbdc-asset-frontend-core';

type AddPayeeQrProps = {
    setPayeeDetails: (payeeDetails: ShareRetailAccountDetails) => void;
};

const AddPayeeQr: React.FC<AddPayeeQrProps> = ({ setPayeeDetails }) => {
    const [codeValue, setCodeValue] = useState<string>('');

    const handleScan = (data) => {
        if (data) {
            setCodeValue(data);
        }
    };
    const handleError = (err) => {
        console.error(err);
    };
    const submit = useCallback(() => {
        const requestJson = JSON.parse(codeValue) as ShareRetailAccountDetails;
        if (verifyPayeeDetails(requestJson)) {
            setPayeeDetails(requestJson);
        } else {
            alert('Failed to interpret QR code, please try again. Please make sure to use a valid account QR code.');
            setCodeValue('');
        }
        // eslint-disable-next-line
    }, [codeValue, setPayeeDetails, verifyPayeeDetails]);

    useEffect(() => {
        if (codeValue.length <= 0) {
            return;
        }
        submit();
    }, [codeValue, submit]);

    return (
        <div style={{ maxWidth: 500, minWidth: 280, height: 'auto', margin: 'auto' }}>
            <QrReader delay={200} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
        </div>
    );
};

export default AddPayeeQr;

const verifyPayeeDetails = (payeeDetails: ShareRetailAccountDetails) => {
    if (payeeDetails.accountId.length > 0 && payeeDetails.desc.length > 0 && payeeDetails.x500.length > 0) {
        return true;
    }

    return false;
};
