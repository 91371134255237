import { Divider, Paper, Typography } from '@material-ui/core';

import AssetSummary from 'components/commonComponents/SummaryComponents/AssetSummary';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import React from 'react';
import { SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type EnterAmountToRedeemStepProps = {
    selectedAsset: SummedAsset;
    assetAmount: string;
    setAssetAmount: (val: string) => void;
    invalidAssetAmountInput: boolean;
};

const EnterAmountToRedeemStep: React.FC<EnterAmountToRedeemStepProps> = (props) => {
    const { selectedAsset, assetAmount, setAssetAmount, invalidAssetAmountInput } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>Enter the amount to Redeem</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <AssetSummary
                    asset={selectedAsset}
                    toSpend={assetAmount.length <= 0 ? 0 : parseFloat(assetAmount)}
                    customSpendText={t('transferAssets.availableToRedeem')}
                />
                <CurrencyInput
                    decimalPlaces={selectedAsset.tokenDecimal}
                    onChange={setAssetAmount}
                    label={invalidAssetAmountInput ? t('commonText.invalidAmount') : t('commonText.assetInput')}
                    testId="amount-to-issue"
                    invalidInput={invalidAssetAmountInput}
                />
            </Paper>
        </div>
    );
};

export default EnterAmountToRedeemStep;
