import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';

type RequestListDropdownToggleProps = {
    toggleCondition: boolean;
    onClick: () => void;
};

const RequestListDropdownToggle: React.FC<RequestListDropdownToggleProps> = ({ toggleCondition, onClick }) => {
    const listClasses = useListStyles();
    return (
        <IconButton
            className={`${listClasses.sortKeyButton} ${toggleCondition ? '' : 'expanded'}`}
            onClick={() => onClick()}
            data-testid={'holder_filter_expand'}
        >
            {toggleCondition ? <ExpandMoreIcon color="secondary" /> : <ExpandLessIcon color="secondary" />}
        </IconButton>
    );
};

export default RequestListDropdownToggle;
