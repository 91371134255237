import { Divider, Typography } from '@material-ui/core';
import { NumberFormat } from '@r3/cbdc-asset-frontend-core';

import { useLayoutStyles } from 'materialStyles/layoutStyles';

type MaxValueRuleSectionProps = {
    amount: string;
};

const MaxValueRuleSection: React.FC<MaxValueRuleSectionProps> = ({ amount }) => {
    const layoutClasses = useLayoutStyles();
    return (
        <div style={{ margin: 'auto' }}>
            <Divider className={layoutClasses.summaryDividerSmall} />
            <Typography className={`${layoutClasses.text}`}>Compliance Controls </Typography>
            <Divider className={layoutClasses.summaryDividerSmall} />
            <Typography className={`${layoutClasses.text}`}>
                Maximum Transaction value with this asset {NumberFormat.addThousandSeparators(amount)}
            </Typography>
        </div>
    );
};

export default MaxValueRuleSection;
