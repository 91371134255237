import ExchangeOrderComponent from './ExchangeOrderComponent';
import { Order } from 'models/orders/Order';
import RequestList from 'components/commonComponents/RequestListComponents/RequestList';
import RequestListDivider from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDivider';
import RequestListDropdownSection from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownSection';
import RequestListDropdownTitle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownTitle';
import RequestListDropdownToggle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownToggle';
import sortOrdersDescBy from 'components/decentralizedExchange/sortOrdersBy';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type OffersListProps = {
    sortedOrderGroups: [string, Order[]][];
    onSelected: (offer: Order) => void;
};

const OrdersList: React.FC<OffersListProps> = ({ sortedOrderGroups, onSelected }) => {
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const { t } = useTranslation();
    useEffect(() => {
        if (sortedOrderGroups.length <= 1) {
            return;
        }
        setCollapesedKeys(sortedOrderGroups.map((group) => group[0]));
    }, [sortedOrderGroups]);

    const handlePublicKeyClick = (key: string) => {
        const allKeys = sortedOrderGroups.map((group) => group[0]);
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys(allKeys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };
    return (
        <>
            <RequestListDivider />
            <RequestList>
                {sortedOrderGroups.length > 0 &&
                    sortedOrderGroups.map(([key, items]) => {
                        const publickKeyElement: JSX.Element = (
                            <RequestListDropdownSection>
                                <RequestListDropdownTitle>
                                    {
                                        <b>
                                            {`${t('liquiditySavings.cbdcPair')}: ${key} | ${t(
                                                'liquiditySavings.offers'
                                            )}: ${items.length}`}
                                        </b>
                                    }
                                </RequestListDropdownTitle>
                                <RequestListDropdownToggle
                                    toggleCondition={collapsedKeys.includes(key)}
                                    onClick={() => handlePublicKeyClick(key)}
                                />
                            </RequestListDropdownSection>
                        );

                        if (collapsedKeys.includes(key)) {
                            return <div key={key}>{publickKeyElement} </div>;
                        }

                        return (
                            <div key={key}>
                                {publickKeyElement}
                                {sortOrdersDescBy(items, 'lastUpdated').map((offer) => {
                                    return (
                                        <ExchangeOrderComponent
                                            key={offer.orderId}
                                            order={offer}
                                            onClick={() => onSelected(offer)}
                                        />
                                    );
                                })}
                            </div>
                        );
                    })}
            </RequestList>
            <RequestListDivider />
        </>
    );
};

export default OrdersList;
