import 'date-fns';

import { NumberFormat, useTableStyles } from '@r3/cbdc-asset-frontend-core';
import { Order, getComparator, preserveOrderSort } from './sorting';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';

import React from 'react';

export interface HeadCell<T> {
    disablePadding: boolean;
    id: keyof T;
    label: string;
    numeric: boolean;
    date?: boolean;
    formatDecimals?: boolean;
}

interface EnhancedTableProps<T> {
    classes: ReturnType<typeof useTableStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    order: Order;
    orderBy: keyof T;
    rowCount: number;
    headCells: HeadCell<T>[];
}

function EnhancedTableHead<T extends object>(props: EnhancedTableProps<T>) {
    const { classes, order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className={classes.tableHeader}>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id as string}
                        align={headCell.numeric ? 'right' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            className={orderBy === headCell.id ? classes.activeSortLabel : classes.inactiveSortLabel}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

type TableProps<T> = {
    onClickRow: (row: T) => void;
    totalResults: number;
    page: number;
    setPage: (page: number) => void;
    data: T[];
    headCells: HeadCell<T>[];
    defaultSortBy: keyof T;
    defaultOrder?: Order;
};

export const EnhancedTable = <T extends object>(props: TableProps<T>) => {
    const { onClickRow, page, setPage, data, defaultSortBy, headCells, defaultOrder } = props;
    const classes = useTableStyles();
    const [order, setOrder] = React.useState<Order>(defaultOrder ? defaultOrder : Order.Descending);
    const [orderBy, setOrderBy] = React.useState<keyof T>(defaultSortBy);
    const [rowsPerPage] = React.useState(10);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
        const sortAsc = orderBy === property && order === Order.Descending;
        setOrder(sortAsc ? Order.Ascending : Order.Descending);
        setOrderBy(property);
        setPage(0);
    };

    const handleClick = (event: React.MouseEvent<unknown>, row: T) => {
        onClickRow(row);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getCellStyle = (index: number, row: T, cellCount: number) => {
        if (index === 0 && row['rowColor']) {
            return { borderLeft: `solid 6px ${row['rowColor']}` };
        }
        if (index === cellCount - 1 && row['rowColor']) {
            return { borderRight: `solid 6px ${row['rowColor']}` };
        }
        return {};
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
        <Paper elevation={10} className={classes.root}>
            <div className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead<T>
                            headCells={headCells}
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={10}
                        />
                        <TableBody>
                            {preserveOrderSort<T>(data, getComparator<T>(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="row"
                                            tabIndex={-1}
                                            key={row.toString() + index}
                                            className={classes.tableRow}
                                        >
                                            {headCells.map((headCell, index) => {
                                                let displayVal = row[headCell.id] as unknown as string;
                                                if (headCell.date) {
                                                    displayVal = new Date(displayVal).toLocaleString();
                                                }
                                                if (
                                                    headCell.numeric &&
                                                    headCell.formatDecimals &&
                                                    !isNaN(parseFloat(displayVal))
                                                ) {
                                                    displayVal = NumberFormat.addThousandSeparators(
                                                        parseFloat(displayVal).toFixed(
                                                            row['tokenDefinition']['decimals']
                                                        )
                                                    );
                                                }

                                                return (
                                                    <TableCell
                                                        style={getCellStyle(index, row, headCells.length)}
                                                        key={index}
                                                        align={headCell.numeric ? 'right' : 'center'}
                                                    >
                                                        {displayVal}
                                                    </TableCell>
                                                );
                                            })}
                                            {/* <TableCell component="th" id={labelId} scope="row" align="center">
                                                {row.command}
                                            </TableCell>
                                            <TableCell align="center">{row.tokenDefinition.tokenName}</TableCell>
                                            <TableCell align="right">
                                                {row.amount !== null && row.amount !== 'N/A'
                                                    ? NumberFormat.addThousandSeparators(
                                                          parseFloat(row.amount.toString()).toFixed(
                                                              row.tokenDefinition.decimals
                                                          )
                                                      )
                                                    : 'N/A'}
                                            </TableCell>
                                            <TableCell align="right">{row.usageCount}</TableCell>
                                            <TableCell align="center">{row.holder}</TableCell>
                                            <TableCell align="center">{row.counterParty}</TableCell>
                                            <TableCell align="center">
                                                {new Date(row.txTime).toLocaleString()}
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    className={`${classes.tableHeader} emptyRow`}
                                    style={{ height: 50 * emptyRows }}
                                    data-testid={'table_row'}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    classes={{ root: classes.paginationRoot }}
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </Paper>
    );
};
