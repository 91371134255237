import {
    Asset,
    ComplianceRuleKey,
    EMPTY_SUMMED_STATE,
    INITIAL_EMPTY_ASSET_STATE,
    StepperFormStep,
    SummedAsset,
} from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import AssetAmountsStep from 'components/cbdcManager/RequestCrossChainSwap/AssetsAmountsStep';
import AssetTransforms from 'utils/AssetTransforms';
import { Fade } from '@material-ui/core';
import ReviewOrderStep from './ReviewOrderStep';
import SelectAssetsStep from './SelectAssetsStep';
import SelectParticipantsStep from './SelectParticipantsStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { createOrder } from 'api/decentralizedExchange';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import useGetParticipantsOnNetwork from 'hooks/GetParticipantsOnNetwork';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const CreateOrder = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>();
    const { assets, getAllAssets, assetsError } = useGetAssetDefinitions(true);
    const { assetSummary, summaryError, getAssetSummary } = useGetAssetSummary(true);
    const [assetsForTransfer, setAssetsForTransfer] = useState<SummedAsset[]>([]);
    const [selectedAsset, setSelectedAsset] = useState<SummedAsset>(EMPTY_SUMMED_STATE);
    const [assetAmount, setAssetAmount] = useState<string>('');
    const [selectedSwapAsset, setSelectedSwapAsset] = useState<Asset>(INITIAL_EMPTY_ASSET_STATE);
    const [swapAssetAmount, setSwapAssetAmount] = useState<string>('');
    const { participants } = useGetParticipantsOnNetwork(true);
    const [selectedParticipants, setSelectedParticipants] = useState<string[]>([]);
    const interactiveCheckListContext = useInteractiveChecklistContext();

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    useEffect(() => {
        setError(summaryError);
    }, [summaryError]);

    useEffect(() => {
        setAssetsForTransfer(AssetTransforms.summedAssetsFromSummary(assetSummary, assets));
    }, [assetSummary, assets]);

    const submitRequest = async () => {
        const response = await createOrder(
            selectedSwapAsset.tokenIdentifier,
            parseFloat(swapAssetAmount),
            selectedAsset.tokenIdentifier,
            parseFloat(assetAmount),
            selectedParticipants
        );

        if (response.error) {
            enqueueSnackbar(t('error.creatingExchangeOffer', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.creatingExchangeOffer'), {
                variant: 'success',
            });
            interactiveCheckListContext?.completeStep(window.location.pathname);
        }
        handleReset();
    };

    const invalidAssetAmountInput = (asset: SummedAsset | Asset, amount: string, totalInVault?: number): boolean => {
        if (amount.length <= 0 || parseFloat(amount) <= 0) {
            return true;
        }
        if (
            asset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) &&
            parseFloat(asset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)!.value) <
                parseFloat(amount)
        ) {
            return true;
        }
        if (typeof totalInVault !== 'undefined') {
            if (totalInVault < parseFloat(amount)) {
                return true;
            }
        }
        return false;
    };

    const selectedAssetForTransfer = assetsForTransfer.find(
        (asset) => asset.tokenIdentifier === selectedAsset.tokenIdentifier
    );

    const wholesaleParticipants = participants.participants.filter((participant) => !participant.includes('CB'));

    const steps: StepperFormStep[] = [
        {
            title: t('crossChainSwap.selectAssetsStep'),
            component: (
                <SelectAssetsStep
                    assets={assets}
                    transferableAssets={assetsForTransfer}
                    selectedAsset={selectedAsset}
                    selectedSwapAsset={selectedSwapAsset}
                    setSelectedAsset={setSelectedAsset}
                    setSelectedSwapAsset={setSelectedSwapAsset}
                />
            ),
            isNextStepDisabled: () =>
                !(
                    selectedAsset.tokenIdentifier.length > 0 &&
                    selectedSwapAsset.tokenIdentifier.length > 0 &&
                    selectedAsset.tokenIdentifier !== selectedSwapAsset.tokenIdentifier &&
                    selectedAsset.availableToTransact > 0
                ),
        },
        {
            title: t('crossChainSwap.assetAmountsStep'),
            component: (
                <AssetAmountsStep
                    selectedAsset={selectedAsset}
                    selectedAssetAmountInVault={selectedAssetForTransfer?.totalInVault}
                    selectedAssetAvailableToTransact={selectedAssetForTransfer?.availableToTransact}
                    selectedSwapAsset={selectedSwapAsset}
                    assetAmount={assetAmount}
                    swapAmount={swapAssetAmount}
                    setAssetAmount={setAssetAmount}
                    setSwapAmount={setSwapAssetAmount}
                    invalidAssetInput={invalidAssetAmountInput}
                />
            ),
            isNextStepDisabled: () => {
                if (
                    !invalidAssetAmountInput(
                        selectedAsset,
                        assetAmount,
                        selectedAssetForTransfer?.availableToTransact
                    ) &&
                    !invalidAssetAmountInput(selectedSwapAsset, swapAssetAmount)
                ) {
                    return false;
                }
                return true;
            },
        },
        {
            title: t('liquiditySavings.selectParticipantsStep'),
            component: (
                <SelectParticipantsStep
                    participants={wholesaleParticipants}
                    setSelectedParticipants={setSelectedParticipants}
                />
            ),
            isNextStepDisabled: () => selectedParticipants.length === 0,
        },
        {
            title: t('liquiditySavings.reviewStep'),
            component: (
                <ReviewOrderStep
                    swapAsset={selectedSwapAsset}
                    swapAssetAmount={swapAssetAmount}
                    selectedAsset={selectedAsset}
                    assetAmount={assetAmount}
                    assetInVaultAmount={
                        assetsForTransfer.find((asset) => asset.tokenIdentifier === selectedAsset.tokenIdentifier)
                            ?.totalInVault
                    }
                    originalParticpantTotal={wholesaleParticipants.length}
                    selectedParticipants={selectedParticipants}
                />
            ),
            isNextStepDisabled: () => false,
        },
    ];

    const handleReset = () => {
        setSelectedAsset(EMPTY_SUMMED_STATE);
        setSelectedSwapAsset(INITIAL_EMPTY_ASSET_STATE);
        setAssetAmount('');
        setSwapAssetAmount('');
        setError('');
        getAllAssets();
        getAssetSummary();
    };

    return (
        <Fade in={true}>
            <StepperForm
                submit={submitRequest}
                steps={steps}
                renderError={error}
                submitButtonText={'List Offer'}
                handleReset={handleReset}
            />
        </Fade>
    );
};

export default CreateOrder;
