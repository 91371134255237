import { useContext, useEffect } from 'react';

import { IconButton } from '@material-ui/core';
import { RetailPayementRequestsContext } from '../../../contexts/RetailPaymentRequestContext/RetailPaymentRequestContext';
import RetailPaymentItem from './RetailPaymentItem';
import SyncRoundedIcon from '@material-ui/icons/SyncRounded';
import useGetRetailAssetDefinitions from '../../../hooks/GetRetailAssetDefinitions';
import { useRetailAssetsStyles } from 'materialStyles/retailStyles/retailAssetsStyles';
import { useRetailItemStyles } from 'materialStyles/retailStyles/retailItemStyles';

const RetailPaymentsList = () => {
    const retailAssetStyles = useRetailAssetsStyles();
    const retailItemStyles = useRetailItemStyles();
    const { paymentRequests, refreshPaymentRequests, error, loading } = useContext(RetailPayementRequestsContext);
    const { getAllAssets, getAssetbyId } = useGetRetailAssetDefinitions();

    useEffect(() => {
        refreshPaymentRequests();
        getAllAssets();
    }, [getAllAssets, refreshPaymentRequests]);

    return (
        <div className={retailItemStyles.sectionWrapper}>
            <div className={retailItemStyles.actionIconWrapper}>
                <IconButton
                    className={retailItemStyles.actionButton}
                    onClick={() => {
                        refreshPaymentRequests();
                        getAllAssets();
                    }}
                >
                    <SyncRoundedIcon data-testid={'refresh_payments'} className={retailItemStyles.actionIcon} />
                </IconButton>
            </div>
            <div className={retailItemStyles.list}>
                {(!paymentRequests || (paymentRequests && paymentRequests.length === 0)) && (
                    <div className={retailAssetStyles.noAssetsText}>
                        {error.length > 0 ? error : loading ? 'Loading' : 'No payment requests on record'}
                    </div>
                )}
                {paymentRequests &&
                    paymentRequests.map((request) => {
                        return (
                            <RetailPaymentItem
                                key={request.requestId}
                                request={request}
                                asset={getAssetbyId(request.tokenIdentifier)}
                            />
                        );
                    })}
            </div>
        </div>
    );
};

export default RetailPaymentsList;
