import {
    CANCELLED_COLOR,
    FILLED_COLOR,
    OPEN_COLOR,
    PARTIALLY_FILLED_COLOR,
} from 'components/decentralizedExchange/colors';

import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { Order } from 'models/orders/Order';
import { OrderStatus } from 'models/orders/OrderStatus';
import RequestListContainer from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListContainer';
import RequestListItem from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListItem';
import { isOrderPartiallyFilled } from 'components/decentralizedExchange/offerLog/offerLogUtils';
import { useTranslation } from 'react-i18next';

type ExchangeOfferComponentProps = {
    order: Order;
    onClick: () => void;
    showStatus?: boolean;
};

const ExchangeOrderComponent: React.FC<ExchangeOfferComponentProps> = ({ order, onClick, showStatus = false }) => {
    const { t } = useTranslation();

    const getBorderColor = (status: OrderStatus): string => {
        switch (status) {
            case OrderStatus.OPEN:
                return OPEN_COLOR;
            case OrderStatus.UNLOCKED:
                if (isOrderPartiallyFilled(order)) return PARTIALLY_FILLED_COLOR;
                return FILLED_COLOR;
            case OrderStatus.CANCELLED:
                return CANCELLED_COLOR;
            default:
                return '';
        }
    };
    return (
        <RequestListContainer borderColor={getBorderColor(order.status)} onClick={() => onClick()}>
            <RequestListItem>
                <b>{t('liquiditySavings.offerId') + ': '}</b>
                {order.orderId}
            </RequestListItem>
            <RequestListItem>
                <b>{t('liquiditySavings.currencyRequested') + ': '}</b>
                {`${order.requestedTokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                    order.requestedAssetAmount.toFixed(order.requestedTokenDefinition.tokenDecimals)
                )}`}
            </RequestListItem>
            <RequestListItem>
                <b>{t('liquiditySavings.currencyOffered') + ': '}</b>
                {`${order.offeredTokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                    order.offeredAssetAmount.toFixed(order.offeredTokenDefinition.tokenDecimals)
                )}`}
            </RequestListItem>
            {order.receiver != null && order.receiver.length > 0 && (
                <RequestListItem>
                    <b>{t('liquiditySavings.offerTaker') + ': '}</b>
                    {order.receiver}
                </RequestListItem>
            )}
            {showStatus && (
                <RequestListItem>
                    <b>{t('liquiditySavings.offerStatus') + ': '}</b> {order.status}
                </RequestListItem>
            )}
            <RequestListItem>
                <b>{t('commonText.txTime') + ': '}</b> {new Date(order.lastUpdated).toLocaleString()}
            </RequestListItem>

            {order.fillAmount > 0 && (
                <RequestListItem>
                    <b>{t('liquiditySavings.fillAmount') + ': '}</b>
                    {order.offeredTokenDefinition.tokenName}:{' '}
                    {NumberFormat.addThousandSeparators(
                        order.fillAmount.toFixed(order.offeredTokenDefinition.tokenDecimals)
                    )}
                </RequestListItem>
            )}
        </RequestListContainer>
    );
};

export default ExchangeOrderComponent;
