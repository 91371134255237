import { Button } from '@material-ui/core';
import { useLandingPageStyles } from '@r3/cbdc-asset-frontend-core';

export type TabButtonProps = {
    selected: boolean;
    icon: React.ReactNode;
    text: string;
    onClick: () => void;
};

const TabButton: React.FC<TabButtonProps> = ({ selected, icon, onClick, text }) => {
    const classes = useLandingPageStyles();
    return (
        <Button
            className={` ${classes.tabButton} ${selected ? classes.selectedTab : ''} tab`}
            onClick={onClick}
            endIcon={icon}
        >
            {text}
        </Button>
    );
};

export default TabButton;
