import { DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';

import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import { Button } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import PaymentRoundedIcon from '@material-ui/icons/PaymentRounded';
import RequestPayment from './RequestPayment/RequestPayment';
import RetailPay from './RetailPay/RetailPay';
import { useRetailStickyButtonsStyles } from 'materialStyles/retailStyles/retailStickyButtonsStyles';
import { useState } from 'react';

const RetailStickyButtons = () => {
    const classes = useRetailStickyButtonsStyles();
    return (
        <Paper elevation={10} className={classes.bar}>
            <RequestPaymentButton />
            <ScanQRButton />
        </Paper>
    );
};

export const MakePaymentButton = () => {
    const classes = useRetailStickyButtonsStyles();
    return (
        <Button
            size="large"
            className={classes.button}
            startIcon={<ArrowRightAltRoundedIcon className={classes.buttonIcon} />}
        >
            Send
        </Button>
    );
};

export const RequestPaymentButton: React.FC = () => {
    const classes = useRetailStickyButtonsStyles();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    return (
        <>
            <Button
                size="large"
                className={classes.button}
                startIcon={<AddCircleRoundedIcon className={classes.buttonIcon} />}
                onClick={() => {
                    setIsDialogOpen(true);
                }}
                data-testid={'request_payment_button'}
            >
                Request
            </Button>
            <DialogWindow
                open={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                title={'Request Payment'}
            >
                <DialogBody>
                    <RequestPayment />
                </DialogBody>
            </DialogWindow>
        </>
    );
};

export const ScanQRButton = () => {
    const classes = useRetailStickyButtonsStyles();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                size="large"
                className={classes.button}
                startIcon={<PaymentRoundedIcon className={classes.buttonIcon} />}
                onClick={() => {
                    setIsDialogOpen(true);
                }}
                data-testid={'payment_button'}
            >
                Pay
            </Button>
            <DialogWindow
                open={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                title={'Make a payment'}
            >
                <DialogBody>
                    <RetailPay />
                </DialogBody>
            </DialogWindow>
        </>
    );
};

export default RetailStickyButtons;
