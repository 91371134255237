import { Divider, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core';

import { Asset } from '@r3/cbdc-asset-frontend-core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type SelectAvailableAssetStepProps = {
    selectedAsset: Asset;
    handleAssetChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    assets: Asset[];
    selectedParticipant: string;
};

const SelectAvailableAssetStep: React.FC<SelectAvailableAssetStepProps> = (props) => {
    const { selectedAsset, handleAssetChange, assets, selectedParticipant } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('requestKyc.selectAvailableAsset')}
                </Typography>

                <Divider className={layoutClasses.summaryDivider} />
                <FormControl
                    id="SelectAsset"
                    variant="outlined"
                    className={`${layoutClasses.selectInput} ${layoutClasses.columnNoPadding}`}
                >
                    <InputLabel id="SelectLabel">Asset</InputLabel>
                    <Select
                        labelId="SelectLabel"
                        value={selectedAsset.tokenName}
                        onChange={handleAssetChange}
                        data-testid={'asset-select'}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {assets.map((as, i) => {
                            return (
                                <MenuItem key={as.tokenName + i} value={as.tokenName} data-testid={'asset-option'}>
                                    {as.tokenName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <Typography className={`${layoutClasses.text}`}>
                    {t('requestKyc.issuingAuthority') + ':'}{' '}
                    {selectedParticipant.length > 0 ? selectedParticipant : t('requestKyc.noAssetSelected')}
                </Typography>
            </Paper>
        </div>
    );
};

export default SelectAvailableAssetStep;
