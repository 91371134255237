import { Asset, NumberFormat, PvpRequest } from '@r3/cbdc-asset-frontend-core';

import RequestListContainer from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListContainer';
import RequestListItem from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListItem';
import { getPermittedNotaries } from 'components/cbdcManager/RequestCrossChainSwap/getPermittedNotaries';
import { getRequestStatusColorWithId } from '../requestStatusColors';
import { getRequestStatusText } from '../requestStatusText';
import { useTranslation } from 'react-i18next';

type CrossChainSwapRequestProps = {
    request: PvpRequest;
    onClick: () => void;
    getAssetById: (assetId: string) => Asset;
};

const CrossChainSwapRequest: React.FC<CrossChainSwapRequestProps> = ({ onClick, request, getAssetById }) => {
    const { t } = useTranslation();
    return (
        <RequestListContainer onClick={() => onClick()}>
            <RequestListItem>
                {t('crossChainSwapRequest.assetRequested') + ': '}
                {request.tokenDefinition.tokenName}
            </RequestListItem>
            <RequestListItem>
                {t('crossChainSwapRequest.requestedAssetAmount') + ': '}
                {NumberFormat.addThousandSeparators(request.assetAmount.toString())}
            </RequestListItem>
            <RequestListItem>
                {t('crossChainSwapRequest.requestedAssetNotary') + ': '}
                {getPermittedNotaries(getAssetById(request.tokenDefinition.tokenIdentifier).complianceRules).join(
                    ' | '
                )}
            </RequestListItem>
            <RequestListItem>
                {t('crossChainSwapRequest.offeredAsset') + ': '}
                {request.offeredTokenDefinition.tokenName}
            </RequestListItem>
            <RequestListItem>
                {t('crossChainSwapRequest.offeredAssetAmount') + ': '}
                {NumberFormat.addThousandSeparators(request.offeredAssetAmount)}
            </RequestListItem>
            <RequestListItem>
                {t('crossChainSwapRequest.offeredAssetNotary') + ': '}
                {getPermittedNotaries(
                    getAssetById(request.offeredTokenDefinition.tokenIdentifier).complianceRules
                ).join(' | ')}
            </RequestListItem>
            <RequestListItem>
                <span>{t('commonText.status') + ': '}</span>
                <span
                    style={{
                        color: getRequestStatusColorWithId(request.requestState, request.txId),
                    }}
                >
                    {getRequestStatusText(request.requestState, request.txId)}
                </span>
            </RequestListItem>
            <RequestListItem>
                {'Requestee: '}
                {request.seller}
            </RequestListItem>
        </RequestListContainer>
    );
};

export default CrossChainSwapRequest;
