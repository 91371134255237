import { Divider, Fade, Paper } from '@material-ui/core';
import { KycRequest, KycState } from '@r3/cbdc-asset-frontend-core';
import React, { useCallback, useEffect, useState } from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import { KycRequestDialogue } from './KycRequestDialogue';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { ResolvedPromise } from 'api/resolvePromise';
import { requestAllKycRequests } from 'api/kycApi';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const KycRequests = () => {
    const listClasses = useListStyles();
    const layoutClasses = useLayoutStyles();
    const [kycRequests, setKycRequests] = useState<Map<string, KycState[]>>();
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>('');

    const [selectedKycRequest, setSelectedKycRequest] = useState<KycRequest | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const { getAssetNamebyId, getAllAssets } = useGetAssetDefinitions();

    const loadStates = useCallback(async () => {
        const kycStatesResponse: ResolvedPromise = await requestAllKycRequests();
        if (kycStatesResponse.error) {
            enqueueSnackbar(t('error.gettingKycStates', { error: kycStatesResponse.error }), {
                variant: 'error',
            });
            setError(kycStatesResponse.error.length > 0 ? kycStatesResponse.error : t('error.networkError'));
        } else {
            if (kycStatesResponse.data.length <= 0) {
                const warningText = t('listKyc.noKycRequests');
                enqueueSnackbar(warningText, {
                    variant: 'info',
                });
                setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
            }

            let kycStatesByIssuer = (kycStatesResponse.data as KycState[]).groupBy('issuer') as Map<string, KycState[]>;
            setKycRequests(kycStatesByIssuer);
            const reqKeys = Array.from(kycStatesByIssuer.keys());
            if (reqKeys.length === 1) {
                setCollapesedKeys([]);
            } else {
                setCollapesedKeys(reqKeys);
            }
        }
    }, [t, enqueueSnackbar]);

    useEffect(() => {
        loadStates();
        getAllAssets();
    }, [loadStates, getAllAssets]);

    const openInfoModal = (kycRequest: KycRequest) => {
        setSelectedKycRequest(kycRequest);
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedKycRequest(null);
        setModalOpen(false);
    };

    const handleIssuerClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };

    return (
        <Fade in={true}>
            <div className={`${layoutClasses.componentWrapper} `}>
                <Paper elevation={5} className={`${layoutClasses.column} `}>
                    {error.length > 0 ? (
                        <ListHeader
                            text={error}
                            refreshAction={() => {
                                setError('');
                                loadStates();
                            }}
                        />
                    ) : (
                        <>
                            <ListHeader text={t('listKyc.listOfRequests')} refreshAction={loadStates} />
                            <Divider className={listClasses.divider} />
                            <div className={listClasses.list}>
                                {kycRequests !== undefined &&
                                    kycRequests.mapGroups((issuer, items) => {
                                        const issuerParentElement: JSX.Element = (
                                            <div
                                                className={`${listClasses.sortKey} holderGrouping`}
                                                data-testid={'holder_filter'}
                                            >
                                                <div>
                                                    <p className="stateHolderName" data-testid={'holder_filter_name'}>
                                                        <b>{t('listKyc.issuer') + ': '} </b>
                                                        {issuer}
                                                    </p>
                                                </div>
                                                <IconButton
                                                    className={listClasses.sortKeyButton}
                                                    onClick={() => handleIssuerClick(issuer)}
                                                    data-testid={'holder_filter_expand'}
                                                >
                                                    {collapsedKeys.includes(issuer) ? (
                                                        <ExpandMoreIcon color="secondary" />
                                                    ) : (
                                                        <ExpandLessIcon color="secondary" />
                                                    )}
                                                </IconButton>
                                            </div>
                                        );

                                        if (collapsedKeys.includes(issuer)) {
                                            return <div key={issuer}>{issuerParentElement} </div>;
                                        }

                                        return (
                                            <div key={issuer + '_list_wrapper'}>
                                                {issuerParentElement}
                                                {items.map((kycRequest) => {
                                                    return (
                                                        <div
                                                            data-testid={'request_container'}
                                                            key={issuer + '_' + kycRequest.id.id}
                                                            className={`${listClasses.assetItem} ${listClasses.clickableItem}`}
                                                            onClick={() => openInfoModal(kycRequest)}
                                                        >
                                                            <div className={listClasses.text}>
                                                                {t('defineAsset.assetName') + ': '}
                                                                {getAssetNamebyId(kycRequest.tokenIdentifier)}
                                                            </div>
                                                            <div className={listClasses.text}>
                                                                {t('commonText.assetId') + ': '}
                                                                {kycRequest.tokenIdentifier}
                                                            </div>
                                                            <div className={listClasses.text}>
                                                                {t('commonText.uniqueId') + ': '}
                                                                {kycRequest.id.id}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                            </div>
                            <Divider className={listClasses.divider} />

                            {selectedKycRequest && modalOpen && (
                                <KycRequestDialogue
                                    open={modalOpen}
                                    kycRequest={selectedKycRequest}
                                    onClose={handleClose}
                                />
                            )}
                        </>
                    )}
                </Paper>
            </div>
        </Fade>
    );
};
