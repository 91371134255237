import QRCode from 'qrcode.react';
import { ShareRetailAccountDetails } from '@r3/cbdc-asset-frontend-core';
import { useEffect } from 'react';
import useGetRetailParticipantsOnNetwork from '../../../hooks/GetRetailParticipants';

type ShareAccountDetailsProps = {
    accountId: string;
    name: string;
};

const ShareAccountDetails: React.FC<ShareAccountDetailsProps> = ({ accountId, name }) => {
    const { participants, getParticipants } = useGetRetailParticipantsOnNetwork();
    useEffect(() => {
        getParticipants();
    }, [getParticipants]);

    const qrValue: ShareRetailAccountDetails = { accountId: accountId, desc: name, x500: participants.participants[0] };
    const qrValueAsJson = JSON.stringify(qrValue);

    return (
        <div style={{ width: 'auto', display: 'flex' }}>
            <QRCode
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
                value={qrValueAsJson}
                size={250}
                bgColor={'#ffffff'}
                fgColor={'#000000'}
                level={'M'}
                includeMargin={true}
                renderAs={'svg'}
            />
        </div>
    );
};

export default ShareAccountDetails;
