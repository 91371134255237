import { Fade, Paper } from '@material-ui/core';
import { useMemo, useState } from 'react';

import { Comparison } from '@r3/cbdc-asset-frontend-core';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { Order } from 'models/orders/Order';
import OrdersList from './OrdersList';
import { getCurrencyPairString } from 'components/decentralizedExchange/getCurrencyPairString';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

type OffersListWrapperProps = {
    offers: Order[];
    displayMessage: string;
    header: string;
    getDialog: (order: Order, open: boolean, onClose: () => void) => JSX.Element;
};

const OrdersListWrapper: React.FC<OffersListWrapperProps> = ({ offers, displayMessage, header, getDialog }) => {
    const layoutClasses = useLayoutStyles();
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const openInfoModal = (exchangeOffer: Order) => {
        setSelectedOrder(exchangeOffer);
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedOrder(null);
        setModalOpen(false);
    };

    const sortedOffersGroupedByCurrencyPair = useMemo(() => {
        return Array.from(
            offers.reverse().groupByCustomKey((o) => getCurrencyPairString(o)) as Map<string, Order[]>
        ).sort((a, b) => Comparison.compareStrings(a[0], b[0]));
    }, [offers]);

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column}`}>
                        {displayMessage.length > 0 ? (
                            <ListHeader text={displayMessage} />
                        ) : (
                            <>
                                <ListHeader text={header} />
                                <OrdersList
                                    sortedOrderGroups={sortedOffersGroupedByCurrencyPair}
                                    onSelected={openInfoModal}
                                />
                                {selectedOrder && modalOpen && getDialog(selectedOrder, modalOpen, handleClose)}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
        </>
    );
};

export default OrdersListWrapper;
