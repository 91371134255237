import { CHECKLISTACTIONS, CHECKLISTACTIONSWS2, CheckListAction } from '../../constants/InteractiveChecklistList';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import useAppConfigContext from '../AppConfig/AppConfig';

const CHECKLISTSTEPKEY = 'CHECKLISTSTEP';
const CHECKLISTOTAL = 18;

type CheckListContextProps =
    | {
          completeStep: (url: string) => void;
          getAllActions: () => CheckListAction[];
          getCurrentStateStep: () => number;
          currentStep: number;
          skipCurrentStep: () => void;
          goBackOneStep: () => void;
          getChecklistTotal: () => number;
          completed: boolean;
          reset: () => void;
      }
    | undefined;

const InteractiveChecklistContext = React.createContext<CheckListContextProps>(undefined);

export const InteractiveChecklistContextProvider = (props: { children: ReactNode }) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [completed, setCompleted] = useState<boolean>(false);
    const [checkListActions, setCheckListActions] = useState<CheckListAction[]>([]);
    const url = window.location.href;
    const appConfigContext = useAppConfigContext();

    const getAllActions = (): CheckListAction[] => {
        return checkListActions;
    };

    const getChecklistTotal = (): number => {
        return CHECKLISTOTAL;
    };

    const completeStep = (url: string) => {
        if (currentStep >= checkListActions.length) {
            return;
        }

        if (checkListActions[currentStep].url === url) {
            skipCurrentStep();
            setCompleted(true);
            setTimeout(() => {
                setCompleted(false);
            }, 1000);
        }
    };

    const getCurrentStateStep = (): number => {
        return currentStep;
    };

    const skipCurrentStep = () => {
        if (currentStep < checkListActions.length) {
            setCurrentStep(currentStep + 1);
            localStorage.setItem(CHECKLISTSTEPKEY, (currentStep + 1).toString());
        }
    };

    const goBackOneStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
            localStorage.setItem(CHECKLISTSTEPKEY, (currentStep - 1).toString());
        }
    };

    const reset = () => {
        setCurrentStep(0);
        localStorage.setItem(CHECKLISTSTEPKEY, (0).toString());
    };

    useEffect(() => {
        let value = localStorage.getItem(CHECKLISTSTEPKEY);
        if (value) {
            setCurrentStep(parseInt(value));
        } else {
            localStorage.setItem(CHECKLISTSTEPKEY, '0');
            setCurrentStep(parseInt('0'));
        }

        if (appConfigContext?.appConfig.appType.includes('wholesaletwo')) {
            setCheckListActions(CHECKLISTACTIONSWS2);
        } else {
            setCheckListActions(CHECKLISTACTIONS);
        }
    }, [url, appConfigContext?.appConfig.appType]);

    return (
        <InteractiveChecklistContext.Provider
            value={{
                completeStep,
                getCurrentStateStep,
                getAllActions,
                skipCurrentStep,
                goBackOneStep,
                currentStep,
                getChecklistTotal,
                completed,
                reset,
            }}
        >
            {props.children}
        </InteractiveChecklistContext.Provider>
    );
};

export const useInteractiveChecklistContext = () => useContext(InteractiveChecklistContext);
