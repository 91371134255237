import apiCall from './apiCall';

/**
 * @remark
 * Used to get all asset definitions on the network
 *
 * @returns
 * AxiosResponse which resolved in an error or repsonse data
 */
export const requestAllAssetDefinitions = async (dontTrack?: boolean) => {
    return apiCall({ method: 'get', path: '/api/assetDefinitions', dontTrackRequest: dontTrack });
};
