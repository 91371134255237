import React, { useState } from 'react';

import { Button } from '@material-ui/core';
import { GetModalInfoData } from 'constants/InfoModal';
import { InfoModal } from '../InfoModal';
import { useInfoWidgetStyles } from '@r3/cbdc-asset-frontend-core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useLocation } from 'react-router-dom';

type InfoWidgetProps = {
    showing: boolean;
};

export const InfoWidget: React.FC<InfoWidgetProps> = ({ showing }) => {
    const location = useLocation();
    const classes = useInfoWidgetStyles();
    const infoModalPages = GetModalInfoData(location.pathname);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const layoutClasses = useLayoutStyles();

    return (
        <>
            {showing && (
                <div className={classes.widget}>
                    <Button
                        className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize} ${classes.pulsing}`}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            setModalOpen(true);
                        }}
                        data-testid="educational-modal-button"
                    >
                        {'What happened on Corda?'}
                    </Button>
                </div>
            )}
            {infoModalPages.length > 0 && (
                <InfoModal
                    open={modalOpen}
                    onClose={() => {
                        setModalOpen(false);
                    }}
                    pages={infoModalPages}
                />
            )}
        </>
    );
};
