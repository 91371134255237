import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useRetailAssetsStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            assetList: {
                alignSelf: 'flex-start',
                width: '100%',
                maxWidth: 500,
                height: 260,
                overflow: 'auto',
                animation: `$modalZoom 500ms`,
            },
            '@keyframes modalZoom': {
                '0%': {
                    opacity: 0,
                    transform: 'scale(0.2)',
                },
                '100%': {
                    opacity: 1,
                    transform: 'scale(1)',
                },
            },
            assetWrapper: { display: 'flex', margin: '10px auto 10px auto', minWidth: 300 },
            assetNameText: {
                fontSize: 18,
                fontWeight: 600,
                color: theme.palette.primary.contrastText,
                margin: 'auto auto auto 8px',
                textAlign: 'left',
            },
            amountText: {
                fontSize: 22,
                color: theme.palette.primary.contrastText,
                margin: 'auto 0px auto auto',
                textAlign: 'right',
            },
            assetIcon: {
                minWidth: 38,
                minHeight: 38,
                textAlign: 'center',
                paddingTop: 6,
                fontWeight: 900,
                fontSize: 18,
                marginRight: 6,
                boxShadow: '1px 2px 2px 2px rgba(0,0,0,0.3)',
            },
            noAssetsText: {
                fontSize: 32,
                fontWeight: 800,
                color: theme.palette.primary.contrastText,
            },
        }),
    { index: 1 }
);
