import { Button, IconButton, Modal, Slide, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import { YoutubeEmbedded } from './YoutubeEmbedded/YoutubeEmbedded';
import { useInfoModalStyles } from '@r3/cbdc-asset-frontend-core';

export type InfoModalPage = {
    headerText?: string;
    videoId?: string;
    imageUrl?: string;
    docsLink?: string;
};

type InfoModalProps = {
    open: boolean;
    onClose: () => void;
    pages: InfoModalPage[];
};

export const InfoModal: React.FC<InfoModalProps> = ({ pages, open, onClose }) => {
    const classes = useInfoModalStyles();
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<InfoModalPage>(pages[currentPageIndex]);

    const nextPage = () => {
        if (currentPageIndex + 1 <= pages.length) {
            setCurrentPageIndex(currentPageIndex + 1);
        }
    };

    const previousPage = () => {
        if (currentPageIndex - 1 >= 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    useEffect(() => {
        setCurrentPage(pages[currentPageIndex]);
    }, [currentPageIndex, pages]);

    return (
        <>
            <Modal
                open={open}
                onClose={() => {
                    onClose();
                    setCurrentPageIndex(0);
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <div className={classes.bodyWrapper}>
                    <Slide direction="right" in={true} timeout={{ enter: 1000 }}>
                        <Tooltip
                            title={currentPageIndex === 0 ? '' : pages[currentPageIndex - 1]!.headerText!}
                            placement="top"
                            className={classes.buttonWrapper}
                            style={{ marginRight: 'auto' }}
                        >
                            <span>
                                <Button
                                    id="PreviousPage"
                                    variant="outlined"
                                    onClick={previousPage}
                                    disabled={currentPageIndex <= 0}
                                    className={`${classes.indexButton}`}
                                    startIcon={<ArrowBackIcon className={classes.indexButtonSvg} />}
                                    data-testid="educational-modal-prev-button"
                                />
                            </span>
                        </Tooltip>
                    </Slide>
                    <div className={classes.pageWrapper}>
                        <div className={classes.headerText}>
                            {currentPage.headerText && currentPage.headerText?.length > 0
                                ? currentPage.headerText
                                : currentPageIndex === 0
                                ? 'High Level Diagram'
                                : 'Corda Flow Sequence'}
                        </div>
                        <Slide direction="up" in={true} timeout={{ enter: 800, exit: 600 }}>
                            <div className={classes.imageWrapper}>
                                {currentPage.videoId ? (
                                    <YoutubeEmbedded embedId={currentPage.videoId} />
                                ) : (
                                    <img
                                        alt="Corda"
                                        className={classes.image}
                                        src={currentPage.imageUrl}
                                        data-testid="diagram-image"
                                    ></img>
                                )}
                            </div>
                        </Slide>
                        <Tooltip title="Close Modal" placement="top" className={classes.closeButtonWrapper}>
                            <IconButton id="CloseButton" onClick={onClose} className={`${classes.indexButton}`}>
                                <CloseIcon className={classes.indexButtonSvg} />
                            </IconButton>
                        </Tooltip>
                        <Slide direction="up" in={true} timeout={{ enter: 1000, exit: 600 }}>
                            <div className={classes.learnMore}>
                                <span
                                    onClick={() => {
                                        window.open(currentPage.docsLink, '_blank');
                                    }}
                                    className={classes.learnMoreText}
                                >
                                    Learn More: Sandbox Documentation
                                </span>
                            </div>
                        </Slide>
                    </div>
                    <Slide direction="left" in={true} timeout={{ enter: 1000, exit: 600 }}>
                        <Tooltip
                            title={
                                currentPageIndex === pages.length - 1 ? '' : pages[currentPageIndex + 1]!.headerText!
                            }
                            placement="top"
                            className={classes.buttonWrapper}
                            style={{ marginLeft: 'auto' }}
                        >
                            <span>
                                <Button
                                    id="NextPage"
                                    variant="outlined"
                                    onClick={nextPage}
                                    disabled={currentPageIndex === pages.length - 1}
                                    className={`${classes.indexButton}`}
                                    endIcon={<ArrowForwardIcon className={classes.indexButtonSvg} />}
                                    data-testid="educational-modal-next-button"
                                />
                            </span>
                        </Tooltip>
                    </Slide>
                </div>
            </Modal>
        </>
    );
};
