import { Paper } from '@material-ui/core';
import React from 'react';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useRetailHomeStyles } from 'materialStyles/retailStyles/retailHomeStyles';

type RetailSectionProps = {
    className?: string;
};

const RetailSection: React.FC<RetailSectionProps> = ({ children, className }) => {
    const layoutClasses = useLayoutStyles();
    const classes = useRetailHomeStyles();
    return (
        <Paper elevation={5} className={`${layoutClasses.column} ${classes.retailSection} ${className}`}>
            {children}
        </Paper>
    );
};

export default RetailSection;
