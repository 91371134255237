import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { useContext, useState } from 'react';

import { AccountBalance } from '../../../hooks/GetCustomerDetails';
import { Button } from '@material-ui/core';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import MaxValueRuleSection from 'components/commonComponents/MaxValueRuleSection/MaxValueRuleSection';
import { RetailTransactionsContext } from '../../../contexts/RetailTransactionsContext/RetailTransactionsContext';
import { transferToPayee } from '../../../api/retailApi';
import { useFormStyles } from 'materialStyles/formStyles';
import useGetRetailAssetDefinitions from '../../../hooks/GetRetailAssetDefinitions';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useUserContextProvider } from '../../../contexts/RetailUserLogin/RetailUserContext';
import { ComplianceRuleKey, NumberFormat } from '@r3/cbdc-asset-frontend-core';

type DepositProps = {
    payeeId: string;
    finishedTransfer: () => void;
};

const RetailTransfer: React.FC<DepositProps> = ({ payeeId, finishedTransfer }) => {
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const formClasses = useFormStyles();
    const { enqueueSnackbar } = useSnackbar();
    const userContext = useUserContextProvider();
    const [selectedAsset, setSelectedAsset] = useState<AccountBalance | null>(null);
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const [isTransferPending, setIsTransferPending] = useState<boolean>(false);
    const { refreshTransactions } = useContext(RetailTransactionsContext);
    const { getAssetbyId } = useGetRetailAssetDefinitions(true);

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = userContext?.customerDetails?.accountBalances.find(
            (asset) => asset.tokenDefinition.tokenName === (event.target.value as string)
        );
        if (asset) {
            setSelectedAsset(asset);
        } else {
            setSelectedAsset(null);
        }
        setAssetAmount('');
    };

    const submit = async () => {
        if (!selectedAsset) {
            return;
        }
        setIsTransferPending(true);
        const response = await transferToPayee(
            payeeId,
            selectedAsset.tokenDefinition.tokenIdentifier,
            parseFloat(assetAmount)
        );

        if (response.error) {
            enqueueSnackbar(t('error.retailPushTransfer', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.retailPushTransfer', { error: response.error }), {
                variant: 'success',
            });
        }
        finishedTransfer();
        userContext?.refreshCustomer();
        refreshTransactions();
        setIsTransferPending(false);
    };

    const assetDefinition = selectedAsset ? getAssetbyId(selectedAsset.tokenDefinition.tokenIdentifier) : null;
    const maxValue = assetDefinition
        ? assetDefinition.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)?.value
        : null;
    const maxValueExceeded = maxValue ? parseFloat(assetAmount) > parseFloat(maxValue) : false;
    const invalidAssetAmountInput = !selectedAsset || parseFloat(assetAmount) > selectedAsset?.balance;
    const emptyAsset = selectedAsset == null;

    const submitButtonDisabled =
        invalidAssetAmountInput || emptyAsset || assetAmount.length <= 0 || maxValueExceeded || isTransferPending;
    return (
        <div>
            <FormControl
                id="SelectAsset"
                variant="filled"
                className={`${formClasses.columnItem} ${formClasses.formInput} ${layoutClasses.formInput} `}
            >
                <InputLabel id="SelectLabel">Asset</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={!selectedAsset ? '' : selectedAsset.tokenDefinition.tokenName}
                    onChange={handleAssetChange}
                    data-testid="asset-select"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {userContext?.customerDetails?.accountBalances.map((as, i) => {
                        return (
                            <MenuItem
                                key={as.tokenDefinition.tokenName + i}
                                value={as.tokenDefinition.tokenName}
                                data-testid={'asset-option'}
                            >
                                {as.tokenDefinition.tokenName}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Typography className={layoutClasses.sectionLabel} style={{ textAlign: 'left' }}>
                {selectedAsset &&
                    selectedAsset.tokenDefinition.tokenIdentifier.length > 0 &&
                    'Amount in vault: ' + NumberFormat.addThousandSeparators(selectedAsset.balance)}
                {emptyAsset && 'Select an asset from the vault.'}
            </Typography>
            {assetDefinition && maxValue && <MaxValueRuleSection amount={maxValue} />}
            <CurrencyInput
                disabled={emptyAsset}
                decimalPlaces={selectedAsset?.tokenDefinition ? selectedAsset.tokenDefinition.decimals : 0}
                onChange={setAssetAmount}
                label={
                    !selectedAsset
                        ? 'Select an asset'
                        : invalidAssetAmountInput
                        ? 'Not enough assets in the vault.'
                        : maxValueExceeded
                        ? 'Max value compliance rule exceeded'
                        : t('issueAsset.assetAmount')
                }
                testId="amount-to-transfer"
                invalidInput={invalidAssetAmountInput || assetAmount.length <= 0 || maxValueExceeded}
            />
            <Button
                className={`${formClasses.formControlButton}  ${formClasses.button}`}
                style={{ marginTop: 16 }}
                variant="outlined"
                color="primary"
                disabled={submitButtonDisabled}
                onClick={() => submit()}
                data-testid={'submit'}
            >
                {'Transfer'}
            </Button>
        </div>
    );
};

export default RetailTransfer;
