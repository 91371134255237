import { Theme, alpha, createStyles, makeStyles } from '@material-ui/core';

export const useTransactionSummaryStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            tooltip: {
                background: theme.palette.primary.light,
                maxWidth: 450,
                boxShadow: theme.shadows[20],
            },
            tooltipIcon: { margin: 'auto auto auto 6px', color: theme.palette.primary.contrastText },
            tooltipText: { color: 'white', maxWidth: 700, fontSize: 18, lineHeight: 1.2 },
            infoIcon: {
                width: 16,
                height: 16,
                '&:hover': {
                    cursor: 'pointer',
                },
            },
            table: {
                borderCollapse: 'separate',
                borderSpacing: 0,
                color: theme.palette.primary.contrastText,

                '& tr td:first-child': {
                    borderRightStyle: 'hidden',
                },
                '& tr td:nth-child(2)': {
                    borderLeftStyle: 'hidden',
                },
                '& td,th': {
                    border: `2px solid ${alpha(theme.palette.primary.contrastText, 0.15)}`,
                    textAlign: 'left',
                    padding: theme.spacing(2),
                },
                '& tr:not(:first-child) td': {
                    borderTopStyle: 'hidden',
                },
                '& tr:first-child td:first-child': {
                    borderTopLeftRadius: theme.shape.borderRadius,
                },
                '& tr:first-child td:last-child': {
                    borderTopRightRadius: theme.shape.borderRadius,
                },
                '& tr:last-child td:last-child': {
                    borderBottomRightRadius: theme.shape.borderRadius,
                },
                '& tr:last-child td:first-child': {
                    borderBottomLeftRadius: theme.shape.borderRadius,
                },
            },
            tableRegular: {
                fontSize: 18,
            },
            tableSmall: {
                fontSize: 16,
            },
        }),
    { index: 1 }
);
