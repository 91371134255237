import apiCall from './apiCall';

/**
 * @remark
 * Used to get all participants on the network
 *
 * @returns
 * AxiosResponse which resolves in pariticipant object array or error
 */
export const requestAllParticipants = async () =>
    apiCall({
        method: 'get',
        path: `/api/nodeDetails/participants`,
    });
