import { Divider, Paper, SvgIcon, Typography } from '@material-ui/core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import React from 'react';
import SummaryRow from 'components/commonComponents/SummaryComponents/SummaryRow';
import SummaryTable from 'components/commonComponents/SummaryComponents/SummaryTable';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type DvpRequestSummaryStepProps = {
    assetName: string;
    selectedParticipant: string;
    assetAmount: string;
    bondsAmount: string;
};

const DvpRequestSummaryStep: React.FC<DvpRequestSummaryStepProps> = (props) => {
    const { assetName, selectedParticipant, assetAmount, bondsAmount } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('requestDvp.summaryMessage')}</Typography>

                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.text}`} style={{ marginTop: 12, marginBottom: 12 }}>
                    {t('transferAssets.receivingParty') + ': '} {selectedParticipant}
                </Typography>

                <SummaryTable>
                    <SummaryRow
                        col1Text={`Requested amount of ${assetName}`}
                        col2Text={NumberFormat.addThousandSeparators(assetAmount)}
                    />
                    <SummaryRow
                        col1Text={'Bonds Offering'}
                        col2Text={NumberFormat.addThousandSeparators(bondsAmount)}
                        icon={
                            <SvgIcon color="secondary">
                                <BondsIcon />
                            </SvgIcon>
                        }
                    />
                </SummaryTable>
            </Paper>
        </div>
    );
};

export default DvpRequestSummaryStep;
