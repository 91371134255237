import { CBDCIssuanceRequest, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import RequestListContainer from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListContainer';
import RequestListItem from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListItem';
import { useTranslation } from 'react-i18next';
import { getRequestStatusColor } from '../requestStatusColors';

type CBDCRequestProps = {
    request: CBDCIssuanceRequest;
    onClick: () => void;
};

const CBDCRequest: React.FC<CBDCRequestProps> = ({ request, onClick }) => {
    const { t } = useTranslation();
    return (
        <RequestListContainer onClick={() => onClick()}>
            <RequestListItem>
                {t('defineAsset.assetName') + ': '}
                {request.token.tokenName}
            </RequestListItem>
            <RequestListItem>
                {t('commonText.amount') + ': '}
                {NumberFormat.addThousandSeparators(request.requestedAmount)}
            </RequestListItem>
            <RequestListItem>
                <span>{t('commonText.status') + ': '}</span>
                <span style={{ color: getRequestStatusColor(request.requestState) }}>{request.requestState}</span>
            </RequestListItem>
        </RequestListContainer>
    );
};

export default CBDCRequest;
