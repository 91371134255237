import { PointTooltipProps } from '@nivo/line';
import { NumberFormat, useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import { FunctionComponent } from 'react';
import { LINE_IDS } from './graphDetails';

const getDateLabel = (serieId: string | number) => {
    switch (serieId) {
        case LINE_IDS.IssuedBaseWithInterest:
            return 'Apply Time';
        default:
            return 'Update Time';
    }
};

const getValueLabel = (serieId: string | number) => {
    switch (serieId) {
        case LINE_IDS.IssuedBaseWithInterest:
            return 'Issued base + Interest';
        default:
            return 'Issued base';
    }
};

const BalanceOverTimeLineTooltip: FunctionComponent<PointTooltipProps> = ({ point }) => {
    const classes = useDashboardStyles();
    return (
        <>
            <div className={classes.pieTooltip} style={{ display: 'flex', flexDirection: 'column', marginTop: 120 }}>
                <div>
                    {getValueLabel(point.serieId)}: {NumberFormat.addThousandSeparators(point.data.y.toString())}
                </div>{' '}
                <div>
                    {getDateLabel(point.serieId)}: {point.data.x.toLocaleString()}
                </div>
            </div>
        </>
    );
};
export default BalanceOverTimeLineTooltip;
