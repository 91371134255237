import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { useAssistantStyles } from '@r3/cbdc-asset-frontend-core';
import useContextMenu from 'contexts/ContextMenuContext/ContextMenuContext';
import { useRef } from 'react';

type Props = {
    featureConfigEnabled: boolean;
    setFeatureConfigEnabled: (enabled: boolean) => void;
    themeConfigEnabled: boolean;
    setThemeConfigEnabled: (enabled: boolean) => void;
};

const ConfigEmoji: React.FC<Props> = ({
    featureConfigEnabled,
    setFeatureConfigEnabled,
    themeConfigEnabled,
    setThemeConfigEnabled,
}) => {
    const classes = useAssistantStyles();
    const clickedTimes = useRef<number>(0);

    const { handleContextMenuClick } = useContextMenu();

    function handleEmojiClick() {
        clickedTimes.current++;
        if ((themeConfigEnabled || featureConfigEnabled) && clickedTimes.current === 1) {
            clickedTimes.current = 0;
            setThemeConfigEnabled(false);
            setFeatureConfigEnabled(false);
        } else if (!themeConfigEnabled && clickedTimes.current === 5) {
            clickedTimes.current = 0;
            if (!featureConfigEnabled) {
                setThemeConfigEnabled(true);
            }
        }
    }

    return (
        <EmojiPeopleIcon
            id="ConfigIcon"
            onContextMenu={handleContextMenuClick}
            onClick={handleEmojiClick}
            color={'secondary'}
            className={classes.assistantIcon}
        />
    );
};

export default ConfigEmoji;
