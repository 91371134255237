import { TransferAssets } from 'components/cbdcManager/TransferCBDC/TransferAssets/TransferAssets';
import useLandingPageContext from 'contexts/LandingPageContext/LandingPageContext';

type Props = {
    onSuccessfulSubmit?: () => void;
};

const LandingTransferCBDC: React.FunctionComponent<Props> = ({ onSuccessfulSubmit }) => {
    const { assets, getAllAssets, assetsError, getAssetSummary, assetSummary, summaryError } = useLandingPageContext();
    return (
        <TransferAssets
            onSuccessfulSubmit={onSuccessfulSubmit}
            ignoreInfoModal={true}
            assets={assets}
            getAllAssets={getAllAssets}
            assetsError={assetsError}
            getAssetSummary={getAssetSummary}
            assetSummary={assetSummary}
            summaryError={summaryError}
        />
    );
};

export default LandingTransferCBDC;
