import { useCallback, useEffect, useState } from 'react';

import { ParticipantsResponse } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllParticipants } from 'api/nodeDetailsApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useGetParticipantsOnNetwork(fetchDefault?: boolean) {
    const [participants, setParticipants] = useState<ParticipantsResponse>({ participants: [] });
    const [participantsError, setError] = useState<string>('');
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const getParticipants = useCallback(async () => {
        const participantsData: ResolvedPromise = await requestAllParticipants();
        if (participantsData.error) {
            enqueueSnackbar(t('error.gettingParticipants', { error: participantsData.error }), {
                variant: 'error',
            });
            setError(participantsData.error.length > 0 ? participantsData.error : t('error.networkError'));
        } else {
            if (participantsData.data.length <= 0) {
                const warningText = t('issueAsset.noParticipantsOnNetwork');
                enqueueSnackbar(warningText, {
                    variant: 'info',
                });
                setError((text) => text + (text.length > 0 ? '. ' : '') + warningText);
                return;
            }
            let participants = participantsData.data as ParticipantsResponse;
            participants.participants = participants.participants.filter(
                (p) => !p.toLowerCase().includes('bond') && !p.toLowerCase().includes('notary')
            );
            setParticipants(participants);
        }
    }, [t, enqueueSnackbar]);

    useEffect(() => {
        if (fetchDefault) {
            getParticipants();
        }
    }, [fetchDefault, getParticipants]);

    return { participants, participantsError, getParticipants };
}
