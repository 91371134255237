import {
    DashBoardResponsivePie,
    LandingPagePieTheme,
} from 'components/treasuryDashboard/DashboardComponents/DashboardResponsivePie';
import { Divider, Fade, Paper } from '@material-ui/core';
import { NumberFormat, ResponsivePieData } from '@r3/cbdc-asset-frontend-core';
import React, { useCallback, useEffect, useState } from 'react';

import { BONDSPOLLINGINTERVAL } from 'constants/PollingIntervals';
import { InfoBox } from 'components/treasuryDashboard/DashboardComponents/InfoBox';
import { useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import useLandingPageContext from 'contexts/LandingPageContext/LandingPageContext';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const LandingBonds: React.FunctionComponent = () => {
    const classes = useDashboardStyles();
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const { bonds, getAllBondsData } = useLandingPageContext();
    const [pieData, setPieData] = useState<ResponsivePieData[]>([]);
    const isXsmallScreen = useMediaQuery({ maxWidth: 1250 });
    const isSmallScreen = useMediaQuery({ maxWidth: 1500 });
    const isLargeScreen = useMediaQuery({ minWidth: 1700 });
    let pieSizeY = 100;
    let radialLabelLength = 10;
    if (isSmallScreen) {
        pieSizeY = 120;
        radialLabelLength = 5;
    }
    if (isLargeScreen) {
        pieSizeY = 80;
    }
    if (isXsmallScreen) {
        pieSizeY = 70;
        radialLabelLength = 2;
    }

    const updateChart = useCallback(() => {
        let responsePieData: ResponsivePieData[] = [];
        responsePieData.push({
            id: bonds.usdValue.toString(),
            label: NumberFormat.addThousandSeparators(bonds.usdValue).toString(),
            value: bonds.usdValue,
            color: 'white',
        });
        setPieData(responsePieData);
    }, [bonds]);

    useEffect(() => {
        getAllBondsData(true);
    }, [getAllBondsData]);

    useEffect(() => {
        if (BONDSPOLLINGINTERVAL <= 0) {
            return;
        }
        setInterval(function () {
            getAllBondsData(true);
        }, BONDSPOLLINGINTERVAL);
    }, [getAllBondsData]);

    useEffect(() => {
        updateChart();
    }, [bonds, updateChart]);

    const handlePieClick = () => {
        alert('Bring to transfer screen');
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
                <Fade in={true}>
                    <Paper elevation={5} className={`${layoutClasses.componentWrapper}`}>
                        <InfoBox size={'SMALL'} title={t('dashBoard.bondValue')} content={`1:1 to CBDC`} />
                        <InfoBox
                            size={'SMALL'}
                            title={t('dashBoard.totalBondsInVault')}
                            content={bonds.usdValue.toFixed(3).toString()}
                        />
                    </Paper>
                </Fade>
                <div
                    id="PieGraph"
                    className={` ${classes.nivoWrapper}`}
                    style={{ overflow: 'hidden', height: 400, marginTop: 24 }}
                >
                    <DashBoardResponsivePie
                        pieMarginsTopBottom={pieSizeY}
                        pieMarginLeft={50}
                        pieMarginRight={80}
                        altColour={true}
                        data={pieData}
                        handlePieClick={handlePieClick}
                        pieTheme={LandingPagePieTheme}
                        legendEnabled={false}
                        radialLabelLength={radialLabelLength}
                        radialLabelOffset={10}
                        radialLabelsXLength={10}
                    />
                    <Divider className={classes.divider} />
                </div>
            </div>
        </>
    );
};

export default LandingBonds;
