import * as ROUTES from 'constants/Routes';

import { Button, Card, Divider, IconButton, Slide } from '@material-ui/core';
import { DOCS_URL, FeatureConfigTool, useAssistantStyles } from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useMemo, useState } from 'react';

import AppConfigTool from 'components/themeConfigTool/AppConfigTool';
import ClearIcon from '@material-ui/icons/Clear';
import ConfigEmoji from './ConfigEmoji';
import { ContextMenuProvider } from 'contexts/ContextMenuContext/ContextMenuContext';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { FeedbackModal } from 'components/infoModal/FeedbackModal/FeedbackModal';
import { GetModalInfoData } from 'constants/InfoModal';
import HelpIcon from '@material-ui/icons/Help';
import { InfoModal } from 'components/infoModal/InfoModal';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { openFromLocalStorage } from './Assistant';
import { useHistory } from 'react-router-dom';
import { useSessionStorage } from '@r3/cbdc-asset-frontend-core';

const FIRSTTIMEOPENKEY = 'firstTimeOpeningApp';

type AssistantOption = {
    text: string;
    path: string;
};

export const assistantOptions: AssistantOption[] = [
    { text: 'Home', path: '/' },
    { text: 'View all owned Member Access states', path: ROUTES.LISTKYC },
    { text: 'Request a new Member Access state', path: ROUTES.REQUESTKYCSTATE },
    { text: 'View Member Access requests', path: ROUTES.KYCREQUESTSLIST },
    { text: 'View availabe assets on the network', path: ROUTES.AVAILABLECBDC },
    { text: 'Request issuance of CBDC', path: ROUTES.REQUESTCBDC },
    { text: 'View CBDC Request history', path: ROUTES.CBDCREQUESTSLIST },
    { text: 'Transfer CBDC to another Wholesale', path: ROUTES.TRANSFERCBDC },
    { text: 'View Assets and Bonds Dashboard', path: ROUTES.DEMODASH },
];

type AssistantProps = {
    isVisible: boolean;
    closeWindow: () => void;
    openWindow: () => void;
};

export const AssistantWindow: React.FC<AssistantProps> = ({ isVisible, closeWindow, openWindow }) => {
    const classes = useAssistantStyles();
    const infoModalPages = GetModalInfoData(window.location.pathname);
    const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
    const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
    const history = useHistory();
    const [hideFirstTimeWindow, sethideFirstTimeWindow] = useState<boolean>(openFromLocalStorage(FIRSTTIMEOPENKEY));
    const [themeConfigEnabled, setThemeConfigEnabled] = useSessionStorage<boolean>('themeConfigOpen', false);
    const [featureConfigEnabled, setFeatureConfigEnabled] = useSessionStorage<boolean>('featureConfigOpen', false);

    useEffect(() => {
        if (window.location.pathname === '/' && !hideFirstTimeWindow) {
            openAssistant();
        }
        // eslint-disable-next-line
    }, []);

    const openAssistant = () => {
        setInfoModalOpen(true);
        closeWindow();
        setBackgroundBlurred();
    };
    const options: JSX.Element[] = [];

    const goToPage = (path: string) => {
        closeWindow();
        history.push(path);
    };

    const setBackgroundBlurred = () => {
        let root = document.getElementById('root');
        if (root) {
            root.style.filter = 'blur(12px)';
        }
    };

    const setBackgroundNotBlurred = () => {
        let root = document.getElementById('root');
        if (root) {
            root.style.filter = '';
        }
    };

    assistantOptions.forEach((option, index) => {
        options.push(
            <Card
                key={`option + ${index}`}
                onClick={() => {
                    goToPage(option.path);
                }}
                className={classes.assistantOption}
            >
                <div className={classes.assistantOptionText}>{option.text} </div>
            </Card>
        );
    });

    const contextMenu = useMemo((): React.ReactNode => {
        const buttons: JSX.Element[] = [];

        if (!featureConfigEnabled) {
            if (!themeConfigEnabled) {
                buttons.push(
                    <Button
                        onClick={() => {
                            setThemeConfigEnabled(true);
                        }}
                    >
                        Preview theme
                    </Button>
                );
            }
            if (themeConfigEnabled) {
                buttons.push(
                    <Button
                        onClick={() => {
                            setThemeConfigEnabled(false);
                        }}
                    >
                        Close
                    </Button>
                );
            }
        }
        if (!themeConfigEnabled) {
            if (!featureConfigEnabled) {
                buttons.push(
                    <Button
                        onClick={() => {
                            setFeatureConfigEnabled(true);
                        }}
                    >
                        Configure features
                    </Button>
                );
            }
            if (featureConfigEnabled) {
                buttons.push(
                    <Button
                        onClick={() => {
                            setFeatureConfigEnabled(false);
                        }}
                    >
                        Close
                    </Button>
                );
            }
        }

        return <>{buttons}</>;
    }, [themeConfigEnabled, setThemeConfigEnabled, featureConfigEnabled, setFeatureConfigEnabled]);

    return (
        <>
            <Slide direction="left" in={isVisible} timeout={{ enter: 350, exit: 350 }}>
                <Card
                    elevation={20}
                    style={{
                        height: featureConfigEnabled ? 500 : themeConfigEnabled ? 660 : 300,
                        width: featureConfigEnabled ? 380 : 330,
                    }}
                    className={classes.assistantWindow}
                    data-testid={'assistant_window'}
                >
                    <div className={classes.assistantHeader}>
                        <div className={classes.assistantTitle}>
                            {featureConfigEnabled ? 'Feature Config' : themeConfigEnabled ? 'App Config' : 'Assistant'}
                        </div>
                        <ContextMenuProvider menuContent={contextMenu}>
                            <ConfigEmoji
                                featureConfigEnabled={featureConfigEnabled}
                                setFeatureConfigEnabled={setFeatureConfigEnabled}
                                themeConfigEnabled={themeConfigEnabled}
                                setThemeConfigEnabled={setThemeConfigEnabled}
                            />
                        </ContextMenuProvider>

                        <IconButton
                            className={classes.assistantHideButton}
                            onClick={() => {
                                closeWindow();
                            }}
                            data-testid={'assistant_window_close'}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <Divider className={classes.divider} />

                    {!themeConfigEnabled && !featureConfigEnabled && (
                        <>
                            {infoModalPages.length > 0 ? (
                                <>
                                    <div
                                        className={`${classes.assistantOption} ${classes.moreInfo}`}
                                        onClick={() => {
                                            openAssistant();
                                        }}
                                    >
                                        <div
                                            data-testid={'educational-modal-button'}
                                            className={classes.assistantOptionText}
                                        >
                                            {window.location.pathname === '/'
                                                ? 'Intro Videos'
                                                : 'What happens on corda?'}
                                        </div>
                                        <HelpIcon className={classes.icon} />
                                    </div>
                                    <InfoModal
                                        open={infoModalOpen}
                                        onClose={() => {
                                            setInfoModalOpen(false);
                                            openWindow();
                                            if (!hideFirstTimeWindow) {
                                                sethideFirstTimeWindow(true);
                                                localStorage.setItem(FIRSTTIMEOPENKEY, 'true');
                                            }
                                            setBackgroundNotBlurred();
                                        }}
                                        pages={infoModalPages}
                                    />
                                </>
                            ) : (
                                <div className={`${classes.moreInfo} ${classes.disabledAssistantOption}`}>
                                    <div
                                        data-testid={'educational-modal-button'}
                                        className={classes.assistantOptionText}
                                    >
                                        No education content on this page
                                    </div>
                                    <HelpIcon className={classes.icon} />
                                </div>
                            )}
                            <div
                                className={`${classes.assistantOption} ${classes.moreInfo}`}
                                onClick={() => {
                                    window.open(DOCS_URL, '_blank');
                                }}
                            >
                                <div className={classes.assistantOptionText}>Documentation</div>
                                <MenuBookIcon className={classes.icon} />
                            </div>
                            <div
                                className={`${classes.assistantOption} ${classes.moreInfo}`}
                                onClick={() => {
                                    setFeedbackModalOpen(true);
                                    closeWindow();
                                }}
                            >
                                <div className={classes.assistantOptionText}>Leave a question or feedback</div>
                                <FeedbackIcon className={classes.icon} />
                            </div>{' '}
                        </>
                    )}

                    {themeConfigEnabled && <AppConfigTool />}
                    {featureConfigEnabled && <FeatureConfigTool />}
                </Card>
            </Slide>
            <FeedbackModal
                open={feedbackModalOpen}
                onClose={() => {
                    setFeedbackModalOpen(false);
                    openWindow();
                }}
            />
        </>
    );
};
