import { useCallback, useEffect } from 'react';

import { PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import { getAllPaymentRequests } from '../api/retailApi';
import { useState } from 'react';

export const useGetPaymentRequests = (fetchByDefault?: boolean) => {
    const [paymentRequests, setPaymentRequests] = useState<PaymentRequestResponse[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const getPaymentRequests = useCallback(async (dontTrack?: boolean) => {
        setLoading(true);
        const response = await getAllPaymentRequests(dontTrack);

        if (response.error) {
            setError(response.error);
        } else {
            setPaymentRequests(response.data.reverse());
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        if (fetchByDefault) {
            getPaymentRequests();
        }
    }, [getPaymentRequests, fetchByDefault]);

    const isOwnPaymentRequest = (requestId: string, accountId: string) => {
        if (!paymentRequests) {
            return null;
        }

        return paymentRequests.find((request) => request.requestId === requestId && request.accountId === accountId);
    };

    const isRequestFulfilled = (requestId: string): boolean => {
        if (!paymentRequests) {
            return false;
        }
        const request = paymentRequests.find(
            (payment) => payment.requestId === requestId && payment.requestState === 'PAID'
        );

        if (!request) {
            return false;
        }
        return true;
    };

    return { getPaymentRequests, paymentRequests, loading, error, isOwnPaymentRequest, isRequestFulfilled };
};
