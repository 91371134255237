import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from '../../../constants/Animations';
import { EnhancedTable, HeadCell } from 'components/commonComponents/Table/Table';

import { Customer } from '@r3/cbdc-asset-frontend-core';
import { Order } from 'components/commonComponents/Table/sorting';
import { RetailCustomersDialog } from './RetailCustomersDialog';
import { Slide } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { useEffect } from 'react';
import useGetAllCustomers from '../../../hooks/GetAllCustomers';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useState } from 'react';

const RetailCustomers = () => {
    const { customers, getCustomers } = useGetAllCustomers();
    const [customerId, setCustomerId] = useState<string | null>(null);
    const layoutClasses = useLayoutStyles();
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [page, setPage] = useState<number>(0);
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const handleTableRowClick = async (row: Customer) => {
        setCustomerId(row.customerId);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        getCustomers();
    }, [getCustomers]);

    const updateSearchInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);
        getCustomers(value);
    };

    return (
        <>
            <RetailCustomersDialog open={modalOpen} customerId={customerId} onClose={closeModal} />
            <Slide
                direction="left"
                in={true}
                timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
            >
                <div
                    className={`${layoutClasses.column} ${layoutClasses.columnNoPadding} ${layoutClasses.columnFill}`}
                    style={{ margin: '24px auto auto auto', width: '75%', minWidth: 300 }}
                >
                    <div style={{ width: '95%', marginBottom: 12, marginTop: 6 }}>
                        <TextField
                            className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} nameInput`}
                            style={{ marginLeft: 0, marginTop: 26, height: 30, width: 350 }}
                            type={'text'}
                            label={'Search by email or customer name'}
                            value={searchTerm}
                            name="Password"
                            variant="filled"
                            onChange={(event) => {
                                updateSearchInput(event);
                            }}
                            data-testid={'search-input'}
                        />
                    </div>
                    <EnhancedTable<Customer>
                        defaultSortBy={'emailAddress'}
                        defaultOrder={Order.Ascending}
                        headCells={HEADCELLS}
                        onClickRow={handleTableRowClick}
                        totalResults={customers.length}
                        page={page}
                        setPage={setPage}
                        data={customers}
                    />
                </div>
            </Slide>
        </>
    );
};

export default RetailCustomers;

const HEADCELLS: HeadCell<Customer>[] = [
    { id: 'emailAddress', numeric: false, disablePadding: false, label: 'Email Address' },
    { id: 'customerName', numeric: false, disablePadding: false, label: 'Customer Name' },
    //{ id: 'customerId', numeric: false, disablePadding: false, label: 'Customer Id' },
    //{ id: 'accountName', numeric: false, disablePadding: false, label: 'Account Name' },
    { id: 'accountId', numeric: false, disablePadding: false, label: 'Account Id' },
];
