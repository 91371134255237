import { TokenDataQuery } from '@r3/cbdc-asset-frontend-core';
import apiCall from './apiCall';

/**
 * @remark
 * Used to get the current nodes identity
 *
 * @returns
 * Axios Response which is resolved in an error or response data
 */
export const getOurOwnIdentity = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/nodeDetails/ourIdentity`,
        dontTrackRequest: dontTrack,
    });

export const getBalanceOverTimeData = async (tokenIdentifier: string, nDatapoints: number = 100) =>
    apiCall({
        method: 'post',
        path: `/api/report/balanceOverTime`,
        params: {
            tokenIdentifier: tokenIdentifier,
            nDatapoints: nDatapoints,
        },
    });

export const getMoneySupplyWithInterest = async (tokenIdentifier: string, from?: Date, to?: Date) =>
    apiCall({
        method: 'post',
        path: `/api/report/wholesale/moneySupplyWithInterest`,
        params: {
            tokenIdentifier: tokenIdentifier,
            txTimeFrom: from,
            txTimeTo: to,
        },
    });

/**
 * @remark
 * User to request corda tx logs
 *
 * @param query used to create a query object for the api (See TokenDataQuery definition for more details)
 *
 * @returns
 * Axios Response which is resolved in an error or response data of tx logs
 */

export const requestTokenData = (query: TokenDataQuery, dontTrack?: boolean) =>
    apiCall({
        method: 'post',
        path: `/api/report/transactionLogs`,
        params: query,
        dontTrackRequest: dontTrack,
    });
