import { OffersDisplayProps, Refreshable } from 'components/decentralizedExchange/Types';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';

import { OrderStatus } from 'models/orders/OrderStatus';
import OrdersListWrapper from '../OffersListComponents/OrdersListWrapper';
import useGetExchangeOrders from 'hooks/DecentralizedExchange/useGetExchangeOffers';
import { useTranslation } from 'react-i18next';
import ManageExchangeOrderDialog from '../OffersListComponents/ManageExchangeOrderDialog';

const orderStatus: OrderStatus = OrderStatus.UNLOCKED;

const CompletedOrders = forwardRef(({ identity }: OffersDisplayProps, ref: React.Ref<Refreshable>) => {
    const { t } = useTranslation();
    const { orders, error, getOrders, isLoading } = useGetExchangeOrders(orderStatus);

    const refreshOrders = useCallback(() => {
        if (identity.length !== 0) {
            getOrders({ identity });
        }
    }, [identity, getOrders]);

    useEffect(() => {
        refreshOrders();
    }, [refreshOrders]);

    useImperativeHandle(ref, () => ({
        refresh() {
            refreshOrders();
        },
    }));

    const displayMessage = isLoading
        ? t('commonText.loading')
        : error != null
        ? error
        : orders.length === 0
        ? t('liquiditySavings.noExchangeOffersWithStatus', { status: t('liquiditySavings.unlockedDisplayStatus') })
        : '';

    return (
        <OrdersListWrapper
            offers={orders}
            displayMessage={displayMessage}
            header={t('liquiditySavings.completedOffers')}
            getDialog={(order, open, onClose) => (
                <ManageExchangeOrderDialog isOpen={open} order={order} onClose={onClose} />
            )}
        />
    );
});

export default CompletedOrders;
