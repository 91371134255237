import { TxLogData, TxLogDataOperations } from '@r3/cbdc-asset-frontend-core';
import { useCallback, useEffect, useState } from 'react';

import { requestTokenData } from '../api/retailApi';

export const useGetRetailTransactionLogs = (fetchByDefault?: boolean) => {
    const [txLogData, setTxLogData] = useState<TxLogData[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const getTxLogData = useCallback(async (trackPromise?: boolean) => {
        setLoading(true);
        const response = await requestTokenData(
            { startPage: 1, pageSize: 10000, includeCounterPartyParticipantOrAccount: true },
            trackPromise
        );
        if (response.error) {
            setError(response.error);
        } else {
            let data = (response.data as TxLogData[]).map((tld) => TxLogDataOperations.transformTxLogData(tld));
            setTxLogData(data.reverse());
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (fetchByDefault) {
            getTxLogData();
        }
    }, [getTxLogData, fetchByDefault]);

    return { txLogData, getTxLogData, error, loading };
};
