import { Asset, Bond, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { Divider, Paper, SvgIcon, Typography } from '@material-ui/core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import React from 'react';
import SummaryRow from 'components/commonComponents/SummaryComponents/SummaryRow';
import SummaryTable from 'components/commonComponents/SummaryComponents/SummaryTable';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type SelectAmountToRequestStepProps = {
    selectedAsset: Asset;
    setAmount: (val: string) => void;
    invalidAssetAmountInput: boolean;
    amount: string;
    bonds: Bond;
};

const SelectAmountToRequestStep: React.FC<SelectAmountToRequestStepProps> = (props) => {
    const { selectedAsset, setAmount, invalidAssetAmountInput, amount, bonds } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('requestCBDC.selectAmount', { asset: selectedAsset.tokenName })}
                </Typography>

                <Typography className={`${layoutClasses.text}`}>
                    {t('issueAsset.decimal') + ': '} {selectedAsset.tokenDecimals}
                </Typography>
                <CurrencyInput
                    decimalPlaces={selectedAsset.tokenDecimals}
                    onChange={setAmount}
                    label={invalidAssetAmountInput ? 'Value must be greater than 10' : t('issueAsset.assetAmount')}
                    testId="asset-amount-to-offer"
                    invalidInput={invalidAssetAmountInput}
                />
                <Divider className={layoutClasses.summaryDivider} />

                <SummaryTable>
                    <SummaryRow
                        col1Text={t('requestCBDC.toBeRequested', {
                            asset: selectedAsset.tokenName,
                        })}
                        col2Text={NumberFormat.addThousandSeparators(parseFloat(amount).toFixed(3))}
                    />
                    <SummaryRow
                        col1Text={t('requestCBDC.bondsLeft')}
                        col2Text={NumberFormat.addThousandSeparators((bonds.usdValue - parseFloat(amount)).toFixed(3))}
                        icon={
                            <SvgIcon color="secondary">
                                <BondsIcon />
                            </SvgIcon>
                        }
                    />
                </SummaryTable>
            </Paper>
        </div>
    );
};

export default SelectAmountToRequestStep;
