import React, { useEffect, useState } from 'react';

import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { TextField } from '@material-ui/core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { validateCurrencyInput } from './validateInput';

type Props = {
    decimalPlaces: number;
    onChange: (value: string) => void;
    label: string;
    testId: string;
    initialValue?: number;
    invalidInput?: boolean;
    disabled?: boolean;
};

export const CurrencyInput: React.FC<Props> = ({
    decimalPlaces,
    onChange,
    label,
    testId,
    initialValue,
    invalidInput,
    disabled = false,
}) => {
    const layoutClasses = useLayoutStyles();

    const [localVal, setLocalVal] = useState<string>(
        initialValue !== undefined
            ? initialValue.toFixed(decimalPlaces)
            : NumberFormat.stringToFixed('0', decimalPlaces)
    );

    const handleInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();
        let value = NumberFormat.removeThousandSeparators(event.target.value);
        if (validateCurrencyInput(value, decimalPlaces)) {
            if (decimalPlaces === 0) {
                value = NumberFormat.removeLeadingZeros(value);
            }
            setLocalVal(value);
        }
    };

    useEffect(() => {
        onChange(localVal);
    }, [localVal, onChange]);

    const adjustAssetAmount = () => {
        if (localVal.length === 0) {
            let zeroValue = NumberFormat.stringToFixed('0', decimalPlaces);
            setLocalVal(zeroValue);
            onChange(zeroValue);
            return;
        }
        setLocalVal(NumberFormat.stringToFixed(localVal, decimalPlaces));
    };

    const onFocusHandle = () => {
        if (parseFloat(localVal) === 0) {
            setLocalVal('');
        }
    };

    return (
        <>
            <TextField
                disabled={disabled}
                error={invalidInput}
                className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} currencyInput`}
                label={label}
                onChange={handleInput}
                value={NumberFormat.addThousandSeparators(localVal)}
                required
                variant="filled"
                data-testid={testId}
                onFocus={onFocusHandle}
                onBlur={adjustAssetAmount}
            />
        </>
    );
};
