import 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from 'constants/Animations';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Divider, Slide, TextField } from '@material-ui/core';
import { EnhancedTable, HeadCell } from 'components/commonComponents/Table/Table';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { TokenDataQuery, TxLogData, TxLogDataOperations, useSwitchFilterStyles } from '@r3/cbdc-asset-frontend-core';

import { Refreshable } from 'components/decentralizedExchange/Types';
import { TransactionDialog } from './TransactionDialog';
import { enGB } from 'date-fns/esm/locale';
import { requestTokenData } from 'api/financialReportApi';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

registerLocale('enGb', enGB);

enum FilterInputType {
    NUMBER,
    STRING,
    NONE,
    BOOLEAN,
    DATE,
}

type TableFilter = {
    name: string;
    inputType: FilterInputType;
    enabled: boolean;
    inputValue: string | number | null | boolean | Date;
};

const HEADCELLS: HeadCell<TxLogData>[] = [
    { id: 'command', numeric: false, disablePadding: false, label: 'Tx Type' },
    { id: 'tokenName', numeric: false, disablePadding: false, label: 'Token Name' },
    { id: 'amount', numeric: true, disablePadding: false, label: 'Amount', formatDecimals: true },
    { id: 'usageCount', numeric: true, disablePadding: false, label: 'Usage Count' },
    { id: 'holder', numeric: false, disablePadding: false, label: 'Initiator' },
    { id: 'counterParty', numeric: false, disablePadding: false, label: 'Counterparty' },
    { id: 'txTime', numeric: false, disablePadding: false, label: 'Tx Time', date: true },
];

function createTableFilter(name: string, inputType: FilterInputType, enabled: boolean = false): TableFilter {
    let inputValue;
    if (inputType === FilterInputType.NUMBER) {
        inputValue = 0;
    } else if (inputType === FilterInputType.STRING) {
        inputValue = '';
    } else if (inputType === FilterInputType.BOOLEAN) {
        inputValue = false;
    } else if (inputType === FilterInputType.DATE) {
        inputValue = new Date();
    } else {
        inputValue = null;
    }
    return { name, inputType, enabled, inputValue };
}

const TxTableFilters: TableFilter[] = [
    createTableFilter('amountMin', FilterInputType.NUMBER),
    createTableFilter('amountMax', FilterInputType.NUMBER),
    createTableFilter('usageCountMin', FilterInputType.NUMBER),
    createTableFilter('usageCountMax', FilterInputType.NUMBER),
];

interface TransactionDashboardProps {
    stateToQuery?: 'CBDCToken' | 'KYCReferenceState';
    silentQuery?: boolean;
}

export const TransactionDashboard = forwardRef(
    ({ stateToQuery = 'CBDCToken', silentQuery }: TransactionDashboardProps, ref: React.Ref<Refreshable>) => {
        const classes = useTransactionDashboardStyles();
        const layoutClasses = useLayoutStyles();
        const filterClasses = useSwitchFilterStyles();

        const [tableFilters, setTableFilters] = useState<TableFilter[]>(TxTableFilters);
        const [rows, setRows] = useState<TxLogData[]>([]);
        const [amountFrom, setAmountFrom] = useState<number | null>(0);
        const [amountTo, setAmountTo] = useState<number | null>(1000000000);
        const [usageCountMin, setUsageCountMin] = useState<number | null>(0);
        const [usageCountMax, setUsageCountMax] = useState<number | null>(10000);

        const [sortField] = useState<string>('txTime');
        const [sortOrder] = useState<'DESC' | 'ASC'>('DESC');
        const [txFromDate, setTxFromDate] = useState<Date | null>(new Date('01/01/2021'));
        const [txToDate, setTxToDate] = useState<Date | null>(new Date());
        const [issueFromDate, setIssueFromDate] = useState<Date | null>(new Date('01/01/2021'));
        const [issueToDate, setIssueToDate] = useState<Date | null>(new Date());
        const [page, setPage] = useState<number>(0);
        const [totalResults, setTotalResults] = useState<number>(rows.length);
        const [modalOpen, setModalOpen] = useState<boolean>(false);
        const [selectedRow, setSelectedRow] = useState<TxLogData | null>(null);

        const fetchTableData = async () => {
            let queryParams: TokenDataQuery;
            const isKycQuery = stateToQuery.includes('KYC');
            if (isKycQuery) {
                queryParams = {
                    accountId: null,
                    state: stateToQuery,
                    txTimeFrom: txFromDate,
                    txTimeTo: txToDate,
                    issueDateFrom: null,
                    issueDateTo: null,
                    amountMin: null,
                    amountMax: null,
                    usageCountMin: null,
                    usageCountMax: null,
                    consumed: null,
                    startPage: 1,
                    pageSize: 10000,
                    sortField: null,
                    sortOrder: sortOrder,
                };
            } else {
                queryParams = {
                    accountId: null,
                    state: stateToQuery,
                    txTimeFrom: txFromDate,
                    txTimeTo: txToDate,
                    issueDateFrom: issueFromDate,
                    issueDateTo: issueToDate,
                    amountMin: amountFrom!,
                    amountMax: amountTo!,
                    usageCountMin: usageCountMin,
                    usageCountMax: usageCountMax,
                    consumed: null,
                    startPage: 1,
                    pageSize: 10000,
                    sortField: sortField,
                    sortOrder: sortOrder,
                };
            }

            const dataResponse = await requestTokenData(queryParams, silentQuery);

            if (dataResponse.error) {
            } else {
                const data = (dataResponse.data as TxLogData[]).map((tld) =>
                    TxLogDataOperations.transformTxLogData(tld)
                );
                const finalData = isKycQuery ? data : filterOutRetailTxs(data);
                setRows(finalData);
            }
        };

        /**
         * This is temp solution to hide the Retail transactions from the Wholesale Tx dash view
         * A common pattern of all retail related transactions (deposit, same node retail transfer) is:
         * Amount of 0 is returned from the back end, due to the grouping of transactions from both of the account.
         * The only other 0 amount transactions that are present are "Release" and "Encumber" txs which occure during DVP and Cross Chain
         * We want to make an exception and keep these for now
         */
        const filterOutRetailTxs = (data: TxLogData[]): TxLogData[] => {
            return data.filter((tx) => {
                //This covers deposits to retail + same node retail transfers
                //All which result in zero
                //Ignoring Release, Encumber and ContractKeyCommand which are all related to in flight locks
                if (
                    tx.amount === 0 &&
                    !tx.command.includes('Release') &&
                    !tx.command.includes('Encumber') &&
                    !tx.command.includes('Activate') &&
                    !tx.command.includes('ContractKeyCommand')
                ) {
                    return false;
                }

                //This covers cross node retail transfers which always either have a holderAccountID or counterPartyAccountID
                //Based on sender or receiver
                if (tx.holderAccountId !== 'N/A' || tx.counterPartyAccountId !== 'N/A') {
                    return false;
                }

                return true;
            });
        };

        useEffect(() => {
            tableFilters.forEach((filter) => {
                if (filter.name.includes('amountMin')) {
                    filter.inputValue = amountFrom;
                } else if (filter.name.includes('amountMax')) {
                    filter.inputValue = amountTo;
                } else if (filter.name.includes('usageCountMin')) {
                    filter.inputValue = usageCountMin;
                } else if (filter.name.includes('usageCountMax')) {
                    filter.inputValue = usageCountMax;
                }
            });
            // eslint-disable-next-line
        }, []);

        const resetDate = () => {
            setTxToDate(new Date());
            setIssueToDate(new Date());
        };

        useImperativeHandle(ref, () => ({
            refresh() {
                resetDate();
            },
        }));

        useEffect(() => {
            resetDate();
        }, [stateToQuery]);

        useEffect(() => {
            fetchTableData();
            setPage(0);
            // eslint-disable-next-line
        }, [JSON.stringify(tableFilters), txFromDate, txToDate, issueToDate, issueFromDate, stateToQuery]);

        useEffect(() => {
            setTotalResults(rows.length);
        }, [rows]);

        const updateFilterInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
            event.preventDefault();
            const value = event.target.value;
            let tempFilters = [...tableFilters];
            tempFilters[index].inputValue = value;
            switch (tempFilters[index].name) {
                case 'amountMin': {
                    setAmountFrom(parseFloat(parseFloat(value).toFixed(2)));
                    break;
                }
                case 'amountMax': {
                    setAmountTo(parseFloat(parseFloat(value).toFixed(2)));
                    break;
                }
                case 'usageCountMin': {
                    setUsageCountMin(parseInt(value));
                    break;
                }
                case 'usageCountMax': {
                    setUsageCountMax(parseInt(value));
                    break;
                }
                default: {
                    break;
                }
            }
            setTableFilters(tempFilters);
        };

        const handleTableRowClick = (row: TxLogData) => {
            setSelectedRow(row);
        };

        const closeModal = () => {
            setSelectedRow(null);
            setModalOpen(false);
        };

        useEffect(() => {
            if (selectedRow) {
                setModalOpen(true);
            }
        }, [selectedRow]);

        const filters = (): JSX.Element[] => {
            return tableFilters.map((filter, index) => {
                return (
                    <div key={filter.name + index}>
                        <div className={filterClasses.filterItem} style={{ flexFlow: 'wrap', display: 'flex' }}>
                            <div className={filterClasses.filterName}>{filter.name.toUpperCase()}</div>
                            <div className={filterClasses.filterInput}>
                                {(filter.inputType === FilterInputType.NUMBER ||
                                    filter.inputType === FilterInputType.STRING) && (
                                    <TextField
                                        type={filter.inputType === FilterInputType.NUMBER ? 'number' : 'text'}
                                        color="secondary"
                                        value={filter.inputValue}
                                        onChange={(event) => {
                                            updateFilterInput(event, index);
                                        }}
                                    />
                                )}
                            </div>
                            <div className={filterClasses.filterSwitchInput}></div>
                        </div>
                        <Divider className={filterClasses.filterDivider} />
                    </div>
                );
            });
        };

        return (
            <>
                <TransactionDialog open={modalOpen} row={selectedRow} onClose={closeModal} />
                <div className={layoutClasses.componentWrapper}>
                    <Slide
                        direction="right"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnSmallMed}`}
                            style={{ minWidth: 345 }}
                        >
                            <div className={filterClasses.filter}>
                                <div className={filterClasses.filterLabels} style={{ marginTop: -20 }}>
                                    <div className={filterClasses.filterlabelName}>{'Transaction Date'}</div>
                                </div>
                                <div className={classes.datePickerWrapper}>
                                    <div>
                                        <div className={classes.datePickerLabel}>From Date</div>
                                        <DatePicker
                                            className={classes.datePicker}
                                            selected={txFromDate}
                                            onChange={(date) => setTxFromDate(date as Date)}
                                            locale="enGb"
                                        />
                                    </div>
                                    <div>
                                        <div className={classes.datePickerLabel}>To Date</div>
                                        <DatePicker
                                            className={classes.datePicker}
                                            selected={txToDate}
                                            onChange={(date) => setTxToDate(date as Date)}
                                            locale="enGb"
                                        />
                                    </div>
                                </div>

                                {!stateToQuery.includes('KYC') && (
                                    <>
                                        <div className={filterClasses.filterLabels}>
                                            <div className={filterClasses.filterlabelName}>{'Issue Date'}</div>
                                        </div>
                                        <div className={classes.datePickerWrapper}>
                                            <div>
                                                <div className={classes.datePickerLabel}>From Date</div>
                                                <DatePicker
                                                    className={classes.datePicker}
                                                    selected={issueFromDate}
                                                    onChange={(date) => setIssueFromDate(date as Date)}
                                                    locale="enGb"
                                                />
                                            </div>
                                            <div>
                                                <div className={classes.datePickerLabel}>To Date</div>
                                                <DatePicker
                                                    className={classes.datePicker}
                                                    selected={issueToDate}
                                                    onChange={(date) => setIssueToDate(date as Date)}
                                                    locale="enGb"
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {!stateToQuery.includes('KYC') && (
                                    <>
                                        <div className={filterClasses.filterLabels}>
                                            <div className={filterClasses.filterlabelName}>{'Filter'}</div>

                                            <div className={filterClasses.filterLabelSwitch}>{'Input'}</div>
                                        </div>
                                        <Divider className={filterClasses.filterDivider} />
                                        {filters()}
                                    </>
                                )}
                            </div>
                        </div>
                    </Slide>
                    <Slide
                        direction="left"
                        in={true}
                        timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
                    >
                        <div
                            className={`${layoutClasses.column} ${layoutClasses.columnNoPadding} ${layoutClasses.columnFill}   `}
                        >
                            <EnhancedTable<TxLogData>
                                defaultSortBy={'txTime'}
                                headCells={HEADCELLS}
                                onClickRow={handleTableRowClick}
                                totalResults={totalResults}
                                page={page}
                                setPage={setPage}
                                data={rows}
                            />
                        </div>
                    </Slide>
                </div>
            </>
        );
    }
);

const useTransactionDashboardStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            datePickerWrapper: {
                width: '100%',
                margin: 'auto',
                borderRadius: theme.shape.borderRadius,
                paddingTop: 16,
                paddingBottom: 16,
                marginBottom: 12,
                backgroundColor: theme.palette.primary.main,
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
            },
            datePicker: {
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 8,
                marginBottom: 8,
                width: 130,
                height: 40,
                padding: 10,
                borderRadius: theme.shape.borderRadius,
                '& input': {
                    color: theme.palette.primary.contrastText,
                    fontSize: 14,
                    borderRadius: theme.shape.borderRadius,
                },

                '& label': {
                    color: theme.palette.primary.contrastText,
                    fontSize: 14,
                },

                '& button': {
                    color: theme.palette.secondary.main,
                },
            },
            datePickerLabel: {
                color: theme.palette.primary.contrastText,
            },
        }),
    { index: 1 }
);
