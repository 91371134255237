import { DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';
import { NumberFormat, RedemptionRequest } from '@r3/cbdc-asset-frontend-core';
import { SvgIcon, Typography } from '@material-ui/core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import React from 'react';
import { useTranslation } from 'react-i18next/';

export interface SimpleDialogProps {
    open: boolean;
    request: RedemptionRequest;

    onClose: () => void;
}

export function RedemptionRequestDialog(props: SimpleDialogProps) {
    const { onClose, request, open } = props;
    const { t } = useTranslation();

    return (
        <DialogWindow
            open={open}
            onClose={onClose}
            title={t('redemptionRequests.dialogTitle') + ' : ' + request.requestState}
        >
            <DialogBody>
                <Typography gutterBottom>
                    {t('commonLabels.requestId') + ':  '}
                    {request.requestId}
                </Typography>
                <Typography gutterBottom>
                    {t('listKyc.requester') + '   '}
                    {request.requestorParty}
                </Typography>
                <Typography gutterBottom>
                    {t('defineAsset.assetName') + ':  '}
                    {request.token.tokenName}
                </Typography>
                <Typography gutterBottom>
                    {'CBDC to redeem:  '}
                    {NumberFormat.addThousandSeparators(request.requestedAmount)}
                </Typography>

                <div style={{ display: 'flex' }}>
                    <Typography gutterBottom> {'Requested Bonds  '}</Typography>
                    <SvgIcon style={{ width: 18, height: 18 }}>
                        <BondsIcon />
                    </SvgIcon>
                    <Typography gutterBottom>
                        {':  ' + NumberFormat.addThousandSeparators(request.requestedAmount)}
                    </Typography>
                </div>
            </DialogBody>
        </DialogWindow>
    );
}
