import { OrderStatus } from 'models/orders/OrderStatus';

const getStatusDisplayLabel = (status: OrderStatus, getLabel: (labelName: string) => string) => {
    switch (status) {
        case OrderStatus.OPEN:
            return getLabel('liquiditySavings.openDisplayStatus');
        case OrderStatus.UNLOCKED:
            return getLabel('liquiditySavings.unlockedDisplayStatus');
        case OrderStatus.CANCELLED:
            return getLabel('liquiditySavings.cancelledDisplayStatus');
    }
    throw new Error(`No display label defined for '${status}' OrderStatus`);
};

export default getStatusDisplayLabel;
