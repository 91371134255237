import { Button, TextField } from '@material-ui/core';
import { validateContactNumber, validateEmail } from '../detailsValidation';

import { Customer } from '@r3/cbdc-asset-frontend-core';
import { CustomerWithDetails } from '../../../hooks/GetCustomerDetails';
import { updateCustomerDetails } from 'api/customerApi';
import { useEffect } from 'react';
import { useFormStyles } from 'materialStyles/formStyles';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type UpdateCustomerDetailsProps = {
    customerWithDetails: CustomerWithDetails;
    updatedCustomer: () => void;
};

const UpdateCustomerDetails: React.FC<UpdateCustomerDetailsProps> = ({ customerWithDetails, updatedCustomer }) => {
    const [customer, setCustomer] = useState<CustomerWithDetails>(customerWithDetails);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(true);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const formClasses = useFormStyles();

    const submitUpdate = async () => {
        setSubmitButtonDisabled(true);
        const response = await updateCustomerDetails(customer);
        if (response.error) {
            enqueueSnackbar(t('error.updatingCustomer', { error: response.error }), {
                variant: 'error',
            });
            return;
        }
        enqueueSnackbar(t('success.updatingCustomer'), {
            variant: 'success',
        });
        setSubmitButtonDisabled(false);
        updatedCustomer();
    };

    const updateFormInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const val = event.target.value;
        const tempCustomer = { ...customer };
        if (event.target.name === 'ContactNumber') {
            tempCustomer.contactNumber = val;
        } else if (event.target.name === 'EmailAddress') {
            tempCustomer.emailAddress = val.toLowerCase();
        } else if (event.target.name === 'PostCode') {
            tempCustomer.postcode = val;
        }
        setCustomer(tempCustomer);
    };

    useEffect(() => {
        const tempCustomer = customerWithDetails as Customer;
        let buttonDisabled = true;
        Object.keys(tempCustomer).forEach((key) => {
            if (customer[key] !== tempCustomer[key]) {
                buttonDisabled = false;
            }
        });
        if (
            customer.contactNumber.length === 0 ||
            customer.emailAddress.length === 0 ||
            customer.postcode.length === 0
        ) {
            buttonDisabled = true;
        }
        setSubmitButtonDisabled(buttonDisabled);
        // eslint-disable-next-line
    }, [customer]);

    return (
        <>
            <TextField
                className={`${formClasses.columnItem} ${formClasses.noLeftMargin} ${layoutClasses.formInput} ${layoutClasses.formInputSmall}`}
                variant="filled"
                fullWidth
                name="EmailAddress"
                type="text"
                value={customer.emailAddress}
                onChange={(event) => {
                    updateFormInput(event);
                }}
                error={customer.emailAddress.length === 0 || !validateEmail(customer.emailAddress)}
                label={!validateEmail(customer.emailAddress) ? 'Please enter a valid email' : 'Email Address'}
                data-testid={'email_input'}
            />
            <TextField
                className={`${formClasses.columnItem} ${formClasses.noLeftMargin} ${layoutClasses.formInput} ${layoutClasses.formInputSmall}`}
                variant="filled"
                fullWidth
                name="ContactNumber"
                type="text"
                value={customer.contactNumber}
                onChange={(event) => {
                    updateFormInput(event);
                }}
                error={customer.contactNumber.length === 0}
                label={
                    !validateContactNumber(customer.contactNumber)
                        ? 'Please enter a valid contact number'
                        : 'Contact Number'
                }
                data-testid={'contact_input'}
            />
            <TextField
                className={`${formClasses.columnItem} ${formClasses.noLeftMargin} ${layoutClasses.formInput} ${layoutClasses.formInputSmall}`}
                variant="filled"
                fullWidth
                name="PostCode"
                type="text"
                value={customer.postcode}
                onChange={(event) => {
                    updateFormInput(event);
                }}
                error={customer.postcode.length === 0}
                label={customer.postcode.length === 0 ? 'Please enter a post code' : 'Post Code'}
                data-testid={'postcode_input'}
            />
            <Button
                className={`${formClasses.columnItem} ${formClasses.noLeftMargin} ${formClasses.formControlButton} submitButton  ${formClasses.button}`}
                variant="outlined"
                color="primary"
                disabled={submitButtonDisabled || !validateEmail(customer.emailAddress) || submitButtonDisabled}
                onClick={() => submitUpdate()}
                data-testid={'submit'}
            >
                {'Submit Update'}
            </Button>
            <Button
                className={`${formClasses.columnItem} ${formClasses.noLeftMargin} ${formClasses.formControlButton} submitButton  ${formClasses.button}`}
                variant="outlined"
                color="primary"
                onClick={() => {
                    setCustomer(customerWithDetails);
                }}
                data-testid={'reset'}
            >
                {'Reset Details'}
            </Button>
        </>
    );
};

export default UpdateCustomerDetails;
