import { PaymentRequestResponse, TokenDataQuery } from '@r3/cbdc-asset-frontend-core';

import { retailApiCall } from './apiCall';

/**
 * @remark Checks to see if auth header is correct and authenticates a user
 * @returns
 * Success or error
 */
export const loginRetailCustomer = async (dontTrack?: boolean) => {
    return retailApiCall({
        method: 'get',
        path: '/retail/api/logon',
        dontTrackRequest: dontTrack,
    });
};

/**
 * @remark Creates a new payee
 *
 * @param id payee id
 * @param despayeeNamecription description for payee
 * @param x500Name the name of the corda x500 of associated wholesale corda node
 *
 * @returns
 * Axios Response which resolves in new redemption request object or error
 */
export const createNewPayee = async (id: string, description: string, x500Name: string) =>
    retailApiCall({
        method: 'post',
        path: '/retail/api/payee/create',
        params: {
            accountId: id,
            payeeName: description,
            accountHostX500: x500Name,
        },
    });

/**
 * @remark Delete payee from customers account
 *
 * @returns
 * Axios Response which resolves in success or error
 */
export const deletePayee = async (payeeId: string) =>
    retailApiCall({
        method: 'post',
        path: `/retail/api/payee/${payeeId}/delete`,
    });

/**
 * @remark Request all payees for retail user
 *
 * @returns
 * Axios Response which resolves in payee object array or error
 */
export const requestAllPayees = async () =>
    retailApiCall({
        method: 'post',
        path: '/retail/api/payee',
        params: { startPage: 1, pageSize: 1000 },
    });

/**
 * @remark Requests all participants on corda network
 * @returns
 * Axios Response which resolves in participant object array or error
 */
export const requestAllParticipants = async () =>
    retailApiCall({
        method: 'get',
        path: '/retail/api/participants',
    });

/**
 * Gets all of the customer details for current logged in customer / account
 *
 * @returns
 * Axios Response which resolves in customer details object or error
 */
export const getCustomerDetails = async (dontTrack?: boolean) =>
    retailApiCall({
        method: 'get',
        path: '/retail/api/',
        dontTrackRequest: dontTrack,
    });

/**
 * @remark Transfers some of chosen token to an added payeee account
 *
 * @param payeeId the id of the payee transfering to
 * @param tokenIdentifier token id of the token definition
 * @param quantity quantity of desired transfer
 *
 * @returns
 * Axios Response which resolves in summary of tranfer or error
 */
export const transferToPayee = async (payeeId: string, tokenIdentifier: string, quantity: number) =>
    retailApiCall({
        method: 'post',
        path: `/retail/api/payee/${payeeId}/transfer`,
        params: {
            tokenIdentifier: tokenIdentifier,
            quantity: quantity,
        },
    });

/**
 * @remarks Creates a payment request
 *
 * @param tokenIdentifier the tokenId of the tokenDefinition being requested
 * @param quantity the amount of the asset being requested
 *
 * @returns
 * Axios Response which resolves in new payment request object or error
 */
export const requestPayment = async (tokenIdentifier: string, quantity: number) =>
    retailApiCall({
        method: 'post',
        path: `/retail/api/paymentRequest/create`,
        params: {
            tokenIdentifier: tokenIdentifier,
            quantity: quantity,
        },
    });

/**
 * @remarks Request all asset definitions on the network
 *
 * @returns
 * Axios Response which resolves in asset definition object array or error
 */
export const requestRetailAssetDefinitions = async (dontTrack?: boolean) =>
    retailApiCall({
        method: 'get',
        path: `/retail/api/assetDefinitions`,
        dontTrackRequest: dontTrack,
    });

/**
 * @remarks Creates a payment request
 *
 * @returns
 * Axios Response which resolves in payment request object arry or error
 */
export const getAllPaymentRequests = async (dontTrack?: boolean) =>
    retailApiCall({
        method: 'post',
        path: `/retail/api/paymentRequest/get`,
        params: {
            pageSpec: { startPage: 1, pageSize: 10000 },
        },
        dontTrackRequest: dontTrack,
    });

/**
 * @remarks Attempt a paymnet to a specific payment request
 *
 * @param paymentRequest Payment request object to be payed
 * @returns
 * Axios Response which resolves in payment request object arry or error
 */
export const payPaymentRequest = async (paymentRequest: PaymentRequestResponse) =>
    retailApiCall({
        method: 'post',
        path: `/retail/api/payPaymentRequest`,
        params: paymentRequest,
    });

/**
 * @remark
 * User to request corda tx logs
 *
 * @param query used to create a query object for the api (See TokenDataQuery definition for more details)
 */
export const requestTokenData = (query: TokenDataQuery, dontTrack?: boolean) =>
    retailApiCall({
        method: 'post',
        path: `/retail/api/transactionLogs`,
        params: query,
        dontTrackRequest: dontTrack,
    });
