import React, { useEffect, useState } from 'react';
import { Theme, ThemeProvider } from '@material-ui/core';

import { AppConfig } from '@r3/cbdc-asset-frontend-core';
import { RETAIL } from '../../constants/Routes';
import { createTheme } from '@material-ui/core/styles';
import { retailTheme } from '../../constants/Theme';
import useAppConfigContext from '../../contexts/AppConfig/AppConfig';

export const ThemeController: React.FC = ({ children }) => {
    const appConfigContext = useAppConfigContext();
    const [customTheme, setCustomTheme] = useState<Theme | undefined>(undefined);

    useEffect(() => {
        if (window.location.pathname.includes(RETAIL)) {
            const icon = document.getElementById('favicon');
            icon?.setAttribute('href', '/retailicon.png');
            if (appConfigContext) {
                setCustomTheme(getThemeFromConfig(appConfigContext.appConfig));
            } else {
                setCustomTheme(getThemeFromConfig(retailTheme));
            }
            return;
        }

        if (appConfigContext) {
            setCustomTheme(getThemeFromConfig(appConfigContext.appConfig));
            return;
        }
    }, [appConfigContext]);

    return <>{customTheme && <ThemeProvider theme={customTheme}>{children}</ThemeProvider>}</>;
};

const getThemeFromConfig = (themeJson: AppConfig): Theme => {
    const theme = createTheme({
        palette: {
            action: {
                disabledBackground: 'grey',
                disabled: 'grey',
                disabledOpacity: 0.6,
            },
            background: {
                default: themeJson.backgroundColour,
            },
            primary: {
                main: themeJson.primaryColourMain,
                dark: themeJson.primaryColourDark,
            },
            secondary: {
                main: themeJson.secondaryColourMain,
            },
        },
        shape: {
            borderRadius: themeJson.borderRadius,
        },
        typography: {
            fontFamily: ['Open Sans', 'sans-serif'].join(','),
        },
        spacing: 4,
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        width: '0.7em',
                    },
                    '*::-webkit-scrollbar-track': {
                        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: themeJson.secondaryColourMain,
                        outline: '2px solid grey',
                    },
                },
            },
        },
    });

    return theme;
};
