import AddRetailPayee from './AddRetailPayee';
import { IconButton } from '@material-ui/core';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import RetailPayeesList from './RetailPayeesList';
import { useRetailItemStyles } from 'materialStyles/retailStyles/retailItemStyles';
import { useState } from 'react';

const RetailPayees = () => {
    const classes = useRetailItemStyles();
    const [addPayeeOpen, setAddPayeeOpen] = useState<boolean>(false);

    return (
        <div className={classes.sectionWrapper}>
            <div className={classes.actionIconWrapper}>
                <IconButton
                    className={classes.actionButton}
                    onClick={() => {
                        setAddPayeeOpen((prev) => !prev);
                    }}
                >
                    {addPayeeOpen && (
                        <RecentActorsRoundedIcon data-testid={'view_payees'} className={classes.actionIcon} />
                    )}
                    {!addPayeeOpen && <PersonAddRoundedIcon data-testid={'add_payee'} className={classes.actionIcon} />}
                </IconButton>
            </div>
            {!addPayeeOpen && <RetailPayeesList />}
            <AddRetailPayee isShowing={addPayeeOpen} />
        </div>
    );
};

export default RetailPayees;
