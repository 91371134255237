import { AccountBalance, CustomerWithDetails } from '../../hooks/GetCustomerDetails';
import { NumberFormat, useInterval } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import { loginRetailCustomer } from '../../api/retailApi';
import { useRetailPayementRequestsContext } from '../../contexts/RetailPaymentRequestContext/RetailPaymentRequestContext';
import { useRetailTransactionsContext } from '../../contexts/RetailTransactionsContext/RetailTransactionsContext';
import { useSnackbar } from 'notistack';
import { useUserContextProvider } from '../../contexts/RetailUserLogin/RetailUserContext';

type TokenNameWithBalanceDifference = {
    tokenName: string;
    balanceDifference: number;
    tokenDecimals: number;
};

const RetailNotificationService = () => {
    const [pollingInterval, setPollingInterval] = useState<number | null>(500);
    const [pollCount, setPollCount] = useState<number>(0);
    const [fetching, setFetching] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [userWithDetails, setUserWithDetails] = useState<CustomerWithDetails>({} as CustomerWithDetails);
    const userContext = useUserContextProvider();
    const { refreshTransactions } = useRetailTransactionsContext();
    const { refreshPaymentRequests } = useRetailPayementRequestsContext();

    const checkUserAccountBalance = async () => {
        if (!userContext?.customerDetails) return;
        await userContext?.refreshCustomer(true);
        if (pollCount < 1) {
            setUserWithDetails(userContext.customerDetails);
            return;
        }
        const balDiffPairs = getAccountBalancesTotal(
            userWithDetails.accountBalances,
            userContext.customerDetails.accountBalances
        );
        if (userContext.customerDetails.accountId.length === 0) {
            return;
        }

        if (pollCount > 1 && balDiffPairs.length > 0) {
            balDiffPairs.forEach((balPair) => {
                enqueueSnackbar(
                    `You have received a payment. ${NumberFormat.addThousandSeparators(
                        balPair.balanceDifference.toFixed(balPair.tokenDecimals)
                    )} of ${balPair.tokenName}.`,
                    {
                        variant: 'info',
                        persist: true,
                    }
                );
            });

            refreshTransactions(true);
            refreshPaymentRequests(true);
        }
        setUserWithDetails(userContext.customerDetails);
    };

    const getAccountBalancesTotal = (
        oldAccountBalances: AccountBalance[],
        newAccountBalances: AccountBalance[]
    ): TokenNameWithBalanceDifference[] => {
        let pairs: TokenNameWithBalanceDifference[] = [];
        newAccountBalances.forEach((acc) => {
            const newAccBal = oldAccountBalances.find(
                (newAcc) => newAcc.tokenDefinition.tokenIdentifier === acc.tokenDefinition.tokenIdentifier
            );
            if (!newAccBal) {
                pairs.push({
                    tokenName: acc.tokenDefinition.tokenName,
                    balanceDifference: acc.balance,
                    tokenDecimals: acc.tokenDefinition.decimals,
                });
                return;
            }
            const balDiff = acc.balance - newAccBal.balance;
            if (balDiff > 0) {
                pairs.push({
                    tokenName: acc.tokenDefinition.tokenName,
                    balanceDifference: balDiff,
                    tokenDecimals: acc.tokenDefinition.decimals,
                });
            }
        });
        return pairs;
    };

    useInterval(() => {
        if (!fetching) {
            setFetching(true);
        }
    }, pollingInterval);

    useEffect(() => {
        const fetchAllData = async () => {
            const receivedResponse = await loginRetailCustomer(true);
            if (receivedResponse) {
                await checkUserAccountBalance();
                setPollCount(pollCount + 1);
            } else {
                enqueueSnackbar(
                    `Live notifcations have stopped, error contacting server. Please refresh to try again.`,
                    {
                        variant: 'info',
                        persist: true,
                    }
                );
                setPollingInterval(null);
            }
            setFetching(false);
        };

        if (fetching) {
            fetchAllData();
        }
        // eslint-disable-next-line
    }, [fetching]);

    return <></>;
};

export default RetailNotificationService;
