import { FunctionComponent, useCallback, useMemo, useState } from 'react';

import DialogState from './DialogState';
import { DialogWindow } from '@r3/cbdc-asset-frontend-core';
import { Order } from 'models/orders/Order';
import OrderDetailsContent from './OrderDetailsContent';
import UpdateOrderContent from './UpdateOrderContent';
import { useTranslation } from 'react-i18next';

interface Props {
    open: boolean;
    order: Order;
    refreshOrders: () => void;
    onClose: () => void;
}

const PendingOrderDialog: FunctionComponent<Props> = ({ open, order, refreshOrders, onClose }) => {
    const { t: labels } = useTranslation();
    const [dialogState, setDialogState] = useState<DialogState>(DialogState.orderDetails);

    function handleClickUpdate() {
        setDialogState(DialogState.updateOrder);
    }

    const handleClose = useCallback(() => {
        setDialogState(DialogState.orderDetails);
        onClose();
    }, [onClose]);

    const dialogContent = useMemo(() => {
        if (dialogState === DialogState.orderDetails) {
            return (
                <OrderDetailsContent
                    order={order}
                    refreshOrders={refreshOrders}
                    onClose={handleClose}
                    onClickUpdate={handleClickUpdate}
                />
            );
        }
        return <UpdateOrderContent order={order} refreshOrders={refreshOrders} onClose={handleClose} />;
    }, [dialogState, order, refreshOrders, handleClose]);

    return (
        <>
            <DialogWindow
                title={`${labels('liquiditySavings.exchangeOffer')}: ${labels('liquiditySavings.openDisplayStatus')}`}
                open={open}
                onClose={onClose}
            >
                {dialogContent}
            </DialogWindow>
        </>
    );
};
export default PendingOrderDialog;
