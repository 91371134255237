import { BROWSEEXCHANGE, MAKEOFFER, MANAGEOFFERS, OFFERLOG } from 'constants/Routes';
import { Link, Route, Switch } from 'react-router-dom';
import { Paper, Tab, Tabs } from '@material-ui/core';

import BrowseExchange from './browseExchange/BrowseExchange';
import CreateOrder from './CreateOrder/CreateOrder';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import { Header } from '@r3/cbdc-asset-frontend-core';
import ManageOrders from './ManageOrders/ManageOrders';
import OfferLogTable from './offerLog/OfferLogTable';
import PostAddRoundedIcon from '@material-ui/icons/PostAddRounded';
import React from 'react';
import { RedirectWithTabIndex } from '@r3/cbdc-asset-frontend-core';
import ViewListRoundedIcon from '@material-ui/icons/ViewListRounded';
import WebRoundedIcon from '@material-ui/icons/WebRounded';
import { useLocation } from 'react-router-dom';
import { useTabStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

const DecentralizedExchange: React.FC = () => {
    const tabClasses = useTabStyles();
    const { t } = useTranslation();
    const location = useLocation<RedirectWithTabIndex>();
    return (
        <>
            <Header>{t('pageTitle.decentralizedExchange')}</Header>
            <Paper elevation={2} className={`${tabClasses.wrapper} `}>
                <Tabs
                    className={`${tabClasses.root} tabs`}
                    classes={{ flexContainer: tabClasses.tabContainer }}
                    value={location.pathname}
                >
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.makeOffer')}
                        icon={<PostAddRoundedIcon />}
                        value={MAKEOFFER}
                        component={Link}
                        to={MAKEOFFER}
                    />
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.manageOffers')}
                        icon={<DynamicFeedIcon />}
                        value={MANAGEOFFERS}
                        component={Link}
                        to={MANAGEOFFERS}
                    />
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.dexOfferLog')}
                        icon={<ViewListRoundedIcon />}
                        value={OFFERLOG}
                        component={Link}
                        to={OFFERLOG}
                    />
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.browseExchange')}
                        icon={<WebRoundedIcon />}
                        value={BROWSEEXCHANGE}
                        component={Link}
                        to={BROWSEEXCHANGE}
                    />
                </Tabs>
                <Switch>
                    <Route path={MAKEOFFER} component={CreateOrder} />
                    <Route path={MANAGEOFFERS} component={ManageOrders} />
                    <Route path={OFFERLOG} component={OfferLogTable} />
                    <Route path={BROWSEEXCHANGE} component={BrowseExchange} />
                </Switch>
            </Paper>
        </>
    );
};

export default DecentralizedExchange;
