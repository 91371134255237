import { AppConfig } from '@r3/cbdc-asset-frontend-core';

export const defaultTheme: AppConfig = {
    appType: 'wholesale1',
    appName: 'Wholesale Bank One',
    title: 'Wholesale Bank One',
    defaultIcons: 'true',
    feedbackFormUrl:
        'https://forms.office.com/Pages/ResponsePage.aspx?id=Lh--pBAtlUGHzXNqyptnLJ2vWFCTYNBEhdZZwJu2CjlUQklTTVRSNDBTVkJaNDA1UkNDTjlWTjE1RS4u&embed=true',
    backgroundColour: '#192231',
    primaryColourMain: '#24344d',
    primaryColourDark: '#192231',
    secondaryColourMain: '#ffc48a',
    centralBankName: 'Central Bank',
    centralBankColour: '#75c7ea',
    retailColour: '#b7e1cd',
    wholesaleBankColour: '#ffc48a',
    wholesaleBankOneName: 'Wholesale Bank One',
    wholesaleBankTwoName: 'Wholesale Bank Two',
    wholesaleBankThreeName: 'Wholesale Bank Three',
    borderRadius: 10,
};

export const retailTheme: AppConfig = {
    appType: 'retail',
    appName: 'Retail Client',
    title: 'Retail Client',
    defaultIcons: 'true',
    feedbackFormUrl:
        'https://forms.office.com/Pages/ResponsePage.aspx?id=Lh--pBAtlUGHzXNqyptnLJ2vWFCTYNBEhdZZwJu2CjlUQklTTVRSNDBTVkJaNDA1UkNDTjlWTjE1RS4u&embed=true',
    backgroundColour: '#192231',
    primaryColourMain: '#24344d',
    primaryColourDark: '#192231',
    secondaryColourMain: '#b7e1cd',
    centralBankName: 'Central Bank',
    centralBankColour: '#75c7ea',
    wholesaleBankColour: '#ffc48a',
    wholesaleBankOneName: 'Wholesale Bank One',
    wholesaleBankTwoName: 'Wholesale Bank Two',
    wholesaleBankThreeName: 'Wholesale Bank Three',
    borderRadius: 10,
};
