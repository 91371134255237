import { Fade, Paper } from '@material-ui/core';
import { approveDvpRequest, denyDvpRequest } from 'api/transactApi';
import { useEffect, useState } from 'react';

import { AssetRequest } from '@r3/cbdc-asset-frontend-core';
import { InfoWidget } from 'components/infoModal/InfoWidget/InfoWidget';
import { ListHeader } from 'components/commonComponents/ListHeader/ListHeader';
import { ResolvedPromise } from 'api/resolvePromise';
import { TransferRequestDialog } from './TransferRequestDialog';
import TransferRequestsList from './TransferRequestsList';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useGetAssetsRequests } from 'hooks/GetAssetRequests';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type SelectedAssetRequest = {
    assetRequest: AssetRequest;
    ownRequest: boolean;
};

export const TransferRequests = () => {
    const layoutClasses = useLayoutStyles();

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAssetRequest, setSelectedAssetRequest] = useState<SelectedAssetRequest | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [showInfoWidget, setShowInfoWidget] = useState<boolean>(false);
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { assetRequests, getAssetRequests, error } = useGetAssetsRequests();
    const { getSummedAssetById } = useGetAssetSummary(true);

    useEffect(() => {
        getAssetRequests();
    }, [getAssetRequests]);

    const submitApproval = async () => {
        if (!selectedAssetRequest) {
            return;
        }
        const approvalResponse: ResolvedPromise = await approveDvpRequest(selectedAssetRequest.assetRequest.requestId);
        if (approvalResponse.error) {
            enqueueSnackbar(t('error.approveAssetRequest', { error: approvalResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.approveAssetRequest'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);
        }
    };

    const submitDeny = async () => {
        if (!selectedAssetRequest) {
            return;
        }
        const denyResponse: ResolvedPromise = await denyDvpRequest(selectedAssetRequest.assetRequest.requestId);

        if (denyResponse.error) {
            enqueueSnackbar(t('error.denyAssetRequest', { error: denyResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.denyAssetRequest'), {
                variant: 'success',
            });
        }
    };

    const openInfoModal = (assetRequest: AssetRequest, ourRequests: boolean) => {
        setSelectedAssetRequest({ assetRequest: assetRequest, ownRequest: ourRequests });
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedAssetRequest(null);
        setModalOpen(false);
    };

    const approve = async () => {
        handleClose();
        await submitApproval();
        getAssetRequests();
        setShowInfoWidget(true);
    };

    const deny = async () => {
        handleClose();
        await submitDeny();
        getAssetRequests();
        setShowInfoWidget(true);
    };

    return (
        <>
            <Fade in={true}>
                <div className={`${layoutClasses.componentWrapper} `}>
                    <Paper elevation={5} className={`${layoutClasses.column} `}>
                        {error.length > 0 ? (
                            <ListHeader
                                text={error}
                                refreshAction={() => {
                                    getAssetRequests();
                                }}
                            />
                        ) : (
                            <>
                                <ListHeader
                                    text={t('pageTitle.assetTransferRequests')}
                                    refreshAction={() => {
                                        getAssetRequests();
                                    }}
                                />
                                <TransferRequestsList assetRequests={assetRequests} openInfoModal={openInfoModal} />
                                {selectedAssetRequest && modalOpen && (
                                    <TransferRequestDialog
                                        open={modalOpen}
                                        request={selectedAssetRequest.assetRequest}
                                        assetSummary={getSummedAssetById(
                                            selectedAssetRequest.assetRequest.tokenDefinition.tokenIdentifier
                                        )}
                                        ownRequest={selectedAssetRequest.ownRequest}
                                        approve={approve}
                                        onClose={handleClose}
                                        decline={deny}
                                    />
                                )}
                            </>
                        )}
                    </Paper>
                </div>
            </Fade>
            <InfoWidget showing={showInfoWidget} />
        </>
    );
};
