import {
    Asset,
    ComplianceRuleKey,
    EMPTY_SUMMED_STATE,
    INITIAL_EMPTY_ASSET_STATE,
    StepperFormStep,
    SummedAsset,
} from '@r3/cbdc-asset-frontend-core';

import AssetAmountsStep from './AssetsAmountsStep';
import { Fade } from '@material-ui/core';
import ReviewRequestStep from './ReviewRequestStep';
import SelectParticipantStep from 'components/commonComponents/GenericStepperFormComponents/SelectParticipantStep';
import SelectSwapAssetsStep from './SelectSwapAssetsStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { requestCrossChainSwap } from 'api/transactApi';
import { useEffect } from 'react';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import useGetParticipantsOnNetwork from 'hooks/GetParticipantsOnNetwork';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RequestCrossChainSwap = () => {
    const [selectedAsset, setSelectedAsset] = useState<SummedAsset>(EMPTY_SUMMED_STATE);
    const [assetAmount, setAssetAmount] = useState<string>('');
    const [selectedSwapAsset, setSelectedSwapAsset] = useState<Asset>(INITIAL_EMPTY_ASSET_STATE);
    const [swapAssetAmount, setSwapAssetAmount] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { assets, getAllAssets, assetsError } = useGetAssetDefinitions();
    const { getAssetSummary, assetSummary, summaryError } = useGetAssetSummary();
    const [assetsForTransfer, setAssetsForTransfer] = useState<SummedAsset[]>([]);
    const [selectedParticipant, setSelectedParticipant] = useState<string>('');
    const { participants, getParticipants, participantsError } = useGetParticipantsOnNetwork();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const checklistContext = useInteractiveChecklistContext();
    const location = useLocation();

    useEffect(() => {
        setError(assetsError);
    }, [assetsError]);

    useEffect(() => {
        setError(summaryError);
    }, [summaryError]);

    useEffect(() => {
        setError(participantsError);
    }, [participantsError]);

    useEffect(() => {
        getAllAssets();
    }, [getAllAssets]);

    useEffect(() => {
        if (assets.length > 0) {
            getParticipants();
            getAssetSummary();
        }
    }, [assets, getAssetSummary, getParticipants]);

    const handleReset = () => {
        setSelectedAsset(EMPTY_SUMMED_STATE);
        setSelectedSwapAsset(INITIAL_EMPTY_ASSET_STATE);
        setAssetAmount('');
        setSwapAssetAmount('');
        setError('');
        getAllAssets();
    };

    useEffect(() => {
        setAssetsForTransfer(
            assetSummary.map((assetSummary) => ({
                tokenIdentifier: assetSummary.tokenDefinition.tokenIdentifier,
                totalInVault: assetSummary.totalAmount,
                totalInAccounts: assetSummary.amountHeldByAccounts,
                availableToTransact: assetSummary.amountHeldByNode,
                tokenName: assetSummary.tokenDefinition.tokenName,
                tokenDecimal: assetSummary.tokenDecimals,
                complianceRules: assets.find((a) => a.tokenIdentifier === assetSummary.tokenDefinition.tokenIdentifier)
                    ?.complianceRules,
                issuer: assetSummary.issuer,
            }))
        );
    }, [assetSummary, assets]);

    const invalidAssetAmountInput = (asset: SummedAsset | Asset, amount: string, totalInVault?: number): boolean => {
        if (amount.length <= 0 || parseFloat(amount) <= 0) {
            return true;
        }
        if (
            asset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) &&
            parseFloat(asset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)!.value) <
                parseFloat(amount)
        ) {
            return true;
        }
        if (totalInVault) {
            if (totalInVault < parseFloat(amount)) {
                return true;
            }
        }
        return false;
    };

    const submitRequest = async () => {
        const response = await requestCrossChainSwap(
            selectedSwapAsset.tokenIdentifier,
            parseFloat(swapAssetAmount),
            selectedAsset.tokenIdentifier,
            parseFloat(assetAmount),
            selectedParticipant
        );

        if (response.error) {
            enqueueSnackbar(t('error.requestingCrossChainSwap', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.requestingCrossChainSwap'), {
                variant: 'success',
            });
            checklistContext?.completeStep(location.pathname);
        }
        handleReset();
    };

    const selectedAssetForTransfer = assetsForTransfer.find(
        (asset) => asset.tokenIdentifier === selectedAsset.tokenIdentifier
    );

    const steps: StepperFormStep[] = [
        {
            title: t('crossChainSwap.selectParticipantStep'),
            component: (
                <SelectParticipantStep
                    title={t('crossChainSwap.selectParticipant')}
                    participants={participants.participants}
                    selectedParticipant={selectedParticipant}
                    setSelectedParticipant={setSelectedParticipant}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedParticipant.length > 0) {
                    return false;
                }
                return true;
            },
        },
        {
            title: t('crossChainSwap.selectAssetsStep'),
            component: (
                <SelectSwapAssetsStep
                    assets={assets}
                    transferableAssets={assetsForTransfer}
                    selectedAsset={selectedAsset}
                    selectedSwapAsset={selectedSwapAsset}
                    setSelectedAsset={setSelectedAsset}
                    setSelectedSwapAsset={setSelectedSwapAsset}
                />
            ),
            isNextStepDisabled: () => {
                if (
                    selectedAsset.tokenIdentifier.length > 0 &&
                    selectedAsset.availableToTransact > 0 &&
                    selectedSwapAsset.tokenIdentifier.length > 0
                ) {
                    return false;
                }
                return true;
            },
        },
        {
            title: t('crossChainSwap.assetAmountsStep'),
            component: (
                <AssetAmountsStep
                    selectedAsset={selectedAsset}
                    selectedAssetAmountInVault={selectedAssetForTransfer?.totalInVault}
                    selectedAssetAvailableToTransact={selectedAssetForTransfer?.availableToTransact}
                    selectedSwapAsset={selectedSwapAsset}
                    assetAmount={assetAmount}
                    swapAmount={swapAssetAmount}
                    setAssetAmount={setAssetAmount}
                    setSwapAmount={setSwapAssetAmount}
                    invalidAssetInput={invalidAssetAmountInput}
                />
            ),
            isNextStepDisabled: () => {
                if (
                    !invalidAssetAmountInput(
                        selectedAsset,
                        assetAmount,
                        selectedAssetForTransfer?.availableToTransact
                    ) &&
                    !invalidAssetAmountInput(selectedSwapAsset, swapAssetAmount)
                ) {
                    return false;
                }
                return true;
            },
        },
        {
            title: t('crossChainSwap.reviewStep'),
            component: (
                <ReviewRequestStep
                    selectedParticipant={selectedParticipant}
                    swapAsset={selectedSwapAsset}
                    swapAssetAmount={swapAssetAmount}
                    selectedAsset={selectedAsset}
                    assetAmount={assetAmount}
                    assetInVaultAmount={
                        assetsForTransfer.find((asset) => asset.tokenIdentifier === selectedAsset.tokenIdentifier)
                            ?.totalInVault
                    }
                />
            ),
            isNextStepDisabled: () => false,
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitRequest}
                    steps={steps}
                    renderError={error}
                    submitButtonText={'Request'}
                    handleReset={handleReset}
                />
            </Fade>
        </>
    );
};

export default RequestCrossChainSwap;
