import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';
import './i18n/i18n';

import { FeatureFlagContextProvider, InfoModalContextProvider } from '@r3/cbdc-asset-frontend-core';

import { App } from 'components/app/App';
import { AppConfigContextProvider } from 'contexts/AppConfig/AppConfig';
import { CssBaseline } from '@material-ui/core';
import { FEATURE_PAGES } from 'featureFlags/features';
import { INFO_MODAL_PAGES } from 'constants/InfoModal';
import { InteractiveChecklistContextProvider } from './contexts/InteractiveChecklist/InteractiveChecklist';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeController } from './components/themeController/ThemeController';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <Router>
        <AppConfigContextProvider>
            <InteractiveChecklistContextProvider>
                <InfoModalContextProvider infoModalPages={INFO_MODAL_PAGES}>
                    <FeatureFlagContextProvider defaultFeaturePages={FEATURE_PAGES}>
                        <ThemeController>
                            <CssBaseline />
                            <App />
                        </ThemeController>
                    </FeatureFlagContextProvider>
                </InfoModalContextProvider>
            </InteractiveChecklistContextProvider>
        </AppConfigContextProvider>
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
