import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import AddPayeeQr from './AddPayeeQr';
import CropFreeRoundedIcon from '@material-ui/icons/CropFreeRounded';
import { ShareRetailAccountDetails } from '@r3/cbdc-asset-frontend-core';
import { createNewPayee } from '../../../api/retailApi';
import { useFormStyles } from 'materialStyles/formStyles';
import useGetRetailParticipantsOnNetwork from '../../../hooks/GetRetailParticipants';
import { useRetailItemStyles } from 'materialStyles/retailStyles/retailItemStyles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type AddRetailPayeeProps = {
    isShowing: boolean;
};

const AddRetailPayee: React.FC<AddRetailPayeeProps> = ({ isShowing }) => {
    const classes = useRetailItemStyles();
    const formClasses = useFormStyles();
    const [id, setId] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const { participants, getParticipants } = useGetRetailParticipantsOnNetwork();
    const [x500Name, setX500Name] = useState<string>('');
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleFormInput = (event) => {
        event.preventDefault();
        const value = event.target.value;
        if (event.target.name === 'Id') {
            setId(value);
        } else if (event.target.name === 'Description') {
            setDesc(value);
        }
    };

    const reset = () => {
        setId('');
        setDesc('');
        setX500Name('');
    };

    const setPayeeDetails = (payeeDetails: ShareRetailAccountDetails) => {
        setId(payeeDetails.accountId);
        setDesc(payeeDetails.desc);
        setX500Name(payeeDetails.x500);
        setIsDialogOpen(false);
    };

    const submit = async () => {
        setSubmitDisabled(true);
        const response = await createNewPayee(id, desc, x500Name);
        if (response.error) {
            enqueueSnackbar(t('error.creatingPayee', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.creatingPayee'), {
                variant: 'success',
            });
            reset();
        }
        setSubmitDisabled(false);
    };

    useEffect(() => {
        getParticipants();
    }, [getParticipants]);

    const handleParticipantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setX500Name(event.target.value as string);
    };

    return (
        <div className={classes.itemListWrapper} style={{ display: isShowing ? 'block' : 'none' }}>
            <div style={{ width: '100%', display: 'flex', marginTop: 6 }}>
                <div className={classes.itemListTitle}>Add new payee</div>
                <IconButton
                    style={{ margin: '2px 2px auto 2px', color: 'white', opacity: 1, fontSize: 16 }}
                    onClick={() => {
                        setIsDialogOpen(true);
                    }}
                >
                    QR
                    <CropFreeRoundedIcon style={{ width: 22, height: 22, marginLeft: 6 }} />
                </IconButton>
            </div>
            <div style={{ width: '90%', marginTop: 2 }}>
                <TextField
                    className={`${formClasses.columnItem} idInput ${formClasses.formInput}`}
                    label={'Payee Account ID'}
                    onChange={(event) => {
                        handleFormInput(event);
                    }}
                    name="Id"
                    value={id}
                    required
                    variant="filled"
                    data-testid={'id_input'}
                />
                <TextField
                    className={`${formClasses.columnItem} descInput ${formClasses.formInput}`}
                    label={'Name (Pick a name for new payee)'}
                    onChange={(event) => {
                        handleFormInput(event);
                    }}
                    name="Description"
                    value={desc}
                    required
                    variant="filled"
                    data-testid={'description_input'}
                />
                <FormControl
                    id="SelectParticipant"
                    variant="filled"
                    className={`${formClasses.columnItem} descInput ${formClasses.formInput}`}
                >
                    <InputLabel id="SelectLabel">{'Select wholesale bank'}</InputLabel>
                    <Select
                        labelId="SelectLabel"
                        value={x500Name}
                        onChange={handleParticipantChange}
                        data-testid={'x500_input'}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {participants.participants
                            .filter((p) => !p.toLowerCase().includes('cb'))
                            .map((participant, i) => {
                                return (
                                    <MenuItem key={participant + i} value={participant}>
                                        {participant}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>

                <Button
                    className={`${formClasses.formControlButton} submitButton  ${formClasses.button}`}
                    style={{ marginTop: 16 }}
                    variant="outlined"
                    color="primary"
                    disabled={id.length === 0 || desc.length === 0 || x500Name.length === 0 || submitDisabled}
                    onClick={() => submit()}
                    data-testid={'submit'}
                >
                    {'Add Payee'}
                </Button>
            </div>
            <DialogWindow
                open={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                title={'Add payee with QR code.'}
            >
                <DialogBody>
                    <AddPayeeQr setPayeeDetails={setPayeeDetails} />
                </DialogBody>
            </DialogWindow>
        </div>
    );
};

export default AddRetailPayee;
