import AssetSummary from '../SummaryComponents/AssetSummary';
import { SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
    asset: SummedAsset | undefined;
    toSpend: number;
    showRemainingAssets?: boolean;
    showTitle?: boolean;
    showAssetName?: boolean;
    showSpendAmount?: boolean;
};

const DialogTransactionSummary: React.FC<Props> = ({
    asset,
    toSpend,
    showRemainingAssets = true,
    showTitle = true,
    showAssetName = true,
    showSpendAmount = true,
}) => {
    const { t: texts } = useTranslation();
    if (!asset) {
        return (
            <Typography variant={'h6'} style={{ color: 'orange' }} gutterBottom>
                {texts('transactionSummary.noAssetInVault')}
            </Typography>
        );
    }
    return (
        <>
            {showTitle && (
                <Typography gutterBottom style={{ marginBottom: 12 }}>
                    {texts('transactionSummary.transactionSummary')}
                </Typography>
            )}
            <AssetSummary
                asset={asset}
                toSpend={toSpend}
                showRemainingAssets={showRemainingAssets}
                style={{ marginRight: 'auto' }}
                small
                showAssetName={showAssetName}
                showSpendAmount={showSpendAmount}
            />
        </>
    );
};

export default DialogTransactionSummary;
