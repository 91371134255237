import { useCallback, useState } from 'react';

import { DvpRequest } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllDvpRequests } from 'api/transactApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export function useGetDvPRequests() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>('');
    const [dvpRequests, setDvpRequests] = useState<DvpRequest[]>([]);

    const getDvpRequests = useCallback(
        async (dontTrack?: boolean) => {
            setError('');
            const dvpRequestsResponse: ResolvedPromise = await requestAllDvpRequests();
            if (dvpRequestsResponse.error) {
                if (dontTrack) {
                    return;
                }
                enqueueSnackbar(t('error.gettingDvPRequests', { error: dvpRequestsResponse.error }), {
                    variant: 'error',
                });
                setError(dvpRequestsResponse.error.length > 0 ? dvpRequestsResponse.error : t('error.networkError'));
            } else {
                if (dvpRequestsResponse.data.length <= 0) {
                    const warningText = t('No DVP Requests');
                    if (!dontTrack) {
                        enqueueSnackbar(warningText, {
                            variant: 'info',
                        });
                        setError(warningText);
                    }
                }
                setDvpRequests(dvpRequestsResponse.data as DvpRequest[]);
            }
        },
        [t, enqueueSnackbar]
    );

    return { dvpRequests, getDvpRequests, error };
}
