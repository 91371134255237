import React, { ChangeEvent } from 'react';

import { TextField } from '@material-ui/core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

type AppConfigThemeInputFieldProps = {
    name: string;
    value: string;
    handleInputChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    optional?: boolean;
};

const AppConfigThemeInputField: React.FC<AppConfigThemeInputFieldProps> = (props) => {
    const { name, value, handleInputChange, optional } = props;
    const layoutClasses = useLayoutStyles();
    return (
        <TextField
            variant="filled"
            className={`${layoutClasses.formInput}`}
            style={{ marginTop: 2, marginBottom: 2 }}
            error={optional ? false : value.length === 0}
            label={value.length > 0 ? name : optional ? `(Optional) ${name}` : `Enter ${name}`}
            value={value}
            onChange={handleInputChange}
            name={name}
        />
    );
};

export default AppConfigThemeInputField;
