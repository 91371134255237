import { LineData, NumberFormat, useScaledColors, useToggledLineData } from '@r3/cbdc-asset-frontend-core';
import { PointTooltip, ResponsiveLine } from '@nivo/line';
import React, { useMemo } from 'react';

import { Theme } from '@nivo/core';
import useAppConfigContext from 'contexts/AppConfig/AppConfig';
import { useMediaQuery } from 'react-responsive';

export const LineTheme: Theme = {
    textColor: '#fafafa',
    fontSize: 13,
    axis: {
        domain: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#777777',
                strokeWidth: 1,
            },
        },
    },
    grid: {
        line: {
            stroke: '#F7F7F6',
            strokeWidth: 0.5,
            opacity: 0.8,
        },
    },
};

interface Props {
    data: LineData[];
    pointTooltip?: PointTooltip;
}

const DashboardResponsiveLine: React.FC<Props> = ({ data, pointTooltip }) => {
    const isSmallScreen = useMediaQuery({ maxWidth: 1550 });
    const appConfigContext = useAppConfigContext();
    const { colors } = useScaledColors(
        data,
        appConfigContext?.appConfig.nivoPieTheme,
        appConfigContext?.appConfig.secondaryColourMain
    );
    const { displayData, displayedColors, legendData } = useToggledLineData(data, colors);

    const minValue = useMemo(() => {
        const min = displayData.reduce(
            (prev, item) =>
                Math.min(
                    prev,
                    item.data.reduce((prev, item) => Math.min(prev, item.y), Infinity)
                ),
            Infinity
        );
        return min === Infinity ? 0 : min;
    }, [displayData]);

    return (
        <ResponsiveLine
            theme={LineTheme}
            colors={displayedColors}
            data={displayData}
            pointSize={8}
            enableGridX={true}
            animate={true}
            margin={{
                top: 10,
                right: 180,
                bottom: 130,
                left: 120,
            }}
            yScale={{
                type: 'linear',
                stacked: false,
                min: 'auto',
                max: 'auto',
            }}
            curve="monotoneX"
            xScale={{
                type: 'time',
                precision: 'second',
                format: 'native',
            }}
            axisBottom={{
                tickSize: 8,
                tickRotation: 90,
                tickValues: isSmallScreen ? 12 : 19,
                format: '%b %d %H:%M:%S',
            }}
            axisLeft={{
                tickSize: 6,
                tickValues: 6,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'amount',
                legendOffset: -100,
                format: function (value) {
                    return NumberFormat.addThousandSeparators(value.toString());
                },
            }}
            tooltip={pointTooltip}
            isInteractive={true}
            useMesh={true}
            enableCrosshair={true}
            areaBaselineValue={minValue}
            areaOpacity={0.5}
            enableArea
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 75,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    data: legendData,
                },
            ]}
        />
    );
};

export default DashboardResponsiveLine;
