import './App.css';

import * as ROUTES from 'constants/Routes';

import { FeatureWrapper } from '@r3/cbdc-asset-frontend-core';
import Footer from 'components/layout/Footer/Footer';
import { PROMISETRACKERCONFIG } from 'constants/PromiseTrackerConfig';
import React from 'react';
import RetailWrapper from '../applicationWrappers/RetailWrapper';
import { SMALLSCREENWIDTH } from 'constants/ScreenSizes';
import SnackbarCloseButton from '../commonComponents/Notifications/SnackBarCloseButton';
import { SnackbarProvider } from 'notistack';
import { UserContextProvider } from 'contexts/RetailUserLogin/RetailUserContext';
import WholesaleWrapper from '../applicationWrappers/WholesaleWrapper';
import { useAppStyles } from 'materialStyles/appStyles';
import { useMediaQuery } from 'react-responsive';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';

export const App: React.FC = () => {
    const classes = useAppStyles();
    const { promiseInProgress } = usePromiseTracker(PROMISETRACKERCONFIG);
    const { t } = useTranslation();
    const retailClient = window.location.pathname.includes(ROUTES.RETAIL);
    const isSmallScreen = useMediaQuery({ maxWidth: SMALLSCREENWIDTH });
    return (
        <SnackbarProvider
            id="snackBarProvider"
            className="snackBarProvider"
            maxSnack={5}
            anchorOrigin={{
                vertical: isSmallScreen ? 'bottom' : 'top',
                horizontal: isSmallScreen ? 'center' : 'right',
            }}
            classes={{ root: classes.notifBase }}
            action={(k) => (
                <>
                    <SnackbarCloseButton snackKey={k} />
                </>
            )}
        >
            <div className={'App'}>
                {retailClient ? (
                    <FeatureWrapper featureName="retailCBDC">
                        <UserContextProvider>
                            <RetailWrapper promiseInProgress={promiseInProgress} />
                        </UserContextProvider>
                    </FeatureWrapper>
                ) : (
                    <WholesaleWrapper promiseInProgress={promiseInProgress} />
                )}
                <Footer copyright="© 2022 R3. All rights reserved.">
                    <a rel="noopener noreferrer" href="/#" title="Terms and Conditions">
                        {t('commonText.termsAndConditions')}
                    </a>
                    <a rel="noopener noreferrer" href="/#" title="Privacy Policy">
                        {t('commonText.rightsReserved')}
                    </a>
                </Footer>
            </div>
        </SnackbarProvider>
    );
};
