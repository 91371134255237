import { Divider, Paper, Typography } from '@material-ui/core';

import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import SummaryRow from 'components/commonComponents/SummaryComponents/SummaryRow';
import SummaryTable from 'components/commonComponents/SummaryComponents/SummaryTable';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type Props = {
    assetName: string;
    selectedParticipant: string;
    assetAmount: string;
};

const RequestTransferSummaryStep: React.FC<Props> = (props) => {
    const { assetName, selectedParticipant, assetAmount } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('requestTransfer.summaryMessage')}
                </Typography>

                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.text}`} style={{ marginTop: 12, marginBottom: 12 }}>
                    {t('transferAssets.receivingParty') + ': '} {selectedParticipant}
                </Typography>
                <SummaryTable>
                    <SummaryRow
                        col1Text={`Requested amount of ${assetName}`}
                        col2Text={NumberFormat.addThousandSeparators(assetAmount)}
                    />
                </SummaryTable>
            </Paper>
        </div>
    );
};

export default RequestTransferSummaryStep;
