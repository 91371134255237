import { FeaturePage } from '@r3/cbdc-asset-frontend-core';

export const FEATURE_PAGES: FeaturePage[] = [
    {
        feature: { featureName: 'cbdcManager', label: 'CBDC Manager', enabled: true },
        subFeatures: [
            { featureName: 'viewAssets', label: 'View CBDCs', enabled: true },
            { featureName: 'requestCBDC', label: 'Request CBDC', enabled: true },
            { featureName: 'transferCBDC', label: 'Transfer CBDC', enabled: true },
            { featureName: 'redeemCBDC', label: 'Redeem CBDC', enabled: true },
            { featureName: 'dvp', label: 'Request DVP', enabled: true },
            { featureName: 'crossChainSwap', label: 'Cross Chain Swap', enabled: true },
        ],
    },
    {
        feature: { featureName: 'accessControls', label: 'Access Controls', enabled: true },
        subFeatures: [
            { featureName: 'viewMemberAccessStates', label: 'View Member Access', enabled: true },
            { featureName: 'requestAccess', label: 'Request Access', enabled: true },
        ],
    },
    {
        feature: {
            featureName: 'requests',
            label: 'Requests',
            enabled: true,
        },
        subFeatures: [
            { featureName: 'issuanceRequests', label: 'Issuance Requests', enabled: true },
            { featureName: 'redemptionRequests', label: 'Redemption Requests', enabled: true },
            { featureName: 'dvpRequests', label: 'DVP Requests', enabled: true },
            { featureName: 'pullTransferRequests', label: 'Transfer Requests', enabled: true },
            { featureName: 'crossChainRequests', label: 'Cross Chain Requests', enabled: true },
            { featureName: 'memberAccessRequests', label: 'Member Requests', enabled: true },
        ],
    },
    { feature: { featureName: 'treasuryDashboard', label: 'Treasury Dashboard', enabled: true } },
    { feature: { featureName: 'decentralizedExchange', label: 'Decentralized Exchange', enabled: true } },
    { feature: { featureName: 'txDashboard', label: 'TX Dashboard', enabled: true } },
    { feature: { featureName: 'retailCBDC', label: 'Retail CBDC', enabled: true } },
];
