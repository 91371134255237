import React, { useContext } from 'react';

import { TxLogData } from '@r3/cbdc-asset-frontend-core';
import { useGetRetailTransactionLogs } from '../../hooks/GetRetailTransactionLogs';

type RetailTransactionsContextProps = {
    data: TxLogData[];
    refreshTransactions: (trackPromise?: boolean) => void;
    error: string;
    loading: boolean;
};

export const RetailTransactionsContext = React.createContext<RetailTransactionsContextProps>(
    {} as RetailTransactionsContextProps
);

export const RetailTransactionsContextProvider = ({ children }) => {
    const { txLogData, getTxLogData, error, loading } = useGetRetailTransactionLogs(true);
    return (
        <RetailTransactionsContext.Provider
            value={{ data: txLogData, refreshTransactions: getTxLogData, error, loading }}
        >
            {children}
        </RetailTransactionsContext.Provider>
    );
};

export const useRetailTransactionsContext = () => useContext(RetailTransactionsContext);
