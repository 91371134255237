import { NumberFormat, PvpRequest } from '@r3/cbdc-asset-frontend-core';

import { DEFAULT_SNACKBAR_INFO_OPTIONS } from './PollingNotificationService';
import { ProviderContext } from 'notistack';
import { ResolvedPromise } from 'api/resolvePromise';
import { requestAllCrossChainSwapRequests } from 'api/transactApi';

export const runPvpNotificationQueries = async (
    enqueueSnackbar: ProviderContext['enqueueSnackbar'],
    identity: string,
    pollCount: number,
    existingPvpRequests: PvpRequest[],
    setExistingPvpRequests: (requests: PvpRequest[]) => void
) => {
    const requestsResponse: ResolvedPromise = await requestAllCrossChainSwapRequests(true);
    if (!requestsResponse.error) {
        const requests = requestsResponse.data as PvpRequest[];
        if (requests.length !== existingPvpRequests.length && pollCount > 1) {
            notifyAboutNewPvpRequests(enqueueSnackbar, identity, existingPvpRequests, requests);
        }
        setExistingPvpRequests(requests);
    }
};

const notifyAboutNewPvpRequests = (
    enqueueSnackbar: ProviderContext['enqueueSnackbar'],
    identity: string,
    existingPvpRequests: PvpRequest[],
    requests: PvpRequest[]
) => {
    const newRequests = requests.filter(
        (r) => r.buyer !== identity && !existingPvpRequests.some((er) => er.requestId === r.requestId)
    );

    newRequests.forEach((request) => {
        enqueueSnackbar(
            `New Cross Chain Swap request from ${request.buyer}. Requesting ${NumberFormat.addThousandSeparators(
                request.assetAmount.toFixed(3)
            )} of ${request.tokenDefinition.tokenName}. Go to Requests page to view.`,
            DEFAULT_SNACKBAR_INFO_OPTIONS
        );
    });
};
