import { useCallback, useState } from 'react';

import { DEFAULT_SNACKBAR_INFO_OPTIONS } from './PollingNotificationService';
import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { Order } from 'models/orders/Order';
import { OrderStatus } from 'models/orders/OrderStatus';
import { getOrders } from 'api/decentralizedExchange';
import { useSnackbar } from 'notistack';

const useExchangeOfferNotifications = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [orders, setOrders] = useState<Order[]>([]);

    const getNotifications = useCallback(
        async (pollingCount: number, identity: string) => {
            const response = await getOrders(
                OrderStatus.UNLOCKED,
                identity,
                undefined,
                undefined,
                undefined,
                'RELEVANT',
                true
            );
            if (response.error != null) {
                return;
            }

            const currentOrders = response.data as Order[];
            if (pollingCount > 1) {
                const newFilledOrders = currentOrders.filter((o) => !orders.some((os) => os.orderId === o.orderId));
                newFilledOrders.forEach((nao) =>
                    enqueueSnackbar(
                        `Offer for '${NumberFormat.addThousandSeparators(
                            nao.requestedAssetAmount.toFixed(nao.requestedTokenDefinition.tokenDecimals)
                        )} of ${nao.requestedTokenDefinition.tokenName} / ` +
                            `${NumberFormat.addThousandSeparators(
                                nao.offeredAssetAmount.toFixed(nao.offeredTokenDefinition.tokenDecimals)
                            )} of ${nao.offeredTokenDefinition.tokenName}' ` +
                            `has been accepted by '${nao.receiver}'.`,
                        DEFAULT_SNACKBAR_INFO_OPTIONS
                    )
                );
            }
            setOrders(currentOrders);
        },
        [orders, enqueueSnackbar]
    );

    return getNotifications;
};

export default useExchangeOfferNotifications;
