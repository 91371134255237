import { SvgIcon, Typography } from '@material-ui/core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import { NumberFormat, RedemptionRequest } from '@r3/cbdc-asset-frontend-core';
import RequestListContainer from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListContainer';
import RequestListItem from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListItem';
import { useTranslation } from 'react-i18next';
import { getRequestStatusColor } from '../requestStatusColors';

type RedemptionRequestProps = {
    request: RedemptionRequest;
    onClick: () => void;
};

const RedemptionRequestComponent: React.FC<RedemptionRequestProps> = ({ onClick, request }) => {
    const { t } = useTranslation();
    return (
        <RequestListContainer onClick={() => onClick()}>
            <RequestListItem>
                {t('defineAsset.assetName') + ': '}
                {request.token.tokenName}
            </RequestListItem>
            <RequestListItem>
                {'To redeem'}
                {': ' + NumberFormat.addThousandSeparators(request.requestedAmount)}
            </RequestListItem>
            <div style={{ display: 'flex' }}>
                <Typography style={{ fontSize: 14 }}>{'Bond Value Offered'}</Typography>
                <SvgIcon style={{ width: 16, height: 16 }}>
                    <BondsIcon />
                </SvgIcon>
                <Typography style={{ fontSize: 14 }}>
                    {': ' + NumberFormat.addThousandSeparators(request.requestedAmount)}
                </Typography>
            </div>
            <RequestListItem>
                <span>{t('commonText.status') + ': '}</span>
                <span style={{ color: getRequestStatusColor(request.requestState) }}>{request.requestState}</span>
            </RequestListItem>
        </RequestListContainer>
    );
};

export default RedemptionRequestComponent;
