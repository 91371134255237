import { DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';

import { KycRequest } from '@r3/cbdc-asset-frontend-core';
import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next/';

export interface SimpleDialogProps {
    open: boolean;
    kycRequest: KycRequest;
    onClose: () => void;
}

export function KycRequestDialogue(props: SimpleDialogProps) {
    const { onClose, kycRequest, open } = props;
    const { t } = useTranslation();

    return (
        <DialogWindow open={open} title={t('listKyc.summaryOfKycRequest')} onClose={onClose}>
            <DialogBody>
                <Typography gutterBottom>
                    {t('commonText.uniqueId') + ': '}
                    {kycRequest.id.id}
                </Typography>
                <Typography gutterBottom>
                    {t('listKyc.issuer') + ': '}
                    {kycRequest.issuer}
                </Typography>
                <Typography gutterBottom>
                    {t('listKyc.authorisedParty') + ': '}
                    {kycRequest.authorisedParty}
                </Typography>
            </DialogBody>
        </DialogWindow>
    );
}
