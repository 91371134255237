import { DialogAction, DialogActions, DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';

import { Order } from 'models/orders/Order';
import SwapOfferDisplay from 'components/decentralizedExchange/SwapOfferDisplay';
import { useTranslation } from 'react-i18next';
import getStatusDisplayLabel from 'components/decentralizedExchange/getStatusDisplayLabel';

export type ExchangeDialogAction = {
    text: string;
    action: (offer: Order) => void;
    icon: JSX.Element;
    closeOnClick?: boolean;
};

export type ManageExchangeOfferDialogProps = {
    isOpen: boolean;
    order: Order;
    onClose: () => void;
    actions?: ExchangeDialogAction[];
};

const ManageExchangeOrderDialog: React.FC<ManageExchangeOfferDialogProps> = ({ isOpen, order, onClose, actions }) => {
    const { t: labels } = useTranslation();
    return (
        <DialogWindow
            open={isOpen}
            onClose={onClose}
            title={`${labels('liquiditySavings.exchangeOffer')}: ${getStatusDisplayLabel(order.status, labels)}`}
        >
            <DialogBody>
                <SwapOfferDisplay order={order} />
            </DialogBody>
            {actions && (
                <DialogActions>
                    {actions.map((action) => (
                        <DialogAction
                            key={action.text + '_action'}
                            text={action.text}
                            action={() => {
                                action.action(order);
                                if (action.closeOnClick) {
                                    onClose();
                                }
                            }}
                            icon={action.icon}
                        />
                    ))}
                </DialogActions>
            )}
        </DialogWindow>
    );
};

export default ManageExchangeOrderDialog;
