import { AppConfig, ColorOperations } from '@r3/cbdc-asset-frontend-core';
import { NivoColourSchemes, ValidAppConfigTypes } from 'constants/AppConfig';

export const isValidAppConfig = (appConfig: AppConfig): boolean => {
    let validAppConfig = true;

    if (appConfig.appType.length === 0 || !ValidAppConfigTypes.includes(appConfig.appType)) {
        console.error(
            `Invalid appType : please make sure the appType is one of the following: ${ValidAppConfigTypes.join(' | ')}`
        );
        validAppConfig = false;
    }
    if (appConfig.appName.length === 0) {
        console.error('Please have a valid appName.');
        validAppConfig = false;
    }
    if (!ColorOperations.isValidHexColour(appConfig.backgroundColour)) {
        console.error('Invalid backgroundColour in theme.json');
        validAppConfig = false;
    }
    if (!ColorOperations.isValidHexColour(appConfig.primaryColourMain)) {
        console.error('Invalid primaryCoulourMain in theme.json');
        validAppConfig = false;
    }
    if (!ColorOperations.isValidHexColour(appConfig.primaryColourDark)) {
        console.error('Invalid primaryCoulourDark in theme.json');
        validAppConfig = false;
    }
    if (!ColorOperations.isValidHexColour(appConfig.secondaryColourMain)) {
        console.error('Invalid secondaryColourMain in theme.json');
        validAppConfig = false;
    }
    if (!ColorOperations.isValidHexColour(appConfig.centralBankColour)) {
        console.error('Invalid centralBankColour in theme.json');
        console.error(appConfig.centralBankColour);
        validAppConfig = false;
    }
    if (
        appConfig.nivoPieTheme &&
        appConfig.nivoPieTheme.length > 0 &&
        !NivoColourSchemes.includes(appConfig.nivoPieTheme)
    ) {
        console.error('Invalid nivoPieTheme in theme.json applying pie graph colours based of secondary colour');
        console.log('Valid NivoPie Themes can be found here https://nivo.rocks/guides/colors/');
    }
    if (appConfig.feedbackFormUrl.length === 0) {
        console.error('No feedback form url. (This is optional will not effect theme application)');
    }
    if (appConfig.centralBankName.length === 0) {
        console.error('Please have a valid centralBankName.');
        validAppConfig = false;
    }
    if (appConfig.wholesaleBankOneName.length === 0) {
        console.error('Please have a valid wholesaleBankOneName.');
        validAppConfig = false;
    }
    if (appConfig.wholesaleBankTwoName.length === 0) {
        console.error('Please have a valid wholesaleBankTwoName.');
        validAppConfig = false;
    }
    if (appConfig.wholesaleBankThreeName.length === 0) {
        console.error('Please have a valid wholesaleBankThreeName.');
        validAppConfig = false;
    }

    if (!appConfig.defaultIcons.match(/true|false/i)) {
        console.error('Please have a valid wholesaleBankThreeName.');
        validAppConfig = false;
    }

    if (!validAppConfig) {
        console.error('App Config:');
        console.error(appConfig);
    }
    return validAppConfig;
};
