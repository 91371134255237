import { CancelToken } from 'axios';
import apiCall from './apiCall';

/**
 *
 * @remark
 * Used to request issuance of CBDC from the Central Bank
 *
 * @param issuer Name of issuer to request from
 * @param tokenIdentifier token identifier (relating to the asset being requested)
 * @param requestedAmount amount of CBDC to request
 *
 * @returns
 * AxiosResponse which resolves in a successfull request object or repsonse data
 */
export const createIssuanceRequest = async (issuer: string, tokenIdentifier: string, requestedAmount: number) =>
    apiCall({
        method: 'post',
        path: `/api/issuanceRequest/create`,
        params: {
            issuer: issuer,
            tokenIdentifier: tokenIdentifier,
            requestedAmount: requestedAmount,
        },
    });

/**
 * @remark
 * Used to get all CBDC issuance requests
 *
 * @returns
 * AxiosResponse which resolved in an error or repsonse data
 */
export const requestAllIssuanceRequests = async (dontTrack?: boolean, cancelToken?: CancelToken) =>
    apiCall({
        method: 'get',
        path: `/api/issuanceRequest`,
        cancelToken: cancelToken,
        dontTrackRequest: dontTrack,
    });
