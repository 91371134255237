import React, { useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';

type ImageUploadProps = {
    buttonText: string;
    setTempPreviewSource: (url: string) => void;
};

const ImageUpload: React.FC<ImageUploadProps> = (props) => {
    const { buttonText, setTempPreviewSource } = props;
    const [selectedFile, setSelectedFile] = useState<undefined | File>();
    const layoutClasses = useLayoutStyles();

    useEffect(() => {
        if (!selectedFile) {
            return;
        }
        const objectUrl = URL.createObjectURL(selectedFile);
        setTempPreviewSource(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }
        setSelectedFile(e.target.files[0]);
    };

    return (
        <div>
            <input
                style={{ display: 'none' }}
                type="file"
                id={`contained-button-file-${buttonText}`}
                onChange={onSelectFile}
            />
            <label htmlFor={`contained-button-file-${buttonText}`}>
                <Button
                    style={{ marginBottom: 2, marginTop: 2, marginLeft: 0 }}
                    className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize}`}
                    variant="outlined"
                    size="small"
                    component="span"
                >
                    {buttonText}
                </Button>
            </label>
        </div>
    );
};

export default ImageUpload;
