import {
    DashBoardResponsivePie,
    LandingPagePieTheme,
    LandingPagePieThemeSmall,
} from 'components/treasuryDashboard/DashboardComponents/DashboardResponsivePie';
import { Divider, Fade, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { ASSETPOLLINGINTERVAL } from 'constants/PollingIntervals';
import { AssetResponse } from '@r3/cbdc-asset-frontend-core';
import { InfoBox } from 'components/treasuryDashboard/DashboardComponents/InfoBox';
import { useDashboardPieData } from 'hooks/useDashboardPieData';
import { useDashboardStyles } from '@r3/cbdc-asset-frontend-core';
import useLandingPageContext from 'contexts/LandingPageContext/LandingPageContext';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const LandingPie: React.FunctionComponent = () => {
    const [filterAssets, setFilterAssets] = useState<string[]>([]);
    const [vaultAssets, setVaultAssets] = useState<AssetResponse[]>([]);
    const classes = useDashboardStyles();
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    const { getAllAssets, getAssetSummary, assetSummary } = useLandingPageContext();
    const { assetsInVault, pieData, updatePieData } = useDashboardPieData('SMALL');
    const isXsmallScreen = useMediaQuery({ maxWidth: 1250 });
    const isSmallScreen = useMediaQuery({ maxWidth: 1500 });
    const isLargeScreen = useMediaQuery({ minWidth: 1700 });
    let pieSizeY = 100;
    let radialLabelLength = 20;
    if (isSmallScreen) {
        pieSizeY = 120;
        radialLabelLength = 5;
    }
    if (isLargeScreen) {
        pieSizeY = 80;
        radialLabelLength = 8;
    }
    if (isXsmallScreen) {
        pieSizeY = 70;
        radialLabelLength = 5;
    }
    useEffect(() => {
        getAssetSummary(true);
    }, [getAssetSummary]);

    useEffect(() => {
        if (ASSETPOLLINGINTERVAL <= 0) {
            return;
        }
        const interval = setInterval(function () {
            getAssetSummary(true);
        }, ASSETPOLLINGINTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [getAssetSummary]);

    useEffect(() => {
        setFilterAssets(assetSummary.map((asset) => asset.tokenDefinition.tokenName));
        setVaultAssets(assetSummary);
        getAllAssets(true);
    }, [assetSummary, getAllAssets]);

    const handlePieClick = () => {};

    useEffect(() => {
        updatePieData(vaultAssets, filterAssets);
    }, [filterAssets, updatePieData, vaultAssets]);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
                <Fade in={true}>
                    <Paper elevation={5} className={`${layoutClasses.componentWrapper}`}>
                        <InfoBox size={'SMALL'} title={t('dashBoard.assetTypes')} content={pieData.length.toString()} />
                        <InfoBox
                            size={'SMALL'}
                            title={t('dashBoard.totalAssetsInVault')}
                            content={assetsInVault.toFixed(3).toString()}
                        />
                    </Paper>
                </Fade>
                <div
                    id="PieGraph"
                    className={` ${classes.nivoWrapper}`}
                    style={{ overflow: 'hidden', height: 400, marginTop: 24 }}
                >
                    <DashBoardResponsivePie
                        pieMarginsTopBottom={pieSizeY}
                        pieMarginLeft={45}
                        pieMarginRight={50}
                        altColour={true}
                        data={pieData}
                        handlePieClick={handlePieClick}
                        pieTheme={isSmallScreen || isXsmallScreen ? LandingPagePieThemeSmall : LandingPagePieTheme}
                        legendEnabled={false}
                        radialLabelLength={radialLabelLength}
                        radialLabelOffset={4}
                        radialLabelsXLength={5}
                    />
                    <Divider className={classes.divider} />
                </div>
            </div>
        </>
    );
};

export default LandingPie;
