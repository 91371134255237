import apiCall from './apiCall';

/**
 *
 * @remark
 * Used transfer (push) amount of asset to another wholesale party
 *
 * @param partyName Name of party pushing CBDC tokens to
 * @param amount The amount of tokens being pushed
 * @tokenIdentifier the token id for CBDC
 */
export const transferAssetsTo = async (tokenIdentifier: string, partyName: string, amount: string) =>
    apiCall({
        method: 'post',
        path: `/api/transact/pushTo`,
        params: {
            tokenIdentifier: tokenIdentifier,
            party: partyName,
            quantity: amount,
        },
    });

/**
 * @remark
 * Used to make a DVP request to another wholesale party (this does not initiate a DVP tx just sends a request)
 *
 * @param party the party DVP request is sent to
 * @param tokenIdentifier CBDC token identifier
 * @param assetAmount amount of cbdc to request
 * @param bondsAmount amount of bonds to offer
 */
export const requestDvp = (party: string, tokenIdentifier: string, assetAmount: string, bondsAmount: string) =>
    apiCall({
        method: 'post',
        path: `/api/transact/requestDvp`,
        params: {
            party: party,
            tokenIdentifier: tokenIdentifier,
            assetAmount: assetAmount,
            bondsAmount: bondsAmount,
        },
    });

/**
 * @remark
 * Used to make a Pull Transfer request to another wholesale party (this does not initiate a transfer tx just sends a request)
 *
 * @param party the party DVP request is sent to
 * @param tokenIdentifier CBDC token identifier
 * @param assetAmount amount of cbdc to request
 */
export const requestPullTransfer = (party: string, tokenIdentifier: string, assetAmount: string) =>
    apiCall({
        method: 'post',
        path: `/api/transact/requestAsset`,
        params: {
            party: party,
            tokenIdentifier: tokenIdentifier,
            assetAmount: assetAmount,
        },
    });

/**
 * @remark
 * Used to approve a DvP Request
 *
 * @param requestId
 * The id of the request
 *
 * @returns
 * Axios Response which is resolved in an error or response data
 */
export const approveDvpRequest = async (requestId: string) =>
    apiCall({
        method: 'get',
        path: `/api/transact/${requestId}/approve`,
    });

/**
 * @remark
 * Used to deny a DvP request
 *
 * @param requestId
 * The id of the request
 *
 * @returns
 * Axios Response which is resolved in an error or response data
 */
export const denyDvpRequest = async (requestId: string) =>
    apiCall({
        method: 'get',
        path: `/api/transact/${requestId}/deny`,
    });

/**
 * @remark
 * Used to get all DvP requests
 *
 * @returns
 * Axios Response which is resolved in an error or response data
 */
export const requestAllDvpRequests = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/transact/listDvp`,
        params: { startPage: 1, pageSize: 10000 },
        dontTrackRequest: dontTrack,
    });

/**
 * @remark
 * Used to get all Cross Chain requests
 *
 * @returns
 * Axios Response which is resolved in an error or response data
 */
export const requestAllCrossChainSwapRequests = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/transact/listPvp`,
        params: { startPage: 1, pageSize: 10000, targeted: true },
        dontTrackRequest: dontTrack,
    });

/**
 * @remark
 * Used to approve a Pvp Request
 *
 * @param requestId
 * The id of the request
 *
 * @returns
 * Axios Response which is resolved in an error or response data
 */
export const approvePvpRequest = async (requestId: string) =>
    apiCall({
        method: 'get',
        path: `/api/transact/${requestId}/approve`,
    });

/**
 * @remark
 * Used to approve a Pvp Request
 *
 * @param requestId
 * The id of the request
 *
 * @returns
 * Axios Response which is resolved in an error or response data
 */
export const denyPvpRequest = async (requestId: string) =>
    apiCall({
        method: 'get',
        path: `/api/transact/${requestId}/deny`,
    });

/**
 * @remark
 * Used to get all DvP requests
 *
 * @returns
 * Axios Response which is resolved in an error or response data
 */
export const requestAllAssetRequests = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/transact/listAssetRequests`,
        params: { startPage: 1, pageSize: 10000 },
        dontTrackRequest: dontTrack,
    });

export const requestCrossChainSwap = async (
    requestedTokenIdentifier: string,
    requestedAssetAmount: number,
    offeredTokenIdentifier: string,
    offeredAssetAmount: number,
    party: string | null = null
) =>
    apiCall({
        method: 'post',
        path: `/api/transact/requestPvp`,
        params: {
            party: party,
            requestedTokenIdentifier: requestedTokenIdentifier,
            requestedAssetAmount: requestedAssetAmount,
            offeredTokenIdentifier: offeredTokenIdentifier,
            offeredAssetAmount: offeredAssetAmount,
        },
    });
