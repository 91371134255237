import { DvpRequest, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { SvgIcon, Typography } from '@material-ui/core';

import { ReactComponent as BondsIcon } from 'icons/bonds.svg';
import RequestListContainer from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListContainer';
import RequestListItem from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListItem';
import { getRequestStatusColorWithId } from '../requestStatusColors';
import { getRequestStatusText } from '../requestStatusText';
import { useTranslation } from 'react-i18next';

type CrossChainSwapRequestProps = {
    request: DvpRequest;
    onClick: () => void;
};

const DvpRequestComponent: React.FC<CrossChainSwapRequestProps> = ({ onClick, request }) => {
    const { t } = useTranslation();
    return (
        <RequestListContainer onClick={() => onClick()}>
            <RequestListItem>
                {t('defineAsset.assetName') + ': '}
                {request.tokenDefinition.tokenName}
            </RequestListItem>
            <RequestListItem>
                {'Asset Amount Requested: '}
                {NumberFormat.addThousandSeparators(request.assetAmount.toString())}
            </RequestListItem>
            <RequestListItem>
                {'Requestee: '}
                {request.seller}
            </RequestListItem>
            <div style={{ display: 'flex' }}>
                <Typography style={{ fontSize: 14 }}>{'Bond Value Offered'}</Typography>
                <SvgIcon style={{ width: 16, height: 16 }}>
                    <BondsIcon />
                </SvgIcon>
                <Typography style={{ fontSize: 14 }}>
                    {': ' + NumberFormat.addThousandSeparators(request.bondAmount.toString())}
                </Typography>
            </div>
            <RequestListItem>
                <span>{t('commonText.status') + ': '}</span>
                <span style={{ color: getRequestStatusColorWithId(request.requestState, request.txId) }}>
                    {getRequestStatusText(request.requestState, request.txId)}
                </span>
            </RequestListItem>
        </RequestListContainer>
    );
};

export default DvpRequestComponent;
