import { NumberFormat } from '@r3/cbdc-asset-frontend-core';

const DECIMAL_POINT = '.';

export function validateCurrencyInput(input: string, digits: number) {
    if (input === '') {
        return true;
    }

    input = NumberFormat.removeThousandSeparators(input);
    let items = input.split(DECIMAL_POINT);
    if (items.length > 2) {
        return false;
    }
    // We will accept number with decimal point suffix, so use can type and delete continuously.
    if (items.length === 1 || items[1] === '') {
        return NumberFormat.isValidInteger(items[0]);
    }

    return NumberFormat.isNumberWithFractionalValid(input, digits);
}
