import { Divider, Paper, Typography } from '@material-ui/core';

import { Asset } from '@r3/cbdc-asset-frontend-core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type RequestKycOverviewStepProps = {
    selectedAsset: Asset;
    selectedParticipant: string;
};

const RequestKycOverviewStep: React.FC<RequestKycOverviewStepProps> = (props) => {
    const { selectedAsset, selectedParticipant } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('requestKyc.summaryMessage')}</Typography>

                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.text}`}>
                    {t('commonText.asset') + ':'} {selectedAsset.tokenName}
                </Typography>
                <Typography className={`${layoutClasses.text}`}>
                    {t('requestKyc.issuingAuthority') + ':'} {selectedParticipant}
                </Typography>
            </Paper>
        </div>
    );
};

export default RequestKycOverviewStep;
