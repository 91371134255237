import { Theme, alpha, createStyles, makeStyles } from '@material-ui/core';

export const useRetailItemStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            list: {
                alignSelf: 'flex-start',
                width: '100%',
                maxWidth: 500,
                overflow: 'auto',
                maxHeight: 380,
                [theme.breakpoints.down('xs')]: {
                    overflow: 'auto',
                },
                paddingRight: 10,
                animation: `$modalZoom 500ms`,
            },
            sectionWrapper: {
                width: '100%',
            },
            actionIconWrapper: { marginTop: -70, marginLeft: 'auto', marginRight: 8, width: 50 },
            actionButton: {
                transition: 'all 0.1s linear',
                color: theme.palette.primary.contrastText,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    transform: 'scale(1.1)',
                },
            },
            actionIcon: {
                width: 40,
                height: 40,
            },
            itemListTitle: { margin: '0px 6px auto 0px', fontSize: 30, color: 'white', fontWeight: 600 },
            itemListWrapper: { width: '100%', animation: `$modalZoom 500ms` },
            '@keyframes modalZoom': {
                '0%': {
                    opacity: 0,
                    transform: 'scale(0.2)',
                },
                '100%': {
                    opacity: 1,
                    transform: 'scale(1)',
                },
            },
            request: {
                background: `linear-gradient(120deg, ${alpha(theme.palette.primary.light, 0.2)} 65%, ${alpha(
                    theme.palette.primary.light,
                    0.6
                )} 110%)`,
                minHeight: 80,
                maxHeight: 240,
                padding: '10px 6px 10px 0px',
                width: '98%',
                margin: '12px auto 12px auto',
                display: 'flex',
            },
            hoverableRequest: {
                '&:hover *': {
                    cursor: 'pointer',
                },
                '&:hover': {
                    cursor: 'pointer',
                    background: 'none',
                    backgroundColor: alpha(theme.palette.secondary.main, 0.4),
                },
            },
            itemInfo: {
                margin: 'auto auto auto 0px',
            },
            payeeDesc: { color: 'white', margin: 'auto auto auto 0px', fontSize: 18 },
            requestIcon: {
                width: 35,
                height: 35,
            },
            requestInfoWrapper: {
                marginTop: 2,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
                columnGap: 12,
                rowGap: 2,
                color: alpha(theme.palette.primary.contrastText, 0.85),
            },
            deleteIcon: {
                width: 20,
                color: 'red !important',
            },
            sendIcon: {
                width: 20,
                color: theme.palette.primary.contrastText,
            },

            actionButtonTwo: {
                height: 40,
                width: 120,
                fontSize: 14,
                color: theme.palette.primary.contrastText,
                margin: 'auto auto auto auto',
                transition: 'all 0.1s ease-in-out',
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.contrastText,
                    transform: 'scale(1.1)',
                },
            },
            buttonIcon: {
                width: 26,
                height: 26,
            },
            itemIcon: {
                margin: 'auto 20px auto 16px',
                width: 35,
                height: 35,
            },
            pendingIcon: {
                color: 'yellow',
            },
            completeIcon: {
                color: 'green',
            },
            pulsingUpdated: {
                animationIterationCount: 1,
                animation: `$colourPulse 4s`,
            },
            '@keyframes colourPulse': {
                '0%': {
                    backgroundColor: 'lightgreen',
                },
                '50%': {
                    backgroundColor: theme.palette.primary.main,
                },
                '100%': {
                    backgroundColor: 'lightgreen',
                },
            },
        }),
    { index: 1 }
);
