import { DialogAction, DialogActions, DialogBody, NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Theme, Tooltip, makeStyles } from '@material-ui/core';

import { CancelRounded } from '@material-ui/icons';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { Order } from 'models/orders/Order';
import SwapOfferDisplay from 'components/decentralizedExchange/SwapOfferDisplay';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { updateOrder } from 'api/decentralizedExchange';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const useLocalStyles = makeStyles((theme: Theme) => ({
    amountInputContainer: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    infoIcon: {
        width: 16,
        height: 16,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    tooltip: {
        background: theme.palette.primary.light,
        maxWidth: 450,
        boxShadow: theme.shadows[20],
    },
    tooltipIcon: {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    tooltipText: {
        color: 'white',
        maxWidth: 700,
        fontSize: 16,
        lineHeight: 1.2,
    },
}));

interface Props {
    order: Order;
    refreshOrders: () => void;
    onClose: () => void;
}

const UpdateOrderContent: FunctionComponent<Props> = ({ order, refreshOrders, onClose }) => {
    const localStyles = useLocalStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { t: texts } = useTranslation();
    const [requestedAmount, setRequestedAmount] = useState<number>(order.requestedAssetAmount);
    const [offeredAmount, setOfferedAmount] = useState<number>(order.offeredAssetAmount);
    const { getSummedAssetById, getAssetSummary } = useGetAssetSummary();

    const asset = useMemo(
        () => getSummedAssetById(order.offeredTokenDefinition.tokenIdentifier),
        [getSummedAssetById, order.offeredTokenDefinition.tokenIdentifier]
    );
    const availableToTransact = useMemo(
        () => (asset === undefined ? order.offeredAssetAmount : order.offeredAssetAmount + asset.availableToTransact),
        [order, asset]
    );
    const isOfferedAmountValid = useMemo(
        () => offeredAmount > 0 && offeredAmount <= availableToTransact,
        [offeredAmount, availableToTransact]
    );

    useEffect(() => {
        getAssetSummary();
    }, [order.orderId, getAssetSummary]);

    function handleRequestedAmountChange(value: string) {
        setRequestedAmount(parseFloat(value));
    }
    function handleOfferedAmountChange(value: string) {
        setOfferedAmount(parseFloat(value));
    }

    async function handleSubmitClicked() {
        if (
            offeredAmount > availableToTransact ||
            (order.requestedAssetAmount === requestedAmount && order.offeredAssetAmount === offeredAmount) ||
            requestedAmount === 0 ||
            offeredAmount === 0
        ) {
            return;
        }

        onClose();
        const response = await updateOrder(order.orderId, {
            requestedAssetAmount: requestedAmount,
            offeredAssetAmount: offeredAmount,
        });

        if (response.error === undefined) {
            enqueueSnackbar(texts('success.updateOrder'), { variant: 'info' });
            refreshOrders();
        } else {
            enqueueSnackbar(texts('error.updateOrder', { error: response.error }), { variant: 'error' });
        }
    }

    return (
        <>
            <DialogBody>
                <SwapOfferDisplay order={order} />
                {asset && (
                    <div data-testid="available-to-transact">
                        {`${texts('transferAssets.availableToTransact')}: `}
                        {NumberFormat.addThousandSeparators(availableToTransact.toFixed(asset.tokenDecimal))}
                        {
                            <Tooltip
                                className={localStyles.tooltipIcon}
                                classes={{ tooltip: localStyles.tooltip }}
                                placement="top"
                                title={
                                    <div className={localStyles.tooltipText}>
                                        {texts('assetSummary.availableToTransactExplanation')}
                                    </div>
                                }
                            >
                                <InfoRoundedIcon className={localStyles.infoIcon} />
                            </Tooltip>
                        }
                    </div>
                )}
                <div className={localStyles.amountInputContainer}>
                    <CurrencyInput
                        testId="update-requested-amount-input"
                        label={texts('liquiditySavings.updateRequestedAmountLabel')}
                        initialValue={requestedAmount}
                        onChange={handleRequestedAmountChange}
                        decimalPlaces={order.requestedTokenDefinition.tokenDecimals}
                        invalidInput={requestedAmount <= 0}
                    />
                    <CurrencyInput
                        testId="update-offered-amount-input"
                        label={texts('liquiditySavings.updateOfferedAmountLabel')}
                        initialValue={offeredAmount}
                        onChange={handleOfferedAmountChange}
                        decimalPlaces={order.offeredTokenDefinition.tokenDecimals}
                        invalidInput={!isOfferedAmountValid}
                    />
                </div>
            </DialogBody>
            <DialogActions>
                <DialogAction
                    icon={<ThumbUpIcon />}
                    text={texts('commonText.submit')}
                    action={handleSubmitClicked}
                    disabled={!isOfferedAmountValid || requestedAmount <= 0}
                />
                <DialogAction icon={<CancelRounded />} text={texts('commonText.cancel')} action={onClose} />
            </DialogActions>
        </>
    );
};
export default UpdateOrderContent;
