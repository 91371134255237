import { EMPTY_SUMMED_STATE, StepperFormStep, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import React, { useEffect, useState } from 'react';

import EnterAmountToRedeemStep from './EnterAmountToRedeemStep';
import { Fade } from '@material-ui/core';
import RedeemAssetReviewStep from './RedeemAssetReviewStep';
import { ResolvedPromise } from 'api/resolvePromise';
import SelectAssetStep from './SelectAssetStep';
import { StepperForm } from '@r3/cbdc-asset-frontend-core';
import { createRedemptionRequest } from 'api/redemptionApi';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const RedeemCBDC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedAsset, setSelectedAsset] = useState<SummedAsset>(EMPTY_SUMMED_STATE);
    const [error, setError] = useState<string>('');
    const { t } = useTranslation();
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const [summedAssets, setSummedAssets] = useState<SummedAsset[]>([]);
    const checkListContext = useInteractiveChecklistContext();
    const location = useLocation();
    const { getAssetSummary, assetSummary, summaryError } = useGetAssetSummary();

    const submitRequest = async () => {
        const submitResponse: ResolvedPromise = await createRedemptionRequest(
            selectedAsset.tokenIdentifier,
            selectedAsset.issuer,
            parseFloat(assetAmount)
        );
        if (submitResponse.error) {
            enqueueSnackbar(t('error.requestingRedemption', { error: submitResponse.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.requestingRedemption'), {
                variant: 'success',
            });
            checkListContext?.completeStep(location.pathname);
        }
    };

    useEffect(() => {
        setError(summaryError);
    }, [summaryError]);

    useEffect(() => {
        getAssetSummary();
    }, [getAssetSummary]);

    useEffect(() => {
        let summedAssets: SummedAsset[] = [];
        assetSummary.forEach((asset) => {
            const objIndex = summedAssets.findIndex(
                (obj) => obj.tokenIdentifier === asset.tokenDefinition.tokenIdentifier
            );
            if (objIndex === -1) {
                summedAssets.push({
                    tokenIdentifier: asset.tokenDefinition.tokenIdentifier,
                    totalInVault: asset.totalAmount,
                    availableToTransact: asset.amountHeldByNode,
                    totalInAccounts: asset.amountHeldByAccounts,
                    tokenName: asset.tokenDefinition.tokenName,
                    tokenDecimal: asset.tokenDecimals,
                    complianceRules: [],
                    issuer: asset.issuer,
                });
            }
        });
        setSummedAssets(summedAssets);
    }, [assetSummary]);

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const tokenIdentifier: string = event.target.value as string;
        const asset = summedAssets.find((obj) => obj.tokenIdentifier === tokenIdentifier);
        if (asset) {
            setSelectedAsset(asset);
        } else {
            setSelectedAsset(EMPTY_SUMMED_STATE);
        }
    };

    const handleReset = () => {
        setSelectedAsset(EMPTY_SUMMED_STATE);
        setAssetAmount('0');
        getAssetSummary();
    };

    const invalidAssetAmountInput = (): boolean => {
        if (
            assetAmount.length <= 0 ||
            parseFloat(assetAmount) <= 0 ||
            selectedAsset.availableToTransact < parseFloat(assetAmount)
        ) {
            return true;
        }

        return false;
    };

    const steps: StepperFormStep[] = [
        {
            title: 'Select Asset to Redeem',
            component: (
                <SelectAssetStep
                    selectedAsset={selectedAsset}
                    handleAssetChange={handleAssetChange}
                    summedAssets={summedAssets}
                />
            ),
            isNextStepDisabled: () => {
                if (selectedAsset.tokenName.length > 0 && selectedAsset.availableToTransact > 0) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: 'Select Amount to Redeem',
            component: (
                <EnterAmountToRedeemStep
                    selectedAsset={selectedAsset}
                    assetAmount={assetAmount}
                    setAssetAmount={setAssetAmount}
                    invalidAssetAmountInput={invalidAssetAmountInput()}
                />
            ),
            isNextStepDisabled: () => {
                if (assetAmount.length > 0 && !invalidAssetAmountInput()) {
                    return false;
                } else {
                    return true;
                }
            },
        },
        {
            title: t('requestCBDC.requestReviewStep'),
            component: <RedeemAssetReviewStep selectedAsset={selectedAsset} assetAmount={assetAmount} />,
            isNextStepDisabled: () => {
                return false;
            },
        },
    ];

    return (
        <>
            <Fade in={true}>
                <StepperForm
                    submit={submitRequest}
                    steps={steps}
                    renderError={error}
                    submitButtonText={t('commonText.request')}
                    handleReset={handleReset}
                />
            </Fade>
        </>
    );
};
