import React, { useEffect, useState } from 'react';

import { CheckListAction } from '../../constants/InteractiveChecklistList';
import InfoIcon from '@material-ui/icons/Info';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { Tooltip } from '@material-ui/core';
import { useAssistantStyles } from '@r3/cbdc-asset-frontend-core';

type TutorialControlsProps = {
    previousStep: (() => void) | undefined;
    skipCurrentStep: (() => void) | undefined;
    setFocusedItem: (option: CheckListAction) => void;
    currentOption: CheckListAction;
    progress: number;
    totalSteps: number;
};

export const TutorialControls: React.FC<TutorialControlsProps> = (props) => {
    const { previousStep, setFocusedItem, currentOption, progress, skipCurrentStep, totalSteps } = props;
    const classes = useAssistantStyles();
    const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (currentOption?.clickToComplete && currentOption.clickToComplete === true) {
            setNextBtnDisabled(false);
        } else {
            setNextBtnDisabled(true);
        }
    }, [progress, currentOption]);

    return (
        <div style={{ display: 'flex' }}>
            <div
                className={`${classes.assistantOption} ${classes.moreInfo}`}
                onClick={() => {
                    if (previousStep) {
                        previousStep();
                    }
                }}
            >
                <SkipPreviousIcon className={classes.icon} />
                <div className={classes.assistantOptionText} style={{ marginRight: 8, userSelect: 'none' }}>
                    Back
                </div>
            </div>

            <div
                className={`${classes.assistantOption} ${classes.moreInfo}`}
                onClick={() => {
                    setFocusedItem(currentOption);
                }}
                style={{ marginLeft: 'auto' }}
            >
                <Tooltip
                    title="Tutorial"
                    placement="top"
                    classes={{
                        tooltip: classes.tooltip,
                    }}
                >
                    <InfoIcon className={classes.icon} style={{ marginLeft: 6 }} />
                </Tooltip>
            </div>
            <div
                className={` ${classes.moreInfo} ${
                    progress === totalSteps ? classes.disabledOption : classes.assistantOption
                }`}
                onClick={() => {
                    if (skipCurrentStep) {
                        skipCurrentStep();
                    }
                }}
                style={{ marginLeft: 'auto' }}
            >
                <div
                    className={classes.assistantOptionText}
                    style={{
                        marginRight: 10,
                        marginLeft: 4,
                        userSelect: 'none',
                        color: progress === totalSteps ? 'grey' : nextBtnDisabled ? 'yellow' : 'lawngreen',
                    }}
                >
                    {progress === totalSteps ? 'Done' : nextBtnDisabled ? 'Skip' : 'Next'}
                </div>
                <SkipNextIcon
                    style={{
                        marginRight: 8,
                        color: progress === totalSteps ? 'grey' : nextBtnDisabled ? 'yellow' : 'lawngreen',
                    }}
                    className={classes.icon}
                />
            </div>
        </div>
    );
};
