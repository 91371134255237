import { DialogAction, DialogActions, DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';
import React, { useState } from 'react';

import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CustomerDialogDetails from './CustomerDialogDetails';
import Deposit from './Deposit';
import EditIcon from '@material-ui/icons/Edit';
import HowToVoteRoundedIcon from '@material-ui/icons/HowToVoteRounded';
import UpdateCustomerDetails from './UpdateCustomerDetails';
import { useEffect } from 'react';
import { useGetCustomerDetails } from '../../../hooks/GetCustomerDetails';

enum RetailCustomerDialogState {
    UPDATE_CUSTOMER,
    DEPOSIT,
    CUSTOMER_DETAILS,
}

export interface SimpleDialogProps {
    open: boolean;
    customerId: string | null;
    onClose: () => void;
}

export function RetailCustomersDialog(props: SimpleDialogProps) {
    const { onClose, open, customerId } = props;
    const { customer, getCustomer } = useGetCustomerDetails();
    const [dialogState, setDialogState] = useState<RetailCustomerDialogState>(
        RetailCustomerDialogState.CUSTOMER_DETAILS
    );

    const handleClose = () => {
        setDialogState(RetailCustomerDialogState.CUSTOMER_DETAILS);
        onClose();
    };

    const resetToDefaultScreen = () => {
        if (customerId) {
            getCustomer(customerId);
        }
        setDialogState(RetailCustomerDialogState.CUSTOMER_DETAILS);
    };

    useEffect(() => {
        const fetchCustomerData = async () => {
            if (!customerId) {
                return;
            }
            await getCustomer(customerId);
        };
        fetchCustomerData();
    }, [customerId, getCustomer]);

    if (customerId == null) {
        return <> </>;
    }

    const title = (): string => {
        if (dialogState === RetailCustomerDialogState.UPDATE_CUSTOMER) {
            return 'Update Customer';
        }
        if (dialogState === RetailCustomerDialogState.DEPOSIT) {
            return 'Deposit';
        }
        return 'Customer Details';
    };

    const dialogBodyContent = (): JSX.Element => {
        if (!customer) {
            return <></>;
        }
        if (dialogState === RetailCustomerDialogState.UPDATE_CUSTOMER) {
            return (
                <UpdateCustomerDetails
                    customerWithDetails={customer}
                    updatedCustomer={() => {
                        resetToDefaultScreen();
                    }}
                />
            );
        }
        if (dialogState === RetailCustomerDialogState.DEPOSIT) {
            return (
                <Deposit
                    accountId={customer.accountId}
                    finishCallBack={() => {
                        resetToDefaultScreen();
                    }}
                />
            );
        }
        if (dialogState === RetailCustomerDialogState.CUSTOMER_DETAILS) {
            return <CustomerDialogDetails customerWithDetails={customer} />;
        }
        return <></>;
    };

    const dialogActions = (): JSX.Element => {
        if (
            dialogState === RetailCustomerDialogState.UPDATE_CUSTOMER ||
            dialogState === RetailCustomerDialogState.DEPOSIT
        ) {
            return (
                <DialogAction
                    text={'Back'}
                    action={() => {
                        setDialogState(RetailCustomerDialogState.CUSTOMER_DETAILS);
                    }}
                    icon={<ArrowBackRoundedIcon />}
                />
            );
        }
        if (dialogState === RetailCustomerDialogState.CUSTOMER_DETAILS) {
            return (
                <>
                    <DialogAction
                        text={'Deposit'}
                        action={() => {
                            setDialogState(RetailCustomerDialogState.DEPOSIT);
                        }}
                        icon={<HowToVoteRoundedIcon />}
                    />
                    <DialogAction
                        text={'Update customer details'}
                        action={() => {
                            setDialogState(RetailCustomerDialogState.UPDATE_CUSTOMER);
                        }}
                        icon={<EditIcon />}
                    />
                </>
            );
        }
        return <></>;
    };

    return (
        <DialogWindow open={open} onClose={handleClose} title={title()}>
            <DialogBody>{dialogBodyContent()}</DialogBody>
            <DialogActions>{dialogActions()}</DialogActions>
        </DialogWindow>
    );
}
