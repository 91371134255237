import { ReactElement } from 'react';

type Props = {
    col1Text: string;
    col2Text: string;
    icon?: ReactElement;
    color?: string;
};

const SummaryRow: React.FC<Props> = ({ col1Text, col2Text, icon, color }) => {
    return (
        <tr data-testid={'summary-row'} style={{ color: color ? color : '' }}>
            {icon ? (
                <td>
                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {col1Text}
                        <div style={{ margin: 'auto 2px auto 2px' }}>{icon}</div>
                    </div>
                </td>
            ) : (
                <td>{col1Text}</td>
            )}

            <td>{col2Text}</td>
        </tr>
    );
};

export default SummaryRow;
