import { RequestPaymentButton, ScanQRButton } from './RetailStickyButtons';

const RetailFunctions = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                height: 50,
                width: '100%',
            }}
        >
            <RequestPaymentButton />
            <ScanQRButton />
        </div>
    );
};

export default RetailFunctions;
