import { AssetRequest, TransactionRequestState } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import { Divider } from '@material-ui/core';
import RequestList from 'components/commonComponents/RequestListComponents/RequestList';
import RequestListDivider from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDivider';
import RequestListDropdownSection from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownSection';
import RequestListDropdownTitle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownTitle';
import RequestListDropdownToggle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownToggle';
import TransferRequestComponent from './TransferRequestComponent';
import useFilterRequests from 'hooks/FilterRequests';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type TransferRequestsListProps = {
    assetRequests: AssetRequest[];
    openInfoModal: (assetRequest: AssetRequest, ourRequests: boolean) => void;
};

const TransferRequestsList: React.FC<TransferRequestsListProps> = ({ assetRequests, openInfoModal }) => {
    const listClasses = useListStyles();
    const { filterSwitches, displayRequestType } = useFilterRequests(true);
    const { identity, getOwnIdentity } = useGetOurOwnIdentity();
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const { t } = useTranslation();
    const [assetRequestsByRequestor, setAssetRequestsByRequestor] = useState<Map<string, AssetRequest[]>>();

    useEffect(() => {
        getOwnIdentity();
    }, [getOwnIdentity]);

    useEffect(() => {
        let groups = assetRequests.groupBy('requestingParty') as Map<string, AssetRequest[]>;
        setAssetRequestsByRequestor(groups);
        setCollapesedKeys(Array.from(groups.keys()));
    }, [assetRequests]);

    const handlePublicKeyClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };
    return (
        <>
            {filterSwitches}
            <RequestListDivider />
            <RequestList>
                {assetRequestsByRequestor !== undefined &&
                    assetRequestsByRequestor.mapGroups((requestingParty, items) => {
                        const ourRequests = identity === requestingParty;
                        const publickKeyElement: JSX.Element = (
                            <RequestListDropdownSection>
                                <RequestListDropdownTitle>
                                    {ourRequests ? (
                                        <b>Our Requests </b>
                                    ) : (
                                        <>
                                            <b>{t('cbdcRequests.requestingParty') + ':'} </b> {requestingParty}{' '}
                                        </>
                                    )}
                                </RequestListDropdownTitle>
                                <RequestListDropdownToggle
                                    toggleCondition={collapsedKeys.includes(requestingParty)}
                                    onClick={() => handlePublicKeyClick(requestingParty)}
                                />
                            </RequestListDropdownSection>
                        );

                        if (collapsedKeys.includes(requestingParty)) {
                            return <div key={requestingParty}>{publickKeyElement} </div>;
                        }
                        return (
                            <div key={requestingParty}>
                                {publickKeyElement}
                                {items
                                    .filter((req) => displayRequestType(req.requestState as TransactionRequestState))
                                    .reverse()
                                    .map((request) => {
                                        return (
                                            <TransferRequestComponent
                                                key={requestingParty + '_' + request.requestId}
                                                request={request}
                                                onClick={() => openInfoModal(request, ourRequests)}
                                            />
                                        );
                                    })}
                            </div>
                        );
                    })}
            </RequestList>
            <Divider className={listClasses.divider} />{' '}
        </>
    );
};

export default TransferRequestsList;
