const INTEREST_GRAPH_NAME = 'WHOLESALE';

export const getLineId = (graphName: string | null) => {
    if (graphName === INTEREST_GRAPH_NAME) {
        return LINE_IDS.IssuedBaseWithInterest;
    }
    return LINE_IDS.IssuedBase;
};

export const LINE_IDS = {
    IssuedBase: 'Balance',
    IssuedBaseWithInterest: 'Issued Base + Interest',
};
