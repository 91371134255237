import Big from 'big.js';
import { Order } from 'models/orders/Order';

export const calculateRequestedAmount = (order: Order, fillAmount: number): Big => {
    if (isNaN(fillAmount)) {
        return Big(0);
    }

    if (order.offeredAssetAmount === order.requestedAssetAmount) {
        return Big(fillAmount);
    }
    if (order.offeredAssetAmount === fillAmount) {
        return Big(order.requestedAssetAmount);
    }

    return Big(order.requestedAssetAmount).times(Big(fillAmount)).div(Big(order.offeredAssetAmount));
};

export const calculateRequestedAmountAsString = (order: Order, fillAmount: number): string => {
    return calculateRequestedAmount(order, fillAmount).toFixed(
        order.requestedTokenDefinition.tokenDecimals,
        Big.roundUp
    );
};

export const calculateOfferedAmount = (order: Order, fillAmount: number): Big => {
    if (isNaN(fillAmount)) {
        return Big(0);
    }

    if (order.offeredAssetAmount === order.requestedAssetAmount) {
        return Big(fillAmount);
    }
    if (order.requestedAssetAmount === fillAmount) {
        return Big(order.offeredAssetAmount);
    }

    return Big(order.offeredAssetAmount).times(Big(fillAmount)).div(Big(order.requestedAssetAmount));
};

export const calculateOfferedAmountAsString = (order: Order, fillAmount: number): string => {
    return calculateOfferedAmount(order, fillAmount).toFixed(order.offeredTokenDefinition.tokenDecimals, Big.roundDown);
};
