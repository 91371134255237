import { Avatar, Typography } from '@material-ui/core';

import { NumberFormat } from '@r3/cbdc-asset-frontend-core';
import { Order } from 'models/orders/Order';
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import { getOfferLogRowColor } from './offerLog/offerLogUtils';
import { useDialogStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type SwapOfferDisplayProps = {
    order: Order;
};

const SwapOfferDisplay: React.FC<SwapOfferDisplayProps> = ({ order }) => {
    const dialogStyles = useDialogStyles();
    const { t: labels } = useTranslation();

    return (
        <>
            <Typography gutterBottom>{`${labels('liquiditySavings.offerId')}: ${order.orderId}`}</Typography>
            <Typography gutterBottom>{`${labels('liquiditySavings.offerMaker')}: ${order.creator}`}</Typography>
            {order.receiver != null && order.receiver.length > 0 && (
                <Typography gutterBottom>{`${labels('liquiditySavings.offerTaker')}: ${order.receiver}`}</Typography>
            )}
            <Typography>
                {labels('commonText.txTime') + ': '} {new Date(order.lastUpdated).toLocaleString()}
            </Typography>
            {order.fillAmount > 0 && (
                <Typography gutterBottom style={{ fontSize: 20, marginTop: 12, marginBottom: 0 }}>
                    <strong>{`Original Offer`}</strong>
                </Typography>
            )}
            <div className={dialogStyles.hightlightContainer}>
                <Typography className={dialogStyles.swapText}>
                    {`${labels('browseExchange.requestedLabel')}: `}
                    <strong>
                        {`${order.requestedTokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                            order.requestedAssetAmount.toFixed(order.requestedTokenDefinition.tokenDecimals)
                        )}`}
                    </strong>
                </Typography>
                <Avatar className={dialogStyles.exchangeIconContainer}>
                    <SwapHorizontalCircleOutlinedIcon className={dialogStyles.exchangeIcon} />
                </Avatar>
                <Typography className={dialogStyles.swapText}>
                    {`${labels('browseExchange.offeredLabel')}: `}
                    <strong>
                        {`${order.offeredTokenDefinition.tokenName}: ${NumberFormat.addThousandSeparators(
                            order.offeredAssetAmount.toFixed(order.offeredTokenDefinition.tokenDecimals)
                        )}`}
                    </strong>
                </Typography>
            </div>
            {order.fillAmount === order.offeredAssetAmount && (
                <Typography gutterBottom style={{ fontSize: 20, color: getOfferLogRowColor(order) }}>
                    <strong>{labels('liquiditySavings.fullyFilledState')}</strong>
                </Typography>
            )}
            {order.fillAmount > 0 && order.fillAmount < order.offeredAssetAmount && (
                <Typography gutterBottom style={{ fontSize: 20, color: getOfferLogRowColor(order) }}>
                    <strong>{labels('liquiditySavings.partiallyFilledState')}</strong>
                </Typography>
            )}
            {order.fillAmount > 0 && (
                <div>
                    <Typography gutterBottom>
                        {`${labels('liquiditySavings.otherPartyFilledLabel')}: `}
                        <strong>
                            {order.offeredTokenDefinition.tokenName}:{' '}
                            {NumberFormat.addThousandSeparators(
                                order.fillAmount.toFixed(order.offeredTokenDefinition.tokenDecimals)
                            )}
                        </strong>
                    </Typography>
                    <Typography>
                        {`${labels('liquiditySavings.receivedLabel')}: `}
                        <strong>
                            {order.requestedTokenDefinition.tokenName}:{' '}
                            {NumberFormat.addThousandSeparators(
                                order.requestedFillAmount.toFixed(order.requestedTokenDefinition.tokenDecimals)
                            )}
                        </strong>
                    </Typography>
                </div>
            )}
        </>
    );
};

export default SwapOfferDisplay;
