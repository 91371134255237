import { NumberFormat, SummedAsset } from '@r3/cbdc-asset-frontend-core';

import { CSSProperties } from 'react';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import SummaryRow from './SummaryRow';
import SummaryTable from './SummaryTable';
import { Tooltip } from '@material-ui/core';
import { useTransactionSummaryStyles } from 'materialStyles/transactionSummaryStyles';
import { useTranslation } from 'react-i18next';

type Props = {
    asset: SummedAsset;
    customSpendText?: string;
    toSpend?: number;
    showSpendAmount?: boolean;
    showRemainingAssets?: boolean;
    small?: boolean;
    showAssetName?: boolean;
    style?: CSSProperties;
};

const AssetSummary: React.FC<Props> = ({
    asset,
    customSpendText,
    toSpend,
    showRemainingAssets = true,
    showSpendAmount = false,
    small = false,
    showAssetName = false,
    style,
}) => {
    const classes = useTransactionSummaryStyles();
    const { t: texts } = useTranslation();
    const spendOverAvailableAmount =
        (typeof toSpend !== 'undefined' && toSpend > asset.availableToTransact) || asset.availableToTransact === 0;
    const spendOverTotalInVault = typeof toSpend !== 'undefined' && toSpend > asset.totalInVault;
    return (
        <SummaryTable small={small} style={style}>
            {showAssetName && <SummaryRow col1Text={texts('defineAsset.assetName')} col2Text={asset.tokenName} />}
            <SummaryRow
                col1Text={texts('transferAssets.totalInVault')}
                col2Text={NumberFormat.addThousandSeparators(asset.totalInVault.toFixed(asset.tokenDecimal))}
            />
            <SummaryRow
                col1Text={customSpendText ?? texts('transferAssets.availableToTransact')}
                col2Text={NumberFormat.addThousandSeparators(asset.availableToTransact.toFixed(asset.tokenDecimal))}
                icon={
                    <Tooltip
                        className={classes.tooltipIcon}
                        classes={{ tooltip: classes.tooltip }}
                        placement="top"
                        title={
                            <div className={classes.tooltipText}>
                                {texts('assetSummary.availableToTransactExplanation')}
                            </div>
                        }
                    >
                        <InfoRoundedIcon className={classes.infoIcon} />
                    </Tooltip>
                }
                color={spendOverAvailableAmount ? 'orange' : undefined}
            />
            {toSpend !== undefined && showSpendAmount && (
                <SummaryRow
                    col1Text={texts('assetSummary.amountToSpend')}
                    col2Text={NumberFormat.addThousandSeparators(toSpend.toFixed(asset.tokenDecimal))}
                    color={spendOverTotalInVault || spendOverAvailableAmount ? 'orange' : undefined}
                />
            )}
            {toSpend !== undefined && showRemainingAssets && (
                <SummaryRow
                    col1Text={texts('transactionSummary.remainingAfterTransaction')}
                    col2Text={NumberFormat.addThousandSeparators(
                        (asset.totalInVault - toSpend).toFixed(asset.tokenDecimal)
                    )}
                    color={spendOverTotalInVault ? 'orange' : undefined}
                />
            )}
        </SummaryTable>
    );
};

export default AssetSummary;
