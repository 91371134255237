import * as ROUTES from 'constants/Routes';

import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import { Button } from '@material-ui/core';
import useAppConfigContext from 'contexts/AppConfig/AppConfig';
import { useHistory } from 'react-router-dom';
import { useTopNavWrapperStyles } from '@r3/cbdc-asset-frontend-core';

const NetworkButton = () => {
    const appConfigContext = useAppConfigContext();
    const history = useHistory();
    const classes = useTopNavWrapperStyles();
    return (
        <Button
            className={`navButton  ${classes.buttonNav} ${
                history.location.pathname === ROUTES.NETWORKTAB || history.location.pathname === ROUTES.NETWORKTABRETAIL
                    ? classes.selected
                    : ''
            }`}
            startIcon={<AccountTreeRoundedIcon />}
            color="primary"
            href={appConfigContext?.isRetailClient ? ROUTES.NETWORKTABRETAIL : ROUTES.NETWORKTAB}
        >
            {'Network'}
        </Button>
    );
};

export default NetworkButton;
