import { Asset, ComplianceRuleKey, PaymentRequestResponse } from '@r3/cbdc-asset-frontend-core';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import MaxValueRuleSection from 'components/commonComponents/MaxValueRuleSection/MaxValueRuleSection';
import PaymentRequest from '../PaymentRequest/PaymentRequest';
import { requestPayment } from '../../../api/retailApi';
import { useEffect } from 'react';
import { useFormStyles } from 'materialStyles/formStyles';
import useGetRetailAssetDefinitions from '../../../hooks/GetRetailAssetDefinitions';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RequestPayment: React.FC = () => {
    const { t } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const formClasses = useFormStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { getAllAssets, assets } = useGetRetailAssetDefinitions();
    const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
    const [paymentRequestResponse, setPaymentRequestResponse] = useState<PaymentRequestResponse | null>(null);
    const [assetAmount, setAssetAmount] = useState<string>('0');
    const { getAssetbyId } = useGetRetailAssetDefinitions(true);

    useEffect(() => {
        getAllAssets();
    }, [getAllAssets]);

    const handleAssetChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let asset = assets.find((asset) => asset.tokenName === (event.target.value as string));
        if (asset) {
            setSelectedAsset(asset);
        } else {
            setSelectedAsset(null);
        }
        setAssetAmount('');
    };

    const submit = async () => {
        if (!selectedAsset) {
            return;
        }
        const response = await requestPayment(selectedAsset.tokenIdentifier, parseFloat(assetAmount));

        if (response.error) {
            enqueueSnackbar(t('error.retailRequestPayment', { error: response.error }), {
                variant: 'error',
            });
        } else {
            enqueueSnackbar(t('success.retailRequestPayment', { error: response.error }), {
                variant: 'success',
            });

            setPaymentRequestResponse(response.data as PaymentRequestResponse);
        }
    };

    const assetDefinition = selectedAsset ? getAssetbyId(selectedAsset.tokenIdentifier) : null;
    const maxValue = assetDefinition
        ? assetDefinition.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)?.value
        : null;
    const maxValueExceeded = maxValue ? parseFloat(assetAmount) > parseFloat(maxValue) : false;

    const emptyAsset = selectedAsset == null;
    return (
        <>
            {!paymentRequestResponse && (
                <div className={formClasses.centeredFormWrapper}>
                    <FormControl
                        id="SelectAsset"
                        variant="filled"
                        className={`${formClasses.columnItem} ${formClasses.formInput} ${layoutClasses.formInput} `}
                    >
                        <InputLabel id="SelectLabel">Asset</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={!selectedAsset ? '' : selectedAsset.tokenName}
                            onChange={handleAssetChange}
                            data-testid="asset_select"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {assets.map((as, i) => {
                                return (
                                    <MenuItem key={as.tokenName + i} value={as.tokenName} data-testid={'asset-option'}>
                                        {as.tokenName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {assetDefinition && maxValue && <MaxValueRuleSection amount={maxValue} />}
                    <CurrencyInput
                        disabled={emptyAsset}
                        decimalPlaces={selectedAsset ? selectedAsset.tokenDecimals : 0}
                        onChange={setAssetAmount}
                        label={maxValueExceeded ? 'Max value compliance rule exceeded' : t('issueAsset.assetAmount')}
                        testId="amount-to-request"
                        invalidInput={emptyAsset || assetAmount.length <= 0 || maxValueExceeded}
                    />
                    <Button
                        className={`${formClasses.formControlButton}  ${formClasses.button}`}
                        style={{ marginTop: 16 }}
                        variant="outlined"
                        color="primary"
                        disabled={emptyAsset || assetAmount.length <= 0 || maxValueExceeded}
                        onClick={() => submit()}
                        data-testid={'submit'}
                    >
                        {'Generate Request'}
                    </Button>
                </div>
            )}
            {paymentRequestResponse && <PaymentRequest requestResponse={paymentRequestResponse} />}
        </>
    );
};

export default RequestPayment;
