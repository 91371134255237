import { Payee } from 'components/retailClient/RetailPayees/RetailPayeeItem';
import { createCtx } from 'contexts/createCtx';
import useGetAllPayees from 'hooks/GetAllPayees';

type RetailPayeeContextProps = {
    getPayees: () => Promise<void>;
    getPayeeNameByAccountId: (accountId: string) => string | null;
    error: string;
    loading: boolean;
    payees: Payee[];
};

const [useRetailPayeesContext, Provider] = createCtx<RetailPayeeContextProps>();

export default useRetailPayeesContext;

export const RetailPayeeContextProvider = ({ children }) => {
    const { getPayeeNameByAccountId, getPayees, error, loading, payees } = useGetAllPayees(true);
    return <Provider value={{ getPayeeNameByAccountId, getPayees, error, loading, payees }}>{children}</Provider>;
};
