import { Asset, NumberFormat, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { Divider, Paper, Typography } from '@material-ui/core';

import SummaryRow from 'components/commonComponents/SummaryComponents/SummaryRow';
import SummaryTable from 'components/commonComponents/SummaryComponents/SummaryTable';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type ReviewOrderStepProps = {
    swapAsset: Asset;
    selectedAsset: SummedAsset;
    swapAssetAmount: string;
    assetAmount: string;
    assetInVaultAmount: number | undefined;
    originalParticpantTotal: number;
    selectedParticipants: string[];
};

const ReviewOrderStep: React.FC<ReviewOrderStepProps> = (props) => {
    const {
        swapAsset,
        selectedAsset,
        swapAssetAmount,
        assetAmount,

        selectedParticipants,
        originalParticpantTotal,
    } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();
    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('createExchangeOffer.summaryMessage')}
                </Typography>
                <Divider className={layoutClasses.summaryDivider} />

                <SummaryTable>
                    <SummaryRow
                        col1Text={t('crossChainSwap.requestingAmount') + ` of ${swapAsset.tokenName}`}
                        col2Text={NumberFormat.addThousandSeparators(
                            parseFloat(swapAssetAmount).toFixed(swapAsset.tokenDecimals)
                        )}
                    />
                    <SummaryRow
                        col1Text={t('crossChainSwap.offeringAmount') + ` of ${selectedAsset.tokenName}`}
                        col2Text={NumberFormat.addThousandSeparators(
                            parseFloat(assetAmount).toFixed(selectedAsset.tokenDecimal)
                        )}
                    />
                    <SummaryRow
                        col1Text={t('transactionSummary.assetRemainingAfterTransaction', {
                            assetName: selectedAsset.tokenName,
                        })}
                        col2Text={NumberFormat.addThousandSeparators(
                            (selectedAsset.totalInVault - parseFloat(assetAmount)).toFixed(selectedAsset.tokenDecimal)
                        )}
                    />
                </SummaryTable>
                <Divider className={layoutClasses.summaryDivider} />
                <Typography className={`${layoutClasses.sectionLabel}`}>
                    {t('liquiditySavings.selectedOfferBroadcastParticipants') + ':'}
                </Typography>
                {originalParticpantTotal === selectedParticipants.length && (
                    <Typography className={`${layoutClasses.sectionLabel}`}>
                        {t('commonText.allNetworkParticipants')}
                    </Typography>
                )}
                {originalParticpantTotal > selectedParticipants.length &&
                    selectedParticipants.map((participant) => (
                        <Typography key={participant} className={`${layoutClasses.text}`}>
                            {participant}
                        </Typography>
                    ))}
            </Paper>
        </div>
    );
};

export default ReviewOrderStep;
