import { Card } from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { CheckListAction } from '../../constants/InteractiveChecklistList';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React from 'react';
import { useAssistantStyles } from '@r3/cbdc-asset-frontend-core';

type TutorialStepProps = {
    index: number;
    progress: number;
    currentGoalRef: React.RefObject<HTMLElement> | null | undefined;
    setFocusedItem: (option: CheckListAction) => void;
    option: CheckListAction;
};

export const TutorialStep: React.FC<TutorialStepProps> = (props) => {
    const { index, progress, currentGoalRef, setFocusedItem, option } = props;
    const classes = useAssistantStyles();

    return (
        <Card
            ref={index === progress ? currentGoalRef : null}
            key={`option + ${index}`}
            onClick={() => {
                setFocusedItem(option);
            }}
            className={` ${
                index < progress
                    ? classes.completeOption
                    : index > progress
                    ? classes.disabledOption
                    : classes.assistantOption
            } `}
        >
            <div className={classes.assistantOptionText}>{index + 1 + '. ' + option.description} </div>
            {index < progress && (
                <CheckCircleOutlinedIcon
                    style={{ marginTop: 10, marginLeft: 'auto', marginRight: 10, color: 'lawngreen' }}
                />
            )}

            {index === progress && (
                <EmojiFlagsIcon style={{ marginTop: 10, marginLeft: 'auto', marginRight: 10, color: 'yellow' }} />
            )}

            {index > progress && (
                <RadioButtonUncheckedIcon
                    style={{ marginTop: 10, marginLeft: 'auto', marginRight: 10, color: 'lightgrey' }}
                />
            )}
        </Card>
    );
};
