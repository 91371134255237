import { CSSProperties } from 'react';
import { useTransactionSummaryStyles } from 'materialStyles/transactionSummaryStyles';

type Props = {
    small?: boolean;
    style?: CSSProperties;
};

const SummaryTable: React.FC<Props> = ({ small = false, children, style }) => {
    const classes = useTransactionSummaryStyles();
    return (
        <table
            className={`${classes.table} ${small ? classes.tableSmall : classes.tableRegular}`}
            data-testid={'summary-table'}
            style={style}
        >
            <tbody>{children}</tbody>
        </table>
    );
};

export default SummaryTable;
