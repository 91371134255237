import { useCallback, useState } from 'react';

import { Customer } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllCustomers } from 'api/customerApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function useGetAllCustomers() {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const getCustomers = useCallback(
        async (searchTerm: string = '') => {
            setLoading(true);
            let kycStatesResponse: ResolvedPromise;
            kycStatesResponse = await requestAllCustomers(searchTerm);
            if (kycStatesResponse.error) {
                enqueueSnackbar(t('error.gettingCustomers', { error: kycStatesResponse.error }), {
                    variant: 'error',
                });
                setError(kycStatesResponse.error.length > 0 ? kycStatesResponse.error : t('error.gettingCustomers'));
            } else {
                setCustomers(kycStatesResponse.data as Customer[]);
            }

            setLoading(false);
        },
        [enqueueSnackbar, t]
    );

    return { customers, error, getCustomers, loading };
}
