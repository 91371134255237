import apiCall from './apiCall';

/**
 * @remark
 * Used to request all redemption requests from all participants
 *
 * @returns
 * Axios Response which resolved in redemption request object array or error
 */
export const requestAllRedemptionRequests = async (dontTrack?: boolean) =>
    apiCall({
        method: 'get',
        path: `/api/redemptionRequest`,
        dontTrackRequest: dontTrack,
    });

/**
 *
 * @remark
 * Used to create a new Redemption Request to the Central Bank
 *
 * @param issuer Name of issuer to request from
 * @param tokenIdentifier token identifier (relating to the asset being requested)
 * @param requestedAmount Value of the usd bond being requested
 *
 * @returns
 * Axios Response which resolves in new redemption request object or error
 */
export const createRedemptionRequest = async (tokenIdentifier: string, issuer: string, requestedAmount: number) =>
    apiCall({
        method: 'post',
        path: `/api/redemptionRequest/create`,
        params: {
            issuer: issuer,
            tokenIdentifier: tokenIdentifier,
            requestedAmount: requestedAmount,
        },
    });
