import { AppBar, Paper, Toolbar, Typography } from '@material-ui/core';

import { Icon } from '@material-ui/core';
import NetworkButton from './NetworkButton';
import React from 'react';
import { SMALLSCREENWIDTH } from 'constants/ScreenSizes';
import useAppConfigContext from 'contexts/AppConfig/AppConfig';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTopNavWrapperStyles } from '@r3/cbdc-asset-frontend-core';

interface Props {
    button: React.ReactNode;
    navButtons: React.ReactNode;
    icon: React.ReactNode;
    additionalButtons?: React.ReactNode;
}

const TopNavWrapper: React.FC<Props> = ({ button, navButtons, icon, additionalButtons }) => {
    const history = useHistory();
    const classes = useTopNavWrapperStyles();
    const appConfigContext = useAppConfigContext();

    const brandIconSource =
        appConfigContext && appConfigContext?.tempBrandIconSource.length > 0
            ? appConfigContext?.tempBrandIconSource
            : `/appConfig/brandedIcon.png`;
    const appIconSource =
        appConfigContext && appConfigContext?.tempAppIconSource.length > 0
            ? appConfigContext?.tempAppIconSource
            : `/appConfig/appicon.png`;
    const defaultIcons = appConfigContext?.appConfig.defaultIcons === 'true' ? true : false;

    const isSmallScreen = useMediaQuery({ maxWidth: SMALLSCREENWIDTH });

    return (
        <Paper elevation={5} square className={classes.grow}>
            <AppBar className={classes.appBar} elevation={0} position="static">
                <Toolbar>
                    {button}
                    {defaultIcons && <>{icon}</>}
                    {!defaultIcons && (
                        <img
                            id="AppIcon"
                            onClick={() => {
                                history.push('/');
                            }}
                            src={appIconSource}
                            height={55}
                            width={55}
                            alt="App Icon"
                        />
                    )}
                    <Typography className={classes.title} variant="h6" noWrap>
                        {appConfigContext?.appConfig.appName}
                    </Typography>
                    <Typography className={classes.subTitle} variant="body1" noWrap>
                        Powered By
                    </Typography>
                    <img className={classes.cordaImg} src={`/cordawht.png`} height={30} width={75} alt="Corda R3" />

                    <div className={classes.grow}></div>
                    {!isSmallScreen && <NetworkButton />}
                    {additionalButtons}
                    <div className={classes.sectionDesktop}>
                        <Icon className={classes.appIcon}>
                            <img src={brandIconSource} alt={'r3'} height={35} width={35} />
                        </Icon>
                    </div>
                </Toolbar>
                <div className={classes.navButtonContainer}>{navButtons}</div>
            </AppBar>
        </Paper>
    );
};

export default TopNavWrapper;
