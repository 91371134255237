import { Backdrop, CircularProgress, Theme, createStyles, makeStyles } from '@material-ui/core';

import React from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            backdrop: {
                zIndex: 2000,
                color: '#EC1D24',
            },
        }),
    { index: 1 }
);

const LoadingModal: React.FC = () => {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="secondary" />
        </Backdrop>
    );
};

export default LoadingModal;
