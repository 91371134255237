import { useCallback, useState } from 'react';

import { PvpRequest } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllCrossChainSwapRequests } from 'api/transactApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export function useGetPvpRequests() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string | null>(null);
    const [pvpRequests, setPvpRequests] = useState<PvpRequest[]>([]);

    const getCrossChainSwapRequests = useCallback(
        async (dontTrack?: boolean) => {
            setError(null);
            const response: ResolvedPromise = await requestAllCrossChainSwapRequests();
            if (response.error) {
                if (dontTrack) {
                    return;
                }
                enqueueSnackbar(t('error.gettingCrossChainSwapRequests', { error: response.error }), {
                    variant: 'error',
                });
                setError(response.error.length > 0 ? response.error : t('error.networkError'));
            } else {
                if (response.data.length <= 0) {
                    const warningText = t('No Cross Chain Swap Requests');
                    if (!dontTrack) {
                        enqueueSnackbar(warningText, {
                            variant: 'info',
                        });
                        setError(warningText);
                    }
                }
                setPvpRequests(response.data as PvpRequest[]);
            }
        },
        [t, enqueueSnackbar]
    );

    return { pvpRequests, error, getCrossChainSwapRequests };
}
