import { TransactionRequestState } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import React from 'react';
import { Switch } from '@material-ui/core';

export default function useFilterRequests(showFailed?: boolean) {
    const [filterRequestTypes, setFilterRequestTypes] = useState<TransactionRequestState[]>(
        Object.values(TransactionRequestState)
    );
    const [filterSwitches, setFilterSwitches] = useState(<></>);

    const switchFilter = (state: TransactionRequestState) => {
        if (!filterRequestTypes.includes(state)) {
            setFilterRequestTypes((types) => types.concat([state]));
        } else {
            setFilterRequestTypes((types) => types.filter((type) => type !== state));
        }
    };

    const displayRequestType = (requestType: TransactionRequestState) => {
        if (filterRequestTypes.includes(requestType)) {
            return true;
        } else {
            return false;
        }
    };

    useEffect(() => {
        const filters = (
            <div style={{ display: 'flex', color: 'white' }}>
                <div>
                    Approved
                    <Switch
                        id="ApprovedFilterSwitch"
                        checked={filterRequestTypes.includes(TransactionRequestState.APPROVED)}
                        onClick={() => switchFilter(TransactionRequestState.APPROVED)}
                    />
                </div>
                <div>
                    Requested
                    <Switch
                        id="RequestedFilterSwitch"
                        checked={filterRequestTypes.includes(TransactionRequestState.REQUESTED)}
                        onClick={() => switchFilter(TransactionRequestState.REQUESTED)}
                    />
                </div>
                <div>
                    Denied
                    <Switch
                        id="DeniedFilterSwitch"
                        checked={filterRequestTypes.includes(TransactionRequestState.DENIED)}
                        onClick={() => switchFilter(TransactionRequestState.DENIED)}
                    />
                </div>

                {showFailed && (
                    <div>
                        Failed
                        <Switch
                            id="FailedFilterSwitch"
                            checked={filterRequestTypes.includes(TransactionRequestState.FAILED)}
                            onClick={() => switchFilter(TransactionRequestState.FAILED)}
                        />
                    </div>
                )}
            </div>
        );

        setFilterSwitches(filters);
        // eslint-disable-next-line
    }, [filterRequestTypes, showFailed]);

    return { filterSwitches, displayRequestType };
}
