import { ASSETDASH, BALANCEOVERTIME, BONDSDASH } from 'constants/Routes';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { Paper, Tab, Tabs } from '@material-ui/core';

import { AssetDashboard } from './AssetDashboard/AssetDashboard';
import { BalanceOverTime } from './BalanceOvertime/BalanceOverTime';
import { BondsDashboard } from './BondsDashboard/BondsDashboard';
import { Header } from '@r3/cbdc-asset-frontend-core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import React from 'react';
import { RedirectWithTabIndex } from '@r3/cbdc-asset-frontend-core';
import TimelineIcon from '@material-ui/icons/Timeline';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import { useTabStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

export const DemoDashboard: React.FC = () => {
    const tabClasses = useTabStyles();
    const { t } = useTranslation();
    const location = useLocation<RedirectWithTabIndex>();
    return (
        <>
            <Header>{t('pageTitle.treasuryDashboard')}</Header>
            <Paper elevation={2} className={`${tabClasses.wrapper} `}>
                <Tabs
                    className={`${tabClasses.root} tabs`}
                    classes={{ flexContainer: tabClasses.tabContainer }}
                    value={location.pathname}
                >
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.assetDashboard')}
                        icon={<MonetizationOnIcon />}
                        value={ASSETDASH}
                        component={Link}
                        to={ASSETDASH}
                    />
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.balanceOverTime')}
                        icon={<TimelineIcon />}
                        value={BALANCEOVERTIME}
                        component={Link}
                        to={BALANCEOVERTIME}
                    />
                    <Tab
                        className={`${tabClasses.tab} tab`}
                        classes={{ wrapper: tabClasses.tabText }}
                        label={t('pageTitle.bondsDashboard')}
                        icon={<WebAssetIcon />}
                        value={BONDSDASH}
                        component={Link}
                        to={BONDSDASH}
                    />
                </Tabs>
                <Switch>
                    <Route path={ASSETDASH} component={AssetDashboard} />
                    <Route path={BALANCEOVERTIME} component={BalanceOverTime} />
                    <Route path={BONDSDASH} component={BondsDashboard} />
                </Switch>
            </Paper>
        </>
    );
};
