import { RedemptionRequest, TransactionRequestState } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import { Divider } from '@material-ui/core';
import RedemptionRequestComponent from './RedemptionRequest';
import RequestList from 'components/commonComponents/RequestListComponents/RequestList';
import RequestListDivider from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDivider';
import RequestListDropdownSection from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownSection';
import RequestListDropdownTitle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownTitle';
import RequestListDropdownToggle from 'components/commonComponents/RequestListComponents/RequestListDropdown/RequestListDropdownToggle';
import useFilterRequests from 'hooks/FilterRequests';
import { useListStyles } from '@r3/cbdc-asset-frontend-core';
import { useTranslation } from 'react-i18next';

type RedemptionRequestsListProps = {
    sortedRedemptionRequests?: Map<string, RedemptionRequest[]>;
    openInfoModal: (redemptionRequest: RedemptionRequest) => void;
};

const RedemptionRequestsList: React.FC<RedemptionRequestsListProps> = ({ sortedRedemptionRequests, openInfoModal }) => {
    const listClasses = useListStyles();
    const { filterSwitches, displayRequestType } = useFilterRequests();
    const [collapsedKeys, setCollapesedKeys] = useState<string[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        const reqKeys = Array.from(sortedRedemptionRequests?.keys() ?? []);
        if (reqKeys.length === 1) {
            setCollapesedKeys([]);
        } else {
            setCollapesedKeys(reqKeys);
        }
    }, [sortedRedemptionRequests]);

    const handlePublicKeyClick = (key: string) => {
        if (collapsedKeys.includes(key)) {
            setCollapesedKeys((keys) => keys.filter((x) => x !== key));
        } else {
            setCollapesedKeys((keys) => keys.concat([key]));
        }
    };

    return (
        <>
            {filterSwitches}
            <RequestListDivider />
            <RequestList>
                {sortedRedemptionRequests !== undefined &&
                    sortedRedemptionRequests.mapGroups((issuer, items) => {
                        const publickKeyElement: JSX.Element = (
                            <RequestListDropdownSection>
                                <RequestListDropdownTitle>
                                    <b>{t('commonText.issuer') + ':'} </b>
                                    {issuer}
                                </RequestListDropdownTitle>
                                <RequestListDropdownToggle
                                    toggleCondition={collapsedKeys.includes(issuer)}
                                    onClick={() => handlePublicKeyClick(issuer)}
                                />
                            </RequestListDropdownSection>
                        );
                        if (collapsedKeys.includes(issuer)) {
                            return <div key={issuer}>{publickKeyElement} </div>;
                        }
                        return (
                            <div key={issuer}>
                                {publickKeyElement}
                                {(items as RedemptionRequest[])
                                    .filter((req) => displayRequestType(req.requestState as TransactionRequestState))
                                    .map((request) => {
                                        return (
                                            <RedemptionRequestComponent
                                                key={issuer + '_' + request.requestId}
                                                request={request}
                                                onClick={() => {
                                                    openInfoModal(request);
                                                }}
                                            />
                                        );
                                    })}
                            </div>
                        );
                    })}
            </RequestList>
            <Divider className={listClasses.divider} />{' '}
        </>
    );
};

export default RedemptionRequestsList;
