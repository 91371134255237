import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { AppConfig } from '@r3/cbdc-asset-frontend-core';
import AppConfigThemeInputField from './AppConfigThemeInputField';
import ColourInput from './ColourInput';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ImageUpload from './ImageUpload';
import PaletteRoundedIcon from '@material-ui/icons/PaletteRounded';
import { defaultTheme } from '../../constants/Theme';
import useAppConfigContext from '../../contexts/AppConfig/AppConfig';
import { useAppConfigToolStyles } from '@r3/cbdc-asset-frontend-core';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { isValidAppConfig } from 'components/themeConfigTool/isAppConfigValid';

const AppConfigTool = () => {
    const appConfigContext = useAppConfigContext();
    const [appConfig, setAppConfig] = useState<AppConfig>(defaultTheme);
    const layoutClasses = useLayoutStyles();
    const classes = useAppConfigToolStyles();

    useEffect(() => {
        if (appConfigContext) {
            setAppConfig(appConfigContext.appConfig);
        }
    }, [appConfigContext]);

    const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const tempAppConfig: AppConfig = { ...appConfig };
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        switch (name) {
            case 'backgroundColour': {
                tempAppConfig.backgroundColour = value;
                break;
            }
            case 'primaryColourMain': {
                tempAppConfig.primaryColourMain = value;
                break;
            }
            case 'primaryColourDark': {
                tempAppConfig.primaryColourDark = value;
                break;
            }
            case 'secondaryColour': {
                tempAppConfig.secondaryColourMain = value;
                break;
            }
            case 'borderRadius': {
                tempAppConfig.borderRadius = parseInt(value);
                break;
            }
            case 'appName': {
                tempAppConfig.appName = value;
                break;
            }
            case 'wholesaleBankOneName': {
                tempAppConfig.wholesaleBankOneName = value;
                break;
            }
            case 'wholesaleBankTwoName': {
                tempAppConfig.wholesaleBankTwoName = value;
                break;
            }
            case 'wholesaleBankThreeName': {
                tempAppConfig.wholesaleBankThreeName = value;
                break;
            }
            case 'centralBankName': {
                tempAppConfig.centralBankName = value;
                break;
            }
            case 'centralBankColour': {
                tempAppConfig.centralBankColour = value;
                break;
            }
            case 'nivoPieTheme': {
                tempAppConfig.nivoPieTheme = value;
                break;
            }
            case 'title': {
                tempAppConfig.title = value;
                break;
            }
            case 'wholesaleBankColour': {
                tempAppConfig.wholesaleBankColour = value;
                break;
            }
        }
        setAppConfig(tempAppConfig);
    };

    const applyTheme = () => {
        if (isValidAppConfig(appConfig)) {
            let preUploadConfig = { ...appConfig };
            preUploadConfig.feedbackFormUrl = '';
            if (appConfigContext && appConfigContext.tempAppIconSource.length > 0) {
                preUploadConfig.defaultIcons = 'false';
            }
            appConfigContext?.setTempSessionConfig(preUploadConfig);
        }
    };

    return (
        <div className={classes.wrapper}>
            <div style={{ display: 'flex' }}>
                <Typography className={classes.title}> App Config </Typography>
                <Typography className={classes.colourPickerText}>{'Colour Picker ->'}</Typography>
                <IconButton
                    onClick={() => {
                        window.open('https://htmlcolorcodes.com/color-picker/');
                    }}
                >
                    <PaletteRoundedIcon color={'secondary'} />
                </IconButton>
            </div>
            <div className={classes.inputsWrapper}>
                <Typography className={classes.sectionLabel}> Main </Typography>
                <AppConfigThemeInputField
                    value={appConfig.appName}
                    name={'appName'}
                    handleInputChange={handleInputChange}
                />
                <AppConfigThemeInputField
                    value={appConfig.title}
                    name={'title'}
                    handleInputChange={handleInputChange}
                />
                <ColourInput
                    name="backgroundColour"
                    colour={appConfig.backgroundColour}
                    handleInputChange={handleInputChange}
                />
                <ColourInput
                    name="primaryColourDark"
                    colour={appConfig.primaryColourDark}
                    handleInputChange={handleInputChange}
                />
                <ColourInput
                    name="primaryColourMain"
                    colour={appConfig.primaryColourMain}
                    handleInputChange={handleInputChange}
                />
                <ColourInput
                    name="secondaryColour"
                    colour={appConfig.secondaryColourMain}
                    handleInputChange={handleInputChange}
                />

                <Typography className={classes.sectionLabel}> Network Tab </Typography>

                <AppConfigThemeInputField
                    value={appConfig.centralBankName}
                    name={'centralBankName'}
                    handleInputChange={handleInputChange}
                />
                <ColourInput
                    name="centralBankColour"
                    colour={appConfig.centralBankColour}
                    handleInputChange={handleInputChange}
                />

                <AppConfigThemeInputField
                    value={appConfig.wholesaleBankOneName}
                    name={'wholesaleBankOneName'}
                    handleInputChange={handleInputChange}
                />
                <AppConfigThemeInputField
                    value={appConfig.wholesaleBankTwoName}
                    name={'wholesaleBankTwoName'}
                    handleInputChange={handleInputChange}
                />
                <AppConfigThemeInputField
                    value={appConfig.wholesaleBankThreeName}
                    name={'wholesaleBankThreeName'}
                    handleInputChange={handleInputChange}
                />
                <ColourInput
                    name="wholesaleBankColour"
                    colour={appConfig.wholesaleBankColour}
                    handleInputChange={handleInputChange}
                />

                <Typography className={classes.sectionLabel}> Extra </Typography>

                <TextField
                    variant="filled"
                    type="number"
                    className={`${layoutClasses.formInput}`}
                    style={{ marginTop: 2, marginBottom: 2 }}
                    label={'borderRadius'}
                    value={appConfig.borderRadius}
                    onChange={handleInputChange}
                    name="borderRadius"
                />
                <a style={{ color: 'white' }} href="https://nivo.rocks/guides/colors/" target="_blank" rel="noreferrer">
                    Link to themes
                </a>
                <AppConfigThemeInputField
                    value={appConfig.nivoPieTheme ? appConfig.nivoPieTheme : ''}
                    name={'nivoPieTheme'}
                    handleInputChange={handleInputChange}
                />
                {appConfigContext && (
                    <>
                        <ImageUpload
                            buttonText={'Upload App Icon'}
                            setTempPreviewSource={appConfigContext.setTempAppIconSource}
                        />
                        <ImageUpload
                            buttonText={'Upload Brand Icon'}
                            setTempPreviewSource={appConfigContext.setTempBrandIconSource}
                        />
                    </>
                )}
            </div>
            <div className={classes.buttonsWrapper}>
                <Button
                    size={'small'}
                    variant="outlined"
                    color="primary"
                    disabled={!isValidAppConfig(appConfig)}
                    className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize}`}
                    onClick={() => {
                        applyTheme();
                    }}
                >
                    Apply
                </Button>
                <Button
                    size={'small'}
                    variant="outlined"
                    color="primary"
                    className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize}`}
                    onClick={() => {
                        appConfigContext?.clearTempSessionConfig();
                    }}
                >
                    Reset
                </Button>
            </div>
            <div className={classes.buttonsWrapper}>
                <Button
                    href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(appConfig))}`}
                    download="theme.json"
                    size={'small'}
                    variant="outlined"
                    className={`${layoutClasses.button} ${layoutClasses.buttonAutoSize}`}
                    endIcon={<FileCopyIcon color="secondary" />}
                >
                    Download Json
                </Button>
            </div>
        </div>
    );
};

export default AppConfigTool;
