import { Asset, NumberFormat, PaymentRequestResponse, TimeUnit } from '@r3/cbdc-asset-frontend-core';
import { Button, Paper } from '@material-ui/core';
import { DialogBody, DialogWindow } from '@r3/cbdc-asset-frontend-core';
import { useEffect, useState } from 'react';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CompletedRequest from '../PaymentRequest/CompletedRequest';
import PageviewIcon from '@material-ui/icons/Pageview';
import PaymentRequest from '../PaymentRequest/PaymentRequest';
import React from 'react';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import WavesRoundedIcon from '@material-ui/icons/WavesRounded';
import useComponentVisible from '../../../hooks/ComponentVisible';
import { useRetailItemStyles } from 'materialStyles/retailStyles/retailItemStyles';

type RetailPaymentItemProps = {
    request: PaymentRequestResponse;
    asset: Asset;
};

const RetailPaymentItem: React.FC<RetailPaymentItemProps> = ({ request, asset }) => {
    const classes = useRetailItemStyles();
    const {
        ref,
        isComponentVisible: isComponentOpen,
        setIsComponentVisible: setIsComponentOpen,
    } = useComponentVisible(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const isDetailsShowing = isDialogOpen || isComponentOpen;

    useEffect(() => {
        if (ref.current && isComponentOpen) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
        // eslint-disable-next-line
    }, [isComponentOpen]);

    return (
        <div>
            <Paper
                ref={ref}
                className={`${classes.request} ${!isComponentOpen ? classes.hoverableRequest : ''} ${
                    request.paymentDate && new Date(request.paymentDate).isInTimeRangeFromNow(4, TimeUnit.Seconds)
                        ? classes.pulsingUpdated
                        : ''
                }`}
                onClick={() => {
                    if (!isComponentOpen) {
                        setIsComponentOpen(true);
                    }
                }}
                elevation={5}
                data-testid="payment_request"
            >
                {request.requestState === 'REQUESTED' && (
                    <WavesRoundedIcon className={`${classes.itemIcon} ${classes.pendingIcon}`} />
                )}

                {request.requestState === 'PAID' && (
                    <CheckCircleOutlineIcon className={`${classes.itemIcon} ${classes.completeIcon}`} />
                )}

                <div className={classes.itemInfo}>
                    <div className={classes.payeeDesc}>
                        <b>Amount: </b>
                        {NumberFormat.addThousandSeparators(request.amount.toFixed(asset.tokenDecimals))}
                    </div>
                    <div className={classes.requestInfoWrapper}>
                        <div>
                            <b>Asset Name: </b>
                            {asset.tokenName}
                        </div>
                        <div>
                            <b>Request Date: </b>
                            {new Date(request.invoiceDate).toLocaleString()}
                        </div>
                        {isDetailsShowing && (
                            <>
                                <div>
                                    <b>Asset Id: </b>
                                    {request.tokenIdentifier}
                                </div>
                                <div>
                                    <b>Bank Name: </b>
                                    {request.host}
                                </div>
                                <div>
                                    <b>Request Id: </b>
                                    {request.requestId}
                                </div>
                                <div>
                                    <b>Request State: </b>
                                    {request.requestState}
                                </div>
                            </>
                        )}
                    </div>
                    {isDetailsShowing && (
                        <div style={{ display: 'flex', margin: '8px auto auto auto' }}>
                            <Button
                                className={`${classes.actionButtonTwo} ${classes.sendIcon}`}
                                onClick={() => {
                                    setIsDialogOpen(true);
                                }}
                                endIcon={
                                    request.requestState === 'REQUESTED' ? (
                                        <ShareRoundedIcon className={classes.buttonIcon} />
                                    ) : (
                                        <PageviewIcon className={classes.buttonIcon} />
                                    )
                                }
                                data-testid="payment_option"
                            >
                                {request.requestState === 'REQUESTED' ? 'Share' : 'View'}
                            </Button>
                        </div>
                    )}
                </div>
            </Paper>
            <DialogWindow
                open={isDialogOpen}
                onClose={() => {
                    setIsDialogOpen(false);
                }}
                title={request.requestState === 'PAID' ? 'Request Overview' : 'Share Request Details'}
            >
                <DialogBody>
                    {request.requestState === 'REQUESTED' && <PaymentRequest requestResponse={request} />}
                    {request.requestState === 'PAID' && <CompletedRequest requestResponse={request} />}
                </DialogBody>
            </DialogWindow>
        </div>
    );
};

export default React.memo(RetailPaymentItem);
