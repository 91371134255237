import { Asset, ComplianceRuleKey, NumberFormat, SummedAsset } from '@r3/cbdc-asset-frontend-core';
import { Divider, Paper, Typography } from '@material-ui/core';

import AssetSummary from 'components/commonComponents/SummaryComponents/AssetSummary';
import { CurrencyInput } from 'components/commonComponents/CurrencyInput/CurrencyInput';
import React from 'react';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';

type AssetAmountsStepPorps = {
    selectedAsset: SummedAsset;
    selectedAssetAmountInVault: number | undefined;
    selectedAssetAvailableToTransact: number | undefined;
    selectedSwapAsset: Asset;
    assetAmount: string;
    setAssetAmount: (val: string) => void;
    swapAmount: string;
    setSwapAmount: (val: string) => void;
    invalidAssetInput: (asset: SummedAsset | Asset, amount: string, totalInVault?: number) => boolean;
};

const AssetAmountsStep: React.FC<AssetAmountsStepPorps> = (props) => {
    const {
        selectedAsset,
        selectedSwapAsset,
        assetAmount,
        setAssetAmount,
        swapAmount,
        setSwapAmount,
        invalidAssetInput,
        selectedAssetAvailableToTransact,
    } = props;

    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();

    const invalidSwapAssetInput = invalidAssetInput(selectedSwapAsset, swapAmount);
    const invalidSelectedAssetInput = invalidAssetInput(selectedAsset, assetAmount, selectedAssetAvailableToTransact);

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.text}`}>
                    {t('crossChainSwap.enterRequestingAmount', { assetName: selectedSwapAsset.tokenName })}
                </Typography>
                {selectedSwapAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) && (
                    <>
                        <Typography className={`${layoutClasses.text}`}>
                            Maximum Transaction value with this asset{' '}
                            {NumberFormat.addThousandSeparators(
                                selectedSwapAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)
                                    ?.value ?? ''
                            )}
                        </Typography>
                    </>
                )}
                <Typography style={{ fontSize: 18 }} className={`${layoutClasses.text}`}>
                    {t('issueAsset.decimal') + ': ' + selectedSwapAsset.tokenDecimals}
                </Typography>

                <CurrencyInput
                    decimalPlaces={selectedSwapAsset.tokenDecimals}
                    onChange={setSwapAmount}
                    label={invalidSwapAssetInput ? t('commonText.invalidAmount') : 'Asset Amount'}
                    testId="amount-to-request"
                    invalidInput={invalidSwapAssetInput}
                />
                <Divider className={layoutClasses.summaryDivider} />

                <Typography className={`${layoutClasses.text}`}>
                    {t('crossChainSwap.enterOfferAmounts', { assetName: selectedAsset.tokenName })}
                </Typography>

                {selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue) && (
                    <>
                        <Typography className={`${layoutClasses.text}`}>
                            Maximum Transaction value with this asset{' '}
                            {NumberFormat.addThousandSeparators(
                                selectedAsset.complianceRules?.find((x) => x.key === ComplianceRuleKey.MaxValue)
                                    ?.value ?? ''
                            )}
                        </Typography>
                    </>
                )}
                <Typography style={{ fontSize: 18 }} className={`${layoutClasses.text}`}>
                    {t('issueAsset.decimal') + ': ' + selectedAsset.tokenDecimal}
                </Typography>
                <CurrencyInput
                    decimalPlaces={selectedAsset.tokenDecimal}
                    onChange={setAssetAmount}
                    label={invalidSelectedAssetInput ? t('commonText.invalidAmount') : 'Asset Amount'}
                    testId="amount-to-offer"
                    invalidInput={invalidSelectedAssetInput}
                />
                <AssetSummary asset={selectedAsset} toSpend={assetAmount.length === 0 ? 0 : parseFloat(assetAmount)} />
            </Paper>
        </div>
    );
};

export default AssetAmountsStep;
