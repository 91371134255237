import { Asset, AssetResponse, Bond } from '@r3/cbdc-asset-frontend-core';

import React from 'react';
import { createCtx } from 'contexts/createCtx';
import useGetAssetDefinitions from 'hooks/GetAssetDefinitions';
import useGetAssetSummary from 'hooks/GetAssetSummary';
import useGetBondsSummary from 'hooks/GetBondsSummary';

type LandingPageContextProps = {
    assets: Asset[];
    getAllAssets: (dontTrack?: boolean | undefined) => Promise<boolean>;
    assetsError: string;
    getAssetSummary: (dontTrack?: boolean | undefined) => Promise<void>;
    assetSummary: AssetResponse[];
    bonds: Bond;
    getAllBondsData: (dontTrack?: boolean | undefined) => Promise<void>;
    summaryError: string;
};

const [useLandingPageContext, Provider] = createCtx<LandingPageContextProps>();

export const LandingPageContextProvider: React.FunctionComponent = ({ children }) => {
    const { assets, getAllAssets, assetsError } = useGetAssetDefinitions();
    const { getAssetSummary, assetSummary, summaryError } = useGetAssetSummary();
    const { bonds, getAllBondsData } = useGetBondsSummary();

    return (
        <Provider
            value={{
                assets,
                getAllAssets,
                assetsError,
                getAssetSummary,
                assetSummary,
                bonds,
                getAllBondsData,
                summaryError,
            }}
        >
            {children}
        </Provider>
    );
};

export default useLandingPageContext;
