import { Dialog, DialogContent, DialogTitle, Divider, Slide } from '@material-ui/core';

import { CheckListAction } from '../../constants/InteractiveChecklistList';
import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions';
import { TutorialText } from './TutorialText';
import { useDialogStyles } from '@r3/cbdc-asset-frontend-core';

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type TutorialDialogProps = {
    setFocusedItem: (option: null) => void;
    focusedItem: CheckListAction | null;
    progress: number;
};

export const TutorialDialog: React.FC<TutorialDialogProps> = ({ setFocusedItem, focusedItem, progress }) => {
    const dialogStyles = useDialogStyles();
    return (
        <Dialog
            onClose={() => {
                setFocusedItem(null);
            }}
            aria-labelledby="simple-dialog-title"
            open={focusedItem !== null}
            TransitionComponent={Transition}
            classes={{ paper: dialogStyles.dialogueSbGuide }}
            style={{ top: progress === 0 ? 0 : -150 }}
        >
            <DialogTitle style={{ fontSize: 40 }} id="simple-dialog-title">
                {focusedItem?.description}
            </DialogTitle>
            <Divider className={dialogStyles.divider} />
            <DialogContent>
                {focusedItem?.steps.map((stepText, index) => {
                    return <TutorialText text={stepText} index={index} />;
                })}
            </DialogContent>
        </Dialog>
    );
};
