import { Divider, Paper, TextField, Typography } from '@material-ui/core';
import { validateContactNumber, validateEmail } from './detailsValidation';

import React from 'react';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useTranslation } from 'react-i18next';
import { NumberFormat } from '@r3/cbdc-asset-frontend-core';

type BasicDetailsProps = {
    name: string;
    email: string;
    phone: string;
    postCode: string;
    setPostCode: (str: string) => void;
    setEmail: (str: string) => void;
    setName: (str: string) => void;
    setPhone: (str: string) => void;
};

const BasicDetails: React.FC<BasicDetailsProps> = (props) => {
    const { name, email, phone, postCode, setEmail, setName, setPhone, setPostCode } = props;
    const layoutClasses = useLayoutStyles();
    const { t } = useTranslation();

    const updateFormInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const val = event.target.value;
        if (event.target.name === 'Name') {
            setName(val);
        } else if (event.target.name === 'Email') {
            setEmail(val.toLowerCase());
        } else if (event.target.name === 'Phone') {
            if (val.length > 0 && !NumberFormat.isValidInteger(val)) {
                return;
            }
            setPhone(val);
        } else if (event.target.name === 'PostCode') {
            setPostCode(val);
        }
    };

    return (
        <div className={`${layoutClasses.componentWrapper} `}>
            <Paper elevation={5} className={`${layoutClasses.column} `}>
                <Typography className={`${layoutClasses.sectionLabel}`}>{t('retail.basicDetailStep')}</Typography>
                <Divider className={layoutClasses.summaryDivider} />
                <TextField
                    autoFocus
                    className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} nameInput`}
                    type={'text'}
                    label={name.length === 0 ? 'Please enter a valid name' : 'Name'}
                    value={name}
                    name="Name"
                    variant="filled"
                    onChange={(event) => {
                        updateFormInput(event);
                    }}
                    error={name.length === 0}
                    required
                    data-testid={'name-input'}
                />
                <TextField
                    className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} emailInput`}
                    label={!validateEmail(email) ? 'Please enter a valid email' : 'Email'}
                    type={'email'}
                    value={email}
                    name="Email"
                    variant="filled"
                    onChange={(event) => {
                        updateFormInput(event);
                    }}
                    error={!validateEmail(email)}
                    required
                    data-testid={'email-input'}
                />
                <TextField
                    className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} contactNumberInput`}
                    label={!validateContactNumber(phone) ? 'Please enter a valid phone number' : 'Phone Number'}
                    value={phone}
                    name="Phone"
                    variant="filled"
                    onChange={(event) => {
                        updateFormInput(event);
                    }}
                    error={!validateContactNumber(phone)}
                    required
                    data-testid={'phone-input'}
                />
                <TextField
                    className={`${layoutClasses.formInput} ${layoutClasses.formInputSmall} postCodeInput`}
                    type={'text'}
                    label={postCode.length === 0 ? 'Please enter a post code' : 'Post Code'}
                    value={postCode}
                    name="PostCode"
                    variant="filled"
                    onChange={(event) => {
                        updateFormInput(event);
                    }}
                    error={postCode.length === 0}
                    required
                    data-testid={'postcode-input'}
                />
            </Paper>
        </div>
    );
};

export default BasicDetails;
