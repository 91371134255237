import { CreatorFilterState, OrderStatusFilterState, OrderTableData } from './types';
import { DASHBOARDANIMATIONENTERMS, DASHBOARDANIMATIONEXITMS } from 'constants/Animations';
import { HEAD_CELLS, ORDER_STATUS_FILTERS } from './constants';
import { applyDataTransform, shouldDisplayOrder } from './offerLogUtils';
import { useEffect, useState } from 'react';

import { EnhancedTable } from 'components/commonComponents/Table/Table';
import ManageExchangeOrderDialog from '../ManageOrders/OffersListComponents/ManageExchangeOrderDialog';
import OfferLogTableFilters from './OfferLogTableFilters';
import { Order } from 'models/orders/Order';
import { Slide } from '@material-ui/core';
import { Order as SortingOrder } from 'components/commonComponents/Table/sorting';
import useGetExchangeOrders from 'hooks/DecentralizedExchange/useGetExchangeOffers';
import useGetOurOwnIdentity from 'hooks/GetOurOwnIdentity';
import { useInteractiveChecklistContext } from 'contexts/InteractiveChecklist/InteractiveChecklist';
import { useLayoutStyles } from 'materialStyles/layoutStyles';
import { useOfferLogStyles } from 'materialStyles/decentralizedExchange/offerLogStyles';
import { useTranslation } from 'react-i18next';

const constructStatusFilterObject = (): OrderStatusFilterState => {
    let obj: OrderStatusFilterState = {};
    ORDER_STATUS_FILTERS.forEach((filter) => (obj[filter.key] = true));
    return obj;
};

const OfferLogTable: React.FC = () => {
    const { identity } = useGetOurOwnIdentity(true);
    const { orders, getOrders } = useGetExchangeOrders();
    const { t: labels } = useTranslation();
    const layoutClasses = useLayoutStyles();
    const offerLogClasses = useOfferLogStyles();
    const [page, setPage] = useState<number>(0);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);
    const interactiveCheckListContext = useInteractiveChecklistContext();

    const [creatorFilterState, setCreatorFilterState] = useState<CreatorFilterState>({
        thisBank: true,
        otherBanks: true,
    });
    const [orderStatusFilterState, setOrderStatusFilterState] = useState<OrderStatusFilterState>(
        constructStatusFilterObject()
    );

    const handleCreatorFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreatorFilterState({ ...creatorFilterState, [event.target.name]: event.target.checked });
    };

    const handleOrderStatusFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrderStatusFilterState({ ...orderStatusFilterState, [event.target.name]: event.target.checked });
    };

    useEffect(() => {
        interactiveCheckListContext?.completeStep(window.location.pathname);
    }, [interactiveCheckListContext]);

    useEffect(() => {
        if (identity.length === 0) {
            return;
        }
        if (!creatorFilterState.thisBank && creatorFilterState.otherBanks) {
            getOrders({ excludeCreator: identity, relevancyStatus: 'RELEVANT' });
        } else if (creatorFilterState.thisBank && !creatorFilterState.otherBanks) {
            getOrders({ identity: identity, relevancyStatus: 'RELEVANT' });
        } else if (!creatorFilterState.thisBank && !creatorFilterState.otherBanks) {
            getOrders({ excludeCreator: identity, identity: identity, relevancyStatus: 'RELEVANT' });
        } else {
            getOrders({ relevancyStatus: 'RELEVANT' });
        }
    }, [getOrders, identity, creatorFilterState, orderStatusFilterState]);

    const applyDataFilter = (orders: Order[], orderStatusFilterState: OrderStatusFilterState): Order[] => {
        return orders.filter((order) => shouldDisplayOrder(order, orderStatusFilterState));
    };

    const handleTableRowClick = (row: OrderTableData) => {
        const selectedOrder = orders.find((order) => order.orderId === row.orderId);
        if (selectedOrder === undefined) return;
        setSelectedOrder(selectedOrder);
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setSelectedOrder(undefined);
    };

    const dataToDisplay = applyDataTransform(applyDataFilter(orders, orderStatusFilterState), labels);

    return (
        <div className={layoutClasses.componentWrapper}>
            <OfferLogTableFilters
                orderStatusFilterState={orderStatusFilterState}
                creatorFilterState={creatorFilterState}
                onCreatorFilterChange={handleCreatorFilterChange}
                onOrderStatusFilterChange={handleOrderStatusFilterChange}
                identity={identity}
            />
            <Slide
                direction="left"
                in={true}
                timeout={{ enter: DASHBOARDANIMATIONENTERMS, exit: DASHBOARDANIMATIONEXITMS }}
            >
                <div
                    className={`${layoutClasses.column} ${layoutClasses.columnNoPadding} ${layoutClasses.columnFill} ${offerLogClasses.tableWrapper}`}
                >
                    <EnhancedTable<OrderTableData>
                        defaultSortBy={'lastUpdated'}
                        defaultOrder={SortingOrder.Descending}
                        headCells={HEAD_CELLS}
                        onClickRow={handleTableRowClick}
                        totalResults={dataToDisplay.length}
                        page={page}
                        setPage={setPage}
                        data={dataToDisplay}
                    />
                </div>
            </Slide>
            {selectedOrder && (
                <ManageExchangeOrderDialog isOpen={modalOpen} order={selectedOrder} onClose={handleClose} />
            )}
        </div>
    );
};

export default OfferLogTable;
