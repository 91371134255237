import { useEffect, useState } from 'react';

import { axiosInstance } from '../api/apiConfig';
import { resolvePromise } from '../api/resolvePromise';
import { trackPromise } from 'react-promise-tracker';

const LOGINTIMEOUT = 30000;

export function useBasicAuth() {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [loginError, setError] = useState<string>('');

    useEffect(() => {
        const testApiConnection = async () => {
            const apiResponse = await resolvePromise(
                trackPromise(axiosInstance.get('api/test', { timeout: LOGINTIMEOUT }))
            );

            if (apiResponse.error) {
                console.log(apiResponse.error);
                setError(apiResponse.error.toString());
                setIsLoggedIn(false);
            } else {
                setIsLoggedIn(true);
            }
        };
        testApiConnection();
    }, []);

    return { isLoggedIn, loginError };
}
