import { useCallback, useState } from 'react';

import { AssetRequest } from '@r3/cbdc-asset-frontend-core';
import { ResolvedPromise } from '../api/resolvePromise';
import { requestAllAssetRequests } from 'api/transactApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export function useGetAssetsRequests() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [error, setError] = useState<string>('');
    const [assetRequests, setAssetRequests] = useState<AssetRequest[]>([]);

    const getAssetRequests = useCallback(
        async (dontTrack?: boolean) => {
            const assetRequestResponse: ResolvedPromise = await requestAllAssetRequests();
            if (assetRequestResponse.error) {
                if (dontTrack) {
                    return;
                }
                enqueueSnackbar(t('error.gettingAssetRequests', { error: assetRequestResponse.error }), {
                    variant: 'error',
                });
                setError(assetRequestResponse.error.length > 0 ? assetRequestResponse.error : t('error.networkError'));
            } else {
                if (assetRequestResponse.data.length <= 0) {
                    const warningText = t('No Pull Transfer (Asset) Requests');
                    if (!dontTrack) {
                        enqueueSnackbar(warningText, {
                            variant: 'info',
                        });
                        setError(warningText);
                    }
                }
                setAssetRequests(assetRequestResponse.data as AssetRequest[]);
            }
        },
        [t, enqueueSnackbar]
    );

    return { assetRequests, getAssetRequests, error };
}
